import { combineReducers } from "redux";

// Front
import LayoutReducer from "./layouts/reducer";

//Crm
import CrmReducer from "./crm/reducer";

//Campaign
import CampaignReducer from "./campaign/reducer";

//Posts
import PostReducer from "./posts/reducer";

//News
import NewsReducer from "./news/reducer";

//NewsPosts
import NewsPostsReducer from "./newsposts/reducer";

// Authentication
import LoginReducer from "./auth/login/reducer";
import AccountReducer from "./auth/register/reducer";
import ForgetPasswordReducer from "./auth/forgetpwd/reducer";
import ProfileReducer from "./auth/profile/reducer";

// Dashboard Campaign
import DashboardCampaignReducer from "./dashboardCampaign/reducer";

const rootReducer = combineReducers({
    Campaign: CampaignReducer,
    Post: PostReducer,
    News: NewsReducer,
    NewsPosts: NewsPostsReducer,
    Layout: LayoutReducer,
    Login: LoginReducer,
    Account: AccountReducer,
    ForgetPassword: ForgetPasswordReducer,
    Profile: ProfileReducer,
    Crm: CrmReducer,
    DashboardCampaign: DashboardCampaignReducer
});

export default rootReducer;