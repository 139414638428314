import { createSlice } from "@reduxjs/toolkit";
import { getNews, addNewNews, updateNews, deleteNews } from './thunk';

export const initialState = {
  news: [],
  error: {}
};

const newsSlice = createSlice({
  name: "news",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getNews.fulfilled, (state, action) => {
      state.news = action.payload;
      state.isNewsFail = false;
      state.isNewsSuccess = true;
    });

    builder.addCase(getNews.rejected, (state, action) => {
      state.error = action.payload.error || null;
      state.isNewsFail = true;
      state.isNewsSuccess = false;
    });

    builder.addCase(addNewNews.fulfilled, (state, action) => {
      state.news.push(action.payload);
      state.isNewsAdd = true;
      state.isNewsAddFail = false;
    });

    builder.addCase(addNewNews.rejected, (state, action) => {
      state.error = action.payload.error || null;
      state.isNewsAdd = false;
      state.isNewsAddFail = true;
    });

    builder.addCase(updateNews.fulfilled, (state, action) => {
      state.news = state.news.map(news =>
        news._id.toString() === action.payload._id.toString()
          ? { ...news, ...action.payload }
          : news
      );
      state.isNewsUpdate = true;
      state.isNewsUpdateFail = false;
    });

    builder.addCase(updateNews.rejected, (state, action) => {
      state.error = action.payload.error || null;
      state.isNewsUpdate = false;
      state.isNewsUpdateFail = true;
    });

    builder.addCase(deleteNews.fulfilled, (state, action) => {
      state.news = (state.news || []).filter((news) => news._id.toString() !== action.payload.news.toString());

      state.isNewsDelete = true;
      state.isNewsDeleteFail = false;
    });

    builder.addCase(deleteNews.rejected, (state, action) => {
      state.error = action.payload.error || null;
      state.isNewsDelete = false;
      state.isNewsDeleteFail = true;
    });

  },
});

export default newsSlice.reducer;