import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  getNews as getNewsApi,    
  addNewNews as addNewNewsApi,
  updateNews as updateNewsApi,
  deleteNews as deleteNewsApi
} from "../../helpers/realbackend_helper";

export const getNews = createAsyncThunk("news/getNews" , async () => {
  try{
    const response = await getNewsApi()
    return response;
  }catch (error) {
    return error;
  }
})

export const addNewNews = createAsyncThunk("news/addNewNews" , async (news) => {
  try{
    const response = addNewNewsApi(news)
    toast.success("Мэдээний веб сайтыг амжилттай нэмлээ", { autoClose: 3000 });
    return response;
  }catch (error) {
    toast.error("Мэдээний веб сайтыг нэмэхэд алдаа гарлаа!", { autoClose: 3000 });
    return error;
  }
})

export const updateNews = createAsyncThunk("news/updateNews" , async (news) => {
  try{
    const response = updateNewsApi(news)
    toast.success("Мэдээний веб сайтыг амжилттай засварлалаа", { autoClose: 3000 });
    return response;
  }catch (error) {
    toast.error("Мэдээний веб сайтыг засварлахад алдаа гарлаа!", { autoClose: 3000 });
    return error;
  }
})

export const deleteNews = createAsyncThunk("news/deleteNews" , async (news) => {
  try{
    const response = deleteNewsApi(news)
    toast.success("Мэдээний веб сайтыг амжилттай устгалаа", { autoClose: 3000 });
    return { news, ...response };
  }catch (error) {
    toast.error("Мэдээний веб сайтыг устгахад алдаа гарлаа!", { autoClose: 3000 });
    return error;
  }
})
