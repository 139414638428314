import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Navdata = () => {
    const history = useNavigate();
    
    //state data
    const [isDashboard, setIsDashboard] = useState(false);
    const [isUsers, setIsUsers] = useState(false);
    const [isAuth, setIsAuth] = useState(false);
    const [isPages, setIsPages] = useState(false);
    const [isBaseUi, setIsBaseUi] = useState(false);
    const [isAdvanceUi, setIsAdvanceUi] = useState(false);
    const [isForms, setIsForms] = useState(false);
    const [isTables, setIsTables] = useState(false);
    const [isCharts, setIsCharts] = useState(false);
    const [isIcons, setIsIcons] = useState(false);
    const [isMaps, setIsMaps] = useState(false);
    const [isMultiLevel, setIsMultiLevel] = useState(false);

    //Campaign
    const [isCampaigns, setIsCampaigns] = useState(false);
    // Users    
    const [isCRM, setIsCRM] = useState(false);
    const [isCrypto, setIsCrypto] = useState(false);    

    //News
    const [isNews, setIsNews] = useState(false);

    //Television
    const [isTelevision, setIsTelevision] = useState(false);

    //Newspaper
    const [isNewspaper, setIsNewspaper] = useState(false);

    //Magazine
    const [isMagazine, setIsMagazine] = useState(false);

    //Election
    const [isElection2024, setIsElection2024] = useState(false);

    // Authentication
    const [isSignIn, setIsSignIn] = useState(false);
    const [isSignUp, setIsSignUp] = useState(false);
    const [isPasswordReset, setIsPasswordReset] = useState(false);
    const [isPasswordCreate, setIsPasswordCreate] = useState(false);
    const [isLockScreen, setIsLockScreen] = useState(false);
    const [isLogout, setIsLogout] = useState(false);
    const [isSuccessMessage, setIsSuccessMessage] = useState(false);
    const [isVerification, setIsVerification] = useState(false);
    const [isError, setIsError] = useState(false);

    // Pages
    const [isProfile, setIsProfile] = useState(false);

    // Charts
    const [isApex, setIsApex] = useState(false);

    const [iscurrentState, setIscurrentState] = useState("Dashboard");

    function updateIconSidebar(e) {
        if (e && e.target && e.target.getAttribute("subitems")) {
            const ul = document.getElementById("two-column-menu");
            const iconItems = ul.querySelectorAll(".nav-icon.active");
            let activeIconItems = [...iconItems];
            activeIconItems.forEach((item) => {
                item.classList.remove("active");
                var id = item.getAttribute("subitems");
                if (document.getElementById(id))
                    document.getElementById(id).classList.remove("show");
            });
        }
    }

    useEffect(() => {
        document.body.classList.remove("twocolumn-panel");
        if (iscurrentState !== "Dashboard") {
            setIsDashboard(false);
        }
        if (iscurrentState !== "Users") {
            setIsUsers(false);
        }
        if (iscurrentState !== "Campaigns") {
            setIsCampaigns(false);
        }
        if (iscurrentState !== "Auth") {
            setIsAuth(false);
        }
        if (iscurrentState !== "Pages") {
            setIsPages(false);
        }
        if (iscurrentState !== "BaseUi") {
            setIsBaseUi(false);
        }
        if (iscurrentState !== "AdvanceUi") {
            setIsAdvanceUi(false);
        }
        if (iscurrentState !== "Forms") {
            setIsForms(false);
        }
        if (iscurrentState !== "Tables") {
            setIsTables(false);
        }
        if (iscurrentState !== "Charts") {
            setIsCharts(false);
        }
        if (iscurrentState !== "Icons") {
            setIsIcons(false);
        }
        if (iscurrentState !== "Maps") {
            setIsMaps(false);
        }
        if (iscurrentState === "Widgets") {
            history("/widgets");
            document.body.classList.add("twocolumn-panel");
        }
    }, [
        history,
        iscurrentState,
        isDashboard,
        isCampaigns,
        isUsers,
        isAuth,
        isPages,
        isBaseUi,
        isAdvanceUi,
        isForms,
        isTables,
        isCharts,
        isIcons,
        isMaps,
        isMultiLevel,
    ]);

    const menuItems = [
        {
            label: "Үндсэн цэс",
            isHeader: true,
        },
        {
            id: "campaigns",
            label: "Нэгдсэн судалгаа",
            icon: "bx bx-layer",
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsCampaigns(!isCampaigns);
                setIscurrentState("Campaigns");
                updateIconSidebar(e);
            },
            stateVariables: isCampaigns,
            subItems: [ 
                {
                    id: "campaignsControl",
                    label: "Удирдлага",
                    link: "/campaigns",
                    parentId: "campaigns",
                },
                {
                    id: "campaignsList",
                    label: "Судалгааны жагсаалт",
                    link: "/projects",
                    parentId: "campaigns",
                }
            ],
        },        
        {
            id: "news",
            label: "Мэдээний веб сайт",
            icon: "bx bx-news",
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsNews(!isNews);
                setIscurrentState("News");
                updateIconSidebar(e);
            },
            stateVariables: isNews,
            subItems: [ 
                {
                    id: "newsControl",
                    label: "Удирдлага",
                    link: "/newslist",
                    parentId: "news",
                },
                {
                    id: "newsPosts",
                    label: "Мэдээний веб сайтын нийтлэлүүд",
                    link: "/news",
                    parentId: "news",
                }
            ],
        },      
        {
            id: "television",
            label: "Телевиз",
            icon: "bx bx-tv",
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsTelevision(!isTelevision);
                setIscurrentState("Television");
                updateIconSidebar(e);
            },
            stateVariables: isTelevision,
            subItems: [ 
                {
                    id: "tvControl",
                    label: "Удирдлага",
                    link: "/tvlist",
                    parentId: "television",
                },
                {
                    id: "tvAgenda",
                    label: "Телевизийн хөтөлбөр",
                    link: "/tvagenda",
                    parentId: "television",
                },
                {
                    id: "tvNews",
                    label: "Телевизийн мэдээ",
                    link: "/tv",
                    parentId: "television",
                }
            ],
        },    
        {
            id: "newspaper",
            label: "Сонин хэвлэл",
            icon: "bx bxs-news",
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsNewspaper(!isNewspaper);
                setIscurrentState("Newspaper");
                updateIconSidebar(e);
            },
            stateVariables: isNewspaper,
            subItems: [ 
                {
                    id: "newspaperControl",
                    label: "Удирдлага",
                    link: "/newspaperlist",
                    parentId: "newspaper",
                },               
                {
                    id: "newspaperNews",
                    label: "Сонин хэвлэлийн мэдээ",
                    link: "/newspaperNews",
                    parentId: "newspaper",
                }
            ],
        },   
        {
            id: "magazine",
            label: "Сэтгүүл",
            icon: "bx bx-images",
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsMagazine(!isMagazine);
                setIscurrentState("Magazine");
                updateIconSidebar(e);
            },
            stateVariables: isMagazine,
            subItems: [ 
                {
                    id: "magazineControl",
                    label: "Удирдлага",
                    link: "/magazinelist",
                    parentId: "magazine",
                },               
                {
                    id: "magazineNews",
                    label: "Сэтгүүлийн мэдээ",
                    link: "/magazineNews",
                    parentId: "magazine",
                }
            ],
        },   
        {
            id: "election2024",
            label: "Сонгууль 2024",
            icon: "bx bx-news",
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsElection2024(!isElection2024);
                setIscurrentState("election2024");
                updateIconSidebar(e);
            },
            stateVariables: isElection2024,
            subItems: [ 
                {
                    id: "election2024Control",
                    label: "Удирдлага",
                    link: "/election2024control",
                    parentId: "election2024",
                },
                {
                    id: "newsPosts",
                    label: "Сонгуулийн мэдээлэл",
                    link: "/election2024list",
                    parentId: "election2024",
                }
            ],
        },    
        {
            label: "Тохиргоо",
            isHeader: true,
        },
        {
            id: "users",
            label: "Хэрэглэгчид",
            icon: "bx bx-user-circle",
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsUsers(!isUsers);
                setIscurrentState("Users");
                updateIconSidebar(e);
            },
            stateVariables: isUsers,
            subItems: [   
                {
                    id: "userscrm",
                    label: "Хэрэглэгчдийн жагсаалт",
                    link: "/users",
                    parentId: "users",
                }
            ],
        }
    ];

    const menuItemResricted = [
        {
            label: "Үндсэн цэс",
            isHeader: true,
        },
        {
            id: "campaigns",
            label: "Судалгаа",
            icon: "bx bx-layer",
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsCampaigns(!isCampaigns);
                setIscurrentState("Campaigns");
                updateIconSidebar(e);
            },
            stateVariables: isCampaigns,
            subItems: [ 
                {
                    id: "campaignsControl",
                    label: "Удирдлага",
                    link: "/campaigns",
                    parentId: "campaigns",
                },
                // {
                //     id: "campaignsList",
                //     label: "Судалгааны жагсаалт",
                //     link: "/projects",
                //     parentId: "campaigns",
                // }
            ],
        }
    ];

    return <React.Fragment>{JSON.parse(localStorage.getItem("authUser")).role === "SuperAdmin" ? menuItems : menuItemResricted}</React.Fragment>;
};
export default Navdata;
