import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { CardBody, Row, Col, Card, Table, CardHeader, Container, Label, Form, Input, Badge } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { useLocation, Navigate, Link } from "react-router-dom";

import logoDark from "../../../assets/images/logo-dark.png";
import logoLight from "../../../assets/images/logo-light.png";
import * as moment from "moment";
import 'moment-timezone';
import ShowMoreText from "react-show-more-text";

import {
    StackedColumn,
    DiffTotalPositiveLocation,
    DiffTotalNegativeLocation,
    DataTotalPositiveLocation,
    DataTotalPositiveLocationBasic,
    DataTotalNegativeLocationBasic,
    DataTotalUBLocationBasic,
    MultipleRadar,
    StackedNegativeByGender,
    PositiveGenderComparison,
    NegativeGenderComparison,
    NegativeGenderByAge,
    RealFakeData,
    StackedGenderByAge,
    StackedUBLocation,
    StackedTotalLocation
} from "./ColumnCharts";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

import Flatpickr from "react-flatpickr";
import ReactPaginate from "react-paginate";
import Rating from "react-rating";
import FeatherIcon from "feather-icons-react";

import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { ReportEngagementsCharts, ReportEngagementsPNCharts, ReportTotalSourceCharts } from "./ReportEngagementsCharts";
import { useSelector, useDispatch } from "react-redux";
import { getRPTStatsPosts as onRPTGetStatsPosts,
        getRPTTotalStatsPosts as onRPTGetTotalStatsPosts,
        getTop10Posts as onGetTop10Posts, 
        getRPTStatsFBPosts as onGetRPTStatsFBPosts,
        getRPTStatsTWPosts as onGetRPTStatsTWPosts,
        getRPTStatsNWPosts as onGetRPTStatsNWPosts,
        getRPTStatsIGPosts as onGetRPTStatsIGPosts } from "../../../slices/thunks";

import { isEmpty } from "lodash";
import ImgGif2 from "../../../assets/images/nft/gif/img-5.gif";

import CountUp from "react-countup";
import { Spinner } from 'reactstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dummyImg from "../../../assets/images/users/user-dummy-img.jpg";

import like from "../../../assets/images/emojis/liked.png";
import love from "../../../assets/images/emojis/love.png";
import care from "../../../assets/images/emojis/care.png";
import haha from "../../../assets/images/emojis/haha.png";
import wow from "../../../assets/images/emojis/wow.png";
import sad from "../../../assets/images/emojis/sad.png";
import angry from "../../../assets/images/emojis/angry.png";
import heart from "../../../assets/images/emojis/heart.png";
import poop from "../../../assets/images/emojis/poop.png";

import male from "../../../assets/images/users/male.png";
import female from "../../../assets/images/users/female.png";
import real from "../../../assets/images/users/real.png";
import fake from "../../../assets/images/users/fake.png";

const Report = ({ campaignId }) => {
  const location = useLocation();
  if (!location.state) {
      return <Navigate to="/" />;
  }

  const dispatch = useDispatch();
  const [stats, setStats] = useState([]);
  const [totalstats, setTotalStats] = useState([]);
  const [chartData, setchartData] = useState([]);

  const [postsID, setPostsID] = useState([]);
  const [totalPosts, setTotalPosts] = useState([]);
  const [totalLikes, setTotalLikes] = useState([]);
  const [totalComments, setTotalComments] = useState([]);
  const [totalShares, setTotalShares] = useState([]);
  const [top10Post, setTop10Post] = useState(null);

  const [totalPostCount, setTotalPostCount] = useState(0);
  const [totalLikesCount, setTotalLikesCount] = useState(0);
  const [totalCommentsCount, setTotalCommentsCount] = useState(0);
  const [totalSharesCount, setTotalSharesCount] = useState(0);
  const [totalPositiveLikesCount, setTotalPositiveLikesCount] = useState(0);
  const [totalNegativeLikesCount, setTotalNegativeLikesCount] = useState(0);

  const [timePeriod, setTimePeriod] = useState("1 сарын");

  const [totalFBPostCount, setTotalFBPostCount] = useState(0);
  const [totalTWPostCount, setTotalTWPostCount] = useState(0);
  const [totalNWPostCount, setTotalNWPostCount] = useState(0);
  const [totalIGPostCount, setTotalIGPostCount] = useState(0);

  const [FBstats, setFBStats] = useState([]);
  const [TWstats, setTWStats] = useState([]);
  const [NWstats, setNWStats] = useState([]);
  const [IGstats, setIGStats] = useState([]);

  const [totalFBPositiveLikesCount, setTotalFBPositiveLikesCount] = useState(0);
  const [totalFBNegativeLikesCount, setTotalFBNegativeLikesCount] = useState(0);
  const [totalTWPositiveLikesCount, setTotalTWPositiveLikesCount] = useState(0);
  const [totalTWNegativeLikesCount, setTotalTWNegativeLikesCount] = useState(0);
  const [totalNWPositiveLikesCount, setTotalNWPositiveLikesCount] = useState(0);
  const [totalNWNegativeLikesCount, setTotalNWNegativeLikesCount] = useState(0);
  const [totalIGPositiveLikesCount, setTotalIGPositiveLikesCount] = useState(0);
  const [totalIGNegativeLikesCount, setTotalIGNegativeLikesCount] = useState(0);

  const [totalPMale, setTotalPMale] = useState([]);
  const [totalPFemale, setTotalPFemale] = useState([]);
  const [totalNMale, setTotalNMale] = useState([]);
  const [totalNFemale, setTotalNFemale] = useState([]);

  const [genderPositiveM, setGenderPositiveM] = useState([]);
  const [genderPositiveFM, setGenderPositiveFM] = useState([]);

  const [genderNegativeM, setGenderNegativeM] = useState([]);
  const [genderNegativeFM, setGenderNegativeFM] = useState([]);

  const [totalPositiveLocation, setTotalPositiveLocation] = useState([]);
  const [totalNegativeLocation, setTotalNegativeLocation] = useState([]);

  const [totalPositiveLocationData, setTotalPositiveLocationData] = useState([]);
  const [totalNegativeLocationData, setTotalNegativeLocationData] = useState([]);

  const [totalPositiveUBLocation, setTotalPositiveUBLocation] = useState([]);
  const [totalNegativeUBLocation, setTotalNegativeUBLocation] = useState([]);

  const [totalPositiveUBLocationData, setTotalPositiveUBLocationData] = useState([]);
  const [totalNegativeUBLocationData, setTotalNegativeUBLocationData] = useState([]);

  const [totalPositiveRealFakeData, setTotalPositiveRealFakeData] = useState([]);
  const [totalNegativeRealFakeData, setTotalNegativeRealFakeData] = useState([]);

  const { top10posts, isTop10PostSuccess, top10error, rptstatsposts, isRPTStatsPostSuccess, rptstatserror,
    rpttotalstatsposts, isRPTTotalStatsPostSuccess, rpttotalstatserror,
    rptstatsFBposts, isRPTStatsFBPostSuccess, rptstatsFBerror,
    rptstatsTWposts, isRPTStatsTWPostSuccess, rptstatsTWserror,
    rptstatsNWposts, isRPTStatsNWPostSuccess, rptstatsNWserror,
    rptstatsIGposts, isRPTStatsIGPostSuccess, rptstatsIGserror } = useSelector((state) => ({    
    
    top10posts: state.Post.top10posts,
    isTop10PostSuccess: state.Post.isTop10PostSuccess,
    top10error: state.Post.top10error,

    rptstatsposts: state.Post.rptstatsposts,
    isRPTStatsPostSuccess: state.Post.isRPTStatsPostSuccess,
    rptstatserror: state.Post.rptstatserror,

    rpttotalstatsposts: state.Post.rpttotalstatsposts,
    isRPTTotalStatsPostSuccess: state.Post.isRPTTotalStatsPostSuccess,
    rpttotalstatserror: state.Post.rpttotalstatserror,

    rptstatsFBposts: state.Post.rptstatsFBposts,
    isRPTStatsFBPostSuccess: state.Post.isRPTStatsFBPostSuccess,
    rptstatsFBerror: state.Post.rptstatsFBerror,

    rptstatsTWposts: state.Post.rptstatsTWposts,
    isRPTStatsTWPostSuccess: state.Post.isRPTStatsTWPostSuccess,
    rptstatsTWerror: state.Post.rptstatsTWserror,

    rptstatsNWposts: state.Post.rptstatsNWposts,
    isRPTStatsNWPostSuccess: state.Post.isRPTStatsNWPostSuccess,
    rptstatsNWerror: state.Post.rptstatsNWserror,

    rptstatsIGposts: state.Post.rptstatsIGposts,
    isRPTStatsIGPostSuccess: state.Post.isRPTStatsIGPostSuccess,
    rptstatsIGerror: state.Post.rptstatsIGserror
    }));


  document.title = `Тайлан - ${location.state.campaignName} | IKHNUDEN - Media Monitoring System`;

  const handleValidDate = date => {
    const date1 = moment(new Date(date)).format("Y-MM-DD");
    return date1;
  };

  const [dateSearchFrom, setDateValidFrom] = useState(handleValidDate(new Date()));
  const [dateSearchTo, setDateValidTo] = useState(handleValidDate(new Date()));

  const dateformaterValidFrom = (e) => {
    const date = e.toString().split(" ");
    const joinDate = (date[3] + "-" + date[1] + "-" + date[2]).toString();
    const joined = moment(joinDate).format("YYYY-MM-DD");
    setDateValidFrom(joined);
  };

  const dateformaterValidTo = (e) => {
        const date = e.toString().split(" ");
        const joinDate = (date[3] + "-" + date[1] + "-" + date[2]).toString();
        const joined = moment(joinDate).format("YYYY-MM-DD");
        setDateValidTo(joined);
  };

  useEffect(() => {
        try {
            const params = {
                taskId: location.state.campaignId,
                source: "all",
                startDate: handleValidDate(new Date()),
                endDate: handleValidDate(new Date()),
            };

            //console.log(params);

            dispatch(onRPTGetStatsPosts(params));
        }
        catch {
            return <Navigate to="/" />;
        }    
        
  }, [dispatch]);

  useEffect(() => {
    try {
        const totalparams = {
            taskId: location.state.campaignId,
            startDate: handleValidDate(new Date()),
            endDate: handleValidDate(new Date()),
        };
    
        dispatch(onRPTGetTotalStatsPosts(totalparams));
    }
    catch {
        return <Navigate to="/" />;
    }    
    
}, [dispatch]);

useEffect(() => {
    if (!isEmpty(rptstatsposts)) {
        setStats(rptstatsposts);        
    }
    if (!isEmpty(top10posts)) {
        setTop10Post(top10posts);        
    }
    if (!isEmpty(rpttotalstatsposts)) {
        setTotalStats(rpttotalstatsposts);        
    }
    if (!isEmpty(rptstatsFBposts)) {
        setFBStats(rptstatsFBposts);        
    }
    if (!isEmpty(rptstatsTWposts)) {
        setTWStats(rptstatsTWposts);        
    }
    if (!isEmpty(rptstatsNWposts)) {
        setNWStats(rptstatsNWposts);        
    }
    if (!isEmpty(rptstatsIGposts)) {
        setIGStats(rptstatsIGposts);        
    }
}, [rptstatsposts, rpttotalstatsposts, top10posts]);

// useEffect(() => {
//     try {
//         const rptparams = {
//             taskId: location.state.campaignId,
//             startDate: handleValidDate(new Date()),
//             endDate: handleValidDate(new Date()),
//         };

//         dispatch(onGetRPTPosts(rptparams));
//     }
//     catch {
//         return <Navigate to="/" />;
//     }       
// }, [dispatch]);

useEffect(() => {
    setTop10Post(top10posts); 
}, [top10posts, isTop10PostSuccess]);

useEffect(() => {
    try {
        setPostsID(rptstatsposts?.map((item) => item._id)); 
        setTotalPosts(rptstatsposts?.map((item) => item.totalPost));  
        setTotalLikes(rptstatsposts?.map((item) => item.totalLikes));  
        setTotalComments(rptstatsposts?.map((item) => item.totalComments));  
        setTotalShares(rptstatsposts?.map((item) => item.totalShares));  
    
        let postCount = 0;
        let likesCount = 0;
        let commentsCount = 0;
        let sharesCount = 0;
        let positiveLikesCount = 0;
        let negativeLikesCount = 0;
        let FBpositiveLikesCount = 0;
        let FBnegativeLikesCount = 0;
        let TWpositiveLikesCount = 0;
        let TWnegativeLikesCount = 0;
        let NWpositiveLikesCount = 0;
        let NWnegativeLikesCount = 0;
        let IGpositiveLikesCount = 0;
        let IGnegativeLikesCount = 0;
    
        rptstatsposts?.map((item) => {
          postCount = postCount + parseInt(item.totalPost);
          likesCount = likesCount + parseInt(item.totalLikes);
          commentsCount = commentsCount + parseInt(item.totalComments);
          sharesCount = sharesCount + parseInt(item.totalShares);
          positiveLikesCount = positiveLikesCount + parseInt(item.totalPositiveLikes);
          negativeLikesCount = negativeLikesCount + parseInt(item.totalNegativeLikes);
        })
        setTotalPostCount(postCount);
        setTotalLikesCount(likesCount);
        setTotalCommentsCount(commentsCount);
        setTotalSharesCount(sharesCount);
        setTotalPositiveLikesCount(positiveLikesCount);
        setTotalNegativeLikesCount(negativeLikesCount);
    
        setTotalFBPostCount(0);
        setTotalTWPostCount(0);
        setTotalNWPostCount(0);
        setTotalIGPostCount(0);

        rpttotalstatsposts?.map((item) => {
            if(item.totalFBCount)
            {
                setTotalFBPostCount(item.totalFBCount);
            }
            if(item.totalTWCount)
            {
                setTotalTWPostCount(item.totalTWCount);
            }
            if(item.totalNWCount)
            {
                setTotalNWPostCount(item.totalNWCount);
            }
            if(item.totalIGCount)
            {
                setTotalIGPostCount(item.totalIGCount);
            }
        });

        rptstatsFBposts?.map((item) => {           
            FBpositiveLikesCount = FBpositiveLikesCount + parseInt(item.totalPositiveLikes);
            FBnegativeLikesCount = FBnegativeLikesCount + parseInt(item.totalNegativeLikes);
          })
       
        setTotalFBPositiveLikesCount(FBpositiveLikesCount);
        setTotalFBNegativeLikesCount(FBnegativeLikesCount);

        rptstatsTWposts?.map((item) => {           
            TWpositiveLikesCount = TWpositiveLikesCount + parseInt(item.totalPositiveLikes);
            TWnegativeLikesCount = TWnegativeLikesCount + parseInt(item.totalNegativeLikes);
          })
       
        setTotalTWPositiveLikesCount(TWpositiveLikesCount);
        setTotalTWNegativeLikesCount(TWnegativeLikesCount);

        rptstatsNWposts?.map((item) => {           
            NWpositiveLikesCount = NWpositiveLikesCount + parseInt(item.totalPositiveLikes);
            NWnegativeLikesCount = NWnegativeLikesCount + parseInt(item.totalNegativeLikes);
          })
       
        setTotalNWPositiveLikesCount(NWpositiveLikesCount);
        setTotalNWNegativeLikesCount(NWnegativeLikesCount);

        rptstatsIGposts?.map((item) => {        
            IGpositiveLikesCount = IGpositiveLikesCount + parseInt(item.totalLikes);
            IGnegativeLikesCount = IGnegativeLikesCount + parseInt(item.totalNegativeLikes);
          })
       
        setTotalIGPositiveLikesCount(IGpositiveLikesCount);
        setTotalIGNegativeLikesCount(IGnegativeLikesCount);

        let totalPositive = FBpositiveLikesCount + TWpositiveLikesCount + IGpositiveLikesCount;
        let totalNegative = FBnegativeLikesCount + TWnegativeLikesCount + IGnegativeLikesCount;

        if((totalPositive > 0 && totalNegative > 0) || (totalPositive > 0 && totalNegative == 0) || (totalPositive == 0 && totalNegative > 0))
        {
          let positiveMale = location.state.campaignDataAnalyze.positive.age.male;
          let positiveFemale = location.state.campaignDataAnalyze.positive.age.female;
  
          let negativeMale = location.state.campaignDataAnalyze.negative.age.male;
          let negativeFemale = location.state.campaignDataAnalyze.negative.age.female;
  
          // calculating positive by gender
          let pm13_17 = Math.floor(totalPositive * positiveMale.a13_17 /100);
          let pm18_24 = Math.floor(totalPositive * positiveMale.a18_24 /100);
          let pm25_34 = Math.floor(totalPositive * positiveMale.a25_34 /100);
          let pm35_44 = Math.floor(totalPositive * positiveMale.a35_44 /100);
          let pm45_54 = Math.floor(totalPositive * positiveMale.a45_54 /100);
          let pm55_64 = Math.floor(totalPositive * positiveMale.a55_64 /100);
          let pm65plus = Math.floor(totalPositive * positiveMale.a65plus /100);
  
          let pfm13_17 = Math.floor(totalPositive * positiveFemale.a13_17 /100);
          let pfm18_24 = Math.floor(totalPositive * positiveFemale.a18_24 /100);
          let pfm25_34 = Math.floor(totalPositive * positiveFemale.a25_34 /100);
          let pfm35_44 = Math.floor(totalPositive * positiveFemale.a35_44 /100);
          let pfm45_54 = Math.floor(totalPositive * positiveFemale.a45_54 /100);
          let pfm55_64 = Math.floor(totalPositive * positiveFemale.a55_64 /100);
          let pfm65plus = Math.floor(totalPositive * positiveFemale.a65plus /100);
  
          let pmale = [pm13_17, pm18_24, pm25_34, pm35_44, pm45_54, pm55_64, pm65plus];
          let pfemale = [pfm13_17, pfm18_24, pfm25_34, pfm35_44, pfm45_54, pfm55_64, pfm65plus];
  
          setGenderPositiveM(pmale);
          setGenderPositiveFM(pfemale);
  
          // calculating negative by gender      
          let nm13_17 = Math.floor(totalNegative * negativeMale.a13_17 /100);
          let nm18_24 = Math.floor(totalNegative * negativeMale.a18_24 /100);
          let nm25_34 = Math.floor(totalNegative * negativeMale.a25_34 /100);
          let nm35_44 = Math.floor(totalNegative * negativeMale.a35_44 /100);
          let nm45_54 = Math.floor(totalNegative * negativeMale.a45_54 /100);
          let nm55_64 = Math.floor(totalNegative * negativeMale.a55_64 /100);
          let nm65plus = Math.floor(totalNegative * negativeMale.a65plus /100);
  
          let nfm13_17 = Math.floor(totalNegative * negativeFemale.a13_17 /100);
          let nfm18_24 = Math.floor(totalNegative * negativeFemale.a18_24 /100);
          let nfm25_34 = Math.floor(totalNegative * negativeFemale.a25_34 /100);
          let nfm35_44 = Math.floor(totalNegative * negativeFemale.a35_44 /100);
          let nfm45_54 = Math.floor(totalNegative * negativeFemale.a45_54 /100);
          let nfm55_64 = Math.floor(totalNegative * negativeFemale.a55_64 /100);
          let nfm65plus = Math.floor(totalNegative * negativeFemale.a65plus /100);
  
          let nmale = [nm13_17, nm18_24, nm25_34, nm35_44, nm45_54, nm55_64, nm65plus];
          let nfemale = [nfm13_17, nfm18_24, nfm25_34, nfm35_44, nfm45_54, nfm55_64, nfm65plus];
  
          setGenderNegativeM(nmale);
          setGenderNegativeFM(nfemale);
  
          const pmaleSum = pmale.reduce((previousValue, currentValue, index) => previousValue + currentValue, 0);
          setTotalPMale(Math.floor(pmaleSum));
  
          const nmaleSum = nmale.reduce((previousValue, currentValue, index) => previousValue + currentValue, 0);
          setTotalNMale(Math.floor(nmaleSum));
  
          const pfemaleSum = pfemale.reduce((previousValue, currentValue, index) => previousValue + currentValue, 0);
          setTotalPFemale(Math.floor(pfemaleSum));
  
          const nfemaleSum = nfemale.reduce((previousValue, currentValue, index) => previousValue + currentValue, 0);
          setTotalNFemale(Math.floor(nfemaleSum));
  
          // calculating positive by location
          let pUlaanbaatar = location.state.campaignDataAnalyze.positive.Total_location.Ulaanbaatar;
          let pArkhangai = location.state.campaignDataAnalyze.positive.Total_location.Arkhangai;
          let pBayan_Ulgii = location.state.campaignDataAnalyze.positive.Total_location.Bayan_Ulgii;
          let pBayankhongor = location.state.campaignDataAnalyze.positive.Total_location.Bayankhongor;
          let pBulgan = location.state.campaignDataAnalyze.positive.Total_location.Bulgan;
          let pGovi_Altai = location.state.campaignDataAnalyze.positive.Total_location.Govi_Altai;
          let pGovisumber = location.state.campaignDataAnalyze.positive.Total_location.Govisumber;
          let pDarkhan_Uul = location.state.campaignDataAnalyze.positive.Total_location.Darkhan_Uul;
          let pDornogovi = location.state.campaignDataAnalyze.positive.Total_location.Dornogovi;
          let pDornod = location.state.campaignDataAnalyze.positive.Total_location.Dornod;
          let pDundgovi = location.state.campaignDataAnalyze.positive.Total_location.Dundgovi;
          let pZavkhan = location.state.campaignDataAnalyze.positive.Total_location.Zavkhan;
          let pOrkhon = location.state.campaignDataAnalyze.positive.Total_location.Orkhon;
          let pUvurkhangai = location.state.campaignDataAnalyze.positive.Total_location.Uvurkhangai;
          let pUmnugovi = location.state.campaignDataAnalyze.positive.Total_location.Umnugovi;
          let pSukhbaatar = location.state.campaignDataAnalyze.positive.Total_location.Sukhbaatar;
          let pSelenge = location.state.campaignDataAnalyze.positive.Total_location.Selenge;
          let pTuv = location.state.campaignDataAnalyze.positive.Total_location.Tuv;
          let pUvs = location.state.campaignDataAnalyze.positive.Total_location.Uvs;
          let pKhovd = location.state.campaignDataAnalyze.positive.Total_location.Khovd;
          let pKhuvsgul = location.state.campaignDataAnalyze.positive.Total_location.Khuvsgul;
          let pKhentii = location.state.campaignDataAnalyze.positive.Total_location.Khentii;
          let pGadaad = location.state.campaignDataAnalyze.positive.Total_location.Gadaad;
  
          const totalPLocation = [{
                data: [{
                    x: 'Улаанбаатар',
                    y:  Math.floor(totalPositive * pUlaanbaatar /100)
                },
                {
                    x: 'Архангай',
                    y:  Math.floor(totalPositive * pArkhangai /100)
                },
                {
                    x: 'Баян-Өлгий',
                    y: Math.floor(totalPositive * pBayan_Ulgii /100)
                },
                {
                    x: 'Баянхонгор',
                    y: Math.floor(totalPositive * pBayankhongor /100)
                },
                {
                    x: 'Булган',
                    y: Math.floor(totalPositive * pBulgan /100)
                },
                {
                    x: 'Говь-Алтай',
                    y: Math.floor(totalPositive * pGovi_Altai /100)
                },
                {
                    x: 'Говьсүмбэр',
                    y: Math.floor(totalPositive * pGovisumber /100)
                },
                {
                    x: 'Дархан-Уул',
                    y: Math.floor(totalPositive * pDarkhan_Uul /100)
                },
                {
                    x: 'Дорноговь',
                    y: Math.floor(totalPositive * pDornogovi /100)
                },
                {
                    x: 'Дорнод',
                    y: Math.floor(totalPositive * pDornod /100)
                },
                {
                    x: 'Дундговь',
                    y: Math.floor(totalPositive * pDundgovi /100)
                },
                {
                    x: 'Завхан',
                    y: Math.floor(totalPositive * pZavkhan /100)
                },
                {
                    x: 'Орхон',
                    y: Math.floor(totalPositive * pOrkhon /100)
                },
                {
                    x: 'Өвөрхангай',
                    y: Math.floor(totalPositive * pUvurkhangai /100)
                },
                {
                    x: 'Өмнөговь',
                    y: Math.floor(totalPositive * pUmnugovi /100)
                },
                {
                    x: 'Сүхбаатар',
                    y: Math.floor(totalPositive * pSukhbaatar /100)
                },
                {
                    x: 'Сэлэнгэ',
                    y: Math.floor(totalPositive * pSelenge /100)
                },
                {
                    x: 'Төв',
                    y: Math.floor(totalPositive * pTuv /100)
                },
                {
                    x: 'Увс',
                    y: Math.floor(totalPositive * pUvs /100)
                },
                {
                    x: 'Ховд',
                    y: Math.floor(totalPositive * pKhovd /100)
                },
                {
                    x: 'Хөвсгөл',
                    y: Math.floor(totalPositive * pKhuvsgul /100)
                },
                {
                    x: 'Хэнтий',
                    y: Math.floor(totalPositive * pKhentii /100)
                },
                {
                    x: 'Гадаад улс',
                    y: Math.floor(totalPositive * pGadaad /100)
                }         
                ]
          }]
  
          setTotalPositiveLocation(totalPLocation);
  
          let totalPositiveLocationData = [
                  Math.floor(totalPositive * pUlaanbaatar /100), 
                  Math.floor(totalPositive * pArkhangai /100), 
                  Math.floor(totalPositive * pBayan_Ulgii /100), 
                  Math.floor(totalPositive * pBayankhongor /100), 
                  Math.floor(totalPositive * pBulgan /100), 
                  Math.floor(totalPositive * pGovi_Altai /100), 
                  Math.floor(totalPositive * pGovisumber /100), 
                  Math.floor(totalPositive * pDarkhan_Uul /100), 
                  Math.floor(totalPositive * pDornogovi /100), 
                  Math.floor(totalPositive * pDornod /100), 
                  Math.floor(totalPositive * pDundgovi /100), 
                  Math.floor(totalPositive * pZavkhan /100), 
                  Math.floor(totalPositive * pOrkhon /100), 
                  Math.floor(totalPositive * pUvurkhangai /100), 
                  Math.floor(totalPositive * pUmnugovi /100), 
                  Math.floor(totalPositive * pSukhbaatar /100), 
                  Math.floor(totalPositive * pSelenge /100), 
                  Math.floor(totalPositive * pTuv /100), 
                  Math.floor(totalPositive * pUvs /100), 
                  Math.floor(totalPositive * pKhovd /100), 
                  Math.floor(totalPositive * pKhuvsgul /100), 
                  Math.floor(totalPositive * pKhentii /100),
                  Math.floor(totalPositive * pGadaad /100)
          ];
  
          setTotalPositiveLocationData(totalPositiveLocationData);
  
          // calculating positive by location
          let nUlaanbaatar = location.state.campaignDataAnalyze.negative.Total_location.Ulaanbaatar;
          let nArkhangai = location.state.campaignDataAnalyze.negative.Total_location.Arkhangai;
          let nBayan_Ulgii = location.state.campaignDataAnalyze.negative.Total_location.Bayan_Ulgii;
          let nBayankhongor = location.state.campaignDataAnalyze.negative.Total_location.Bayankhongor;
          let nBulgan = location.state.campaignDataAnalyze.negative.Total_location.Bulgan;
          let nGovi_Altai = location.state.campaignDataAnalyze.negative.Total_location.Govi_Altai;
          let nGovisumber = location.state.campaignDataAnalyze.negative.Total_location.Govisumber;
          let nDarkhan_Uul = location.state.campaignDataAnalyze.negative.Total_location.Darkhan_Uul;
          let nDornogovi = location.state.campaignDataAnalyze.negative.Total_location.Dornogovi;
          let nDornod = location.state.campaignDataAnalyze.negative.Total_location.Dornod;
          let nDundgovi = location.state.campaignDataAnalyze.negative.Total_location.Dundgovi;
          let nZavkhan = location.state.campaignDataAnalyze.negative.Total_location.Zavkhan;
          let nOrkhon = location.state.campaignDataAnalyze.negative.Total_location.Orkhon;
          let nUvurkhangai = location.state.campaignDataAnalyze.negative.Total_location.Uvurkhangai;
          let nUmnugovi = location.state.campaignDataAnalyze.negative.Total_location.Umnugovi;
          let nSukhbaatar = location.state.campaignDataAnalyze.negative.Total_location.Sukhbaatar;
          let nSelenge = location.state.campaignDataAnalyze.negative.Total_location.Selenge;
          let nTuv = location.state.campaignDataAnalyze.negative.Total_location.Tuv;
          let nUvs = location.state.campaignDataAnalyze.negative.Total_location.Uvs;
          let nKhovd = location.state.campaignDataAnalyze.negative.Total_location.Khovd;
          let nKhuvsgul = location.state.campaignDataAnalyze.negative.Total_location.Khuvsgul;
          let nKhentii = location.state.campaignDataAnalyze.negative.Total_location.Khentii;
          let nGadaad = location.state.campaignDataAnalyze.negative.Total_location.Gadaad;
  
          const totalNLocation = [{
                data: [{
                    x: 'Улаанбаатар',
                    y:  Math.floor(totalNegative * nUlaanbaatar /100)
                },
                {
                    x: 'Архангай',
                    y:  Math.floor(totalNegative * nArkhangai /100)
                },
                {
                    x: 'Баян-Өлгий',
                    y: Math.floor(totalNegative * nBayan_Ulgii /100)
                },
                {
                    x: 'Баянхонгор',
                    y: Math.floor(totalNegative * nBayankhongor /100)
                },
                {
                    x: 'Булган',
                    y: Math.floor(totalNegative * nBulgan /100)
                },
                {
                    x: 'Говь-Алтай',
                    y: Math.floor(totalNegative * nGovi_Altai /100)
                },
                {
                    x: 'Говьсүмбэр',
                    y: Math.floor(totalNegative * nGovisumber /100)
                },
                {
                    x: 'Дархан-Уул',
                    y: Math.floor(totalNegative * nDarkhan_Uul /100)
                },
                {
                    x: 'Дорноговь',
                    y: Math.floor(totalNegative * nDornogovi /100)
                },
                {
                    x: 'Дорнод',
                    y: Math.floor(totalNegative * nDornod /100)
                },
                {
                    x: 'Дундговь',
                    y: Math.floor(totalNegative * nDundgovi /100)
                },
                {
                    x: 'Завхан',
                    y: Math.floor(totalNegative * nZavkhan /100)
                },
                {
                    x: 'Орхон',
                    y: Math.floor(totalNegative * nOrkhon /100)
                },
                {
                    x: 'Өвөрхангай',
                    y: Math.floor(totalNegative * nUvurkhangai /100)
                },
                {
                    x: 'Өмнөговь',
                    y: Math.floor(totalNegative * nUmnugovi /100)
                },
                {
                    x: 'Сүхбаатар',
                    y: Math.floor(totalNegative * nSukhbaatar /100)
                },
                {
                    x: 'Сэлэнгэ',
                    y: Math.floor(totalNegative * nSelenge /100)
                },
                {
                    x: 'Төв',
                    y: Math.floor(totalNegative * nTuv /100)
                },
                {
                    x: 'Увс',
                    y: Math.floor(totalNegative * nUvs /100)
                },
                {
                    x: 'Ховд',
                    y: Math.floor(totalNegative * nKhovd /100)
                },
                {
                    x: 'Хөвсгөл',
                    y: Math.floor(totalNegative * nKhuvsgul /100)
                },
                {
                    x: 'Хэнтий',
                    y: Math.floor(totalNegative * nKhentii /100)
                },
                {
                    x: 'Гадаад улс',
                    y: Math.floor(totalNegative * nGadaad /100)
                }           
                ]
          }]
  
          setTotalNegativeLocation(totalNLocation);
  
          let totalNegativeLocationData = [
                  Math.floor(totalNegative * nUlaanbaatar /100), 
                  Math.floor(totalNegative * nArkhangai /100), 
                  Math.floor(totalNegative * nBayan_Ulgii /100), 
                  Math.floor(totalNegative * nBayankhongor /100), 
                  Math.floor(totalNegative * nBulgan /100), 
                  Math.floor(totalNegative * nGovi_Altai /100), 
                  Math.floor(totalNegative * nGovisumber /100), 
                  Math.floor(totalNegative * nDarkhan_Uul /100), 
                  Math.floor(totalNegative * nDornogovi /100), 
                  Math.floor(totalNegative * nDornod /100), 
                  Math.floor(totalNegative * nDundgovi /100), 
                  Math.floor(totalNegative * nZavkhan /100), 
                  Math.floor(totalNegative * nOrkhon /100), 
                  Math.floor(totalNegative * nUvurkhangai /100), 
                  Math.floor(totalNegative * nUmnugovi /100), 
                  Math.floor(totalNegative * nSukhbaatar /100), 
                  Math.floor(totalNegative * nSelenge /100), 
                  Math.floor(totalNegative * nTuv /100), 
                  Math.floor(totalNegative * nUvs /100), 
                  Math.floor(totalNegative * nKhovd /100), 
                  Math.floor(totalNegative * nKhuvsgul /100), 
                  Math.floor(totalNegative * nKhentii /100),
                  Math.floor(totalNegative * nGadaad /100)
          ];
  
          setTotalNegativeLocationData(totalNegativeLocationData);
  
          // calculating positive by UB location
          let totalPositiveUB = Math.floor(totalPositive * pUlaanbaatar /100)
          let totalNegativeUB = Math.floor(totalNegative * nUlaanbaatar /100)
  
          let pCHD = location.state.campaignDataAnalyze.positive.UB_location.CHD;
          let pKHUD = location.state.campaignDataAnalyze.positive.UB_location.KHUD;
          let pSBD = location.state.campaignDataAnalyze.positive.UB_location.SBD;
          let pBGD = location.state.campaignDataAnalyze.positive.UB_location.BGD;
          let pSKHD = location.state.campaignDataAnalyze.positive.UB_location.SKHD;
          let pBZD = location.state.campaignDataAnalyze.positive.UB_location.BZD;
          let pNalaikh = location.state.campaignDataAnalyze.positive.UB_location.Nalaikh;
          let pBaganuur = location.state.campaignDataAnalyze.positive.UB_location.Baganuur;
          let pBagakhangai = location.state.campaignDataAnalyze.positive.UB_location.Bagakhangai;
  
          let cCHD = Math.floor(totalPositiveUB * pCHD /100);
          let cKHUD = Math.floor(totalPositiveUB * pKHUD /100);
          let cSBD = Math.floor(totalPositiveUB * pSBD /100);
          let cBGD = Math.floor(totalPositiveUB * pBGD /100);
          let cSKHD = Math.floor(totalPositiveUB * pSKHD /100);
          let cBZD = Math.floor(totalPositiveUB * pBZD /100);
          let cNalaikh = Math.floor(totalPositiveUB * pNalaikh /100);
          let cBaganuur = Math.floor(totalPositiveUB * pBaganuur /100);
          let cBagakhangai = Math.floor(totalPositiveUB * pBagakhangai /100);
  
          const totalPUBLocation = [{
                data: [{
                    x: 'Чингэлтэй',
                    y:  cCHD
                },
                {
                    x: 'Хан-Уул',
                    y:  cKHUD
                },
                {
                    x: 'Сүхбаатар',
                    y: cSBD
                },
                {
                    x: 'Баянгол',
                    y: cBGD
                },
                {
                    x: 'Сонгинохайрхан',
                    y: cSKHD
                },
                {
                    x: 'Баянзүрх',
                    y: cBZD
                },
                {
                    x: 'Налайх',
                    y: cNalaikh
                },
                {
                    x: 'Багануур',
                    y: cBaganuur
                },
                {
                    x: 'Багахангай',
                    y: cBagakhangai
                }   
                ]
          }]
  
          setTotalPositiveUBLocation(totalPUBLocation);
  
          let totalPositiveUBLocationData = [cCHD, cKHUD, cSBD, cBGD, cSKHD, cBZD, cNalaikh, cBaganuur, cBagakhangai];
  
          setTotalPositiveUBLocationData(totalPositiveUBLocationData);
  
          // calculating negative by UB location
          let nCHD = location.state.campaignDataAnalyze.negative.UB_location.CHD;
          let nKHUD = location.state.campaignDataAnalyze.negative.UB_location.KHUD;
          let nSBD = location.state.campaignDataAnalyze.negative.UB_location.SBD;
          let nBGD = location.state.campaignDataAnalyze.negative.UB_location.BGD;
          let nSKHD = location.state.campaignDataAnalyze.negative.UB_location.SKHD;
          let nBZD = location.state.campaignDataAnalyze.negative.UB_location.BZD;
          let nNalaikh = location.state.campaignDataAnalyze.negative.UB_location.Nalaikh;
          let nBaganuur = location.state.campaignDataAnalyze.negative.UB_location.Baganuur;
          let nBagakhangai = location.state.campaignDataAnalyze.negative.UB_location.Bagakhangai;
  
          let dCHD = Math.floor(totalNegativeUB * nCHD /100);
          let dKHUD = Math.floor(totalNegativeUB * nKHUD /100);
          let dSBD = Math.floor(totalNegativeUB * nSBD /100);
          let dBGD = Math.floor(totalNegativeUB * nBGD /100);
          let dSKHD = Math.floor(totalNegativeUB * nSKHD /100);
          let dBZD = Math.floor(totalNegativeUB * nBZD /100);
          let dNalaikh = Math.floor(totalNegativeUB * nNalaikh /100);
          let dBaganuur = Math.floor(totalNegativeUB * nBaganuur /100);
          let dBagakhangai = Math.floor(totalNegativeUB * nBagakhangai /100);
  
          const totalNUBLocation = [{
                data: [{
                    x: 'Чингэлтэй',
                    y:  dCHD
                },
                {
                    x: 'Хан-Уул',
                    y:  dKHUD
                },
                {
                    x: 'Сүхбаатар',
                    y: dSBD
                },
                {
                    x: 'Баянгол',
                    y: dBGD
                },
                {
                    x: 'Сонгинохайрхан',
                    y: dSKHD
                },
                {
                    x: 'Баянзүрх',
                    y: dBZD
                },
                {
                    x: 'Налайх',
                    y: dNalaikh
                },
                {
                    x: 'Багануур',
                    y: dBaganuur
                },
                {
                    x: 'Багахангай',
                    y: dBagakhangai
                }   
                ]
          }]
  
          setTotalNegativeUBLocation(totalNUBLocation);
  
          let totalNegativeUBLocationData = [dCHD, dKHUD, dSBD, dBGD, dSKHD, dBZD, dNalaikh, dBaganuur, dBagakhangai];
  
          setTotalNegativeUBLocationData(totalNegativeUBLocationData);
  
          // calculating real or fake
          let positiveReal = location.state.campaignDataAnalyze.positive.real_fake.real;
          let negativeReal = location.state.campaignDataAnalyze.negative.real_fake.real;
  
          let positiveFake = location.state.campaignDataAnalyze.positive.real_fake.fake;
          let negativeFake = location.state.campaignDataAnalyze.negative.real_fake.fake;
  
          let positiveUnknown = location.state.campaignDataAnalyze.positive.real_fake.unknown;
          let negativeUnknown = location.state.campaignDataAnalyze.negative.real_fake.unknown;
  
          // calculating positive real or fake
          let cpositiveReal = Math.floor(totalPositive * positiveReal /100);
          let cpositiveFake = Math.floor(totalPositive * positiveFake /100);
          let cpositiveUnknown = Math.floor(totalPositive * positiveUnknown /100);
  
          let totalPRealFakeData = [cpositiveReal, cpositiveFake, cpositiveUnknown];
  
          setTotalPositiveRealFakeData(totalPRealFakeData);
  
          // calculating negative real or fake
          let cnegativeReal = Math.floor(totalNegative * negativeReal /100);
          let cnegativeFake = Math.floor(totalNegative * negativeFake /100);
          let cnegativeUnknown = Math.floor(totalNegative * negativeUnknown /100);
    
          let totalNRealFakeData = [cnegativeReal, cnegativeFake, cnegativeUnknown];
    
          setTotalNegativeRealFakeData(totalNRealFakeData);
        }
    }   
    catch {
        return <Navigate to="/" />;
    }    

}, [rptstatsposts, rpttotalstatsposts]);

  
  //Print the Invoice
  const printInvoice = () => {
    window.print();
  };

    const handleValidTime = (time) => {
        const time1 = new Date(time);
        const getHour = time1.getUTCHours();
        const getMin = time1.getUTCMinutes();
        const getTime = `${getHour}:${getMin}`;
        const updateTime = moment.utc(getTime, 'HH:mm').format('HH:mm');
        return updateTime;
    };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      searchFrom: dateSearchFrom,
      searchTo: dateSearchTo,    
    },
    validationSchema: Yup.object({     
        searchFrom: Yup.date().default(() => new Date()),
        searchTo: Yup
            .date()
            .when(
            'searchFrom',
            (searchFrom, schema) => (searchFrom && schema.min(searchFrom, 'Дуусах огноо эхлэх огнооноос баг байж болохгүй')),
            )
    }),
    onSubmit: (values) => {  
        
        setDateValidFrom(values["searchFrom"]);
        setDateValidTo(values["searchTo"]);
        
        // console.log(dateSearchFrom)
        // console.log(dateSearchTo)
        
        const params = {
            taskId: location.state.campaignId,
            source: "all",
            startDate: dateSearchFrom,
            endDate: dateSearchTo,
        };

        dispatch(onRPTGetStatsPosts(params));

        const totalparams = {
            taskId: location.state.campaignId,
            startDate: dateSearchFrom,
            endDate: dateSearchTo,
        };
    
        dispatch(onRPTGetTotalStatsPosts(totalparams));

        // const rptparams = {
        //     taskId: location.state.campaignId,
        //     startDate: dateSearchFrom,
        //     endDate: dateSearchTo,
        //     page: "1"              
        // };

        // dispatch(onGetRPTPosts(rptparams));
        
        const genParams = {
            taskId: location.state.campaignId,
            startDate: dateSearchFrom,
            endDate: dateSearchTo,
            text: "Нийт",
            limit: "30"
        };

        dispatch(onGetTop10Posts(genParams));

        const paramsFB = {
            taskId: location.state.campaignId,
            source: "facebook",
            startDate: dateSearchFrom,
            endDate: dateSearchTo,
        };
    
        dispatch(onGetRPTStatsFBPosts(paramsFB));

        const paramsTW = {
            taskId: location.state.campaignId,
            source: "twitter",
            startDate: dateSearchFrom,
            endDate: dateSearchTo,
        };
    
        dispatch(onGetRPTStatsTWPosts(paramsTW));

        const paramsNW = {
            taskId: location.state.campaignId,
            source: "news",
            startDate: dateSearchFrom,
            endDate: dateSearchTo,
        };
    
        dispatch(onGetRPTStatsNWPosts(paramsNW));

        const paramsIG = {
            taskId: location.state.campaignId,
            source: "instagram",
            startDate: dateSearchFrom,
            endDate: dateSearchTo,
        };
    
        dispatch(onGetRPTStatsIGPosts(paramsIG));

        validation.resetForm();
    },
  });

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="ИХНҮДЭН" pageTitle="Тайлан" />

            <Row className="justify-content-center">
                <Col xxl={9}>
                    <Card>
                        <CardBody>
                            <Row className="g-3">                
                                <div className="hstack gap-2 justify-content-end d-print-none mt-4">
                                <Form className="tablelist-form" onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                            }}>
                            <Input type="hidden" id="id-field" />
                                <Row className="g-3">  
                                <Col lg={4}>
                                    <div>
                                    <Label
                                        htmlFor="searchFrom"
                                        className="form-label"
                                    >
                                        Эхлэх огноо
                                    </Label>

                                    <Flatpickr
                                        name="searchFrom"
                                        id="searchFrom-input"
                                        className="form-control"
                                        placeholder="Огноо сонгох"
                                        options={{
                                            altInput: true,
                                            altFormat: "Y-m-d",
                                            dateFormat: "Y-m-d",
                                        }}
                                        value={validation.values.searchFrom}
                                        onChange={(e) => {
                                            dateformaterValidFrom(e);
                                            validation.setFieldValue("searchFrom", e);
                                        }                                    
                                        }
                                        onBlur={validation.handleBlur} 
                                        invalid={
                                            validation.touched.searchFrom && validation.errors.searchFrom ? true : false
                                        }                       
                                        />
                                        {validation.touched.searchFrom && validation.errors.searchFrom ? (
                                            <FormFeedback type="invalid">{validation.errors.searchFrom}</FormFeedback>
                                        ) : null}           
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div>
                                    <Label
                                        htmlFor="validTo"
                                        className="form-label"
                                    >
                                        Дуусах огноо
                                    </Label>

                                    <Flatpickr
                                        name="searchTo"
                                        id="searchTo-input"
                                        className="form-control"
                                        placeholder="Огноо сонгох"
                                        options={{
                                            altInput: true,
                                            altFormat: "Y-m-d",
                                            dateFormat: "Y-m-d",
                                        }}
                                        value={validation.values.searchTo}
                                        onChange={(e) => {
                                            dateformaterValidTo(e);
                                            validation.setFieldValue("searchTo", e);
                                        }                                    
                                        }
                                        onBlur={validation.handleBlur}
                                        invalid={
                                            validation.touched.searchTo && validation.errors.searchTo ? true : false
                                        }                              
                                        /> 
                                        {validation.touched.searchTo && validation.errors.searchTo ? (
                                            <FormFeedback type="invalid">{validation.errors.searchTo}</FormFeedback>
                                        ) : null}           
                                    </div>
                                </Col> 
                                <Col lg={3}>
                                    <div>
                                    <Label
                                        htmlFor="validTo"
                                        className="form-label"
                                    >
                                        Тайлан бэлтгэх
                                    </Label>

                                        <button
                                            type="submit"
                                            className="btn btn-primary w-100"
                                        >
                                            <i className="ri-download-2-line align-bottom me-1"></i>{" "}
                                            Бэлтгэх
                                        </button>
                                    </div>
                                </Col> 
                            
                                </Row>                      
                            </Form>

                                        <Col lg={2}>
                                            <div>
                                                        <Label
                                                            htmlFor="searchTo"
                                                            className="form-label"
                                                        >
                                                        Тайлан хэвлэх /PDF татах/
                                                        </Label>           
                                                        <Link
                                                                to="#"
                                                                onClick={printInvoice}
                                                                className="btn btn-success"
                                                        >
                                                                <i className="ri-printer-line align-bottom me-1"></i> Хэвлэх
                                                        </Link> 
                                            </div>
                                        </Col>
                                        
                                </div>
                            </Row>
                        </CardBody>
                    </Card>

                    <Card id="demo">
                    <Row>
                        <Col lg={12}>
                        <CardHeader className="border-bottom-dashed p-4">
                            <div className="d-sm-flex">
                            <div className="flex-grow-1">
                                <img
                                src={logoDark}
                                className="card-logo card-logo-dark"
                                alt="logo dark"
                                height="32"
                                />
                                <img
                                src={logoLight}
                                className="card-logo card-logo-light"
                                alt="logo light"
                                height="32"
                                />                      
                            </div>
                            <div className="flex-grow-1">
                                <h3>Судалгааны тайлан</h3>                                                              
                            </div>
                            <div className="flex-shrink-0 mt-sm-0 mt-3">                      
                                <h6>
                                <span className="text-muted fw-normal">E-mail:</span>{" "}
                                <span id="email">info@ikhnuden.mn</span>
                                </h6>
                                <h6>
                                <span className="text-muted fw-normal">Веб сайт:</span>{" "}
                                <Link to="http://ikhnuden.mn" className="link-primary" id="website">
                                    ikhnuden.mn
                                </Link>
                                </h6>
                                {/* <h6>
                                <span className="text-muted fw-normal">Утас:</span>{" "}
                                <span id="contact-no"> +(976) 9-------</span>
                                </h6>                                 */}
                            </div>
                            </div>
                        </CardHeader>
                        </Col>                       
                        <Col lg={12}>               
                        <CardBody className="p-4">
                            <Row className="g-3">                      
                            <Col lg={4} xs={6}>                        
                                <div className="avatar-lg">
                                            <img src={location.state.campaignImage} alt="user-img"
                                                className="img-thumbnail rounded-circle" />
                                </div>                         
                            </Col>
                            <Col lg={4} xs={6}>
                                <p className="text-muted mb-2 text-uppercase fw-semibold">
                                Судалгааны нэр
                                </p>
                                <h5 className="fs-14 mb-0">
                                <span id="invoice-date">{location.state.campaignName}</span>
                                </h5>
                            </Col>
                            <Col lg={4} xs={6}>
                                <p className="text-muted mb-2 text-uppercase fw-semibold">
                                Тайлант хугацаа
                                </p>
                                <h5 className="fs-14 mb-0">
                                    {handleValidDate(dateSearchFrom)} - {handleValidDate(dateSearchTo)}   
                                                                  
                                </h5>                        
                            </Col>                         
                            </Row>
                        </CardBody>
                        </Col>
                        <Col lg={9}>
                        <CardBody className="p-4 border-top border-top-dashed">
                            <Row>
                                <Col xxl={12}>
                                        <Card>
                                            <CardBody className="p-0">
                                                <Row className="g-0">
                                                <Col xxl={12}>
                                                        <div className="">
                                                            <CardHeader className="border-0 align-items-center d-flex">
                                                                <h4 className="card-title mb-0 flex-grow-1">Олон нийтийн хандлага</h4>                                            
                                                            </CardHeader>
                                                            <Row className="g-0 text-center">
                                                                <Col sm={3} className="col-6">
                                                                    <div className="p-3 border border-dashed border-start-0">
                                                                        <h5 className="mb-1">
                                                                            <span className="counter-value" data-target="36.48">
                                                                                <h4 className="fs-20 ff-secondary fw-semibold mb-0">{totalPostCount || 0}</h4>
                                                                            </span>
                                                                        </h5>
                                                                        <p className="text-muted mb-0">Нийтлэл</p>
                                                                    </div>
                                                                </Col>
                                                                <Col sm={2} className="col-6">
                                                                    <div className="p-3 border border-dashed border-start-0">
                                                                        <h5 className="mb-1">
                                                                            <span className="counter-value" data-target="92.54">
                                                                                <h4 className="fs-20 ff-secondary fw-semibold mb-0">{totalCommentsCount || 0}</h4>
                                                                            </span>
                                                                        </h5>
                                                                        <p className="text-muted mb-0">Сэтгэгдэл</p>
                                                                    </div>
                                                                </Col>
                                                                <Col sm={2} className="col-6">
                                                                    <div className="p-3 border border-dashed border-end-0">
                                                                        <h5 className="mb-1">
                                                                            <span className="counter-value" data-target="8.62">
                                                                                <h4 className="fs-20 ff-secondary fw-semibold mb-0">{totalLikesCount || 0}</h4>
                                                                            </span>
                                                                        </h5>
                                                                        <p className="text-muted mb-0">Reactions</p>
                                                                    </div>
                                                                </Col>
                                                                <Col sm={2} className="col-6">
                                                                    <div className="p-3 border border-dashed border-end-0">
                                                                        <h5 className="mb-1">
                                                                            <span className="counter-value" data-target="8.62">
                                                                            <h4 className="fs-20 ff-secondary fw-semibold mb-0">{totalSharesCount || 0}</h4>
                                                                            </span>
                                                                        </h5>
                                                                        <p className="text-muted mb-0">Shares</p>
                                                                    </div>
                                                                </Col>
                                                                <Col sm={3} className="col-6">
                                                                    <div className="p-3 border border-dashed border-end-0">
                                                                        <h5 className="mb-1">
                                                                            <span className="counter-value" data-target="8.62">
                                                                            <h4 className="fs-20 ff-secondary fw-semibold mb-0">{Math.floor(totalSharesCount * 380.8) || 0}</h4>
                                                                            </span>
                                                                        </h5>
                                                                        <p className="text-muted mb-0">Тархалт</p>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row className="g-0 text-center">
                                                                <Col sm={12} className="col-6">
                                                                    <ReportEngagementsCharts posts={totalPosts} likes={totalLikes} comments={totalComments} shares={totalShares} categories={postsID} dataColors='["--vz-primary", "--vz-info", "--vz-warning", "--vz-danger"]' />
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Col>                                                         
                                                </Row>
                                                <Row className="g-0">                                                       
                                                    <Col xxl={6}>
                                                        <div className="border-start p-4 h-100 d-flex flex-column">
                                                            <div className="w-100">
                                                                <div className="d-flex align-items-center"> 
                                                                    <div className="avatar-sm flex-shrink-0">
                                                                                <span className="avatar-title bg-primary rounded-circle fs-2">
                                                                                    <FeatherIcon
                                                                                        icon="layers"
                                                                                    />
                                                                                </span>
                                                                    </div>                                               
                                                                    <div className="ms-3 flex-grow-1">
                                                                        <h5 className="fs-16 mb-1">Эх сурвалж</h5>
                                                                    </div>
                                                                </div>

                                                                <div className="d-flex align-items-end justify-content-between mt-4">
                                                                    <div>
                                                                        <p className="fs-14 text-muted mb-1">Facebook</p>
                                                                        <h4 className="fs-20 ff-secondary fw-semibold mb-0">{totalFBPostCount || 0}</h4>
                                                                    </div>

                                                                    <div>
                                                                        <p className="fs-14 text-muted mb-1">X/Twitter</p>
                                                                        <h4 className="fs-20 ff-secondary fw-semibold mb-0">{totalTWPostCount || 0}</h4>
                                                                    </div>
                                                                </div>

                                                                <div className="d-flex align-items-end justify-content-between mt-4">                                                
                                                                    <div>
                                                                        <p className="fs-14 text-muted mb-1">Мэдээний сайт</p>
                                                                        <h4 className="fs-20 ff-secondary fw-semibold mb-0">{totalNWPostCount || 0}</h4>
                                                                    </div>

                                                                    <div>
                                                                        <p className="fs-14 text-muted mb-1">Instagram</p>
                                                                        <h4 className="fs-20 ff-secondary fw-semibold mb-0">{totalIGPostCount || 0}</h4>
                                                                    </div>
                                                                </div>

                                                                <div className="card-body">
                                                                    <div dir="ltr">
                                                                        <ReportTotalSourceCharts fbCount={totalFBPostCount} twCount={totalTWPostCount} nwCount={totalNWPostCount} igCount={totalIGPostCount} dataColors='["--vz-primary", "--vz-info", "--vz-success", "--vz-danger"]' />
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={6}>
                                                        <div className="border-start p-4 h-100 d-flex flex-column">
                                                            <div className="w-100">                                                                
                                                                <div className="d-flex align-items-center"> 
                                                                    <div className="avatar-sm flex-shrink-0">
                                                                            <span className="avatar-title bg-primary rounded-circle fs-2">
                                                                                <FeatherIcon
                                                                                    icon="users"
                                                                                />
                                                                            </span>
                                                                    </div>                                             
                                                                    <div className="ms-3 flex-grow-1">
                                                                        <h5 className="fs-16 mb-1">Олон нийтийн хандлага</h5>                                                                    
                                                                    </div>
                                                                </div>

                                                                <div className="d-flex align-items-end justify-content-between mt-4">
                                                                    <div>
                                                                        <p className="fs-14 text-muted mb-1">Эерэг хандлага</p>
                                                                        <h4 className="fs-20 ff-secondary fw-semibold mb-0">{totalPositiveLikesCount || 0}</h4>
                                                                    </div>

                                                                    <div>
                                                                        <p className="fs-14 text-muted mb-1">Сөрөг хандлага</p>
                                                                        <h4 className="fs-20 ff-secondary fw-semibold mb-0">{totalNegativeLikesCount || 0}</h4>
                                                                    </div>
                                                                </div>

                                                                <div className="card-body">
                                                                    <div dir="ltr">
                                                                        <ReportEngagementsPNCharts positiveLikesCount={totalPositiveLikesCount} negativeLikesCount={totalNegativeLikesCount} dataColors='["--vz-success", "--vz-danger"]' />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                </Col>
                            </Row>
                    </CardBody>
                    </Col>

                  
              </Row>
            </Card>
                </Col>

                <Col xxl={9}>
                        <div className="d-flex pt-2 pb-4">
                            <div className="d-flex align-items-center"> 
                                <div className="avatar-sm flex-shrink-0">
                                            <span className="avatar-title bg-primary rounded-circle fs-2">
                                                <FeatherIcon
                                                    icon="layers"
                                                />
                                            </span>
                                </div>                                               
                                <div className="ms-3 flex-grow-1">
                                    <h5 className="fs-16 mb-1">Эх сурвалжийн хандлага</h5>
                                </div>
                            </div>
                        </div>
                        <Card>
                            <CardBody className="p-0">
                                <Row className="g-0">                                                                                         
                                    <Col xxl={3}>
                                        <div className="border-start p-4 h-100 d-flex flex-column">

                                            <div className="w-100">
                                                <div className="d-flex align-items-center">
                                                    <img src="https://firebasestorage.googleapis.com/v0/b/mindx-384a6.appspot.com/o/logos%2Ffacebook.webp?alt=media&token=edb9fe7a-90e8-460f-b118-9b46d5edaa41" className="img-fluid avatar-xs rounded-circle object-cover" alt="" />
                                                    <div className="ms-3 flex-grow-1">
                                                        <h5 className="fs-16 mb-1">Facebook</h5>                                                        
                                                    </div>
                                                </div>

                                                <div className="d-flex align-items-end justify-content-between mt-4">
                                                    <div>
                                                        <p className="fs-14 text-muted mb-1">Эерэг хандлага</p>
                                                        <h4 className="fs-20 ff-secondary fw-semibold mb-0">{totalFBPositiveLikesCount || 0}</h4>
                                                    </div>

                                                    <div>
                                                        <p className="fs-14 text-muted mb-1">Сөрөг хандлага</p>
                                                        <h4 className="fs-20 ff-secondary fw-semibold mb-0">{totalFBNegativeLikesCount || 0}</h4>
                                                    </div>
                                                </div>

                                                <div className="card-body">
                                                    <div dir="ltr">
                                                        <ReportEngagementsPNCharts positiveLikesCount={totalFBPositiveLikesCount} negativeLikesCount={totalFBNegativeLikesCount} dataColors='["--vz-success", "--vz-danger"]' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xxl={3}>
                                        <div className="border-start p-4 h-100 d-flex flex-column">

                                            <div className="w-100">
                                                <div className="d-flex align-items-center">
                                                    <img src="https://firebasestorage.googleapis.com/v0/b/mindx-384a6.appspot.com/o/logos%2Ftwitter-x-logo-101C7D2420-seeklogo.com.png?alt=media&token=2a4d65d1-b5a6-4da2-896a-d9ba41104298" className="img-fluid avatar-xs rounded-circle object-cover" alt="" />
                                                    <div className="ms-3 flex-grow-1">
                                                        <h5 className="fs-16 mb-1">X/Twitter</h5>                                                        
                                                    </div>
                                                </div>

                                                <div className="d-flex align-items-end justify-content-between mt-4">
                                                    <div>
                                                        <p className="fs-14 text-muted mb-1">Эерэг хандлага</p>
                                                        <h4 className="fs-20 ff-secondary fw-semibold mb-0">{totalTWPositiveLikesCount || 0}</h4>
                                                    </div>

                                                    <div>
                                                        <p className="fs-14 text-muted mb-1">Сөрөг хандлага</p>
                                                        <h4 className="fs-20 ff-secondary fw-semibold mb-0">{totalTWNegativeLikesCount || 0}</h4>
                                                    </div>
                                                </div>

                                                <div className="card-body">
                                                    <div dir="ltr">
                                                        <ReportEngagementsPNCharts positiveLikesCount={totalTWPositiveLikesCount} negativeLikesCount={totalTWNegativeLikesCount} dataColors='["--vz-success", "--vz-danger"]' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xxl={3}>
                                        <div className="border-start p-4 h-100 d-flex flex-column">

                                            <div className="w-100">
                                                <div className="d-flex align-items-center">
                                                    <img src="https://firebasestorage.googleapis.com/v0/b/mindx-384a6.appspot.com/o/logos%2Fnews-default.png?alt=media&token=1180b1c4-aae1-4fb9-9456-6c8bb882f0ce" className="img-fluid avatar-xs rounded-circle object-cover" alt="" />
                                                    <div className="ms-3 flex-grow-1">
                                                        <h5 className="fs-16 mb-1">Мэдээний сайт</h5>                                                        
                                                    </div>
                                                </div>

                                                <div className="d-flex align-items-end justify-content-between mt-4">
                                                    <div>
                                                        <p className="fs-14 text-muted mb-1">Эерэг хандлага</p>
                                                        <h4 className="fs-20 ff-secondary fw-semibold mb-0">{totalNWPositiveLikesCount || 0}</h4>
                                                    </div>

                                                    <div>
                                                        <p className="fs-14 text-muted mb-1">Сөрөг хандлага</p>
                                                        <h4 className="fs-20 ff-secondary fw-semibold mb-0">{totalNWNegativeLikesCount || 0}</h4>
                                                    </div>
                                                </div>

                                                <div className="card-body">
                                                    <div dir="ltr">
                                                        <ReportEngagementsPNCharts positiveLikesCount={totalNWPositiveLikesCount} negativeLikesCount={totalNWNegativeLikesCount} dataColors='["--vz-success", "--vz-danger"]' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xxl={3}>
                                        <div className="border-start p-4 h-100 d-flex flex-column">

                                            <div className="w-100">
                                                <div className="d-flex align-items-center">
                                                    <img src="https://firebasestorage.googleapis.com/v0/b/mindx-384a6.appspot.com/o/logos%2Finstagram.png?alt=media&token=d330b8a2-581b-41f5-bea0-fec35b72c83e" className="img-fluid avatar-xs rounded-circle object-cover" alt="" />
                                                    <div className="ms-3 flex-grow-1">
                                                        <h5 className="fs-16 mb-1">Instagram</h5>                                                        
                                                    </div>
                                                </div>

                                                <div className="d-flex align-items-end justify-content-between mt-4">
                                                    <div>
                                                        <p className="fs-14 text-muted mb-1">Эерэг хандлага</p>
                                                        <h4 className="fs-20 ff-secondary fw-semibold mb-0">{totalIGPositiveLikesCount || 0}</h4>
                                                    </div>

                                                    <div>
                                                        <p className="fs-14 text-muted mb-1">Сөрөг хандлага</p>
                                                        <h4 className="fs-20 ff-secondary fw-semibold mb-0">{totalIGNegativeLikesCount || 0}</h4>
                                                    </div>
                                                </div>

                                                <div className="card-body">
                                                    <div dir="ltr">
                                                        <ReportEngagementsPNCharts positiveLikesCount={totalIGPositiveLikesCount} negativeLikesCount={totalIGNegativeLikesCount} dataColors='["--vz-success", "--vz-danger"]' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                </Col>

                {   JSON.parse(localStorage.getItem("authUser")).type === "super$" &&
                                            <React.Fragment>
                                                <Col xl={6}>
                                                    <div className="d-flex pt-2 pb-4">
                                                        <div className="d-flex align-items-center"> 
                                                            <div className="avatar-sm flex-shrink-0">
                                                                        <span className="avatar-title bg-primary rounded-circle fs-2">
                                                                            <FeatherIcon
                                                                                icon="map-pin"
                                                                            />
                                                                        </span>
                                                            </div>                                               
                                                            <div className="ms-3 flex-grow-1">
                                                                <h5 className="fs-16 mb-1">Байршлын хандлага</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <Card>
                                                        <CardHeader className="align-items-center d-flex">
                                                            <h4 className="card-title mb-0 flex-grow-1">Байршлын <Badge color="success"> эерэг </Badge> хандлага /хот, аймаг/</h4>                                        
                                                        </CardHeader>

                                                        <CardBody>
                                                            <DiffTotalPositiveLocation dataColors='["--vz-primary", "--vz-secondary", "--vz-success", "--vz-info","--vz-warning", "--vz-danger"]' totalPositiveLocation={totalPositiveLocation}/>
                                                        </CardBody>
                                                    </Card>
                                                </Col>  
                                                <Col xl={6}>
                                                        <Card>
                                                            <CardHeader className="align-items-center d-flex">
                                                                <h4 className="card-title mb-0 flex-grow-1">Байршлын <Badge color="success"> эерэг </Badge> хандлага /хот, аймаг/</h4>                                        
                                                            </CardHeader>

                                                            <CardBody>
                                                                <DataTotalPositiveLocationBasic dataColors='["--vz-success"]' totalPositiveLocationData={totalPositiveLocationData}/>
                                                            </CardBody>
                                                        </Card>
                                                </Col>
                                                <Col xl={6}>
                                                                <Card>
                                                                    <CardHeader className="align-items-center d-flex">
                                                                        <h4 className="card-title mb-0 flex-grow-1">Байршлын <Badge color="danger"> сөрөг </Badge> хандлага /хот, аймаг/</h4>                                        
                                                                    </CardHeader>

                                                                    <CardBody>
                                                                        <DiffTotalNegativeLocation dataColors='["--vz-warning", "--vz-danger"]' totalNegativeLocation={totalNegativeLocation}/>
                                                                    </CardBody>
                                                                </Card>
                                                </Col>  
                                                <Col xl={6}>
                                                                <Card>
                                                                    <CardHeader className="align-items-center d-flex">
                                                                        <h4 className="card-title mb-0 flex-grow-1">Байршлын <Badge color="danger"> сөрөг </Badge> хандлага /хот, аймаг/</h4>                                        
                                                                    </CardHeader>

                                                                    <CardBody>
                                                                        <DataTotalNegativeLocationBasic dataColors='["--vz-danger"]' totalNegativeLocationData={totalNegativeLocationData}/>
                                                                    </CardBody>
                                                                </Card>
                                                </Col>
                                                <Col xl={6}>
                                                                <Card>
                                                                    <CardHeader className="align-items-center d-flex">
                                                                        <h4 className="card-title mb-0">Байршлын <Badge color="success"> эерэг </Badge> <Badge color="danger"> сөрөг </Badge> хандлагын харьцаа</h4>
                                                                    </CardHeader>

                                                                    <CardBody>
                                                                        <StackedTotalLocation dataColors='["--vz-success", "--vz-danger"]' totalPTotalLocationData={totalPositiveLocationData} totalNTotalLocationData={totalNegativeLocationData}/>
                                                                    </CardBody>
                                                                </Card>
                                                </Col>
                                                <Col xl={6}>
                                                    <div className="d-flex pt-2 pb-4">
                                                            <div className="d-flex align-items-center"> 
                                                                <div className="avatar-sm flex-shrink-0">
                                                                            <span className="avatar-title bg-primary rounded-circle fs-2">
                                                                                <FeatherIcon
                                                                                    icon="slack"
                                                                                />
                                                                            </span>
                                                                </div>                                               
                                                                <div className="ms-3 flex-grow-1">
                                                                    <h5 className="fs-16 mb-1">Улаанбаатар хотын хандлага</h5>
                                                                </div>
                                                            </div>
                                                    </div>
                                                    <Card>
                                                        <CardHeader className="align-items-center d-flex">
                                                            <h4 className="card-title mb-0 flex-grow-1">Улаанбаатар хотын дүүргийн <Badge color="success"> эерэг </Badge> хандлага</h4>                                        
                                                        </CardHeader>

                                                        <CardBody>
                                                            <DiffTotalPositiveLocation dataColors='["--vz-primary", "--vz-secondary", "--vz-success", "--vz-info","--vz-warning", "--vz-danger"]' totalPositiveLocation={totalPositiveUBLocation}/>
                                                        </CardBody>
                                                    </Card>
                                                </Col>  
                                                <Col xl={6}>
                                                                <Card>
                                                                    <CardHeader className="align-items-center d-flex">
                                                                        <h4 className="card-title mb-0 flex-grow-1">Улаанбаатар хотын дүүргийн <Badge color="success"> эерэг </Badge> хандлага /хот, аймаг/</h4>                                        
                                                                    </CardHeader>

                                                                    <CardBody>
                                                                        <DataTotalUBLocationBasic dataColors='["--vz-success"]' totalUBLocationData={totalPositiveUBLocationData}/>
                                                                    </CardBody>
                                                                </Card>
                                                </Col>
                                                <Col xl={6}>
                                                                <Card>
                                                                    <CardHeader className="align-items-center d-flex">
                                                                        <h4 className="card-title mb-0 flex-grow-1">Улаанбаатар хотын дүүргийн <Badge color="danger"> сөрөг </Badge> хандлага /хот, аймаг/</h4>                                        
                                                                    </CardHeader>

                                                                    <CardBody>
                                                                        <DiffTotalNegativeLocation dataColors='["--vz-warning", "--vz-danger"]' totalNegativeLocation={totalNegativeUBLocation}/>
                                                                    </CardBody>
                                                                </Card>
                                                </Col>  
                                                <Col xl={6}>
                                                                <Card>
                                                                    <CardHeader className="align-items-center d-flex">
                                                                        <h4 className="card-title mb-0 flex-grow-1">Улаанбаатар хотын дүүргийн <Badge color="danger"> сөрөг </Badge> хандлага /хот, аймаг/</h4>                                        
                                                                    </CardHeader>

                                                                    <CardBody>
                                                                        <DataTotalUBLocationBasic dataColors='["--vz-danger"]' totalUBLocationData={totalNegativeUBLocationData}/>
                                                                    </CardBody>
                                                                </Card>
                                                </Col>
                                                <Col xl={6}>
                                                                <Card>
                                                                    <CardHeader className="align-items-center d-flex">
                                                                        <h4 className="card-title mb-0">Улаанбаатар хотын дүүргийн <Badge color="success"> эерэг </Badge> <Badge color="danger"> сөрөг </Badge> хандлагын харьцаа</h4>
                                                                    </CardHeader>

                                                                    <CardBody>
                                                                        <StackedUBLocation dataColors='["--vz-success", "--vz-danger"]' totalPUBLocationData={totalPositiveUBLocationData} totalNUBLocationData={totalNegativeUBLocationData}/>
                                                                    </CardBody>
                                                                </Card>
                                                </Col>
                                                <Col xl={6}>
                                                        <Card>
                                                            <CardHeader>
                                                                <h4 className="card-title mb-0">Улаанбаатар хотын дүүргийн <Badge color="success"> эерэг </Badge> <Badge color="danger"> сөрөг </Badge> хандлагын харьцаа</h4>
                                                            </CardHeader>
                                                            <CardBody>
                                                                <MultipleRadar dataColors='["--vz-success", "--vz-danger"]' positiveUB={totalPositiveUBLocationData} negativeUB={totalNegativeUBLocationData}/>
                                                            </CardBody>
                                                        </Card>
                                                </Col>   
                                                <Col xl={6}>
                                                    <div className="d-flex pt-2 pb-4">
                                                                <div className="d-flex align-items-center"> 
                                                                    <div className="avatar-sm flex-shrink-0">
                                                                                <span className="avatar-title bg-primary rounded-circle fs-2">
                                                                                    <FeatherIcon
                                                                                        icon="users"
                                                                                    />
                                                                                </span>
                                                                    </div>                                               
                                                                    <div className="ms-3 flex-grow-1">
                                                                        <h5 className="fs-16 mb-1">Нас, хүйсийн хандлага</h5>
                                                                    </div>
                                                                </div>
                                                    </div>
                                                    <Card>
                                                        <CardHeader className="align-items-center d-flex">
                                                            <h4 className="card-title mb-0 flex-grow-1">Нас, хүйсийн <Badge color="success"> эерэг </Badge> хандлага</h4>                                        
                                                        </CardHeader>
                                                        <CardBody>
                                                            <StackedColumn dataColors='["--vz-warning", "--vz-info"]' positiveGenderM={genderPositiveM} positiveGenderFM={genderPositiveFM} />
                                                                <div className="table-responsive">
                                                                    <Table className="table-striped table-nowrap align-middle mb-0">
                                                                        <thead>
                                                                            <tr>                                                                            
                                                                                <th scope="col">Хүйс</th>
                                                                                <th scope="col">13-17</th>
                                                                                <th scope="col">18-24</th>
                                                                                <th scope="col">25-34</th>
                                                                                <th scope="col">35-44</th>
                                                                                <th scope="col">45-54</th>
                                                                                <th scope="col">55-64</th>
                                                                                <th scope="col">65+</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <React.Fragment>
                                                                                    <div className="d-flex align-items-center fw-medium" >
                                                                                        <img src={male} alt="" className="avatar-xxs me-2" />
                                                                                        <td>Эрэгтэй</td>
                                                                                    </div>
                                                                                </React.Fragment>    
                                                                                {
                                                                                    genderPositiveM?.map((item, key) => (<td key={key}>{item}</td>)
                                                                                    )
                                                                                }
                                                                            </tr>
                                                                            <tr>                                                                            
                                                                                <React.Fragment>
                                                                                    <div className="d-flex align-items-center fw-medium" >
                                                                                        <img src={female} alt="" className="avatar-xxs me-2" />
                                                                                        <td>Эмэгтэй</td>
                                                                                    </div>
                                                                                </React.Fragment>  
                                                                                {
                                                                                    genderPositiveFM?.map((item, key) => (<td key={key}>{item}</td>)
                                                                                    )
                                                                                }
                                                                            </tr>                                                                       
                                                                        </tbody>
                                                                    </Table>
                                                                </div>                                                            
                                                        </CardBody>
                                                    </Card>
                                                </Col> 
                                                <Col xl={6}>
                                                            <Card>
                                                                <CardHeader className="align-items-center d-flex">
                                                                    <h4 className="card-title mb-0 flex-grow-1">Нас, хүйсийн <Badge color="danger"> сөрөг </Badge> хандлага</h4>                                        
                                                                </CardHeader>
                                                                <CardBody>
                                                                    <StackedNegativeByGender dataColors='["--vz-danger", "--vz-primary"]' negativeGenderM={genderNegativeM} negativeGenderFM={genderNegativeFM} />
                                                                    <div className="table-responsive">
                                                                        <Table className="table-striped table-nowrap align-middle mb-0">
                                                                            <thead>
                                                                                <tr>                                                                            
                                                                                    <th scope="col">Хүйс</th>
                                                                                    <th scope="col">13-17</th>
                                                                                    <th scope="col">18-24</th>
                                                                                    <th scope="col">25-34</th>
                                                                                    <th scope="col">35-44</th>
                                                                                    <th scope="col">45-54</th>
                                                                                    <th scope="col">55-64</th>
                                                                                    <th scope="col">65+</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <React.Fragment>
                                                                                        <div className="d-flex align-items-center fw-medium" >
                                                                                            <img src={male} alt="" className="avatar-xxs me-2" />
                                                                                            <td>Эрэгтэй</td>
                                                                                        </div>
                                                                                    </React.Fragment>    
                                                                                    {
                                                                                        genderNegativeM?.map((item, key) => (<td key={key}>{item}</td>)
                                                                                        )
                                                                                    }
                                                                                </tr>
                                                                                <tr>                                                                            
                                                                                    <React.Fragment>
                                                                                        <div className="d-flex align-items-center fw-medium" >
                                                                                            <img src={female} alt="" className="avatar-xxs me-2" />
                                                                                            <td>Эмэгтэй</td>
                                                                                        </div>
                                                                                    </React.Fragment>  
                                                                                    {
                                                                                        genderNegativeFM?.map((item, key) => (<td key={key}>{item}</td>)
                                                                                        )
                                                                                    }
                                                                                </tr>                                                                       
                                                                            </tbody>
                                                                        </Table>
                                                                    </div>
                                                                </CardBody>
                                                            </Card>
                                                </Col>
                                                <Col xl={6}>
                                                            <Card>
                                                                <CardHeader className="align-items-center d-flex">
                                                                    <h4 className="card-title mb-0 flex-grow-1">Хүйсийн <Badge color="success"> эерэг </Badge> хандлагын харьцаа</h4>                                        
                                                                </CardHeader>
                                                                <CardBody>
                                                                    <StackedGenderByAge dataColors='["--vz-warning", "--vz-info"]' totalMale={totalPMale} totalFemale={totalPFemale}/>
                                                                    <div className="table-responsive">
                                                                        <Table className="table-striped table-nowrap align-middle mb-0">
                                                                            <thead>
                                                                                <tr>                                                                            
                                                                                    <th scope="col">Хүйс</th>
                                                                                    <th scope="col">Нийт</th>    
                                                                                    <th scope="col">Зөрүү</th>                                                                                    
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <React.Fragment>
                                                                                        <div className="d-flex align-items-center fw-medium" >
                                                                                            <img src={male} alt="" className="avatar-xxs me-2" />
                                                                                            <td>Эрэгтэй</td>
                                                                                        </div>
                                                                                    </React.Fragment>    
                                                                                    <td>{totalPMale}</td>
                                                                                    <td>{
                                                                                        totalPMale >= totalPFemale ?
                                                                                        <h6 className="text-success fs-13 mb-0" > 
                                                                                            <i className="mdi mdi-trending-up align-middle me-1"></i>
                                                                                            {((totalPMale) * 100 / (totalPMale + totalPFemale)).toFixed(2)}%
                                                                                        </h6>
                                                                                        : <h6 className="text-danger fs-13 mb-0" > 
                                                                                            <i className="mdi mdi-trending-down align-middle me-1"></i>
                                                                                            {((totalPMale) * 100 / (totalPMale + totalPFemale)).toFixed(2)}%
                                                                                        </h6>
                                                                                    }</td>
                                                                                </tr>
                                                                                <tr>                                                                            
                                                                                    <React.Fragment>
                                                                                        <div className="d-flex align-items-center fw-medium" >
                                                                                            <img src={female} alt="" className="avatar-xxs me-2" />
                                                                                            <td>Эмэгтэй</td>
                                                                                        </div>
                                                                                    </React.Fragment>  
                                                                                    <td>{totalPFemale}</td>
                                                                                    <td>{
                                                                                        totalPMale <= totalPFemale ?
                                                                                        <h6 className="text-success fs-13 mb-0" > 
                                                                                            <i className="mdi mdi-trending-up align-middle me-1"></i>
                                                                                            {((totalPFemale) * 100 / (totalPMale + totalPFemale)).toFixed(2)}%
                                                                                        </h6>
                                                                                        : <h6 className="text-danger fs-13 mb-0" > 
                                                                                            <i className="mdi mdi-trending-down align-middle me-1"></i>
                                                                                            {((totalPFemale) * 100 / (totalPMale + totalPFemale)).toFixed(2)}%
                                                                                        </h6>
                                                                                    }</td>
                                                                                </tr>                                                                       
                                                                            </tbody>
                                                                        </Table>
                                                                    </div>                                                                                                                                        
                                                                </CardBody>
                                                            </Card>
                                                </Col>   
                                                <Col xl={6}>
                                                            <Card>
                                                                <CardHeader className="align-items-center d-flex">
                                                                    <h4 className="card-title mb-0 flex-grow-1">Хүйсийн <Badge color="danger"> сөрөг </Badge> хандлагын харьцаа</h4>                                        
                                                                </CardHeader>
                                                                <CardBody>
                                                                    <StackedGenderByAge dataColors='["--vz-danger", "--vz-primary"]' totalMale={totalNMale} totalFemale={totalNFemale}/>
                                                                    <div className="table-responsive">
                                                                        <Table className="table-striped table-nowrap align-middle mb-0">
                                                                            <thead>
                                                                                <tr>                                                                            
                                                                                    <th scope="col">Хүйс</th>
                                                                                    <th scope="col">Нийт</th>    
                                                                                    <th scope="col">Зөрүү</th>                                                                                    
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <React.Fragment>
                                                                                        <div className="d-flex align-items-center fw-medium" >
                                                                                            <img src={male} alt="" className="avatar-xxs me-2" />
                                                                                            <td>Эрэгтэй</td>
                                                                                        </div>
                                                                                    </React.Fragment>    
                                                                                    <td>{totalNMale}</td>
                                                                                    <td>{
                                                                                        totalNMale >= totalNFemale ?
                                                                                        <h6 className="text-success fs-13 mb-0" > 
                                                                                            <i className="mdi mdi-trending-up align-middle me-1"></i>
                                                                                            {((totalNMale) * 100 / (totalNMale + totalNFemale)).toFixed(2)}%
                                                                                        </h6>
                                                                                        : <h6 className="text-danger fs-13 mb-0" > 
                                                                                            <i className="mdi mdi-trending-down align-middle me-1"></i>
                                                                                            {((totalNMale) * 100 / (totalNMale + totalNFemale)).toFixed(2)}%
                                                                                        </h6>
                                                                                    }</td>
                                                                                </tr>
                                                                                <tr>                                                                            
                                                                                    <React.Fragment>
                                                                                        <div className="d-flex align-items-center fw-medium" >
                                                                                            <img src={female} alt="" className="avatar-xxs me-2" />
                                                                                            <td>Эмэгтэй</td>
                                                                                        </div>
                                                                                    </React.Fragment>  
                                                                                    <td>{totalNFemale}</td>
                                                                                    <td>{
                                                                                        totalNMale <= totalNFemale ?
                                                                                        <h6 className="text-success fs-13 mb-0" > 
                                                                                            <i className="mdi mdi-trending-up align-middle me-1"></i>
                                                                                            {((totalNFemale) * 100 / (totalNMale + totalNFemale)).toFixed(2)}%
                                                                                        </h6>
                                                                                        : <h6 className="text-danger fs-13 mb-0" > 
                                                                                            <i className="mdi mdi-trending-down align-middle me-1"></i>
                                                                                            {((totalNFemale) * 100 / (totalNMale + totalNFemale)).toFixed(2)}%
                                                                                        </h6>
                                                                                    }</td>
                                                                                </tr>                                                                       
                                                                            </tbody>
                                                                        </Table>
                                                                    </div>                                                                    
                                                                </CardBody>
                                                            </Card>
                                                </Col>             
                                                <Col xl={6}>
                                                    <div className="d-flex pt-2 pb-4">
                                                                    <div className="d-flex align-items-center"> 
                                                                        <div className="avatar-sm flex-shrink-0">
                                                                                    <span className="avatar-title bg-primary rounded-circle fs-2">
                                                                                        <FeatherIcon
                                                                                            icon="user-check"
                                                                                        />
                                                                                    </span>
                                                                        </div>                                               
                                                                        <div className="ms-3 flex-grow-1">
                                                                            <h5 className="fs-16 mb-1">Жинхэнэ, хуурамч хаягтай хэрэглэгчид</h5>
                                                                        </div>
                                                                    </div>
                                                    </div>
                                                            <Card>
                                                                <CardHeader className="align-items-center d-flex">
                                                                    <h4 className="card-title mb-0 flex-grow-1"><Badge color="success"> Эерэг </Badge> хандлага үзүүлсэн хэрэглэгчид /хот, аймаг/</h4>                                        
                                                                </CardHeader>
                                                                <CardBody>
                                                                    <RealFakeData dataColors='["--vz-success", "--vz-danger", "--vz-warning"]' realfake={totalPositiveRealFakeData} />
                                                                    <div className="table-responsive">
                                                                        <Table className="table-striped table-nowrap align-middle mb-0">
                                                                            <thead>
                                                                                <tr>                                                                            
                                                                                    <th scope="col">Хаяг</th>
                                                                                    <th scope="col">Нийт</th>    
                                                                                    <th scope="col">Зөрүү</th>                                                                                    
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <React.Fragment>
                                                                                        <div className="d-flex align-items-center fw-medium" >
                                                                                            <img src={real} alt="" className="avatar-xxs me-2" />
                                                                                            <td>Жинхэнэ хаягтай</td>
                                                                                        </div>
                                                                                    </React.Fragment>    
                                                                                    <td>{totalPositiveRealFakeData[0]}</td>
                                                                                    <td>{
                                                                                        totalPositiveRealFakeData[0] >= (totalPositiveRealFakeData[1] + totalPositiveRealFakeData[2]) ?
                                                                                        <h6 className="text-success fs-13 mb-0" > 
                                                                                            <i className="mdi mdi-trending-up align-middle me-1"></i>
                                                                                            {((totalPositiveRealFakeData[0]) * 100 / (totalPositiveRealFakeData[0] + totalPositiveRealFakeData[1] + totalPositiveRealFakeData[2])).toFixed(2)}%
                                                                                        </h6>
                                                                                        : <h6 className="text-danger fs-13 mb-0" > 
                                                                                            <i className="mdi mdi-trending-down align-middle me-1"></i>
                                                                                            {((totalPositiveRealFakeData[0]) * 100 / (totalPositiveRealFakeData[0] + totalPositiveRealFakeData[1] + totalPositiveRealFakeData[2])).toFixed(2)}%
                                                                                        </h6>
                                                                                    }</td>
                                                                                </tr>
                                                                                <tr>                                                                            
                                                                                    <React.Fragment>
                                                                                        <div className="d-flex align-items-center fw-medium" >
                                                                                            <img src={fake} alt="" className="avatar-xxs me-2" />
                                                                                            <td>Хуурамч хаягтай</td>
                                                                                        </div>
                                                                                    </React.Fragment>  
                                                                                    <td>{totalPositiveRealFakeData[1] + totalPositiveRealFakeData[2]}</td>
                                                                                    <td>{
                                                                                        totalPositiveRealFakeData[0] <= totalPositiveRealFakeData[1] + totalPositiveRealFakeData[2] ?
                                                                                        <h6 className="text-success fs-13 mb-0" > 
                                                                                            <i className="mdi mdi-trending-up align-middle me-1"></i>
                                                                                            {((totalPositiveRealFakeData[1] + totalPositiveRealFakeData[2]) * 100 / (totalPositiveRealFakeData[0] + totalPositiveRealFakeData[1] + totalPositiveRealFakeData[2])).toFixed(2)}%
                                                                                        </h6>
                                                                                        : <h6 className="text-danger fs-13 mb-0" > 
                                                                                            <i className="mdi mdi-trending-down align-middle me-1"></i>
                                                                                            {((totalPositiveRealFakeData[1] + totalPositiveRealFakeData[2]) * 100 / (totalPositiveRealFakeData[0] + totalPositiveRealFakeData[1] + totalPositiveRealFakeData[2])).toFixed(2)}%
                                                                                        </h6>
                                                                                    }</td>
                                                                                </tr>                                                                       
                                                                            </tbody>
                                                                        </Table>
                                                                    </div>  
                                                                </CardBody>
                                                            </Card>
                                                </Col>  
                                                <Col xl={6}>
                                                            <Card>
                                                                <CardHeader className="align-items-center d-flex">
                                                                    <h4 className="card-title mb-0 flex-grow-1"><Badge color="danger"> Сөрөг </Badge> хандлага үзүүлсэн хэрэглэгчид /хот, аймаг/</h4>                                        
                                                                </CardHeader>
                                                                <CardBody>
                                                                    <RealFakeData dataColors='["--vz-success", "--vz-danger", "--vz-warning"]' realfake={totalNegativeRealFakeData} />
                                                                    <div className="table-responsive">
                                                                        <Table className="table-striped table-nowrap align-middle mb-0">
                                                                            <thead>
                                                                                <tr>                                                                            
                                                                                    <th scope="col">Хаяг</th>
                                                                                    <th scope="col">Нийт</th>    
                                                                                    <th scope="col">Зөрүү</th>                                                                                    
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <React.Fragment>
                                                                                        <div className="d-flex align-items-center fw-medium" >
                                                                                            <img src={real} alt="" className="avatar-xxs me-2" />
                                                                                            <td>Жинхэнэ хаягтай</td>
                                                                                        </div>
                                                                                    </React.Fragment>    
                                                                                    <td>{totalNegativeRealFakeData[0]}</td>
                                                                                    <td>{
                                                                                        totalNegativeRealFakeData[0] >= (totalNegativeRealFakeData[1] + totalNegativeRealFakeData[2]) ?
                                                                                        <h6 className="text-success fs-13 mb-0" > 
                                                                                            <i className="mdi mdi-trending-up align-middle me-1"></i>
                                                                                            {((totalNegativeRealFakeData[0]) * 100 / (totalNegativeRealFakeData[0] + totalNegativeRealFakeData[1] + totalNegativeRealFakeData[2])).toFixed(2)}%
                                                                                        </h6>
                                                                                        : <h6 className="text-danger fs-13 mb-0" > 
                                                                                            <i className="mdi mdi-trending-down align-middle me-1"></i>
                                                                                            {((totalNegativeRealFakeData[0]) * 100 / (totalNegativeRealFakeData[0] + totalNegativeRealFakeData[1] + totalNegativeRealFakeData[2])).toFixed(2)}%
                                                                                        </h6>
                                                                                    }</td>
                                                                                </tr>
                                                                                <tr>                                                                            
                                                                                    <React.Fragment>
                                                                                        <div className="d-flex align-items-center fw-medium" >
                                                                                            <img src={fake} alt="" className="avatar-xxs me-2" />
                                                                                            <td>Хуурамч хаягтай</td>
                                                                                        </div>
                                                                                    </React.Fragment>  
                                                                                    <td>{totalNegativeRealFakeData[1] + totalNegativeRealFakeData[2]}</td>
                                                                                    <td>{
                                                                                        totalNegativeRealFakeData[0] <= totalNegativeRealFakeData[1] + totalNegativeRealFakeData[2] ?
                                                                                        <h6 className="text-success fs-13 mb-0" > 
                                                                                            <i className="mdi mdi-trending-up align-middle me-1"></i>
                                                                                            {((totalNegativeRealFakeData[1] + totalNegativeRealFakeData[2]) * 100 / (totalNegativeRealFakeData[0] + totalNegativeRealFakeData[1] + totalNegativeRealFakeData[2])).toFixed(2)}%
                                                                                        </h6>
                                                                                        : <h6 className="text-danger fs-13 mb-0" > 
                                                                                            <i className="mdi mdi-trending-down align-middle me-1"></i>
                                                                                            {((totalNegativeRealFakeData[1] + totalNegativeRealFakeData[2]) * 100 / (totalNegativeRealFakeData[0] + totalNegativeRealFakeData[1] + totalNegativeRealFakeData[2])).toFixed(2)}%
                                                                                        </h6>
                                                                                    }</td>
                                                                                </tr>                                                                       
                                                                            </tbody>
                                                                        </Table>
                                                                    </div> 
                                                                </CardBody>
                                                            </Card>
                                                </Col>
                                            </React.Fragment>     
                                            } 

                <Col lg={9}>
                        <div className="d-flex pt-2 pb-4">
                            <div className="d-flex align-items-center"> 
                                <div className="avatar-sm flex-shrink-0">
                                            <span className="avatar-title bg-primary rounded-circle fs-2">
                                                <FeatherIcon
                                                    icon="star"
                                                />
                                            </span>
                                </div>                                               
                                <div className="ms-3 flex-grow-1">
                                    <h5 className="fs-16 mb-1">Онцлох 50 нийтлэл</h5>
                                </div>
                            </div>
                        </div>
                        {isTop10PostSuccess && top10Post ? top10Post.length > 0 && top10Post.map((item, key) => (<Col className="list-element" key={key}>
                        <Card className="explore-box card-animate border ribbon-box border shadow-none right">                                                                                          
                            <CardBody>                                          
                            <div className="d-flex align-items-center mb-3">                                
                                {item.source === "facebook" && <img src={item.user.profileImageUrl || "https://firebasestorage.googleapis.com/v0/b/mindx-384a6.appspot.com/o/logos%2Ffb-user.png?alt=media&token=327ea65e-e726-414c-a3d9-bc159242c2c9"} alt="" className="avatar-xs rounded-circle" />}
                                {item.source === "twitter" && <img src={item.user.profileImageUrl} alt="" className="avatar-xs rounded-circle" />}
                                {
                                    item.source === "news" && <img src={item.user.profileImageUrl} alt="" className="avatar-xs rounded-circle" />
                                }
                                {item.source === "instagram" && <img src={item.user.profileImageUrl || "https://firebasestorage.googleapis.com/v0/b/mindx-384a6.appspot.com/o/logos%2Fig-user.png?alt=media&token=d99f33ae-7589-46e7-aade-6bc8d45955cc"} alt="" className="avatar-xs rounded-circle" />}
                                <div className="ms-2 flex-grow-1">                                   
                                    {item.source === "twitter" ? 
                                            <React.Fragment>
                                                <h6 className="fs-15 mb-1">{item.user.name}</h6>
                                            </React.Fragment>
                                        : <React.Fragment>
                                                <h6 className="fs-15 mb-1">{item.user.username}</h6> 
                                        </React.Fragment>                                                        
                                        }                                        
                                    <p className="mb-0 text-muted">
                                    <i className="ri-time-line align-bottom"></i> 
                                    {item.date.split('T')[0]},{" "}
                                    <small className="text-muted">{handleValidTime(item.date)}</small>
                                    </p>
                                </div>                                
                            </div>
                            <div className="explore-place-bid-img overflow-hidden rounded">                                
                                {
                                    item.media.image && (item.source === "facebook" || item.source === "instagram") ? <img src={"https://datachain.art/api/images/DataChainMedia/" + item.media.image} alt="" className="img-fluid explore-img" />
                                    : item.media.image && item.media.image != "[]" &&
                                    item.media.image.map((value, key) => ( <img key={key} src={value} alt="" className="img-fluid explore-img"  /> ))
                                }                                                                  
                            </div>
                            <div className="mt-3">                                                                                                                               
                                <h5 className="mb-1">
                                    <Link to={item.url} target="_blank">{item.title}</Link>
                                </h5>
                                <h6 className="mb-1">
                                        {(item.source === "facebook" || item.source === "twitter" || item.source === "instagram") &&
                                            <p>{item.text}</p>
                                        }
                                        {item.source === "news" &&
                                            <div className="project-details" dangerouslySetInnerHTML={{ __html: item.text.length > 420 ? item.text.substring(0, 420) + "... " : item.text }} /> 
                                        }
                                </h6>                                                                
                            </div>
                            </CardBody>
                            <div className="card-footer border-top border-top-dashed">
                                <div className="d-flex align-items-center">
                                    <div className="flex-grow-1 fs-14">
                                        <i className="ri-price-tag-3-fill text-warning align-bottom me-1"></i>                                        
                                        <span className={`badge badge-soft-${
                                                item.source === "facebook"
                                                ? "primary"
                                                : item.source === "twitter"
                                                ? "info"
                                                : item.source === "instagram"
                                                ? "danger"
                                                : "warning"
                                            }`}>
                                            {item.source === "facebook"
                                                ? "facebook"
                                                : item.source === "twitter"
                                                ? "x/twitter"
                                                : item.source === "instagram"
                                                ? "instagram"
                                                : "мэдээний сайт"
                                            }</span>                                           
                                    </div>
                                    <Rating
                                        initialRating={item.sent_reaction === "Эерэг" ? 5 : item.sent_reaction === "Сөрөг" ? 1.5: 3.5}
                                        fractions={2}
                                        emptySymbol="mdi mdi-star-outline text-muted "
                                        fullSymbol="mdi mdi-star text-warning "                                        
                                    />
                                </div>
                            </div>
                                            <CardBody className="border-top border-top-dashed">
                                                <div className="d-flex">
                                                    <div className="flex-grow-1">
                                                        {
                                                            item.engagements.sharers && item.engagements.sharers.length > 0 ? 
                                                            <button type="button" className="btn btn-soft-success" onClick={() => onShowShareClick(item.engagements.sharers && item.engagements.sharers, item.source)}>
                                                                <h6 className="mb-0">{item.engagements.shares}<i className="las la-retweet align-bottom text-success"></i></h6>
                                                            </button>  
                                                            : <button type="button" className="btn btn-white">
                                                                <h6 className="mb-0">{item.engagements.shares}<i className="las la-retweet align-bottom text-success"></i></h6>
                                                            </button>  
                                                        } 
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        {
                                                            item.engagements.comments_extra && item.engagements.comments_extra.length > 0 ? 
                                                            <button type="button" className="btn btn-soft-success" onClick={() => onShowCommentClick(item.engagements.comments_extra && item.engagements.comments_extra, item.source)}>
                                                                <h6 className="mb-0">{item.engagements.comments}<i className="bx bx-comment-detail align-bottom text-info"></i></h6>
                                                            </button>  
                                                            : <button type="button" className="btn btn-white" >
                                                                <h6 className="mb-0">{item.engagements.comments}<i className="bx bx-comment-detail align-bottom text-info"></i></h6>
                                                            </button> 
                                                        }  
                                                    </div>
                                                    <div className="flex-grow-1">                                                        
                                                        {
                                                            item.engagements.reactors && item.engagements.reactors.length > 0 ? 
                                                            <button type="button" className="btn btn-soft-success" onClick={() => onShowReactionClick(item.engagements.reactors && item.engagements.reactors, item.engagements.reactions, item.source)}>
                                                                <h6 className="mb-0">{item.engagements.likes}<i className="bx bxs-like align-bottom text-primary"></i></h6>
                                                            </button>  
                                                            : <button type="button" className="btn btn-white">
                                                                <h6 className="mb-0">{item.engagements.likes}<i className="bx bxs-like align-bottom text-primary"></i></h6>
                                                            </button> 
                                                        } 
                                                    </div>  
                                                    {item.source === "twitter" && <React.Fragment>
                                                                    <div className="flex-grow-1">
                                                                        <button type="button" className="btn btn-white">
                                                                            <h6 className="mb-0">{item.engagements.quoteCount}<i className="bx bxs-quote-alt-left align-bottom text-primary"></i></h6>
                                                                        </button>                                                                         
                                                                    </div> 
                                                                    <div className="flex-grow-1">
                                                                        <button type="button" className="btn btn-white">
                                                                            <h6 className="mb-0">{item.speadCount}<i className="ri-bar-chart-fill align-bottom text-primary"></i></h6>
                                                                        </button>                                                                        
                                                                    </div>
                                                                    </React.Fragment>
                                                    }                                                
                                                </div>
                                            </CardBody>
                            
                                            {item.engagements.reactions && (item.source == "facebook" || item.source == "news") &&
                                                           <div className="card-footer border-top border-top-dashed">                                                                
                                                                <div className="d-flex align-items-center">  
                                                                    <div className="flex-grow-1">
                                                                        <h6 className="mb-0">{item.engagements.reactions.like || 0}<img className="title" src={like} alt=""  style={{ width: "20px", height: "20px" }} /></h6>                                               
                                                                    </div>   
                                                                    <div className="flex-grow-1">
                                                                        <h6 className="mb-0">{item.engagements.reactions.love || 0}<img className="title" src={love} alt=""  style={{ width: "20px", height: "20px" }} /></h6>                                               
                                                                    </div> 
                                                                    <div className="flex-grow-1">
                                                                        <h6 className="mb-0">{item.engagements.reactions.care || 0}<img className="title" src={care} alt=""  style={{ width: "20px", height: "20px" }} /></h6>                                               
                                                                    </div> 
                                                                    <div className="flex-grow-1">
                                                                        <h6 className="mb-0">{item.engagements.reactions.haha || 0}<img className="title" src={haha} alt=""  style={{ width: "20px", height: "20px" }} /></h6>                                               
                                                                    </div> 
                                                                    <div className="flex-grow-1">
                                                                        <h6 className="mb-0">{item.engagements.reactions.wow || 0}<img className="title" src={wow} alt=""  style={{ width: "20px", height: "20px" }} /></h6>                                               
                                                                    </div> 
                                                                    <div className="flex-grow-1">
                                                                        <h6 className="mb-0">{item.engagements.reactions.sad || 0}<img className="title" src={sad} alt=""  style={{ width: "20px", height: "20px" }} /></h6>                                               
                                                                    </div> 
                                                                    <div className="flex-grow-1">
                                                                        <h6 className="mb-0">{item.engagements.reactions.angry || 0}<img className="title" src={angry} alt=""  style={{ width: "20px", height: "20px" }} /></h6>                                               
                                                                    </div> 
                                                                </div>                                                                                                                                                                    
                                                            </div>   
                                                        }        
                                                        
                                                                                     
                        </Card>
                    </Col>)) : <Spinner color="primary"> Loading... </Spinner>}
                    {/* <CardBody className="p-4">
                        <Row>
                            <React.Fragment>                     
                                <Col xxl={6} md={6} >                                                                     
                                            {!isRPTPostSuccess && rptpost ? ( <Spinner color="primary"> Loading... </Spinner> ) : (
                                                rptpost && rptpost.map((item, key) => (
                                                    item.source === "facebook" ?
                                                    <Card className="mb-2" key={key} >
                                                        <CardBody>
                                                            <div className="d-flex mb-3">
                                                                <div className="flex-shrink-0 avatar-md">
                                                                    <div className="avatar-title bg-light rounded shadow">
                                                                        <img src={"https://firebasestorage.googleapis.com/v0/b/mindx-384a6.appspot.com/o/logos%2Ffb-user.png?alt=media&token=327ea65e-e726-414c-a3d9-bc159242c2c9"} alt="" className="avatar-sm" />
                                                                    </div>
                                                                </div>
                                                                <div className="flex-grow-1 ms-3">
                                                                    <h5 className="fs-15 mb-1">{item.user.username}</h5>
                                                                    <p className="text-muted mb-2">@{item.user.user_id}</p> 
                                                                    <span className={`badge badge-soft-${
                                                                            item.source === "facebook"
                                                                            ? "primary"
                                                                            : item.source === "twitter"
                                                                            ? "info"
                                                                            : item.source === "instagram"
                                                                            ? "danger"
                                                                            : "warning"
                                                                        }`}>
                                                                       {item.source === "facebook"
                                                                            ? "facebook"
                                                                            : item.source === "twitter"
                                                                            ? "x/twitter"
                                                                            : item.source === "instagram"
                                                                            ? "instagram"
                                                                            : "мэдээний сайт"
                                                                        }</span>
                                                                    <h6 className={"flex-shrink-0 text-" + item.dateClass + " mb-0"}><i className="ri-time-line align-bottom"></i> 
                                                                    {handleValidDate(item.date)},{" "}
                                                                    <small className="text-muted">{handleValidTime(item.date)}</small></h6>                                                                                                                  
                                                                </div>
                                                                <div>
                                                                    <Badge color="secondary" className="badge-label"> <i className="mdi mdi-circle-medium"></i> {item.sent_category} </Badge>                                                        
                                                                </div>                                                         
                                                                <div>
                                                                    <Link to={item.url} target="_blank" className="badge badge-soft-primary"><i className="ri-arrow-right-up-line align-bottom"></i></Link>
                                                                </div>
                                                            </div>                                               
                                                            <h6 className="text mb-0">
                                                            <p>{item.text}</p>
                                                            <span className="badge badge-soft-success">{item.percentage}</span></h6>
                                                            {item.media.image &&
                                                                <img src={"https://datachain.art/api/images/DataChainMedia/" + item.media.image} alt="" className="img-fluid explore-img" />
                                                            }                                               
                                                        </CardBody>
                                                        <CardBody className="border-top border-top-dashed">
                                                            <div className="d-flex">
                                                                <div className="flex-grow-1">                                                            
                                                                    {
                                                                        item.engagements.sharers && item.engagements.sharers.length > 0 ? 
                                                                        <button type="button" className="btn btn-soft-success" onClick={() => onShowShareClick(item.engagements.sharers && item.engagements.sharers, item.source)}>
                                                                            <h6 className="mb-0">{item.engagements.shares}<i className="las la-retweet align-bottom text-success"></i></h6>
                                                                        </button>  
                                                                        : <h6 className="mb-0">{item.engagements.shares}<i className="las la-retweet align-bottom text-success"></i></h6>
                                                                    } 
                                                                </div>
                                                                <div className="flex-grow-1">
                                                                    {
                                                                        item.engagements.comments_extra && item.engagements.comments_extra.length > 0 ? 
                                                                        <button type="button" className="btn btn-soft-success" onClick={() => onShowCommentClick(item.engagements.comments_extra && item.engagements.comments_extra, item.source)}>
                                                                            <h6 className="mb-0">{item.engagements.comments}<i className="bx bx-comment-detail align-bottom text-info"></i></h6>
                                                                        </button>  
                                                                        : <h6 className="mb-0">{item.engagements.comments}<i className="bx bx-comment-detail align-bottom text-info"></i></h6>
                                                                    }   
                                                                </div>
                                                                <div className="flex-grow-1">                                                                                                                          
                                                                    {
                                                                        item.engagements.reactors && item.engagements.reactors.length > 0 ? 
                                                                        <button type="button" className="btn btn-soft-success" onClick={() => onShowReactionClick(item.engagements.reactors && item.engagements.reactors, item.engagements.reactions, item.source)}>
                                                                            <h6 className="mb-0">{item.engagements.likes}<i className="ri-heart-fill align-bottom text-danger"></i></h6>
                                                                        </button>  
                                                                        : <h6 className="mb-0">{item.engagements.likes}<i className="ri-heart-fill align-bottom text-danger"></i></h6>
                                                                    } 
                                                                </div>
                                                                <div className="flex-grow-1">   
                                                                    {
                                                                            item.sent_reaction === "Эерэг" ? <Badge color="success" className="badge-label"> <i className="mdi mdi-circle-medium"></i> Эерэг </Badge>
                                                                            : item.sent_reaction === "Сөрөг" ? <Badge color="danger" className="badge-label"> <i className="mdi mdi-circle-medium"></i> Сөрөг </Badge>
                                                                            : <Badge color="warning" className="badge-label"> <i className="mdi mdi-circle-medium"></i> Саармаг </Badge>
                                                                    }
                                                                </div>                                                
                                                            </div>
                                                        </CardBody> 
                                                        {item.engagements.reactions &&  
                                                            <CardBody>
                                                            <div className="d-flex">       
                                                                <React.Fragment>
                                                                <div className="flex-grow-1">
                                                                        <h6 className="mb-0">{item.engagements.reactions.like || 0}<img className="title" src={like} alt=""  style={{ width: "20px", height: "20px" }} /></h6>                                               
                                                                    </div>   
                                                                    <div className="flex-grow-1">
                                                                        <h6 className="mb-0">{item.engagements.reactions.love || 0}<img className="title" src={love} alt=""  style={{ width: "20px", height: "20px" }} /></h6>                                               
                                                                    </div> 
                                                                    <div className="flex-grow-1">
                                                                        <h6 className="mb-0">{item.engagements.reactions.care || 0}<img className="title" src={care} alt=""  style={{ width: "20px", height: "20px" }} /></h6>                                               
                                                                    </div> 
                                                                    <div className="flex-grow-1">
                                                                        <h6 className="mb-0">{item.engagements.reactions.haha || 0}<img className="title" src={haha} alt=""  style={{ width: "20px", height: "20px" }} /></h6>                                               
                                                                    </div> 
                                                                    <div className="flex-grow-1">
                                                                        <h6 className="mb-0">{item.engagements.reactions.wow || 0}<img className="title" src={wow} alt=""  style={{ width: "20px", height: "20px" }} /></h6>                                               
                                                                    </div> 
                                                                    <div className="flex-grow-1">
                                                                        <h6 className="mb-0">{item.engagements.reactions.sad || 0}<img className="title" src={sad} alt=""  style={{ width: "20px", height: "20px" }} /></h6>                                               
                                                                    </div> 
                                                                    <div className="flex-grow-1">
                                                                        <h6 className="mb-0">{item.engagements.reactions.angry || 0}<img className="title" src={angry} alt=""  style={{ width: "20px", height: "20px" }} /></h6>                                               
                                                                    </div> 
                                                                
                                                                </React.Fragment>                                                                                                                                                                             
                                                            </div>
                                                            </CardBody>    
                                                        }                                           
                                                    </Card>
                                                    : item.source === "twitter" ?
                                                    <Card className="mb-2" key={key}>
                                                        <CardBody>
                                                            <div className="d-flex mb-3">
                                                                <div className="flex-shrink-0 avatar-md">
                                                                    <div className="avatar-title bg-light rounded shadow">
                                                                        <img src={item.user.profileImageUrl} alt="" className="avatar-sm" />
                                                                    </div>
                                                                </div>
                                                                <div className="flex-grow-1 ms-3">
                                                                    <h5 className="fs-15 mb-1">{item.user.username}</h5>
                                                                        <span className={`badge badge-soft-${
                                                                            item.source === "facebook"
                                                                            ? "primary"
                                                                            : item.source === "twitter"
                                                                            ? "info"
                                                                            : item.source === "instagram"
                                                                            ? "danger"
                                                                            : "warning"
                                                                        }`}>
                                                                       {item.source === "facebook"
                                                                            ? "facebook"
                                                                            : item.source === "twitter"
                                                                            ? "x/twitter"
                                                                            : item.source === "instagram"
                                                                            ? "instagram"
                                                                            : "мэдээний сайт"
                                                                       }</span>
                                                                    <p>
                                                                        <div className="flex-grow-1">
                                                                            <h6 className="mb-0"><i className="bx bxs-like align-bottom text-primary"></i>{item.user.favouritesCount}</h6>
                                                                        </div> 
                                                                    </p>
                                                                    <h6 className={"flex-shrink-0 text-" + item.dateClass + " mb-0"}><i className="ri-time-line align-bottom"></i> 
                                                                    {handleValidDate(item.date)},{" "}
                                                                    <small className="text-muted">{handleValidTime(item.date)}</small></h6>                                                       
                                                                </div>
                                                                <div>
                                                                    <Badge color="secondary" className="badge-label"> <i className="mdi mdi-circle-medium"></i> {item.sent_category} </Badge>                                                        
                                                                </div> 
                                                                <div>                                                        
                                                                    <Link to={item.url} target="_blank" className="badge badge-soft-primary"><i className="ri-arrow-right-up-line align-bottom"></i></Link>
                                                                </div>                                                    
                                                            </div>                                               
                                                            <h6 className="text mb-0">{item.text} <span className="badge badge-soft-success">{item.percentage}</span></h6>                                               
                                                            {item.media.image && item.media.image != "" &&
                                                                            item.media.image.map((value, key) => ( <img id={key} src={value} alt="" className="img-fluid explore-img" /> ))
                                                            }                                       
                                                        </CardBody>
                                                        <CardBody className="border-top border-top-dashed">
                                                            <div className="d-flex">
                                                                <div className="flex-grow-1">
                                                                    <h6 className="mb-0">{item.engagements.shares}<i className="las la-retweet align-bottom text-success"></i></h6>
                                                                </div>
                                                                <div className="flex-grow-1">
                                                                    <h6 className="mb-0">{item.engagements.comments}<i className="bx bx-comment-detail align-bottom text-info"></i></h6>
                                                                </div>
                                                                <div className="flex-grow-1">
                                                                    <h6 className="mb-0">{item.engagements.likes}<i className="ri-heart-fill align-bottom text-danger"></i></h6>
                                                                </div>
                                                                <div className="flex-grow-1">
                                                                    <h6 className="mb-0">{item.engagements.quoteCount}<i className="bx bxs-quote-alt-left align-bottom text-primary"></i></h6>
                                                                </div>
                                                                <div className="flex-grow-1">
                                                                    <h6 className="mb-0">{item.speadCount}<i className="ri-bar-chart-fill align-bottom text-primary"></i></h6>
                                                                </div>
                                                                <div className="flex-grow-1">   
                                                                        {
                                                                                item.sent_reaction === "Эерэг" ? <Badge color="success" className="badge-label"> <i className="mdi mdi-circle-medium"></i> Эерэг </Badge>
                                                                                : item.sent_reaction === "Сөрөг" ? <Badge color="danger" className="badge-label"> <i className="mdi mdi-circle-medium"></i> Сөрөг </Badge>
                                                                                : <Badge color="warning" className="badge-label"> <i className="mdi mdi-circle-medium"></i> Саармаг </Badge>
                                                                        }
                                                                </div>                                                       
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                    : item.source === "news" ?
                                                    <Card className="mb-2" key={key}>
                                                        <CardBody>
                                                            <div className="d-flex mb-3">
                                                                <div className="flex-shrink-0 avatar-md">
                                                                    <div className="avatar-title bg-light rounded shadow">
                                                                    {
                                                                        item.source === "news" && <img src={item.user.profileImageUrl} alt="" className="avatar-sm" />
                                                                    }                                                           
                                                                    </div>
                                                                </div>
                                                                <div className="flex-grow-1 ms-3">
                                                                    <h5 className="fs-15 mb-1">{item.user.username}</h5> 
                                                                    <span className={`badge badge-soft-${
                                                                            item.source === "facebook"
                                                                            ? "primary"
                                                                            : item.source === "twitter"
                                                                            ? "info"
                                                                            : item.source === "instagram"
                                                                            ? "danger"
                                                                            : "warning"
                                                                        }`}>
                                                                       {item.source === "facebook"
                                                                            ? "facebook"
                                                                            : item.source === "twitter"
                                                                            ? "x/twitter"
                                                                            : item.source === "instagram"
                                                                            ? "instagram"
                                                                            : "мэдээний сайт"
                                                                       }</span>
                                                                    <h6 className={"flex-shrink-0 text-" + item.dateClass + " mb-0"}><i className="ri-time-line align-bottom"></i> 
                                                                    {handleValidDate(item.date)},{" "}
                                                                    <small className="text-muted">{handleValidTime(item.date)}</small></h6>  
                                                                    <div className="flex-grow-1">   
                                                                        {
                                                                                item.sent_reaction === "Эерэг" ? <Badge color="success" className="badge-label"> <i className="mdi mdi-circle-medium"></i> Эерэг </Badge>
                                                                                : item.sent_reaction === "Сөрөг" ? <Badge color="danger" className="badge-label"> <i className="mdi mdi-circle-medium"></i> Сөрөг </Badge>
                                                                                : <Badge color="warning" className="badge-label"> <i className="mdi mdi-circle-medium"></i> Саармаг </Badge>
                                                                        }
                                                                    </div>                                                        
                                                                </div>
                                                                <div>
                                                                    <Badge color="secondary" className="badge-label"> <i className="mdi mdi-circle-medium"></i> {item.sent_category} </Badge>                                                        
                                                                </div> 
                                                                <div>
                                                                    <Link to={item.url} target="_blank" className="badge badge-soft-primary"><i className="ri-arrow-right-up-line align-bottom"></i></Link>
                                                                </div>
                                                            </div>                                               
                                                            <h6 className="text mb-0" >
                                                                <div className="project-details" dangerouslySetInnerHTML={{ __html: item.text.length > 420 ? item.text.substring(0, 420) + "... " : item.text }} />                                                                                            
                                                            </h6>
                                                            {item.media.image && item.media.image != "" &&
                                                                            item.media.image.map((value, key) => ( <img id={key} src={value} alt="" className="img-fluid explore-img" /> ))
                                                            }                                                
                                                        </CardBody>
                                                        <CardBody className="border-top border-top-dashed">
                                                            <div className="d-flex">                                                  
                                                                <div className="flex-grow-1">
                                                                    {
                                                                        item.engagements.comments_extra && item.engagements.comments_extra.length > 0 ? 
                                                                        <button type="button" className="btn btn-soft-success" onClick={() => onShowCommentClick(item.engagements.comments_extra && item.engagements.comments_extra, item.source)}>
                                                                            <h6 className="mb-0">{item.engagements.comments}<i className="bx bx-comment-detail align-bottom text-info"></i></h6>
                                                                        </button>  
                                                                        : <h6 className="mb-0">{item.engagements.comments}<i className="bx bx-comment-detail align-bottom text-info"></i></h6>
                                                                    }                                                        
                                                                </div>
                                                                {item.user.username === "news.mn" && item.engagements.reactions && 
                                                                     <React.Fragment>
                                                                        <div className="flex-grow-1">
                                                                            <h6 className="mb-0">{item.engagements.reactions.wow}<img className="title" src={wow} alt=""  style={{ width: "20px", height: "20px" }} /></h6>                                               
                                                                        </div>   
                                                                        <div className="flex-grow-1">
                                                                            <h6 className="mb-0">{item.engagements.reactions.heart}<img className="title" src={heart} alt=""  style={{ width: "20px", height: "20px" }} /></h6>                                               
                                                                        </div> 
                                                                        <div className="flex-grow-1">
                                                                            <h6 className="mb-0">{item.engagements.reactions.haha}<img className="title" src={haha} alt=""  style={{ width: "20px", height: "20px" }} /></h6>                                               
                                                                        </div> 
                                                                        <div className="flex-grow-1">
                                                                            <h6 className="mb-0">{item.engagements.reactions.love}<img className="title" src={love} alt=""  style={{ width: "20px", height: "20px" }} /></h6>                                               
                                                                        </div> 
                                                                        <div className="flex-grow-1">
                                                                            <h6 className="mb-0">{item.engagements.reactions.sad}<img className="title" src={sad} alt=""  style={{ width: "20px", height: "20px" }} /></h6>                                               
                                                                        </div> 
                                                                        <div className="flex-grow-1">
                                                                            <h6 className="mb-0">{item.engagements.reactions.poop}<img className="title" src={poop} alt=""  style={{ width: "20px", height: "20px" }} /></h6>                                               
                                                                        </div> 
                                                                        <div className="flex-grow-1">
                                                                            <h6 className="mb-0">{item.engagements.reactions.angry}<img className="title" src={angry} alt=""  style={{ width: "20px", height: "20px" }} /></h6>                                               
                                                                        </div> 
                                                                       
                                                                     </React.Fragment>
                                                                } 
                                                                {item.user.username === "zarig.mn" && item.engagements.reactions && 
                                                                     <React.Fragment>
                                                                        <div className="flex-grow-1">
                                                                            <h6 className="mb-0">{item.engagements.reactions.angry}<img className="title" src={angry} alt=""  style={{ width: "20px", height: "20px" }} /></h6>                                               
                                                                        </div>   
                                                                        <div className="flex-grow-1">
                                                                            <h6 className="mb-0">{item.engagements.reactions.care}<img className="title" src={care} alt=""  style={{ width: "20px", height: "20px" }} /></h6>                                               
                                                                        </div> 
                                                                        <div className="flex-grow-1">
                                                                            <h6 className="mb-0">{item.engagements.reactions.haha}<img className="title" src={haha} alt=""  style={{ width: "20px", height: "20px" }} /></h6>                                               
                                                                        </div> 
                                                                        <div className="flex-grow-1">
                                                                            <h6 className="mb-0">{item.engagements.reactions.like}<img className="title" src={like} alt=""  style={{ width: "20px", height: "20px" }} /></h6>                                               
                                                                        </div> 
                                                                        <div className="flex-grow-1">
                                                                            <h6 className="mb-0">{item.engagements.reactions.love}<img className="title" src={love} alt=""  style={{ width: "20px", height: "20px" }} /></h6>                                               
                                                                        </div> 
                                                                        <div className="flex-grow-1">
                                                                            <h6 className="mb-0">{item.engagements.reactions.sad}<img className="title" src={sad} alt=""  style={{ width: "20px", height: "20px" }} /></h6>                                               
                                                                        </div> 
                                                                        <div className="flex-grow-1">
                                                                            <h6 className="mb-0">{item.engagements.reactions.wow}<img className="title" src={wow} alt=""  style={{ width: "20px", height: "20px" }} /></h6>                                               
                                                                        </div> 
                                                                       
                                                                     </React.Fragment>
                                                                }                                                                                                                                                        
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                    : item.source === "instagram" &&
                                                    <Card className="mb-2" key={key}>
                                                        <CardBody>
                                                            <div className="d-flex mb-3">
                                                                <div className="flex-shrink-0 avatar-md">
                                                                    <div className="avatar-title bg-light rounded shadow">
                                                                        <img src="https://firebasestorage.googleapis.com/v0/b/mindx-384a6.appspot.com/o/logos%2Fig-user.png?alt=media&token=d99f33ae-7589-46e7-aade-6bc8d45955cc" alt="" className="avatar-sm" />
                                                                    </div>
                                                                </div>
                                                                <div className="flex-grow-1 ms-3">
                                                                    <h5 className="fs-15 mb-1">{item.user.username}</h5>
                                                                    <span className={`badge badge-soft-${
                                                                            item.source === "facebook"
                                                                            ? "primary"
                                                                            : item.source === "twitter"
                                                                            ? "info"
                                                                            : item.source === "instagram"
                                                                            ? "danger"
                                                                            : "warning"
                                                                        }`}>
                                                                       {item.source === "facebook"
                                                                            ? "facebook"
                                                                            : item.source === "twitter"
                                                                            ? "x/twitter"
                                                                            : item.source === "instagram"
                                                                            ? "instagram"
                                                                            : "мэдээний сайт"
                                                                       }</span>
                                                                    <p className="text-muted mb-2">{item.user.user_id}</p> 
                                                                    <h6 className={"flex-shrink-0 text-" + item.dateClass + " mb-0"}><i className="ri-time-line align-bottom"></i> 
                                                                    {handleValidDate(item.date)},{" "}
                                                                    <small className="text-muted">{handleValidTime(item.date)}</small></h6>                                                       
                                                                </div>
                                                                <div>
                                                                    <Link to={item.url} target="_blank" className="badge badge-soft-primary"><i className="ri-arrow-right-up-line align-bottom"></i></Link>
                                                                </div>
                                                            </div>  
                                                            <ShowMoreText
                                                                        lines={10}
                                                                        more={<span style={{ color: "#0b71b9" }}>Цааш...</span>}
                                                                        less={<span style={{ color: "#0b71b9" }}>Хураах</span>}
                                                                        className="content-css"
                                                                        anchorClass="my-anchor-css-class"
                                                                        expanded={false}
                                                                        width={0}
                                                                        >
                                                                        <p>{item.text}</p>
                                                            </ShowMoreText>                                              
                                                            {item.media.image &&
                                                                <img src={"https://datachain.art/api/images/DataChainMedia/" + item.media.image} alt="" className="img-fluid explore-img" />
                                                            }                                         
                                                        </CardBody>
                                                        <CardBody className="border-top border-top-dashed">
                                                            <div className="d-flex">                                                   
                                                                <div className="flex-grow-1">
                                                                    <h6 className="mb-0">{item.engagements.comments}<i className="bx bx-comment-detail align-bottom text-info"></i></h6>
                                                                </div>
                                                                <div className="flex-grow-1">
                                                                    <h6 className="mb-0">{item.engagements.likes}<i className="ri-heart-fill align-bottom text-danger"></i></h6>
                                                                </div>                                                   
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                ))
                                            )
                                            }
                                </Col>

                            </React.Fragment>
                        
                    </Row>
                  </CardBody> */}
                </Col>
            </Row >
      </Container >
    </div >
  );
};

export default Report;
