import React from 'react';
import { Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from 'reactstrap';
import CountUp from "react-countup";

// Import Images
import Bgd from "../../assets/images/bg-d.png";
import { Link } from 'react-router-dom';

import * as moment from "moment";
import 'moment-timezone';

const TaskOverview = ({ taskName, taskDesc, taskValidFrom, taskValidTo, taskImage }) => {
    const handleValidDate = date => {
        const date1 = moment(new Date(date)).format("Y-MM-DD");
        return date1;
    };
    
    const handleValidTime = (time) => {
        const time1 = new Date(time);
        const getHour = time1.getUTCHours();
        const getMin = time1.getUTCMinutes();
        const getTime = `${getHour}:${getMin}`;
        const updateTime = moment.utc(getTime, 'HH:mm').format('HH:mm');
        return updateTime;
    };

    return (
        <React.Fragment>
            <Row>
                <Col xl={3}>
                    <Card className="overflow-hidden">
                        <CardBody className="bg-marketplace d-flex">
                            <div className="flex-grow-1">
                                    <div className="avatar-xl">
                                        <img src={taskImage} alt="user-img"
                                            className="img-thumbnail rounded-circle" />
                                    </div>
                            </div>
                            <div className="flex-grow-1">
                                <h4 className="fs-18 lh-base mb-0"><span className="text-primary">{taskName}</span> </h4>
                                <p className="mb-0 mt-2 pt-1 text-muted">{taskDesc}</p>
                                <p className="mb-0 mt-2 pt-1 text-muted">
                                    {handleValidDate(taskValidFrom)},{" "}
                                                    <small className="text-muted">{handleValidTime(taskValidFrom)}</small>
                                    {" - "} {handleValidDate(taskValidTo)},{" "}
                                                    <small className="text-muted">{handleValidTime(taskValidTo)}</small>                                                
                                </p>
                                <div className="d-flex gap-3 mt-4">
                                    <Link to="/#" className="btn btn-primary">Discover Now </Link>
                                    <Link to="/#" className="btn btn-success">Нийтлэл</Link>
                                </div>                                                                                        
                            </div>                                                     
                        </CardBody>
                    </Card>
                </Col>
                <Col xl={3} md={6}>
                    <Card className="card-height-100">
                        <CardBody>
                            <div className="float-end">
                                <UncontrolledDropdown className="card-header-dropdown">
                                    <DropdownToggle tag="a" className="text-reset dropdown-btn" role="button">
                                        <span className="text-muted fs-18"><i className="mdi mdi-dots-vertical align-middle"></i></span>
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-end">
                                        <DropdownItem>Today</DropdownItem>
                                        <DropdownItem>Last Week</DropdownItem>
                                        <DropdownItem>Last Month</DropdownItem>
                                        <DropdownItem>Current Year</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </div>
                            <div className="d-flex align-items-center">
                                <div className="avatar-sm flex-shrink-0">
                                    <span className="avatar-title bg-primary rounded fs-3">
                                        <i className="bx bx-dollar-circle"></i>
                                    </span>
                                </div>
                                <div className="flex-grow-1 ps-3">
                                    <h5 className="text-muted text-uppercase fs-13 mb-0">Total Revenue</h5>
                                </div>
                            </div>
                            <div className="mt-4 pt-1">
                                <h4 className="fs-22 fw-semibold ff-secondary mb-0">$
                                    <span className="counter-value">
                                        <CountUp start={0} end={559526.564} separator={","} decimals={3} duration={4} />
                                    </span>
                                </h4>
                                <p className="mt-4 mb-0 text-muted"><span className="badge bg-soft-danger text-danger mb-0 me-1"> <i className="ri-arrow-down-line align-middle"></i> 3.96 % </span> vs. previous month</p>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col xl={3} md={6}>
                    <Card className="card-height-100">
                        <CardBody>
                            <div className="float-end">
                                <UncontrolledDropdown className="card-header-dropdown">
                                    <DropdownToggle tag="a" className="text-reset dropdown-btn" role="button">
                                        <span className="text-muted fs-18"><i className="mdi mdi-dots-vertical align-middle"></i></span>
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-end">
                                        <DropdownItem>Today</DropdownItem>
                                        <DropdownItem>Last Week</DropdownItem>
                                        <DropdownItem>Last Month</DropdownItem>
                                        <DropdownItem>Current Year</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </div>
                            <div className="d-flex align-items-center">
                                <div className="avatar-sm flex-shrink-0">
                                    <span className="avatar-title bg-danger rounded fs-3">
                                        <i className="bx bx-wallet"></i>
                                    </span>
                                </div>
                                <div className="flex-grow-1 ps-3">
                                    <h5 className="text-muted text-uppercase fs-13 mb-0">Estimated</h5>
                                </div>
                            </div>
                            <div className="mt-4 pt-1">
                                <h4 className="fs-22 fw-semibold ff-secondary mb-0">$
                                    <span className="counter-value">
                                        <CountUp start={0} end={624562.564} separator={","} decimals={3} duration={4} />
                                    </span>
                                </h4>
                                <p className="mt-4 mb-0 text-muted"><span className="badge bg-soft-success text-success mb-0"> <i className="ri-arrow-up-line align-middle"></i> 16.24 % </span> vs. previous month</p>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col xl={3} md={6}>
                    <Card className="card-height-100">
                        <CardBody>
                            <div className="float-end">
                                <UncontrolledDropdown className="card-header-dropdown">
                                    <DropdownToggle tag="a" className="text-reset dropdown-btn" role="button">
                                        <span className="text-muted fs-18"><i className="mdi mdi-dots-vertical align-middle"></i></span>
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-end">
                                        <DropdownItem>Today</DropdownItem>
                                        <DropdownItem>Last Week</DropdownItem>
                                        <DropdownItem>Last Month</DropdownItem>
                                        <DropdownItem>Current Year</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </div>
                            <div className="d-flex align-items-center">
                                <div className="avatar-sm flex-shrink-0">
                                    <span className="avatar-title bg-primary rounded fs-3">
                                        <i className="bx bx-dollar-circle"></i>
                                    </span>
                                </div>
                                <div className="flex-grow-1 ps-3">
                                    <h5 className="text-muted text-uppercase fs-13 mb-0">Total Revenue</h5>
                                </div>
                            </div>
                            <div className="mt-4 pt-1">
                                <h4 className="fs-22 fw-semibold ff-secondary mb-0">$
                                    <span className="counter-value">
                                        <CountUp start={0} end={559526.564} separator={","} decimals={3} duration={4} />
                                    </span>
                                </h4>
                                <p className="mt-4 mb-0 text-muted"><span className="badge bg-soft-danger text-danger mb-0 me-1"> <i className="ri-arrow-down-line align-middle"></i> 3.96 % </span> vs. previous month</p>
                            </div>
                        </CardBody>
                    </Card>
                </Col>                
            </Row>
        </React.Fragment>
    );
};

export default TaskOverview;