import { createSlice } from "@reduxjs/toolkit";
import { getTop10Posts, getTheLatestPosts, getFBPosts, getTWPosts, getNWPosts, getIGPosts, addNewPost, updatePost, deletePost, 
         getStatsPosts, getTotalStatsPosts, getTotalStatsQueryPosts, 
         getStatsFBPosts, getStatsTWPosts, getStatsNWPosts, getStatsIGPosts,
         getRPTStatsPosts, getRPTStatsFBPosts, getRPTStatsTWPosts, getRPTStatsNWPosts, getRPTStatsIGPosts, getRPTTotalStatsPosts, getRPTPosts } from './thunk';

export const initialState = {
  top10posts: [],
  thelatestposts: [],
  totalstatsposts: [],
  rpttotalstatsposts: [],
  totalstatsQueryposts: [],

  posts: [],
  fbposts: [],
  twposts: [],
  nwposts: [],
  igposts: [],
  rptposts: [],

  statsposts: [],  
  rptstatsposts: [], 
  statsFBposts: [],
  statsTWposts: [],
  statsNWposts: [],
  statsIGposts: [],

  rptstatFBsposts: [], 
  rptstatTWsposts: [], 
  rptstatNWsposts: [], 
  rptstatIGsposts: [], 

  top10error: {},
  thelatesterror: {},
  fberror: {},
  twerror: {},
  nwerror: {},
  igerror: {}, 
  rpterror: {}, 
  statserror: {},
  totalstatserror: {},
  rptstatserror: {},
  rptstatsFBerror: {},
  rptstatsTWerror: {},
  rptstatsNWerror: {},
  rptstatsIGerror: {},
  rpttotalstatserror: {},
  totalstatsQueryerror: {},
  statFBserror: {},
  statTWserror: {},
  statNWserror: {},
  statIGserror: {},

  top10postsLoading: {},
  thelatestpostsLoading: {},
  rptpostsLoading: {},
  fbpostsLoading: {},
  twpostsLoading: {},
  nwpostsLoading: {},
  igpostsLoading: {},
  statspostsLoading: {},
  rptstatspostsLoading: {},
  rpttotalstatspostsLoading: [],
};

const postSlice = createSlice({
  name: "post",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //The latest posts
    builder.addCase(getTheLatestPosts.fulfilled, (state, action) => {
      state.thelatestposts = action.payload;
      state.isTheLatestPostFail = false;
      state.isTheLatestPostSuccess = true;
      state.top10postsLoading = false;
    });

    builder.addCase(getTheLatestPosts.rejected, (state, action) => {
      state.thelatesterror = action.payload.thelatesterror || null;
      state.isTheLatestPostFail = true;
      state.isTheLatestPostSuccess = false;
      top10postsLoading = true;
    });

    //Top 10 posts
    builder.addCase(getTop10Posts.fulfilled, (state, action) => {
      state.top10posts = action.payload;
      state.isTop10PostFail = false;
      state.isTop10PostSuccess = true;
      state.thelatestpostsLoading = false;
    });

    builder.addCase(getTop10Posts.rejected, (state, action) => {
      state.top10error = action.payload.top10error || null;
      state.isTop10PostFail = true;
      state.isTop10PostSuccess = false;
      state.thelatestpostsLoading = true;
    });

    //total
    builder.addCase(getTotalStatsPosts.fulfilled, (state, action) => {
      state.totalstatsposts = action.payload;
      state.isTotalStatsPostFail = false;
      state.isTotalStatsPostSuccess = true;
    });

    builder.addCase(getTotalStatsPosts.rejected, (state, action) => {
      state.totalstatsposts = action.payload.totalstatserror || null;
      state.isTotalStatsPostFail = true;
      state.isTotalStatsPostSuccess = false;
    });

    //total report
    builder.addCase(getRPTTotalStatsPosts.fulfilled, (state, action) => {
      state.rpttotalstatsposts = action.payload;
      state.isRPTTotalStatsPostFail = false;
      state.isRPTTotalStatsPostSuccess = true;
    });

    builder.addCase(getRPTTotalStatsPosts.rejected, (state, action) => {
      state.rpttotalstatsposts = action.payload.rpttotalstatserror || null;
      state.isRPTTotalStatsPostFail = true;
      state.isRPTTotalStatsPostSuccess = false;
    });

    //total Query
    builder.addCase(getTotalStatsQueryPosts.fulfilled, (state, action) => {
      state.totalstatsQueryposts = action.payload;
      state.isTotalStatsQueryPostFail = false;
      state.isTotalStatsQueryPostSuccess = true;
    });

    builder.addCase(getTotalStatsQueryPosts.rejected, (state, action) => {
      state.totalstatsQueryposts = action.payload.totalstatsQueryerror || null;
      state.isTotalStatsQueryPostFail = true;
      state.isTotalStatsQueryPostSuccess = false;
    });

    //facebook
    builder.addCase(getStatsFBPosts.fulfilled, (state, action) => {
      state.statsFBposts = action.payload;
      state.isStatsFBPostFail = false;
      state.isStatsFBPostSuccess = true;
    });

    builder.addCase(getStatsFBPosts.rejected, (state, action) => {
      state.statsFBposts = action.payload.statserror || null;
      state.isStatsFBPostFail = true;
      state.isStatsFBPostSuccess = false;
    });

    //twitter
    builder.addCase(getStatsTWPosts.fulfilled, (state, action) => {
      state.statsTWposts = action.payload;
      state.isStatsTWPostFail = false;
      state.isStatsTWPostSuccess = true;
      state.twpostsLoading = false;
    });

    builder.addCase(getStatsTWPosts.rejected, (state, action) => {
      state.statsTWposts = action.payload.statTWserror || null;
      state.isStatsTWPostFail = true;
      state.isStatsTWPostSuccess = false;
      state.twpostsLoading = false;
    });

    //news
    builder.addCase(getStatsNWPosts.fulfilled, (state, action) => {
      state.statsNWposts = action.payload;
      state.isStatsNWPostFail = false;
      state.isStatsNWPostSuccess = true;
      state.nwpostsLoading = false;
    });

    builder.addCase(getStatsNWPosts.rejected, (state, action) => {
      state.statsNWposts = action.payload.statNWserror || null;
      state.isStatsNWPostFail = true;
      state.isStatsNWPostSuccess = false;
      state.nwpostsLoading = true;
    });

    //instagram
    builder.addCase(getStatsIGPosts.fulfilled, (state, action) => {
      state.statsIGposts = action.payload;
      state.isStatsIGPostFail = false;
      state.isStatsIGPostSuccess = true;
      state.igpostsLoading = false;
    });

    builder.addCase(getStatsIGPosts.rejected, (state, action) => {
      state.statsIGposts = action.payload.statIGserror || null;
      state.isStatsIGPostFail = true;
      state.isStatsIGPostSuccess = false;
      state.igpostsLoading = true;
    });

    //total
    builder.addCase(getStatsPosts.fulfilled, (state, action) => {
      state.statsposts = action.payload;
      state.isStatsPostFail = false;
      state.isStatsPostSuccess = true;
    });

    builder.addCase(getStatsPosts.rejected, (state, action) => {
      state.statsposts = action.payload.statserror || null;
      state.isStatsPostFail = true;
      state.isStatsPostSuccess = false;
    });

    //report
    builder.addCase(getRPTStatsPosts.fulfilled, (state, action) => {
      state.rptstatsposts = action.payload;
      state.isRPTStatsPostFail = false;
      state.isRPTStatsPostSuccess = true;
    });

    builder.addCase(getRPTStatsPosts.rejected, (state, action) => {
      state.rptstatsposts = action.payload.rptstatserror || null;
      state.isRPTStatsPostFail = true;
      state.isRPTStatsPostSuccess = false;
    });

    //report fb
    builder.addCase(getRPTStatsFBPosts.fulfilled, (state, action) => {
      state.rptstatsFBposts = action.payload;
      state.isRPTStatsFBPostFail = false;
      state.isRPTStatsFBPostSuccess = true;
    });

    builder.addCase(getRPTStatsFBPosts.rejected, (state, action) => {
      state.rptstatsFBposts = action.payload.rptstatserror || null;
      state.isRPTStatsFBPostFail = true;
      state.isRPTStatsFBPostSuccess = false;
    });

    //report tw
    builder.addCase(getRPTStatsTWPosts.fulfilled, (state, action) => {
      state.rptstatsTWposts = action.payload;
      state.isRPTStatsTWPostFail = false;
      state.isRPTStatsTWPostSuccess = true;
    });

    builder.addCase(getRPTStatsTWPosts.rejected, (state, action) => {
      state.rptstatsTWposts = action.payload.rptstatserror || null;
      state.isRPTStatsTWPostFail = true;
      state.isRPTStatsTWPostSuccess = false;
    });

    //report nw
    builder.addCase(getRPTStatsNWPosts.fulfilled, (state, action) => {
      state.rptstatsNWposts = action.payload;
      state.isRPTStatsNWPostFail = false;
      state.isRPTStatsNWPostSuccess = true;
    });

    builder.addCase(getRPTStatsNWPosts.rejected, (state, action) => {
      state.rptstatsNWposts = action.payload.rptstatserror || null;
      state.isRPTStatsNWPostFail = true;
      state.isRPTStatsNWPostSuccess = false;
    });

    //report ig
    builder.addCase(getRPTStatsIGPosts.fulfilled, (state, action) => {
      state.rptstatsIGposts = action.payload;
      state.isRPTStatsIGPostFail = false;
      state.isRPTStatsIGPostSuccess = true;
    });

    builder.addCase(getRPTStatsIGPosts.rejected, (state, action) => {
      state.rptstatsIGposts = action.payload.rptstatserror || null;
      state.isRPTStatsIGPostFail = true;
      state.isRPTStatsIGPostSuccess = false;
    });

    //report
    builder.addCase(getRPTPosts.fulfilled, (state, action) => {
      state.rptposts = action.payload;
      state.isRPTPostFail = false;
      state.isRPTPostSuccess = true;
    });

    builder.addCase(getRPTPosts.rejected, (state, action) => {
      state.rpterror = action.payload.rpterror || null;
      state.isRPTPostFail = true;
      state.isRPTPostSuccess = false;
    });

    builder.addCase(getFBPosts.fulfilled, (state, action) => {
      state.fbposts = action.payload;
      state.isFBPostFail = false;
      state.isFBPostSuccess = true;
    });

    builder.addCase(getFBPosts.rejected, (state, action) => {
      state.fberror = action.payload.fberror || null;
      state.isFBPostFail = true;
      state.isFBPostSuccess = false;
    });

    builder.addCase(getTWPosts.fulfilled, (state, action) => {
      state.twposts = action.payload;
      state.isTWPostFail = false;
      state.isTWPostSuccess = true;
    });

    builder.addCase(getTWPosts.rejected, (state, action) => {
      state.twerror = action.payload.twerror || null;
      state.isTWPostFail = true;
      state.isTWPostSuccess = false;
    });

    builder.addCase(getNWPosts.fulfilled, (state, action) => {
      state.nwposts = action.payload;
      state.isNWPostFail = false;
      state.isNWPostSuccess = true;
    });

    builder.addCase(getNWPosts.rejected, (state, action) => {
      state.nwerror = action.payload.nwerror || null;
      state.isNWPostFail = true;
      state.isNWPostSuccess = false;
    });

    builder.addCase(getIGPosts.fulfilled, (state, action) => {
      state.igposts = action.payload;
      state.isIGPostFail = false;
      state.isIGPostSuccess = true;
    });

    builder.addCase(getIGPosts.rejected, (state, action) => {
      state.igerror = action.payload.igerror || null;
      state.isIGPostFail = true;
      state.isIGPostSuccess = false;
    });

    builder.addCase(addNewPost.fulfilled, (state, action) => {
      state.posts.push(action.payload);
      state.isPostAdd = true;
      state.isPostAddFail = false;
    });

    builder.addCase(addNewPost.rejected, (state, action) => {
      state.error = action.payload.error || null;
      state.isPostAdd = false;
      state.isPostAddFail = true;
    });

    builder.addCase(updatePost.fulfilled, (state, action) => {
      state.posts = state.posts.map(post =>
        post._id.toString() === action.payload._id.toString()
          ? { ...post, ...action.payload }
          : post
      );
      state.isPostUpdate = true;
      state.isPostUpdateFail = false;
    });

    builder.addCase(updatePost.rejected, (state, action) => {
      state.error = action.payload.error || null;
      state.isPostUpdate = false;
      state.isPostUpdateFail = true;
    });

    builder.addCase(deletePost.fulfilled, (state, action) => {
      state.posts = (state.posts || []).filter((post) => post._id.toString() !== action.payload.post.toString());

      state.isPostDelete = true;
      state.isPostDeleteFail = false;
    });

    builder.addCase(deletePost.rejected, (state, action) => {
      state.error = action.payload.error || null;
      state.isPostDelete = false;
      state.isPostDeleteFail = true;
    });

  },
});

export default postSlice.reducer;