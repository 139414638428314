import { createSlice } from "@reduxjs/toolkit";
import { getUsers, addNewUser, updateUser, deleteUser } from './thunk';

export const initialState = {
  crmusers: [],
  error: {}
};

const crmSlice = createSlice({
  name: "crm",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUsers.fulfilled, (state, action) => {
      state.crmusers = action.payload;
      state.isUserFailed = false;
      state.isUserSuccess = true;
    });

    builder.addCase(getUsers.rejected, (state, action) => {
      state.error = action.payload.error || null;
      state.isUserFailed = true;
      state.isUserSuccess = false;
    });

    builder.addCase(addNewUser.fulfilled, (state, action) => {
      state.crmusers.push(action.payload);
      state.isContactAdd = true;
      state.isContactAddFail = false;
    });

    builder.addCase(addNewUser.rejected, (state, action) => {
      state.error = action.payload.error || null;
      state.isContactAdd = false;
      state.isContactAddFail = true;
    });

    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.crmusers = state.crmusers.map(user =>
        user._id.toString() === action.payload._id.toString()
          ? { ...user, ...action.payload }
          : user
      );
      state.isContactUpdate = true;
      state.isContactUpdateFail = false;
    });

    builder.addCase(updateUser.rejected, (state, action) => {
      state.error = action.payload.error || null;
      state.isUserUpdate = false;
      state.isUserUpdateFail = true;
    });

    builder.addCase(deleteUser.fulfilled, (state, action) => {
      state.crmusers = (state.crmusers || []).filter((contact) => contact._id.toString() !== action.payload.contact.toString());
      // console.log(state.crmusers);

      // state.crmusers = state.crmusers.filter(
      //   user => user._id.toString() !== action.payload.toString()
      // );

      state.isUserDelete = true;
      state.isUserDeleteFail = false;
    });

    builder.addCase(deleteUser.rejected, (state, action) => {
      state.error = action.payload.error || null;
      state.isUserDelete = false;
      state.isUserDeleteFail = true;
    });

  },
});

export default crmSlice.reducer;