import React from "react";
import { Navigate } from "react-router-dom";

//Dashboard
import DashboardCampaign from "../pages/DashboardCampaign";
import AdvancedDashboard from "../pages/AdvancedDashboard";

//Crm Pages
import CrmContacts from "../pages/Crm/CrmContacts";

//Campaign
import Campaigns from "../pages/Campaign/index";
import Posts from "../pages/Campaign/Posts/index";
import Report from "../pages/Campaign/Report/index";

//News
import NewsList from "../pages/News/index";
import AllNews from "../pages/News/Posts/index";

//Election2024
import Election2024 from "../pages/Election2024/index";
import Election2024List from "../pages/Election2024/List/index";

// //Ecommerce Pages
import EcommerceSellers from "../pages/Ecommerce/EcommerceSellers/index";

//pages
import Maintenance from '../pages/Pages/Maintenance/Maintenance';
import ComingSoon from '../pages/Pages/ComingSoon/ComingSoon';

//login
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";

// User Profile
import UserProfile from "../pages/Authentication/user-profile";

import PrivacyPolicy from "../pages/Pages/PrivacyPolicy";
import TermsCondition from "../pages/Pages/TermsCondition";

const authProtectedRoutes = [    
  { path: "/dashboard", component: <DashboardCampaign /> },
  { path: "/main", component: <AdvancedDashboard /> },
  { path: "/index", component: <DashboardCampaign /> },
  { path: "/projects", component: <EcommerceSellers /> },
  { path: "/campaigns", component: <Campaigns /> },
  { path: "/posts", component: <Posts /> },
  { path: "/report", component: <Report /> },
  { path: "/newslist", component: <NewsList /> },
  { path: "/news", component: <AllNews /> },
  { path: "/election2024control", component: <Election2024 /> },
  { path: "/election2024list", component: <Election2024List /> },

  //Crm
  { path: "/users", component: <CrmContacts /> },
  
  //Pages
  { path: "/pages-privacy-policy", component: <PrivacyPolicy /> },
  { path: "/pages-term-conditions", component: <TermsCondition /> },

  //User Profile
  { path: "/profile", component: <UserProfile /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/campaigns" />,
  },
  { path: "*", component: <Navigate to="/campaigns" /> },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },  
  
  { path: "/pages-maintenance", component: <Maintenance /> },
  { path: "/pages-coming-soon", component: <ComingSoon /> }

];

export { authProtectedRoutes, publicRoutes };