import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Link } from 'react-router-dom';
import * as moment from "moment";
import { Card, CardBody, CardHeader, Col, Dropdown, Badge, DropdownItem, DropdownMenu, DropdownToggle, Input, Nav, NavItem, NavLink, Offcanvas, OffcanvasBody, Pagination, PaginationItem, PaginationLink, Row, TabContent, TabPane } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import classnames from "classnames";

// import Images
import smallImage1 from '../../../assets/images/small/img-1.jpg';
import smallImage3 from '../../../assets/images/small/img-3.jpg';
import smallImage4 from '../../../assets/images/small/img-4.jpg';
import smallImage5 from '../../../assets/images/small/img-5.jpg';
import { SearchGallery, news, video, swiper  } from '../../../common/data';
import { Spinner } from 'reactstrap';

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";
import Rating from "react-rating";

import like from "../../../assets/images/emojis/liked.png";
import love from "../../../assets/images/emojis/love.png";
import care from "../../../assets/images/emojis/care.png";
import haha from "../../../assets/images/emojis/haha.png";
import wow from "../../../assets/images/emojis/wow.png";
import sad from "../../../assets/images/emojis/sad.png";
import angry from "../../../assets/images/emojis/angry.png";
import heart from "../../../assets/images/emojis/heart.png";
import poop from "../../../assets/images/emojis/poop.png";

//Import actions
import {
    getNewsPosts as onGetNewsPosts,
    addNewNewsPost as onAddNewNewsPost,
    updateNewsPost as onUpdateNewsPost,
    deleteNewsPost as onDeleteNewsPost,
    getTotalNewsPosts as onGetTotalNewsPosts,
} from "../../../slices/thunks";

import ReactPaginate from "react-paginate";
import { isEmpty } from "lodash";
import { useSelector, useDispatch } from "react-redux";

const NewsPosts = () => {
    const dispatch = useDispatch();
    const { newsposts, isNewsPostsSuccess, error } = useSelector((state) => ({
        newsposts: state.NewsPosts.newsposts,
        isNewsPostsSuccess: state.NewsPosts.isNewsPostsSuccess,
        error: state.NewsPosts.error,
    }));

    SwiperCore.use([Autoplay]);

    const [newsPostsAll, setNewsPostsAll] = useState(null);
    const [isEdit, setIsEdit] = useState(false);
    const [totalNewsPostCount, setTotalNewsPostCount] = useState(0);
    const [currentPageNumber, setCurrentPageNumber] = useState("1");
    
    //Tab 
    const [activeTab, setActiveTab] = useState('1');
    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };
    //OffCanvas  
    const [isOpen, setIsOpen] = useState(false);

    const toggleOffCanvas = () => {
        setIsOpen(!isOpen);
    };

    //Dropdown
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggledropDown = () => {
        setDropdownOpen(!dropdownOpen);
    };
    
    useEffect(() => {
        try {
            const newsparams = {
                page: "1"              
            };

          dispatch(onGetNewsPosts(newsparams));
          setCurrentPageNumber("0");
        }
        catch {
            return <Navigate to="/" />;
        }       
    }, [dispatch]);

    useEffect(() => {
        setNewsPostsAll(newsposts); 
    }, [newsposts, isNewsPostsSuccess]);

    useEffect(() => {
        if (!isEmpty(newsposts)) {
            setNewsPostsAll(newsposts);
            setIsEdit(false);
        }
    }, [newsposts, isNewsPostsSuccess]);
    
    document.title="Мэдээний веб сайтын нийтлэлүүд | IKHNUDEN - Media Monitoring System";

    const handleNewsPageClick = async (data) => {
        try {
            let currentPage = data.selected + 1;
            setCurrentPageNumber(data.selected);

            const newsparams = {                
                page: currentPage
            };
        
            dispatch(onGetNewsPosts(newsparams));
        }
        catch {
            return <Navigate to="/" />;
        }
    };

    const formatValidDate = date => { 
        const fullDate = new Date(date);
        const getYear = fullDate.getFullYear();
        const getMonth = fullDate.getMonth() + 1;
        const getDay = fullDate.getDate();
        return getYear + "-" + getMonth + "-" + getDay;
    };

    const handleValidDate = date => {
        const date1 = moment(new Date(date)).format("Y-MM-DD");
        return date1;
    };
    
    const handleValidTime = (time) => {
        const time1 = new Date(time);
        const getHour = time1.getUTCHours();
        const getMin = time1.getUTCMinutes();
        const getTime = `${getHour}:${getMin}`;
        const updateTime = moment.utc(getTime, 'HH:mm').format('HH:mm');
        return updateTime;
    };
    
    return (
        <React.Fragment>
            <div className="page-content">
                
                <div className="container-fluid">
                    <BreadCrumb title="Мэдээний веб сайтын нийтлэлүүд" pageTitle="Мэдээний веб сайт" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader className="border-0">
                                    <Row className="justify-content-center mb-4">
                                        <Col lg={6}>
                                            <Row className="g-2">
                                                <Col>
                                                    <div className="position-relative mb-3">
                                                        <Input type="text" className="form-control form-control-lg bg-light border-light" placeholder="Хайх..." defaultValue="Хайх..." />
                                                        <Link to="#" className="btn btn-link link-success btn-lg position-absolute end-0 top-0 shadow-none" onClick={toggleOffCanvas}><i className="ri-mic-fill"></i></Link>
                                                    </div>
                                                </Col>
                                                <div className="col-auto">
                                                    <button type="submit" className="btn btn-primary btn-lg waves-effect waves-light"><i className="mdi mdi-magnify me-1"></i> Хайх</button>
                                                </div>
                                            </Row>
                                        </Col>                                        
                                    </Row>

                                    <Offcanvas isOpen={isOpen}
                                        direction="top"
                                        toggle={toggleOffCanvas}
                                        tabIndex="-1"
                                    >
                                        <OffcanvasBody>
                                            <button type="button" className="btn-close text-reset float-end" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                                            <div className="d-flex flex-column h-100 justify-content-center align-items-center">
                                                <div className="search-voice">
                                                    <i className="ri-mic-fill align-middle"></i>
                                                    <span className="voice-wave"></span>
                                                    <span className="voice-wave"></span>
                                                    <span className="voice-wave"></span>
                                                </div>
                                                <h4>Talk to me, what can I do for you?</h4>
                                            </div>
                                        </OffcanvasBody>
                                    </Offcanvas>
                                </CardHeader>
                                <div>
                                    <Nav className="nav-tabs nav-tabs-custom" role="tablist">
                                        <NavItem>
                                            <NavLink
                                                href="#"
                                                className={classnames({ active: activeTab === '1' })}
                                                onClick={() => { toggleTab('1'); }}
                                            >
                                                <i className="ri-list-unordered text-muted align-bottom me-1"></i> Trending
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                href="#"
                                                className={classnames({ active: activeTab === '2' })}
                                                onClick={() => { toggleTab('2'); }}
                                            >
                                                <i className="ri-image-fill text-muted align-bottom me-1"></i> Шинэ мэдээ
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                href="#"
                                                className={classnames({ active: activeTab === '3' })}
                                                onClick={() => { toggleTab('3'); }}
                                            >
                                                <i className="ri-list-unordered text-muted align-bottom me-1"></i> Их уншсан
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                href="#"
                                                className={classnames({ active: activeTab === '4' })}
                                                onClick={() => { toggleTab('4'); }}
                                            >
                                                <i className="ri-video-line text-muted align-bottom me-1"></i> Хадгалсан мэдээ
                                            </NavLink>
                                        </NavItem>                                        
                                    </Nav>
                                </div>
                                <CardBody className="p-4">
                                    <TabContent activeTab={activeTab} className="text-muted">
                                        <TabPane tabId="1">
                                                <Row className="g-3">                                                   
                                                    <Col xxl={12}>
                                                        {<ReactPaginate
                                                            previousLabel={"←"}
                                                            nextLabel={"→"}
                                                            breakLabel={"..."}
                                                            pageCount={100}
                                                            marginPagesDisplayed={5}
                                                            pageRangeDisplayed={2}
                                                            onPageChange={handleNewsPageClick}
                                                            forcePage={Number(currentPageNumber)}
                                                            containerClassName={"pagination justify-content-center"}
                                                            pageClassName={"page-item"}
                                                            pageLinkClassName={"page-link"}
                                                            previousClassName={"page-item"}
                                                            previousLinkClassName={"page-link"}
                                                            nextClassName={"page-item"}
                                                            nextLinkClassName={"page-link"}
                                                            breakClassName={"page-item"}
                                                            breakLinkClassName={"page-link"}
                                                            activeClassName={"active"}
                                                        />
                                                        }
                                                    </Col>
                                                </Row>
                                                <Row
                                                    className="row-cols-xxl-5 row-cols-xl-4 row-cols-lg-3 row-cols-md-2 row-cols-1"
                                                >
                                                    {isNewsPostsSuccess ? newsPostsAll && newsPostsAll.map((item, key) => (<Col className="list-element" key={key}>
                                                        <Card className="explore-box card-animate border">                                                           
                                                            <CardBody>                                                            
                                                            <div className="d-flex align-items-center mb-3">
                                                                <img src={item.source_imageUrl} alt="" className="avatar-xs rounded-circle" />
                                                                <div className="ms-2 flex-grow-1">
                                                                    <h6 className="mb-0 fs-15">{item.source_sub}</h6>
                                                                    <p className="mb-0 text-muted">
                                                                    <i className="ri-time-line align-bottom"></i> 
                                                                    {item.date.split('T')[0]},{" "}
                                                                    <small className="text-muted">{handleValidTime(item.date)}</small>
                                                                    </p>
                                                                </div>
                                                                <div className="bookmark-icon">
                                                                    <button type="button"
                                                                        className={item.isActive ? "btn btn-icon active " : "btn btn-icon"}
                                                                        data-bs-toggle="button"
                                                                        aria-pressed="true"
                                                                        onClick={(e) => favouriteBtn(e.target)}
                                                                    >
                                                                        <i className="mdi mdi-content-save fs-16"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div className="explore-place-bid-img overflow-hidden rounded">
                                                                {
                                                                    item.media.image && item.media.image != "" &&
                                                                        item.media.image.map((value, key) => ( <img id={key} src={value} alt="" className="img-fluid explore-img" /> ))
                                                                }                                                                 
                                                            </div>
                                                            <div className="mt-3">                                                                                                                               
                                                                <h5 className="mb-1">
                                                                    <Link to={item.url} target="_blank">{item.title}</Link>
                                                                </h5>
                                                                <p className="text-muted mb-0">{item.desc}</p>                                                                
                                                            </div>
                                                            </CardBody>
                                                            <div className="card-footer border-top border-top-dashed">
                                                                <div className="d-flex align-items-center">
                                                                    <div className="flex-grow-1 fs-14">
                                                                        <i className="ri-price-tag-3-fill text-warning align-bottom me-1"></i>
                                                                        <span className="fw-medium">{item.category_name}</span>
                                                                    </div>
                                                                    <Rating
                                                                        initialRating={1.5}
                                                                        fractions={2}
                                                                        emptySymbol="mdi mdi-star-outline text-muted "
                                                                        fullSymbol="mdi mdi-star text-warning "
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="card-footer border-top border-top-dashed">                                                                
                                                                <div className="d-flex align-items-center"> 
                                                                    <div className="flex-grow-1">
                                                                        <h6 className="mb-0">{item.engagements.comments}<i className="bx bx-comment-detail align-bottom text-info"></i></h6> 
                                                                    </div>                                                                                                                                                                                                   
                                                                    <div className="flex-grow-1">
                                                                        <h6 className="mb-0">{item.engagements.wow}<img className="title" src={wow} alt=""  style={{ width: "20px", height: "20px" }} /></h6>                                               
                                                                    </div>   
                                                                    <div className="flex-grow-1">
                                                                        <h6 className="mb-0">{item.engagements.heart}<img className="title" src={heart} alt=""  style={{ width: "20px", height: "20px" }} /></h6>                                               
                                                                    </div> 
                                                                    <div className="flex-grow-1">
                                                                        <h6 className="mb-0">{item.engagements.haha}<img className="title" src={haha} alt=""  style={{ width: "20px", height: "20px" }} /></h6>                                               
                                                                    </div> 
                                                                    <div className="flex-grow-1">
                                                                        <h6 className="mb-0">{item.engagements.love}<img className="title" src={love} alt=""  style={{ width: "20px", height: "20px" }} /></h6>                                               
                                                                    </div> 
                                                                    <div className="flex-grow-1">
                                                                        <h6 className="mb-0">{item.engagements.sad}<img className="title" src={sad} alt=""  style={{ width: "20px", height: "20px" }} /></h6>                                               
                                                                    </div> 
                                                                    <div className="flex-grow-1">
                                                                        <h6 className="mb-0">{item.engagements.poop}<img className="title" src={poop} alt=""  style={{ width: "20px", height: "20px" }} /></h6>                                               
                                                                    </div> 
                                                                    <div className="flex-grow-1">
                                                                        <h6 className="mb-0">{item.engagements.angry}<img className="title" src={angry} alt=""  style={{ width: "20px", height: "20px" }} /></h6>                                               
                                                                    </div> 
                                                                </div>
                                                            </div>                                                           
                                                        </Card>
                                                    </Col>)) : <Spinner color="primary"> Loading... </Spinner>}
                                                </Row>
                                                {<ReactPaginate
                                                            previousLabel={"←"}
                                                            nextLabel={"→"}
                                                            breakLabel={"..."}
                                                            pageCount={100}
                                                            marginPagesDisplayed={5}
                                                            pageRangeDisplayed={2}
                                                            onPageChange={handleNewsPageClick}
                                                            forcePage={Number(currentPageNumber)}
                                                            containerClassName={"pagination justify-content-center"}
                                                            pageClassName={"page-item"}
                                                            pageLinkClassName={"page-link"}
                                                            previousClassName={"page-item"}
                                                            previousLinkClassName={"page-link"}
                                                            nextClassName={"page-item"}
                                                            nextLinkClassName={"page-link"}
                                                            breakClassName={"page-item"}
                                                            breakLinkClassName={"page-link"}
                                                            activeClassName={"active"}
                                                        />
                                                        }
                                        </TabPane>
                                        <TabPane tabId="2">
                                            <Row>
                                                <Col lg={12}>
                                                    
                                                </Col>
                                            </Row>
                                            
                                        </TabPane>
                                        <TabPane tabId="3">
                                           
                                        </TabPane>
                                        <TabPane tabId="4">
                                            <Row>
                                                
                                            </Row>
                                        </TabPane>
                                    </TabContent>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </div>

            </div>
        </React.Fragment>
    );
};

export default NewsPosts;