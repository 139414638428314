import { APIClient } from "./api_helper";

import * as url from "./url_helper";

const api = new APIClient();

// Gets the logged in user data from local session
export const getLoggedInUser = () => {
  const user = localStorage.getItem("authUser");
  if (user) return JSON.parse(user);
  return null;
};

// //is user is logged in
export const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

// Register Method
export const postJwtRegister = (url, data) => {
  return api.create(url, data)
    .catch(err => {
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message = "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// Login Method
export const postREJwtLogin = data => api.create(url.POST_LOGIN, data);

export const getSignIn = data => api.create(url.POST_LOGIN, data);

// CRM
// get Users
export const getUsers = () => api.get(url.GET_USERS);

// add User
export const addNewUser = contact => api.create(url.ADD_NEW_USER, contact);

// update User
export const updateUser = contact => api.put(url.UPDATE_USER + '/' + contact._id, contact);

// delete User
export const deleteUser = contact => api.delete(url.DELETE_USER + '/' + contact);

// Campaign
// get Campaigns
export const getCampaigns = () => api.get(url.GET_CAMPAIGNS);

export const getCampaignsSpecial = campaign => api.get(url.GET_CAMPAIGNS_SPECIAL + '?toirog=' + campaign.toirog);

// add Campaign
export const addNewCampaign = campaign => api.create(url.ADD_NEW_CAMPAIGN, campaign);

// update Campaign
export const updateCampaign = campaign => api.put(url.UPDATE_CAMPAIGN + '/' + campaign._id, campaign);

// delete Campaign
export const deleteCampaign = campaign => api.delete(url.DELETE_CAMPAIGN + '/' + campaign);

// Posts
// get Posts
export const getFBPosts = post => api.get(url.GET_FB_POSTS + '?type=fb&taskId=' + post.taskId + '&total=no&page=' + post.page + '&text=' + post.text + '&startDate=' + post.startDate + '&endDate=' + post.endDate);

export const getRPTPosts = post => api.get(url.GET_RPT_POSTS + '?taskId=' + post.taskId + '&startDate=' + post.startDate + '&endDate=' + post.endDate);

export const getTWPosts = post => api.get(url.GET_TW_POSTS + '?type=tw&taskId=' + post.taskId + '&total=no&page=' + post.page + '&text=' + post.text + '&startDate=' + post.startDate + '&endDate=' + post.endDate);

export const getNWPosts = post => api.get(url.GET_NW_POSTS + '?type=nw&taskId=' + post.taskId + '&total=no&page=' + post.page + '&text=' + post.text + '&startDate=' + post.startDate + '&endDate=' + post.endDate);

export const getIGPosts = post => api.get(url.GET_IG_POSTS + '?type=ig&taskId=' + post.taskId + '&total=no&page=' + post.page + '&text=' + post.text + '&startDate=' + post.startDate + '&endDate=' + post.endDate);

export const getTop10Posts = post => api.get(url.GET_TOP10_POSTS + '?taskId=' + post.taskId + '&text=' + post.text + '&startDate=' + post.startDate + '&endDate=' + post.endDate + '&limit=' + post.limit);

export const getTheLatestPosts = post => api.get(url.GET_THELATEST_POSTS + '?taskId=' + post.taskId);

export const getStatsPosts = post => api.get(url.GET_STATS_POSTS + '?taskId=' + post.taskId + '&source=' + post.source + '&dateRange=' + post.dateRange);

export const getTotalStatsPosts = post => api.get(url.GET_TOTALSTATS_POSTS + '?taskId=' + post.taskId + '&source=' + post.source + '&dateRange=' + post.dateRange);

export const getRPTStatsPosts = post => api.get(url.GET_RPTSTATS_POSTS + '?taskId=' + post.taskId + '&source=' + post.source + '&startDate=' + post.startDate + '&endDate=' + post.endDate);

export const getRPTStatsFBPosts = post => api.get(url.GET_RPTSTATS_POSTS + '?taskId=' + post.taskId + '&source=facebook&startDate=' + post.startDate + '&endDate=' + post.endDate);

export const getRPTStatsTWPosts = post => api.get(url.GET_RPTSTATS_POSTS + '?taskId=' + post.taskId + '&source=twitter&startDate=' + post.startDate + '&endDate=' + post.endDate);

export const getRPTStatsNWPosts = post => api.get(url.GET_RPTSTATS_POSTS + '?taskId=' + post.taskId + '&source=news&startDate=' + post.startDate + '&endDate=' + post.endDate);

export const getRPTStatsIGPosts = post => api.get(url.GET_RPTSTATS_POSTS + '?taskId=' + post.taskId + '&source=instagram&startDate=' + post.startDate + '&endDate=' + post.endDate);

export const getRPTTotalStatsPosts = post => api.get(url.GET_RPTTOTALSTATS_POSTS + '?taskId=' + post.taskId + '&source=' + post.source + '&startDate=' + post.startDate + '&endDate=' + post.endDate);

export const getTotalStatsQueryPosts = post => api.get(url.GET_TOTALSTATS_QUERY_POSTS + '?taskId=' + post.taskId + '&source=' + post.source + '&text=' + post.text + '&startDate=' + post.startDate + '&endDate=' + post.endDate);

export const getStatsFBPosts = post => api.get(url.GET_STATS_POSTS + '?taskId=' + post.taskId + '&source=facebook&dateRange=' + post.dateRange);

export const getStatsTWPosts = post => api.get(url.GET_STATS_POSTS + '?taskId=' + post.taskId + '&source=twitter&dateRange=' + post.dateRange);

export const getStatsNWPosts = post => api.get(url.GET_STATS_POSTS + '?taskId=' + post.taskId + '&source=news&dateRange=' + post.dateRange);

export const getStatsIGPosts = post => api.get(url.GET_STATS_POSTS + '?taskId=' + post.taskId + '&source=instagram&dateRange=' + post.dateRange);

// add Post
export const addNewPost = post => api.create(url.ADD_NEW_POST, post);

// update Post
export const updatePost = post => api.put(url.UPDATE_POST + '/' + post._id, post);

// delete Post
export const deletePost = post => api.delete(url.DELETE_POST + '/' + post);

// News
// get News
export const getNews = () => api.get(url.GET_NEWS);

// add News
export const addNewNews = news => api.create(url.ADD_NEW_NEWS, news);

// update News
export const updateNews = news => api.put(url.UPDATE_NEWS + '/' + news._id, news);

// delete News
export const deleteNews = news => api.delete(url.DELETE_NEWS + '/' + news);

// NewsPosts
// get News
export const getNewsPosts = newsposts => api.get(url.GET_NEWS_POSTS + '?page=' + newsposts.page);

// add News
export const addNewNewsPost = newsposts => api.create(url.ADD_NEW_NEWS_POSTS, newsposts);

// update News
export const updateNewsPost = newsposts => api.put(url.UPDATE_NEWS_POSTS + '/' + newsposts._id, newsposts);

// delete News
export const deleteNewsPost = newsposts => api.delete(url.DELETE_NEWS_POSTS + '/' + newsposts);
