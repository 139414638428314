import React from 'react';
import ReactApexChart from "react-apexcharts";

import getChartColorsArray from "../../../Components/Common/ChartsDynamicColor";

const StackedColumn = ({ dataColors, positiveGenderM, positiveGenderFM }) => {
    var chartColumnStackedColors = getChartColorsArray(dataColors);
    const series = [
        {
            name: "Эмэгтэй",
            data: positiveGenderFM,
        },
        {
            name: "Эрэгтэй",
            data: positiveGenderM,
        },
    ];

    const options = {
        chart: {
            stacked: !0,
            toolbar: {
                show: !1,
            },
            zoom: {
                enabled: !0,
            },
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    legend: {
                        position: "bottom",
                        offsetX: -10,
                        offsetY: 0,
                    },
                },
            },
        ],
        plotOptions: {
            bar: {
                horizontal: !1,
                borderRadius: 10,
            },
        },
        xaxis: {
            type: "text",
            categories: [
                "13-17",
                "18-24",
                "25-34",
                "35-44",
                "45-54",
                "55-64",
                "65+",
            ],
        },
        legend: {
            position: "right",
            offsetY: 40,
        },
        fill: {
            opacity: 1,
        },
        colors: chartColumnStackedColors,
    };
    return <ReactApexChart dir="ltr" className="apex-charts" series={series} options={options} type="bar" height={350} />;
};

const StackedNegativeByGender = ({ dataColors, negativeGenderM, negativeGenderFM }) => {
    var chartColumnStackedColors = getChartColorsArray(dataColors);
    const series = [
        {
            name: "Эмэгтэй",
            data: negativeGenderFM,
        },
        {
            name: "Эрэгтэй",
            data: negativeGenderM,
        },
    ];

    const options = {
        chart: {
            stacked: !0,
            toolbar: {
                show: !1,
            },
            zoom: {
                enabled: !0,
            },
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    legend: {
                        position: "bottom",
                        offsetX: -10,
                        offsetY: 0,
                    },
                },
            },
        ],
        plotOptions: {
            bar: {
                horizontal: !1,
                borderRadius: 10,
            },
        },
        xaxis: {
            type: "text",
            categories: [
                "13-17",
                "18-24",
                "25-34",
                "35-44",
                "45-54",
                "55-64",
                "65+",
            ],
        },
        legend: {
            position: "right",
            offsetY: 40,
        },
        fill: {
            opacity: 1,
        },
        colors: chartColumnStackedColors,
    };
    return <ReactApexChart dir="ltr" className="apex-charts" series={series} options={options} type="bar" height={350} />;
};

const PositiveGenderComparison = ({ dataColors, totalMale, totalFemale }) => {
    var chartPieBasicColors = getChartColorsArray(dataColors);
    const series = [totalFemale, totalMale]
    var options = {
        chart: {
            height: 350,
            type: 'pie',
        },
        labels: ['Эмэгтэй', 'Эрэгтэй'],
        legend: {
            position: 'bottom'
        },
        title: {
            text: "Эерэг",
            style: {
                fontWeight: 500,
            },
        },
        dataLabels: {
            dropShadow: {
                enabled: false,
            }
        },
        colors: chartPieBasicColors
    };
    return (
        <ReactApexChart dir="ltr"
            className="apex-charts"
            series={series}
            options={options}
            type="pie"
            height={208}
        />
    )
}

const NegativeGenderComparison = ({ dataColors, totalMale, totalFemale }) => {
    var chartPieBasicColors = getChartColorsArray(dataColors);
    const series = [totalFemale, totalMale]
    var options = {
        chart: {
            height: 350,
            type: 'pie',
        },
        labels: ['Эмэгтэй', 'Эрэгтэй'],
        legend: {
            position: 'bottom'
        },
        title: {
            text: "Сөрөг",
            style: {
                fontWeight: 500,
            },
        },
        dataLabels: {
            dropShadow: {
                enabled: false,
            }
        },
        colors: chartPieBasicColors
    };
    return (
        <ReactApexChart dir="ltr"
            className="apex-charts"
            series={series}
            options={options}
            type="pie"
            height={208}
        />
    )
}

const DiffTotalPositiveLocation = ({ dataColors, totalPositiveLocation }) => {
    var chartTreemapDiffColor = getChartColorsArray(dataColors);
    const series = totalPositiveLocation
    var options = {
        legend: {
            show: false
        },
        chart: {
            height: 350,
            type: 'treemap',
            toolbar: {
                show: false
            }
        },
        title: {
            text: 'Эерэг хандлага үзүүлсэн иргэдийн харьяалал',
            align: 'center',
            style: {
                fontWeight: 500,
            }
        },
        dataLabels: {
            enabled: true,
            style: {
                fontSize: '12px',
            },
            formatter: function (text, op) {
                return [text, op.value]
            },
            offsetY: -4
        },
        colors: chartTreemapDiffColor,
        plotOptions: {
            treemap: {
                distributed: true,
                enableShades: false
            }
        }
    };
    return (
        <ReactApexChart dir="ltr"
            className="apex-charts"
            series={series}
            options={options}
            type="treemap"
            height={365}
        />
    )
};

//color range
const DiffTotalNegativeLocation = ({ dataColors, totalNegativeLocation }) => {
    var chartTreemapRangeColors = getChartColorsArray(dataColors);
    const series = totalNegativeLocation
    var options = {

        legend: {
            show: false
        },
        chart: {
            height: 350,
            type: 'treemap',
            toolbar: {
                show: false
            }
        },
        title: {
            text: 'Сөрөг хандлага үзүүлсэн иргэдийн харьяалал',
            align: 'center',
            style: {
                fontWeight: 500,
            }
        },
        dataLabels: {
            enabled: true,
            style: {
                fontSize: '12px',
            },
            formatter: function (text, op) {
                return [text, op.value]
            },
            offsetY: -4
        },
        plotOptions: {
            treemap: {
                enableShades: true,
                shadeIntensity: 0.5,
                reverseNegativeShade: true,
                colorScale: {
                    ranges: [{
                        from: -6,
                        to: 0,
                        color: chartTreemapRangeColors[0]
                    },
                    {
                        from: 0.001,
                        to: 6,
                        color: chartTreemapRangeColors[1]
                    }
                    ]
                }
            }
        }
    };
    return (
        <ReactApexChart dir="ltr"
            className="apex-charts"
            series={series}
            options={options}
            type="treemap"
            height={365}
        />
    )
}

const DataTotalPositiveLocationBasic = ({ dataColors, totalPositiveLocationData }) => {
    var BasicColors = getChartColorsArray(dataColors);
    const series = [{
        data: totalPositiveLocationData
    }];

    const options = {
        chart: {
            type: 'bar',
            height: 436,
            toolbar: {
                show: false,
            }
        },
        plotOptions: {
            bar: {
                borderRadius: 4,
                horizontal: true,
                distributed: true,
                dataLabels: {
                    position: 'top',
                },
            }
        },        
        dataLabels: {
            enabled: true,
            offsetX: 32,
            style: {
                fontSize: '12px',
                fontWeight: 400,
                colors: ['#adb5bd']
            }
        },

        legend: {
            show: false,
        },
        grid: {
            borderColor: "#f1f1f1",
        },
        colors: BasicColors,       
        xaxis: {
            categories: ['Улаанбаатар',
                        "Архангай",
                        "Баян-Өлгий",
                        "Баянхонгор",
                        "Булган",
                        "Говь-Алтай",
                        "Говьсүмбэр",
                        "Дархан-Уул",
                        "Дорноговь",
                        "Дорнод",
                        "Дундговь",
                        "Завхан",
                        "Орхон",
                        "Өвөрхангай",
                        "Өмнөговь",
                        "Сүхбаатар",
                        "Сэлэнгэ",
                        "Төв",
                        "Увс",
                        "Ховд",
                        "Хөвсгөл",
                        "Хэнтий",
                        "Гадаад улс"
            ],
        }
    };

    return (
        <React.Fragment>
            <ReactApexChart dir="ltr"
                className="apex-charts"
                options={options}
                series={series}
                type="bar"
                height={365}
            />
        </React.Fragment>
    );
};

const DataTotalNegativeLocationBasic = ({ dataColors, totalNegativeLocationData }) => {
    var BasicColors = getChartColorsArray(dataColors);
    const series = [{
        data: totalNegativeLocationData
    }];

    const options = {
        chart: {
            type: 'bar',
            height: 436,
            toolbar: {
                show: false,
            }
        },
        plotOptions: {
            bar: {
                borderRadius: 4,
                horizontal: true,
                distributed: true,
                dataLabels: {
                    position: 'top',
                },
            }
        },        
        dataLabels: {
            enabled: true,
            offsetX: 32,
            style: {
                fontSize: '12px',
                fontWeight: 400,
                colors: ['#adb5bd']
            }
        },

        legend: {
            show: false,
        },
        grid: {
            borderColor: "#f1f1f1",
        },
        colors: BasicColors,      
        xaxis: {
            categories: ['Улаанбаатар',
                        "Архангай",
                        "Баян-Өлгий",
                        "Баянхонгор",
                        "Булган",
                        "Говь-Алтай",
                        "Говьсүмбэр",
                        "Дархан-Уул",
                        "Дорноговь",
                        "Дорнод",
                        "Дундговь",
                        "Завхан",
                        "Орхон",
                        "Өвөрхангай",
                        "Өмнөговь",
                        "Сүхбаатар",
                        "Сэлэнгэ",
                        "Төв",
                        "Увс",
                        "Ховд",
                        "Хөвсгөл",
                        "Хэнтий",
                        "Гадаад улс"
            ],
        }
    };

    return (
        <React.Fragment>
            <ReactApexChart dir="ltr"
                className="apex-charts"
                options={options}
                series={series}
                type="bar"
                height={365}
            />
        </React.Fragment>
    );
};

const DataTotalUBLocationBasic = ({ dataColors, totalUBLocationData }) => {
    var BasicColors = getChartColorsArray(dataColors);
    const series = [{
        data: totalUBLocationData
    }];

    const options = {
        chart: {
            type: 'bar',
            height: 436,
            toolbar: {
                show: false,
            }
        },
        plotOptions: {
            bar: {
                borderRadius: 4,
                horizontal: true,
                distributed: true,
                dataLabels: {
                    position: 'top',
                },
            }
        },        
        dataLabels: {
            enabled: true,
            offsetX: 32,
            style: {
                fontSize: '12px',
                fontWeight: 400,
                colors: ['#adb5bd']
            }
        },

        legend: {
            show: false,
        },
        grid: {
            borderColor: "#f1f1f1",
        },
        colors: BasicColors,          
        xaxis: {
            categories: [ "Чингэлтэй",
                        "Хан-Уул",
                        "Сүхбаатар",
                        "Баянгол",
                        "Сонгинохайрхан",
                        "Баянзүрх",
                        "Налайх",
                        "Багануур",
                        "Багахангай",
            ],
        }
    };

    return (
        <React.Fragment>
            <ReactApexChart dir="ltr"
                className="apex-charts"
                options={options}
                series={series}
                type="bar"
                height={365}
            />
        </React.Fragment>
    );
};

const DataTotalPositiveLocation = ({ dataColors, totalPositiveLocationData }) => {
    var chartDatalabelsBarColors = getChartColorsArray(dataColors);
    const series = [{
        data: totalPositiveLocationData
    }];
    var options = {
        chart: {
            type: 'bar',
            height: 350,
            toolbar: {
                show: false,
            }
        },
        plotOptions: {
            bar: {
                barHeight: '100%',
                distributed: true,
                horizontal: true,
                dataLabels: {
                    position: 'bottom'
                },
            }
        },
        colors: chartDatalabelsBarColors,
        dataLabels: {
            enabled: true,
            textAnchor: 'start',
            style: {
                colors: ['#fff']
            },
            formatter: function (val, opt) {
                return "Тоо: " + val;
            },
            offsetX: 0,
            dropShadow: {
                enabled: false
            }
        },
        stroke: {
            width: 1,
            colors: ['#fff']
        },
        xaxis: {
            categories: ['Улаанбаатар',
                            "Архангай",
                            "Баян-Өлгий",
                            "Баянхонгор",
                            "Булган",
                            "Говь-Алтай",
                            "Говьсүмбэр",
                            "Дархан-Уул",
                            "Дорноговь",
                            "Дорнод",
                            "Дундговь",
                            "Завхан",
                            "Орхон",
                            "Өвөрхангай",
                            "Өмнөговь",
                            "Сүхбаатар",
                            "Сэлэнгэ",
                            "Төв",
                            "Увс",
                            "Ховд",
                            "Хөвсгөл",
                            "Хэнтий",
                            "Гадаад улс"
            ],
        },
        yaxis: {
            labels: {
                show: false
            }
        },
        title: {
            text: 'Custom DataLabels',
            align: 'center',
            floating: true,
            style: {
                fontWeight: 500,
            },
        },
        subtitle: {
            text: 'Category Names as DataLabels inside bars',
            align: 'center',
        },
        tooltip: {
            theme: 'dark',
            x: {
                show: false
            },
            y: {
                title: {
                    formatter: function () {
                        return '';
                    }
                }
            }
        }
    };
    return (
        <React.Fragment>
            <ReactApexChart dir="ltr"
                className="apex-charts"
                options={options}
                series={series}
                type="bar"
                height={350}
            />
        </React.Fragment>
    );
};

const NegativeGenderByAge = ({ dataColors,  negativeGenderM, negativeGenderFM }) => {
    var chartNegativeBarColors = getChartColorsArray(dataColors);
    const series = [
        {
            name: "Эмэгтэй",
            data: negativeGenderFM,
        },
        {
            name: "Эрэгтэй",
            data: negativeGenderM,
        },
    ];

    const options = {
        chart: {
            type: "bar",
            height: 360,
            stacked: !0,
            toolbar: {
                show: !1,
            },
        },
        colors: chartNegativeBarColors,
        plotOptions: {
            bar: {
                horizontal: !0,
                barHeight: "80%",
            },
        },
        dataLabels: {
            enabled: !1,
        },
        stroke: {
            width: 1,
            colors: ["#fff"],
        },

        grid: {
            xaxis: {
                lines: {
                    show: !1,
                },
            },
        },
        yaxis: {
            min: -5,
            max: 5,
            title: {
                text: "Age",
                style: {
                    fontWeight: 600,
                },
            },
        },
        tooltip: {
            shared: !1,
            x: {
                formatter: function (val) {
                    return val;
                },
            },
            y: {
                formatter: function (val) {
                    return Math.abs(val) + "%";
                },
            },
        },
        title: {
            text: "Mauritius population pyramid 2011",
            style: {
                fontWeight: 600,
            },
        },
        xaxis: {
            categories: [
                "13-17",
                "18-24",
                "25-34",
                "35-44",
                "45-54",
                "55-64",
                "65+",
            ],
            title: {
                text: "тоо",
            },
            labels: {
                formatter: function (val) {
                    return Math.abs(Math.round(val)) + "%";
                },
            },
        },
    };

    return (
        <React.Fragment>
            <ReactApexChart dir="ltr"
                className="apex-charts"
                options={options}
                series={series}
                type="bar"
                height={350}
            />
        </React.Fragment>
    );
};

const StackedGenderByAge = ({ dataColors, totalMale, totalFemale }) => {
    var chartStackedBar100Colors = getChartColorsArray(dataColors);
    const series = [
        {
            name: "Эрэгтэй",
            data: [totalMale],
        },
        {
            name: "Эмэгтэй",
            data: [totalFemale],
        }              
    ];

    const options = {
        chart: {
            stacked: !0,
            stackType: "100%",
            toolbar: {
                show: !0,
            },
        },
        plotOptions: {
            bar: {
                horizontal: !0,
            },
        },
        stroke: {
            width: 1,
            colors: ["#fff"],
        },
        title: {            
            style: {
                fontWeight: 600,
            },
        },        
        xaxis: {
            categories: ["Эерэг"],
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val;
                },
            },
        },
        fill: {
            opacity: 1,
        },
        legend: {
            position: "top",
            horizontalAlign: "left",
            offsetX: 40,
        },
        colors: chartStackedBar100Colors,
    };

    return (
        <React.Fragment>
            <ReactApexChart dir="ltr"
                className="apex-charts"
                options={options}
                series={series}
                type="bar"
                height={150}
            />
        </React.Fragment>
    );
};

const StackedUBLocation = ({ dataColors, totalPUBLocationData, totalNUBLocationData }) => {
    var chartStackedBarColors = getChartColorsArray(dataColors);
    const series = [{
        name: 'Эерэг',
        data: totalPUBLocationData
    }, {
        name: 'Сөрөг',
        data: totalNUBLocationData
    }];
    var options = {
        chart: {
            type: 'bar',
            height: 350,
            stacked: true,
            toolbar: {
                show: false,
            }
        },
        plotOptions: {
            bar: {
                horizontal: true,
            },
        },
        stroke: {
            width: 1,
            colors: ['#fff']
        },      
        xaxis: {
            categories: [ "Чингэлтэй",
                        "Хан-Уул",
                        "Сүхбаатар",
                        "Баянгол",
                        "Сонгинохайрхан",
                        "Баянзүрх",
                        "Налайх",
                        "Багануур",
                        "Багахангай",
            ],
            labels: {
                formatter: function (val) {
                    return val + "K";
                }
            }
        },
        yaxis: {
            title: {
                text: undefined
            },
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val + "K";
                }
            }
        },
        fill: {
            opacity: 1
        },
        legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 40
        },
        colors: chartStackedBarColors,
    };

    return (
        <React.Fragment>
            <ReactApexChart dir="ltr"
                className="apex-charts"
                options={options}
                series={series}
                type="bar"
                height={350}
            />
        </React.Fragment>
    );
};

const StackedTotalLocation = ({ dataColors, totalPTotalLocationData, totalNTotalLocationData }) => {
    var chartStackedBarColors = getChartColorsArray(dataColors);
    const series = [{
        name: 'Эерэг',
        data: totalPTotalLocationData
    }, {
        name: 'Сөрөг',
        data: totalNTotalLocationData
    }];
    var options = {
        chart: {
            type: 'bar',
            height: 650,
            stacked: true,
            toolbar: {
                show: false,
            }
        },
        plotOptions: {
            bar: {
                horizontal: true,
            },
        },
        stroke: {
            width: 1,
            colors: ['#fff']
        },       
        xaxis: {
            categories: ['Улаанбаатар',
                        "Архангай",
                        "Баян-Өлгий",
                        "Баянхонгор",
                        "Булган",
                        "Говь-Алтай",
                        "Говьсүмбэр",
                        "Дархан-Уул",
                        "Дорноговь",
                        "Дорнод",
                        "Дундговь",
                        "Завхан",
                        "Орхон",
                        "Өвөрхангай",
                        "Өмнөговь",
                        "Сүхбаатар",
                        "Сэлэнгэ",
                        "Төв",
                        "Увс",
                        "Ховд",
                        "Хөвсгөл",
                        "Хэнтий",
                        "Гадаад улс"
            ],
            labels: {
                formatter: function (val) {
                    return val + "K";
                }
            }
        },
        yaxis: {
            title: {
                text: undefined
            },
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val + "K";
                }
            }
        },
        fill: {
            opacity: 1
        },
        legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 40
        },
        colors: chartStackedBarColors,
    };

    return (
        <React.Fragment>
            <ReactApexChart dir="ltr"
                className="apex-charts"
                options={options}
                series={series}
                type="bar"
                height={650}
            />
        </React.Fragment>
    );
};

const MultipleRadar = ({ dataColors, positiveUB, negativeUB }) => {
    var chartRadarMultiColors = getChartColorsArray(dataColors);
    const series = [{
        name: 'Эерэг',
        data: positiveUB,
    },
    {
        name: 'Сөрөг',
        data: negativeUB,
    }
    ]
    var options = {
        chart: {
            height: 350,
            type: 'radar',
            dropShadow: {
                enabled: true,
                blur: 1,
                left: 1,
                top: 1
            },
            toolbar: {
                show: false
            },
        },
        stroke: {
            width: 2
        },
        fill: {
            opacity: 0.2
        },
        markers: {
            size: 0
        },
        colors: chartRadarMultiColors,
        xaxis: {
            categories: ["Чингэлтэй",
            "Хан-Уул",
            "Сүхбаатар",
            "Баянгол",
            "Сонгинохайрхан",
            "Баянзүрх",
            "Налайх",
            "Багануур",
            "Багахангай"]
        }
    };
    return (
        <ReactApexChart dir="ltr"
            className="apex-charts"
            series={series}
            options={options}
            type="radar"
            height={450}
        />
    )
}

const RealFakeData = ({ dataColors, realfake }) => {
    var chartPieBasicColors = getChartColorsArray(dataColors);
    const series = realfake
    var options = {
        chart: {
            height: 300,
            type: 'pie',
        },
        labels: ['Жинхэнэ хэрэглэгч', 'Хуурамч хэрэглэгч', 'Тодорхойлогдоогүй'],
        legend: {
            position: 'bottom'
        },
        dataLabels: {
            dropShadow: {
                enabled: false,
            }
        },
        colors: chartPieBasicColors
    };
    return (
        <ReactApexChart dir="ltr"
            className="apex-charts"
            series={series}
            options={options}
            type="pie"
            height={350}
        />
    )
}

export {
    StackedColumn, DiffTotalPositiveLocation, DiffTotalNegativeLocation, DataTotalPositiveLocation, DataTotalPositiveLocationBasic, DataTotalNegativeLocationBasic,
    DataTotalUBLocationBasic, MultipleRadar, StackedNegativeByGender, PositiveGenderComparison, NegativeGenderComparison, NegativeGenderByAge, RealFakeData, StackedGenderByAge,
    StackedUBLocation, StackedTotalLocation
};