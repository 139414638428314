import React, { useEffect, useState, useCallback, useMemo } from "react";
//Import Flatepicker
import Flatpickr from "react-flatpickr";
import { Link } from "react-router-dom";
import * as moment from "moment";

//Import Breadcrumb
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import {
  CardBody,
  CardHeader,
  Container,
  Card,
  Row,
  Col,
  Input,
  ModalHeader,
  Modal,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Label,
  Form,
  DropdownItem, DropdownMenu, DropdownToggle,
  UncontrolledDropdown,
  FormFeedback
} from "reactstrap";
import classnames from "classnames";
import { isEmpty } from "lodash";
import Select from "react-select";

//redux
import { useSelector, useDispatch } from "react-redux";

import { getCampaigns as onGetCampaigns,
         getStatsPosts as onGetStatsPosts,
         getTotalStatsPosts as onGetTotalStatsPosts } from "../../../slices/thunks";

import SellerChats from "./SellerChats";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

const EcommerceSellers = () => {
  const dispatch = useDispatch();
  const [campaignList, setCampaignList] = useState([]);
  const [modal, setModal] = useState(false);
  const [companyType, setcompanyType] = useState(null);

  const [keywords, setKeywords] = React.useState([]);

  const { campaigns, statsposts, isStatsPostSuccess, statserror,
    totalstatsposts, isTotalStatsPostSuccess, totalstatserror, } = useSelector((state) => ({
    campaigns: state.Campaign.campaigns,

    statsposts: state.Post.statsposts,
    isStatsPostSuccess: state.Post.isStatsPostSuccess,
    statserror: state.Post.statserror,

    totalstatsposts: state.Post.totalstatsposts,
    isTotalStatsPostSuccess: state.Post.isTotalStatsPostSuccess,
    totalstatserror: state.Post.totalstatserror,
  }));

  useEffect(() => {
    setCampaignList(campaigns);
  }, [campaigns]);

  useEffect(() => {
    dispatch(onGetCampaigns());
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(campaigns)) setCampaignList(campaigns);
  }, [campaigns]);

  const [isEdit, setIsEdit] = useState(false);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setKeywords([])
    } else {
      setModal(true);
    }
  }, [modal]);

  //Tab
  const [activeTab, setActiveTab] = useState("1");
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (campaignList && campaignList.name) || '',
      keywords: (campaignList && campaignList.keywords) || [],
      validFrom: (campaignList && campaignList.email) || moment(new Date()).format("Y.MM.DD"),
      validTo: (campaignList && campaignList.password) || moment(new Date()).format("Y.MM.DD"),
      desc: (campaignList && campaignList.desc) || '',

      status: (campaignList && campaignList.status) || '',
      image: (campaignList && campaignList.image) || '',
      whitelist: (campaignList && campaignList.whitelist) || [],
      priority: (campaignList && campaignList.priority) || '',
      category: (campaignList && campaignList.category) || ''
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Судалгааны нэрийг заавал оруулна"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateContact = {
          _id: contact ? contact._id : 0,
          name: values.name,
          role: values.role,
          email: values.email,
          password: values.password,
          phone: values.phone,
          status: values.status,
          company: values.company,
          designation: values.designation,
          dueDate: date,
          tags: assignTag,
        };
        // update User
        dispatch(onUpdateUser(updateContact));
        validation.resetForm();
      } else {
        const newContact = {
          name: values["name"],
          role: values["role"],
          email: values["email"],
          password: values["password"],
          phone: values["phone"],
          status: values["status"],
          company:  values["company"],
          designation: values["designation"],
          dueDate: date,
          tags: assignTag,
        };
        // save new Contact
        dispatch(onAddNewUser(newContact));
        validation.resetForm();
      }
      toggle();
    },
  });

  // Update Data
  const handleContactClick = useCallback((arg) => {
    const campaign = arg;

    setCampaignList({
      _id: campaign._id,
      name: campaign.name,      
      desc: campaign.desc
    });

    //setTag(contact.tags);
    setIsEdit(true);
    toggle();
  }, [toggle]);

  const onEditClick = (id) => {
    
    campaignList.forEach((item) => {
      if (item._id === id) {
        //setTags(item.keywords);
        setCampaignList({
          _id: item._id,
          name: item.name,      
          desc: item.desc,          
          keywords: item.keywords,       
          validFrom: new Date(`${item.validFrom}`),
          validTo: new Date(`${item.validTo}`),
          status: item.status,
          image: item.image,
        });
        setIsEdit(true);
      }
    });
  };

  const companytypes = [
    {
      options: [
        { label: "Төлөвөөр шүүх", value: "All" },
        { label: "Бүгд", value: "All" },
        { label: "Идэвхтэй", value: "Active" },
        { label: "Идэвхгүй", value: "Suspend" },
        { label: "Хүлээгдэж буй", value: "Pending" },
      ],
    },
  ];

  function handlecompanyType(companyType) {
    setcompanyType(companyType);
  }

  const category = (e) => {
    if (e === "All") {
      var filter = campaigns.filter((item) => item.status !== e);
    } else {
      filter = campaigns.filter((item) => item.status === e);
    }
    setCampaignList(filter);
  };

  const defaultdate = () => {
    let d = new Date(),
      months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return ((d.getFullYear() + '.' + d.getMonth() + '.' + d.getDate()).toString());
  };

  const [dateValidFrom, setDateValidFrom] = useState(defaultdate());
  const [dateValidTo, setDateValidTo] = useState(defaultdate());

  const dateFormaterValidFrom = (e) => {
    const date = e.toString().split(" ");
    const joinDate = (date[3] + "." + date[2] + "." + date[1]).toString();
    setDateValidFrom(joinDate);
  };

  const dateFormaterValidTo = (e) => {
    const date = e.toString().split(" ");
    const joinDate = (date[3] + "." + date[2] + "." + date[1]).toString();
    setDateValidTo(joinDate);
  };

  function collectData(campaignId) {
    try {
        const params = {
          taskId: campaignId,
          source: "all",
          dateRange: "1month"
        };
  
        //dispatch(onGetStatsPosts(params));

        const array = [];
        //statsposts?.map((item) => array.push(item.totalPost));  
        
        return [12, 14, 2, 47, 42, 15, 35, 75, 20, 67, 89];
    }
    catch {
        return <Navigate to="/projects" />;
    }    
  }

  document.title = "Судалгаа | IKHNUDEN - Media Monitoring System";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Судалгааны жагсаалт" pageTitle="Судалгаа" />
          <Card>
            <CardHeader className="border-0 rounded">
              <Row className="g-2">
                <Col xl={3}>
                  <div className="search-box">
                    <Input
                      type="text"
                      className="form-control search"
                      placeholder="Хайх..."
                    />{" "}
                    <i className="ri-search-line search-icon"></i>
                  </div>
                </Col>
                <Col xxl={3} className="ms-auto">
                  <div>
                    <select className="form-control"
                      onChange={(e) => category(e.target.value)}
                    >
                      <option value="All">Төлөвөөр шүүх</option>
                      <option value="All">Бүгд</option>
                      <option value="Active">Идэвхтэй</option>
                      <option value="Suspend">Идэвхгүй</option>
                    </select>
                  </div>
                </Col>                
              </Row>
            </CardHeader>
          </Card>

          <Row className="row-cols-xl-5 row-cols-lg-3 row-cols-md-2 row-cols-1">
            {campaignList && campaignList.map((campaign, key) => (
              <React.Fragment key={key}>
                <Col>
                  <Card className="ribbon-box right overflow-hidden">                  
                      <img src="https://firebasestorage.googleapis.com/v0/b/mindx-384a6.appspot.com/o/logos%2F881563d6444b370fa4ceea0c3183bb4c.gif?alt=media&token=24f6ffc3-e0a3-464d-b5a1-9205a04ff426" alt="" className="object-cover card-img-top" height="120" />
                      <div className="card-body text-center">
                                        <img src={campaign.image} alt="" className="avatar-lg rounded-circle object-cover mt-n5 img-thumbnail border-light mx-auto d-block" />
                                        <Link to="/pages-profile">
                                            <h5 className="mt-2 mb-1">{campaign.name}</h5>
                                        </Link>
                                        <p className="text-muted mb-2">{campaign.desc}</p> 
                      </div>
                      
                      <CardBody className="text-center p-4">                      
                        {campaign.status && (
                          <div className={`ribbon ribbon-${
                              campaign.status === "Active"
                              ? "info"
                              : campaign.status === "Suspend"
                              ? "danger"
                              : "warning"
                          } ribbon-shape trending-ribbon`}>
                            <i className={`ri-${
                              campaign.status === "Active"
                              ? "flashlight"
                              : "stop-circle"
                            }-fill text-white align-bottom`}></i>{" "}
                            <span className="trending-ribbon-text">
                              {
                                campaign.status === "Active" ? "Идэвхтэй" :
                                campaign.status === "Suspend" ? "Идэвхгүй" :
                                "Хүлээгдэж байгаа"
                              }
                            </span>                            
                          </div>
                        )}                      
                                           
                        <div className="mt-4">
                          <Link className="btn btn-info" to="/dashboard" state={{campaignId: campaign._id, 
                                              campaignName: campaign.name,
                                              campaignDesc: campaign.desc,
                                              campaignImage: campaign.image,
                                              campaignStatus: campaign.status,
                                              campaignValidFrom: campaign.validFrom,
                                              campaignValidTo: campaign.validTo
                                            }}>
                              <i className=" ri-dashboard-line me-1 align-bottom"></i>
                              Хянах самбар
                          </Link>
                        </div>
                    </CardBody>
                  </Card>
                </Col>
              </React.Fragment>
            ))}
          </Row>

        </Container>
      </div>
    </React.Fragment>
  );
};

export default EcommerceSellers;