import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";
import * as moment from "moment";
import 'moment-timezone';
import CryptoJS from "crypto-js";

// Import Images
import dummyImg from "../../assets/images/users/user-dummy-img.jpg";

import {
  Col,
  Container,
  Row,
  Card,
  CardHeader,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  ModalFooter,
  Table,
  FormFeedback
} from "reactstrap";
import Select from "react-select";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import DeleteModal from "../../Components/Common/DeleteModal";

//Import actions
import {
  getUsers as onGetUsers,
  addNewUser as onAddNewUser,
  updateUser as onUpdateUser,
  deleteUser as onDeleteUser,
} from "../../slices/thunks";
//redux
import { useSelector, useDispatch } from "react-redux";
import TableContainer from "../../Components/Common/TableContainer";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

import Loader from "../../Components/Common/Loader";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Export Modal
import ExportCSVModal from "../../Components/Common/ExportCSVModal";

import Flatpickr from "react-flatpickr";

const CrmContacts = () => {
  const dispatch = useDispatch();
  const { crmusers, isUserSuccess, error } = useSelector((state) => ({
    crmusers: state.Crm.crmusers,
    isUserSuccess: state.Crm.isUserSuccess,
    error: state.Crm.error,
  }));

  useEffect(() => {
    if (crmusers && !crmusers.length) {
      dispatch(onGetUsers());
    }
  }, [dispatch, crmusers]);

  useEffect(() => {
    setContact(crmusers);
  }, [crmusers]);

  useEffect(() => {
    if (!isEmpty(crmusers)) {
      setContact(crmusers);
      setIsEdit(false);
    }
  }, [crmusers]);

  const [isEdit, setIsEdit] = useState(false);
  const [contact, setContact] = useState([]);

  //delete Conatct
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);

  const [modal, setModal] = useState(false);
  const [tag, setTag] = useState([]);
  const [assignTag, setAssignTag] = useState([]);

  const [showPassword, setShowPassword] = useState(false)
  
  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setContact(null);
      setTag([])
    } else {
      setModal(true);
    }
  }, [modal]);

  // Delete Data
  const handleDeleteContact = () => {
    if (contact) {
      dispatch(onDeleteUser(contact._id));
      setDeleteModal(false);
    }
  };

  const onClickDelete = (contact) => {
    setContact(contact);
    setDeleteModal(true);
  };

  // Add Data
  const handleContactClicks = () => {
    setContact("");
    setIsEdit(false);
    toggle();
  };

  // Date & Time Format

  const dateFormat = () => {
    var d = new Date(),
      months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return (d.getDate() + ' ' + months[d.getMonth()] + ', ' + d.getFullYear());
  };

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (contact && contact.name) || '',
      role: (contact && contact.role) || '',
      email: (contact && contact.email) || '',
      password: (contact && contact.password) || '',
      phone: (contact && contact.phone) || '',

      status: (contact && contact.status) || '',
      address: (contact && contact.address) || '',
      company: (contact && contact.company) || '',
      designation: (contact && contact.designation) || '',
      projects: (contact && contact.projects) || '',
      tags: (contact && contact.tags) || [],
      projects: (contact && contact.projects) || '',
      dueDate: (contact && contact.dueDate) || moment(new Date()).format("Y-MM-DD"),
      limit: (contact && contact.limit) || '2',
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Овог, нэрийг заавал оруулна"),
      email: Yup.string().required("E-mail хаягийг заавал оруулна"),
      password: Yup.string().required("Нууц үгийг заавал оруулна"),
      phone: Yup.string().required("Утасны дугаарыг заавал оруулна"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateContact = {
          _id: contact ? contact._id : 0,
          name: values.name,
          role: values.role,
          email: values.email,
          password: values.password,
          phone: values.phone,
          status: values.status,
          company: values.company,
          designation: values.designation,
          dueDate: date,
          tags: assignTag,
          limit: values.limit,
        };
        // update User
        dispatch(onUpdateUser(updateContact));
        validation.resetForm();
      } else {
        const newContact = {
          name: values["name"],
          role: values["role"],
          email: values["email"],
          password: values["password"],
          phone: values["phone"],
          status: values["status"],
          company:  values["company"],
          designation: values["designation"],
          dueDate: date,
          tags: assignTag,
          limit: values["limit"],
        };
        // save new Contact
        dispatch(onAddNewUser(newContact));
        validation.resetForm();
      }
      toggle();
    },
  });
  
  // function to close the form modal
  const onFormCancel = () => {
    setModal(false);
    setContact("");
    setIsEdit(false);
    validation.resetForm();
    setTag([])
  };

  // Update Data
  const handleContactClick = useCallback((arg) => {
    const contact = arg;

    const bytes = CryptoJS.AES.decrypt(contact.password, "r34l$$$10011");
    const originalPassword = bytes.toString(CryptoJS.enc.Utf8);

    setContact({
      _id: contact._id,
      name: contact.name,
      role: contact.role,
      email: contact.email,
      password: originalPassword,
      phone: contact.phone,
      status: contact.status,
      company: contact.company,     
      designation: contact.designation,
      tags: contact.tags,
      dueDate: handleValidDate(contact.dueDate),
      limit: contact.limit
    });

    setTag(contact.tags);
    setIsEdit(true);
    toggle();
  }, [toggle]);

  // Node API 
  // useEffect(() => {
  //   if (isContactCreated) {
  //     setContact(null);
  //     dispatch(onGetUsers());
  //   }
  // }, [
  //   dispatch,
  //   isContactCreated,
  // ]);

  const handleValidDate = date => {
    const date1 = moment(new Date(date)).format("Y-MM-DD");
    return date1;
  };

  const handleValidTime = (time) => {
    const time1 = new Date(time);
    const getHour = time1.getUTCHours();
    const getMin = time1.getUTCMinutes();
    const getTime = `${getHour}:${getMin}`;
    const updateTime = moment.utc(getTime, 'HH:mm').format('HH:mm');
    return updateTime;
  };

  // Checked All
  const checkedAll = useCallback(() => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".contactCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
    deleteCheckbox();
  }, []);

  const defaultdate = () => {
    let d = new Date(),
      months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return ((d.getFullYear() + '-' + d.getMonth() + '-' + d.getDate()).toString());
  };

  const [date, setDate] = useState(defaultdate());

  const dateformate = (e) => {
    const date = e.toString().split(" ");
    const joinDate = (date[3] + "-" + date[2] + "-" + date[1]).toString();
    setDate(joinDate);
  };

  // Delete Multiple
  const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
  const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);

  const deleteMultiple = () => {
    const checkall = document.getElementById("checkBoxAll");
    selectedCheckBoxDelete.forEach((element) => {
      dispatch(onDeleteUser(element.value));
      setTimeout(() => { toast.clearWaitingQueue(); }, 3000);
    });
    setIsMultiDeleteButton(false);
    checkall.checked = false;
  };

  const deleteCheckbox = () => {
    const ele = document.querySelectorAll(".contactCheckBox:checked");
    ele.length > 0 ? setIsMultiDeleteButton(true) : setIsMultiDeleteButton(false);
    setSelectedCheckBoxDelete(ele);
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: <input type="checkbox" id="checkBoxAll" className="form-check-input" onClick={() => checkedAll()} />,
        Cell: (cellProps) => {
          return <input type="checkbox" className="contactCheckBox form-check-input" value={cellProps.row.original._id} onChange={() => deleteCheckbox()} />;
        },
        id: '#',
      },
      {
        Header: "Овог, нэр",
        accessor: "name",
        filterable: true,
        Cell: (contact) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
                {contact.row.original.image ? <img
                  src={contact.row.original.image}
                  alt=""
                  className="avatar-xxs rounded-circle"
                /> :
                  <div className="flex-shrink-0 avatar-xs me-2">
                    <div className="avatar-title bg-soft-success text-success rounded-circle fs-13">
                      <img src={dummyImg} alt="" className="avatar-xxs rounded-circle" />
                    </div>
                  </div>
                }
              </div>
              <div className="flex-grow-1 ms-2 name">
                {contact.row.original.name}
              </div>
            </div>
          </>
        ),
      },
      {
        Header: "Түвшин",
        accessor: "status",
        filterable: false,
        Cell: (contact) => (
          <>
            <span className={`badge badge-soft-${
                            contact.row.original.role === "SuperAdmin"
                            ? "primary"
                            : contact.row.original.role === "Admin"
                            ? "info"
                            : contact.row.original.role === "Moderator"
                            ? "success"
                            : contact.row.original.role === "Operator"
                            ? "danger"
                            : "warning"
                        } me-1`}>
            {contact.row.original.role === "SuperAdmin"
                        ? "Супер Админ"
                        : contact.row.original.role === "Admin"
                        ? "Админ"
                        : contact.row.original.role === "Moderator"
                        ? "Зохицуулагч"
                        : contact.row.original.role === "Operator"
                        ? "Оператор"
                        : "Хэрэглэгч"}</span>
          </>
        ),
      },
      {
        Header: "Компани",
        accessor: "company",
        filterable: false,
        Cell: (contact) => (
          <>
            <span className="badge badge-soft-primary me-1">{contact.row.original.company}</span>
          </>
        ),
      },
      {
        Header: "E-mail хаяг",
        accessor: "email",
        filterable: true,
      },
      {
        Header: "Утасны дугаар",
        accessor: "phone",
        filterable: true,
      },
      {
        Header: "Төлөв",
        filterable: true,
        Cell: (contact) => (
          <>
            <span className={`badge badge-soft-${
                            contact.row.original.status === "Active"
                            ? "success"
                            : contact.row.original.status === "Suspend"
                            ? "danger"
                            : "warning"
                        } me-1`}>
            {contact.row.original.status === "Active"
                            ? "Идэвхтэй"
                            : contact.row.original.status === "Suspend"
                            ? "Идэвхгүй"
                            : "Хүлээгдэж байгаа"}</span>
          </>
        ),
      },
      {
        Header: "Хүчинтэй хугацаа",
        Cell: (contact) => (
          <>
            {handleValidDate(contact.row.original.dueDate)},{" "}
            <small className="text-muted">{handleValidTime(contact.row.original.dueDate)}</small>
          </>
        ),
      },
      {
        Header: "Үйлдэл",
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">              
              <li className="list-inline-item">
                <UncontrolledDropdown>
                  <DropdownToggle
                    href="#"
                    className="btn btn-soft-secondary btn-sm dropdown"
                    tag="button"
                  >
                    <i className="ri-more-fill align-middle"></i>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem className="dropdown-item" href="#"
                      onClick={() => { const contactData = cellProps.row.original; setInfo(contactData); }}
                    >
                      <i className="ri-eye-fill align-bottom me-2 text-muted"></i>{" "}
                      Харах
                    </DropdownItem>
                    <DropdownItem
                      className="dropdown-item edit-item-btn"
                      href="#"
                      onClick={() => { const contactData = cellProps.row.original; handleContactClick(contactData); }}
                    >
                      <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>{" "}
                      Засварлах
                    </DropdownItem>
                    <DropdownItem
                      className="dropdown-item remove-item-btn"
                      href="#"
                      onClick={() => { const contactData = cellProps.row.original; onClickDelete(contactData); }}
                    >
                      <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>{" "}
                      Устгах
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </li>
            </ul>
          );
        },
      },
    ],
    [handleContactClick, checkedAll]
  );

  const tagsOptions = [
    { label: "Улс төр", value: "Улс төр" },
    { label: "Боловсрол", value: "Боловсрол" },
    { label: "Мэдээллийн технологи", value: "Мэдээллийн технологи" },
    { label: "Тусгай байгууллага", value: "Тусгай байгууллага" },
    { label: "Шинжлэх ухаан", value: "Шинжлэх ухаан" },
    { label: "Хэвлэл мэдээлэл", value: "Хэвлэл мэдээлэл" },
    { label: "Худалдаа", value: "Худалдаа" }
  ];

  function handlestag(tags) {  
    setTag(tags);
    setAssignTag(tags);
  }

  const userRoleOptions = [
    {
      options: [
        { label: "Супер админ", value: "SuperAdmin" },
        { label: "Админ", value: "Admin" },
        { label: "Зохицуулагч", value: "Moderator" },
        { label: "Оператор", value: "Operator" },
        {
          label: "Хэрэглэгч",
          value: "User",
        }
      ],
    },
  ];

  const statusOptions = [
    {
      options: [
        { label: "Идэвхтэй", value: "Active" },
        { label: "Идэвхгүй", value: "Suspend" },
        { label: "Хүлээгдэж буй", value: "Pending" },
      ],
    },
  ];

  // SideBar Contact Deatail
  const [info, setInfo] = useState([]);

  // Export Modal
  const [isExportCSV, setIsExportCSV] = useState(false);

  document.title = "Хэрэглэгчид | IKHNUDEN - Media Monitoring System";
  return (
    <React.Fragment>
      <div className="page-content">
        <ExportCSVModal
          show={isExportCSV}
          onCloseClick={() => setIsExportCSV(false)}
          data={crmusers}
        />

        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDeleteContact}
          onCloseClick={() => setDeleteModal(false)}
        />

        <DeleteModal
          show={deleteModalMulti}
          onDeleteClick={() => {
            deleteMultiple();
            setDeleteModalMulti(false);
          }}
          onCloseClick={() => setDeleteModalMulti(false)}
        />

        <Container fluid>
          <BreadCrumb title="Хэрэглэгчид" pageTitle="ИХНҮДЭН" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <div className="d-flex align-items-center flex-wrap gap-2">
                    <div className="flex-grow-1">
                      <button
                        className="btn btn-info add-btn"
                        onClick={() => {
                          setModal(true);
                        }}
                      >
                        <i className="ri-add-fill me-1 align-bottom"></i> Шинээр хэрэглэгч нэмэх
                      </button>
                    </div>
                    <div className="flex-shrink-0">
                      <div className="hstack text-nowrap gap-2">
                        {isMultiDeleteButton && <button className="btn btn-soft-danger"
                          onClick={() => setDeleteModalMulti(true)}
                        ><i className="ri-delete-bin-2-line"></i></button>}
                        <button className="btn btn-danger">
                          <i className="ri-filter-2-line me-1 align-bottom"></i>{" "}
                          Шүүлт хийх
                        </button>
                        <button className="btn btn-soft-success" onClick={() => setIsExportCSV(true)}>Хадгалах</button>

                        <UncontrolledDropdown>
                          <DropdownToggle
                            href="#"
                            className="btn btn-soft-info"
                            tag="button"
                          >
                            <i className="ri-more-2-fill"></i>
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-end">
                            <DropdownItem className="dropdown-item" href="#">Бүх хугацаа</DropdownItem>
                            <DropdownItem className="dropdown-item" href="#">Сүүлийн 3 хоног</DropdownItem>
                            <DropdownItem className="dropdown-item" href="#">Сүүлийн 1 сар</DropdownItem>
                            <DropdownItem className="dropdown-item" href="#">Сүүлийн 6 сар</DropdownItem>
                            <DropdownItem className="dropdown-item" href="#">Сүүлийн 1 жил</DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>

                      </div>
                    </div>
                  </div>
                </CardHeader>
              </Card>
            </Col>
            <Col xxl={9}>
              <Card id="contactList">
                <CardBody className="pt-0">
                  <div>
                    {isUserSuccess && crmusers.length ? (
                      <TableContainer
                        columns={columns}
                        data={(crmusers || [])}
                        isGlobalFilter={true}
                        isAddUserList={false}
                        customPageSize={10}
                        className="custom-header-css"
                        divClass="table-responsive table-card mb-3"
                        tableClass="align-middle table-nowrap"
                        theadClass="table-light"
                        handleContactClick={handleContactClicks}
                        isContactsFilter={true}
                        SearchPlaceholder='Search for contact...'
                      />
                    ) : (<Loader error={error} />)
                    }
                  </div>

                  <Modal id="showModal" isOpen={modal} toggle={toggle} centered> 
                    <ModalHeader className="bg-soft-info p-3" toggle={toggle}>
                      {!!isEdit ? "Хэрэглэгчийн мэдээллийг засварлах" : "Шинээр хэрэглэгч нэмэх"}
                    </ModalHeader>

                    <Form className="tablelist-form" onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}>
                      <ModalBody>
                        <Input type="hidden" id="id-field" />
                        <Row className="g-3">
                          <Col lg={12}>
                            <div className="text-center">
                              <div className="position-relative d-inline-block">
                                <div className="position-absolute  bottom-0 end-0">
                                  <Label htmlFor="customer-image-input" className="mb-0">
                                    <div className="avatar-xs cursor-pointer">
                                      <div className="avatar-title bg-light border rounded-circle text-muted">
                                        <i className="ri-image-fill"></i>
                                      </div>
                                    </div>
                                  </Label>
                                  <Input className="form-control d-none" id="customer-image-input" type="file"
                                    accept="image/png, image/gif, image/jpeg"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.img || ""}
                                    invalid={
                                      validation.touched.img && validation.errors.img ? true : false
                                    }
                                  />
                                </div>
                                <div className="avatar-lg p-1">
                                  <div className="avatar-title bg-light rounded-circle">
                                    <img src={dummyImg} alt="dummyImg" id="customer-img" className="avatar-md rounded-circle object-cover" />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div>
                              <Label
                                htmlFor="name-field"
                                className="form-label"
                              >
                                Овог, нэр{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="name"
                                id="customername-field"
                                className="form-control"
                                placeholder="Овог, нэрийг оруулах" 
                                type="text"
                                validate={{
                                  required: { value: true },
                                }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.name || ""}
                                invalid={
                                  validation.touched.name && validation.errors.name ? true : false
                                }
                              />
                              {validation.touched.name && validation.errors.name ? (
                                <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                              ) : null}

                            </div>
                          </Col>
                          <Col lg={6}>
                            <div>
                              <Label
                                htmlFor="company_name-field"
                                className="form-label"
                              >
                                Компанийн нэр
                              </Label>
                              <Input
                                name="company"
                                id="company_name-field"
                                className="form-control"
                                placeholder="Компанийн нэрийг оруулах."
                                type="text"                                
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.company || ""}
                              />
                            </div>
                          </Col>

                          <Col lg={6}>
                            <div>
                              <Label
                                htmlFor="designation-field"
                                className="form-label"
                              >
                                Албан тушаал
                              </Label>

                              <Input
                                name="designation"
                                id="designation-field"
                                className="form-control"
                                placeholder="Албан тушаал оруулах"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.designation || ""}
                              />
                            </div>
                          </Col>

                          <Col lg={6}>
                            <div>
                              <Label
                                htmlFor="email_id-field"
                                className="form-label"
                              >
                                E-mail хаяг{" "}
                                <span className="text-danger">*</span>
                              </Label>

                              <Input
                                name="email"
                                id="email_id-field"
                                className="form-control"
                                placeholder="E-mail хаяг"
                                type="text"
                                validate={{
                                  required: { value: true },
                                }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.email || ""}
                                invalid={
                                  validation.touched.email && validation.errors.email ? true : false
                                }
                              />
                              {validation.touched.email && validation.errors.email ? (
                                <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                              ) : null}

                            </div>
                          </Col>
                          <Col lg={6}>
                            <div>
                              <Label
                                htmlFor="phone-field"
                                className="form-label"
                              >
                                Нууц үг{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <div className="position-relative auth-pass-inputgroup mb-3">
                                <Input
                                  name="password"
                                  id="password-field"
                                  className="form-control pe-5"
                                  placeholder="Нууц үгийг оруулна уу"
                                  type={showPassword ? "text" : "password"}
                                  validate={{
                                    required: { value: true },
                                  }}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.password || ""}
                                  invalid={
                                    validation.touched.password && validation.errors.password ? true : false
                                  }
                                />
                                {validation.touched.password && validation.errors.password ? (
                                  <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                                ) : null}
                                <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted shadow-none" onClick={() => setShowPassword(!showPassword)} type="button" id="password-addon"><i className="ri-eye-fill align-middle"></i></button>
                              </div>
                              
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div>
                              <Label
                                htmlFor="lead_score-field"
                                className="form-label"
                              >
                                Утасны дугаар
                              </Label>

                              <Input
                                name="phone"
                                id="phone-field"
                                className="form-control"
                                placeholder="Утасны дугаар оруулах"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.phone || ""}
                              />
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div>
                              <Label
                                htmlFor="industry_type-field"
                                className="form-label"
                              >
                                Системийн түвшин
                              </Label>

                              <Input
                                name="role"
                                type="select"
                                className="form-select"
                                id="role-field"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={
                                  validation.values.role || "User"
                                }
                              >
                                {userRoleOptions.map((item, key) => (
                                  <React.Fragment key={key}>
                                    {item.options.map((item, key) => (<option value={item.value} key={key}>{item.label}</option>))}
                                  </React.Fragment>
                                ))}
                              </Input>
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div>
                              <Label
                                htmlFor="status-field"
                                className="form-label"
                              >
                                Төлөв
                              </Label>

                              <Input
                                name="status"
                                type="select"
                                className="form-select"
                                id="status-field"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={
                                  validation.values.status || "Active"
                                }
                              >
                                {statusOptions.map((item, key) => (
                                  <React.Fragment key={key}>
                                    {item.options.map((item, key) => (<option value={item.value} key={key}>{item.label}</option>))}
                                  </React.Fragment>
                                ))}
                              </Input>
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div>
                              <Label
                                htmlFor="dueDate"
                                className="form-label"
                              >
                                Хүчинтэй хугацаа
                              </Label>

                              <Flatpickr
                                name="dueDate"
                                id="dueDate-input"
                                className="form-control"
                                placeholder="Огноо сонгох"
                                options={{
                                  altInput: true,
                                  altFormat: "Y-m-d",
                                  dateFormat: "Y-m-d",
                                }}
                                onChange={(e) =>
                                  dateformate(e)
                                }
                                value={validation.values.dueDate || ""}
                              />
                              {validation.touched.date && validation.errors.date ? (
                                <FormFeedback type="invalid">{validation.errors.date}</FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={12}>
                            <div>
                              <Label
                                htmlFor="lead_score-field"
                                className="form-label"
                              >
                                Судалгаа удирдах хязгаар
                              </Label>

                              <Input
                                name="limit"
                                id="limit-field"
                                className="form-control"
                                placeholder="Дугаар оруулах"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.limit || ""}
                              />
                            </div>
                          </Col>
                          <Col lg={12}>
                            <div>
                              <Label
                                htmlFor="taginput-choices"
                                className="form-label font-size-13 text-muted"
                              >
                                Салбар
                              </Label>
                              <Select
                                name="tags"
                                isMulti
                                value={tag}
                                onChange={(e) => {
                                  handlestag(e);
                                }}
                                className="mb-0"
                                options={tagsOptions}
                                id="taginput-choices"
                              >
                              </Select>
                            </div>
                          </Col>
                        </Row>
                      </ModalBody>
                      <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                          <button type="button" className="btn btn-light" onClick={(ev) => { ev.preventDefault(); onFormCancel(); }} > Цуцлах </button>
                          <button type="submit" className="btn btn-success" id="add-btn"> {!!isEdit ? "Засварлах" : "Хадгалах"} </button>
                        </div>
                      </ModalFooter>
                    </Form>
                  </Modal>
                  <ToastContainer closeButton={false} limit={1} />
                </CardBody>
              </Card>
            </Col>

            <Col xxl={3}>
              <Card id="contact-view-detail">
                <CardBody className="text-center">
                  <div className="position-relative d-inline-block">
                    <img
                      src={info.image || dummyImg}
                      alt=""
                      className="avatar-lg rounded-circle img-thumbnail"
                    />
                    <span className="contact-active position-absolute rounded-circle bg-success">
                      <span className="visually-hidden"></span>
                    </span>
                  </div>
                  <h5 className="mt-4 mb-1">{info.name || ""}</h5>
                  <p className="text-muted">
                    <span className={`badge badge-soft-${
                            info.role === "SuperAdmin"
                            ? "primary"
                            : info.role === "Admin"
                            ? "success"
                            : "warning"
                        } me-1`}>{info.role}
                      </span>
                  </p>
                  
                  <p className="text-muted">{info.company || ""}</p>
                  
                  <ul className="list-inline mb-0">
                    <li className="list-inline-item avatar-xs">
                      <Link
                        to="#"
                        className="avatar-title bg-soft-success text-success fs-15 rounded"
                      >
                        <i className="ri-phone-line"></i>
                      </Link>
                    </li>
                    <li className="list-inline-item avatar-xs">
                      <Link
                        to="#"
                        className="avatar-title bg-soft-danger text-danger fs-15 rounded"
                      >
                        <i className="ri-mail-line"></i>
                      </Link>
                    </li>
                    <li className="list-inline-item avatar-xs">
                      <Link
                        to="#"
                        className="avatar-title bg-soft-warning text-warning fs-15 rounded"
                      >
                        <i className="ri-question-answer-line"></i>
                      </Link>
                    </li>
                  </ul>
                </CardBody>
                <CardBody>
                  <h6 className="text-muted text-uppercase fw-semibold mb-3">
                    Хувийн мэдээлэл
                  </h6>
                  <p className="text-muted mb-4">
                    
                  </p>
                  <div className="table-responsive table-card">
                    <Table className="table table-borderless mb-0">
                      <tbody>
                        <tr>
                          <td className="fw-medium">
                            Албан тушаал
                          </td>
                          <td>{info.designation || ""}</td>
                        </tr>
                        <tr>
                          <td className="fw-medium">
                            E-mail хаяг
                          </td>
                          <td>{info.email || ""}</td>
                        </tr>
                        <tr>
                          <td className="fw-medium">
                            Утасны дугаар
                          </td>
                          <td>{info.phone || ""}</td>
                        </tr>
                        <tr>
                          <td className="fw-medium">
                            Судалгаа удирдах хязгаар
                          </td>
                          <td>
                            <span className={`badge badge-soft-${
                              info.limit === "Unlimited"
                                            ? "primary"
                                            : "warning"
                                        } me-1`}>
                              {info.limit}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td className="fw-medium">
                            Төлөв
                          </td>
                          <td>
                            <span className={`badge badge-soft-${
                              info.status === "Active"
                                            ? "success"
                                            : info.status === "Suspend"
                                            ? "danger"
                                            : "warning"
                                        } me-1`}>
                              {info.status}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td className="fw-medium">
                            Салбар
                          </td>
                          <td>
                            {(info.tags || [""]).map((item, key) => (<span className="badge badge-soft-primary me-1" key={key}>{item.value}</span>))}
                          </td>
                        </tr>
                        <tr>
                          <td className="fw-medium">
                            Бүртгэгдсэн огноо
                          </td>
                          <td>
                            {handleValidDate(info.createdAt || "")}{" "}
                            <small className="text-muted">{handleValidTime(info.createdAt || "")}</small>
                          </td>
                        </tr>
                        <tr>
                          <td className="fw-medium">
                            Системд хандах хүчинтэй огноо
                          </td>
                          <td>
                            {handleValidDate(info.dueDate || "")}{" "}
                            <small className="text-muted">{handleValidTime(info.dueDate || "")}</small>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CrmContacts;
