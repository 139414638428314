import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
 
// Initialize Firebase
const app = initializeApp ({
  apiKey: "AIzaSyADe0-q_5rwyNVcvANoMQQhI4Ney8Kzxow",
  authDomain: "mindx-384a6.firebaseapp.com",
  projectId: "mindx-384a6",
  storageBucket: "mindx-384a6.appspot.com",
  messagingSenderId: "933265028105",
  appId: "1:933265028105:web:6adfdab7cd1519ef70b4d8",
  measurementId: "G-SN221N6JJH"
});
 
// Firebase storage reference
const storage = getStorage(app);
export default storage;