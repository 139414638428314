import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";

import { useLocation, Navigate, Link } from "react-router-dom";

import TaskOverview from "./TaskOverview";

import * as moment from "moment";
import 'moment-timezone';

const AdvancedDashboard = () => {
    const location = useLocation();
    const [campId, setCampId] = useState(null);
  
    if (!location.state) {
      return <Navigate to="/" />;
    }

    document.title = `${location.state.campaignName} | IKHNUDEN - Media Monitoring System`;

    const handleValidDate = date => {
        const date1 = moment(new Date(date)).format("Y-MM-DD");
        return date1;
    };
    
    const handleValidTime = (time) => {
        const time1 = new Date(time);
        const getHour = time1.getUTCHours();
        const getMin = time1.getUTCMinutes();
        const getTime = `${getHour}:${getMin}`;
        const updateTime = moment.utc(getTime, 'HH:mm').format('HH:mm');
        return updateTime;
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={location.state.campaignName} pageTitle="Судалгааны ажил" />
                    <Row className="dash-nft">
                        <TaskOverview taskName={location.state.campaignName} 
                                      taskDesc={location.state.campaignDesc} 
                                      taskImage={location.state.campaignImage}
                                      taskValidFrom={location.state.campaignValidFrom}
                                      taskValidTo={location.state.campaignValidTo}
                                      />
                    </Row>                   
                </Container>
            </div>
        </React.Fragment>
    );
};

export default AdvancedDashboard;
