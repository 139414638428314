import { createSlice } from "@reduxjs/toolkit";
import { getNewsPosts, addNewNewsPost, updateNewsPost, deleteNewsPost } from './thunk';

export const initialState = {
  newsposts: [],
  error: {}
};

const newsPostsSlice = createSlice({
  name: "newsposts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getNewsPosts.fulfilled, (state, action) => {
      state.newsposts = action.payload;
      state.isNewsPostsFail = false;
      state.isNewsPostsSuccess = true;
    });

    builder.addCase(getNewsPosts.rejected, (state, action) => {
      state.error = action.payload.error || null;
      state.isNewsPostsFail = true;
      state.isNewsPostsSuccess = false;
    });

    builder.addCase(addNewNewsPost.fulfilled, (state, action) => {
      state.newsposts.push(action.payload);
      state.isNewsPostAdd = true;
      state.isNewsPostAddFail = false;
    });

    builder.addCase(addNewNewsPost.rejected, (state, action) => {
      state.error = action.payload.error || null;
      state.isNewsPostAdd = false;
      state.isNewsPostAddFail = true;
    });

    builder.addCase(updateNewsPost.fulfilled, (state, action) => {
      state.newsposts = state.newsposts.map(newsposts =>
        newsposts._id.toString() === action.payload._id.toString()
          ? { ...newsposts, ...action.payload }
          : newsposts
      );
      state.isNewsPostUpdate = true;
      state.isNewsPostUpdateFail = false;
    });

    builder.addCase(updateNewsPost.rejected, (state, action) => {
      state.error = action.payload.error || null;
      state.isNewsPostUpdate = false;
      state.isNewsPostUpdateFail = true;
    });

    builder.addCase(deleteNewsPost.fulfilled, (state, action) => {
      state.newsposts = (state.newsposts || []).filter((newsposts) => newsposts._id.toString() !== action.payload.newsposts.toString());

      state.isNewsPostDelete = true;
      state.isNewsPostDeleteFail = false;
    });

    builder.addCase(deleteNewsPost.rejected, (state, action) => {
      state.error = action.payload.error || null;
      state.isNewsPostDelete = false;
      state.isNewsPostDeleteFail = true;
    });

  },
});

export default newsPostsSlice.reducer;