import React, { useState, useEffect } from "react";
import { Col, Container, Row, Button, Card, CardBody, CardHeader, Badge, Nav, NavItem, NavLink, TabContent, TabPane, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import Engagements from "./Engagements";
import TopPosts from "./TopPosts";
import LatestPosts from "./LatestPosts";
import AllPosts from "./AllPosts";
import Gender from "./Gender";
import { isEmpty } from "lodash";
import classnames from 'classnames';
import CountUp from "react-countup";
import { EngagementsCharts, EngagementsPNCharts, TotalSourceCharts } from "./DashboardEngagementsCharts";

import { useSelector, useDispatch } from "react-redux";

import profileBg from '../../assets/images/dc-bg.gif';
import { useLocation, Navigate, Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";

import { getStatsPosts as onGetStatsPosts,
    getTotalStatsPosts as onGetTotalStatsPosts,
    getStatsFBPosts as onGetStatsFBPosts,
    getStatsTWPosts as onGetStatsTWPosts,
    getStatsNWPosts as onGetStatsNWPosts,
    getStatsIGPosts as onGetStatsIGPosts } from "../../slices/thunks";

import * as moment from "moment";
import 'moment-timezone';

import TeamTab from './TeamTab';

import {
  StackedColumn,
  DiffTotalPositiveLocation,
  DiffTotalNegativeLocation,
  DataTotalPositiveLocation,
  DataTotalPositiveLocationBasic,
  DataTotalNegativeLocationBasic,
  DataTotalUBLocationBasic,
  MultipleRadar,
  StackedNegativeByGender,
  PositiveGenderComparison,
  NegativeGenderComparison,
  NegativeGenderByAge,
  RealFakeData
} from "./ColumnCharts";

const DashboardCampaign = ({ campaignId }) => { 
  const dispatch = useDispatch();
 
  const location = useLocation();
  const [stats, setStats] = useState([]);
  const [FBstats, setFBStats] = useState([]);
  const [TWstats, setTWStats] = useState([]);
  const [NWstats, setNWStats] = useState([]);
  const [IGstats, setIGStats] = useState([]);
  const [totalstats, setTotalStats] = useState([]);
  const [chartData, setchartData] = useState([]);

  const [postsID, setPostsID] = useState([]);
  const [totalPosts, setTotalPosts] = useState([]);
  const [totalLikes, setTotalLikes] = useState([]);
  const [totalComments, setTotalComments] = useState([]);
  const [totalShares, setTotalShares] = useState([]);

  const [totalPostCount, setTotalPostCount] = useState(0);
  const [totalLikesCount, setTotalLikesCount] = useState(0);
  const [totalCommentsCount, setTotalCommentsCount] = useState(0);
  const [totalSharesCount, setTotalSharesCount] = useState(0);
  const [totalPositiveLikesCount, setTotalPositiveLikesCount] = useState(0);
  const [totalNegativeLikesCount, setTotalNegativeLikesCount] = useState(0);

  const [totalFBPositiveLikesCount, setTotalFBPositiveLikesCount] = useState(0);
  const [totalFBNegativeLikesCount, setTotalFBNegativeLikesCount] = useState(0);
  const [totalTWPositiveLikesCount, setTotalTWPositiveLikesCount] = useState(0);
  const [totalTWNegativeLikesCount, setTotalTWNegativeLikesCount] = useState(0);
  const [totalNWPositiveLikesCount, setTotalNWPositiveLikesCount] = useState(0);
  const [totalNWNegativeLikesCount, setTotalNWNegativeLikesCount] = useState(0);
  const [totalIGPositiveLikesCount, setTotalIGPositiveLikesCount] = useState(0);
  const [totalIGNegativeLikesCount, setTotalIGNegativeLikesCount] = useState(0);

  const [timePeriod, setTimePeriod] = useState("1 сарын");

  const [totalFBPostCount, setTotalFBPostCount] = useState(0);
  const [totalTWPostCount, setTotalTWPostCount] = useState(0);
  const [totalNWPostCount, setTotalNWPostCount] = useState(0);
  const [totalIGPostCount, setTotalIGPostCount] = useState(0);

  const [totalPMale, setTotalPMale] = useState([]);
  const [totalPFemale, setTotalPFemale] = useState([]);
  const [totalNMale, setTotalNMale] = useState([]);
  const [totalNFemale, setTotalNFemale] = useState([]);

  const [genderPositiveM, setGenderPositiveM] = useState([]);
  const [genderPositiveFM, setGenderPositiveFM] = useState([]);

  const [genderNegativeM, setGenderNegativeM] = useState([]);
  const [genderNegativeFM, setGenderNegativeFM] = useState([]);

  const [totalPositiveLocation, setTotalPositiveLocation] = useState([]);
  const [totalNegativeLocation, setTotalNegativeLocation] = useState([]);

  const [totalPositiveLocationData, setTotalPositiveLocationData] = useState([]);
  const [totalNegativeLocationData, setTotalNegativeLocationData] = useState([]);

  const [totalPositiveUBLocation, setTotalPositiveUBLocation] = useState([]);
  const [totalNegativeUBLocation, setTotalNegativeUBLocation] = useState([]);

  const [totalPositiveUBLocationData, setTotalPositiveUBLocationData] = useState([]);
  const [totalNegativeUBLocationData, setTotalNegativeUBLocationData] = useState([]);

  const [totalPositiveRealFakeData, setTotalPositiveRealFakeData] = useState([]);
  const [totalNegativeRealFakeData, setTotalNegativeRealFakeData] = useState([]);

  //Tab 
  const [activeTab, setActiveTab] = useState('1');
  const toggleTab = (tab) => {
      if (activeTab !== tab) {
          setActiveTab(tab);
      }
  };

  const { statsposts, isStatsPostSuccess, statserror,
    totalstatsposts, isTotalStatsPostSuccess, totalstatserror,
    statsFBposts, isStatsFBPostSuccess, statFBserror,
    statsTWposts, isStatsTWPostSuccess, statTWserror,
    statsNWposts, isStatsNWPostSuccess, statNWserror,
    statsIGposts, isStatsIGPostSuccess, statIGserror } = useSelector((state) => ({    

    statsposts: state.Post.statsposts,
    isStatsPostSuccess: state.Post.isStatsPostSuccess,
    statserror: state.Post.statserror,

    totalstatsposts: state.Post.totalstatsposts,
    isTotalStatsPostSuccess: state.Post.isTotalStatsPostSuccess,
    totalstatserror: state.Post.totalstatserror,

    statsFBposts: state.Post.statsFBposts,
    isStatsFBPostSuccess: state.Post.isStatsFBPostSuccess,
    statsFBerror: state.Post.statFBserror,

    statsTWposts: state.Post.statsTWposts,
    isStatsTWPostSuccess: state.Post.isStatsTWPostSuccess,
    statsTWerror: state.Post.statTWserror,

    statsNWposts: state.Post.statsNWposts,
    isStatsNWPostSuccess: state.Post.isStatsNWPostSuccess,
    statsNWerror: state.Post.statNWserror,

    statsIGposts: state.Post.statsIGposts,
    isStatsIGPostSuccess: state.Post.isStatsIGPostSuccess,
    statsIGerror: state.Post.statIGserror
    }));

  if (!location.state) {
    return <Navigate to="/" />;
  }

  document.title = `${location.state.campaignName} | IKHNUDEN - Media Monitoring System`;
  
  useEffect(() => {
    try {
        const params = {
            taskId: location.state.campaignId,
            source: "all",
            dateRange: "1month"
          };
    
          dispatch(onGetStatsPosts(params));
    }
    catch {
        return <Navigate to="/" />;
    }    
    
}, [dispatch]);

    useEffect(() => {
    try {
        const totalparams = {
            taskId: location.state.campaignId,
            dateRange: "1month"
        };

        dispatch(onGetTotalStatsPosts(totalparams));
    }
    catch {
        return <Navigate to="/" />;
    }    

    }, [dispatch]);

    useEffect(() => {
    try {
        const paramsFB = {
            taskId: location.state.campaignId,
            source: "facebook",
            dateRange: "1month"
        };

        dispatch(onGetStatsFBPosts(paramsFB));
    }
    catch {
        return <Navigate to="/" />;
    }    

    }, [dispatch]);

    useEffect(() => {
    try {
        const paramsTW = {
            taskId: location.state.campaignId,
            source: "twitter",
            dateRange: "1month"
        };

        dispatch(onGetStatsTWPosts(paramsTW));
    }
    catch {
        return <Navigate to="/" />;
    }    

    }, [dispatch]);

    useEffect(() => {
    try {
        const paramsNW = {
            taskId: location.state.campaignId,
            source: "news",
            dateRange: "1month"
        };

        dispatch(onGetStatsNWPosts(paramsNW));
    }
    catch {
        return <Navigate to="/" />;
    }    

    }, [dispatch]);

    useEffect(() => {
    try {
        const paramsIG = {
            taskId: location.state.campaignId,
            source: "instagram",
            dateRange: "1month"
        };

        dispatch(onGetStatsIGPosts(paramsIG));
    }
    catch {
        return <Navigate to="/" />;
    }    

    }, [dispatch]);

    useEffect(() => {
    if (!isEmpty(statsposts)) {
        setStats(statsposts);        
    }
    if (!isEmpty(totalstatsposts)) {
        setTotalStats(totalstatsposts);        
    }
    if (!isEmpty(statsFBposts)) {
        setFBStats(statsFBposts);        
    }
    if (!isEmpty(statsTWposts)) {
        setTWStats(statsTWposts);        
    }
    if (!isEmpty(statsNWposts)) {
        setNWStats(statsNWposts);        
    }
    if (!isEmpty(statsIGposts)) {
        setIGStats(statsIGposts);        
    }
    }, [statsposts, totalstatsposts, statsFBposts, statsTWposts, statsNWposts, statsIGposts]);

    useEffect(() => {
    try {
        setPostsID(statsposts?.map((item) => item._id)); 
        setTotalPosts(statsposts?.map((item) => item.totalPost));  
        setTotalLikes(statsposts?.map((item) => item.totalLikes));  
        setTotalComments(statsposts?.map((item) => item.totalComments));  
        setTotalShares(statsposts?.map((item) => item.totalShares));  

        let postCount = 0;
        let likesCount = 0;
        let commentsCount = 0;
        let sharesCount = 0;
        let positiveLikesCount = 0;
        let negativeLikesCount = 0;
        let FBpositiveLikesCount = 0;
        let FBnegativeLikesCount = 0;
        let TWpositiveLikesCount = 0;
        let TWnegativeLikesCount = 0;
        let NWpositiveLikesCount = 0;
        let NWnegativeLikesCount = 0;
        let IGpositiveLikesCount = 0;
        let IGnegativeLikesCount = 0;
        
        statsposts?.map((item) => {
        postCount = postCount + parseInt(item.totalPost);
        likesCount = likesCount + parseInt(item.totalLikes);
        commentsCount = commentsCount + parseInt(item.totalComments);
        sharesCount = sharesCount + parseInt(item.totalShares);
        positiveLikesCount = positiveLikesCount + parseInt(item.totalPositiveLikes);
        negativeLikesCount = negativeLikesCount + parseInt(item.totalNegativeLikes);
        })
        setTotalPostCount(postCount);
        setTotalLikesCount(likesCount);
        setTotalCommentsCount(commentsCount);
        setTotalSharesCount(sharesCount);
        setTotalPositiveLikesCount(positiveLikesCount);
        setTotalNegativeLikesCount(negativeLikesCount);

        setTotalFBPostCount(0);
        setTotalTWPostCount(0);
        setTotalNWPostCount(0);
        setTotalIGPostCount(0);

        totalstatsposts?.map((item) => {
            if(item.totalFBCount)
            {
                setTotalFBPostCount(item.totalFBCount);
            }
            if(item.totalTWCount)
            {
                setTotalTWPostCount(item.totalTWCount);
            }
            if(item.totalNWCount)
            {
                setTotalNWPostCount(item.totalNWCount);
            }
            if(item.totalIGCount)
            {
                setTotalIGPostCount(item.totalIGCount);
            }
        });

        statsFBposts?.map((item) => {           
            FBpositiveLikesCount = FBpositiveLikesCount + parseInt(item.totalPositiveLikes);
            FBnegativeLikesCount = FBnegativeLikesCount + parseInt(item.totalNegativeLikes);
        })
    
        setTotalFBPositiveLikesCount(FBpositiveLikesCount);
        setTotalFBNegativeLikesCount(FBnegativeLikesCount);

        statsTWposts?.map((item) => {           
            TWpositiveLikesCount = TWpositiveLikesCount + parseInt(item.totalPositiveLikes);
            TWnegativeLikesCount = TWnegativeLikesCount + parseInt(item.totalNegativeLikes);
        })
    
        setTotalTWPositiveLikesCount(TWpositiveLikesCount);
        setTotalTWNegativeLikesCount(TWnegativeLikesCount);

        statsNWposts?.map((item) => {           
            NWpositiveLikesCount = NWpositiveLikesCount + parseInt(item.totalPositiveLikes);
            NWnegativeLikesCount = NWnegativeLikesCount + parseInt(item.totalNegativeLikes);
        })
    
        setTotalNWPositiveLikesCount(NWpositiveLikesCount);
        setTotalNWNegativeLikesCount(NWnegativeLikesCount);

        statsIGposts?.map((item) => {        
            IGpositiveLikesCount = IGpositiveLikesCount + parseInt(item.totalLikes);
            IGnegativeLikesCount = IGnegativeLikesCount + parseInt(item.totalNegativeLikes);
        })
    
        setTotalIGPositiveLikesCount(IGpositiveLikesCount);
        setTotalIGNegativeLikesCount(IGnegativeLikesCount);
        
        let totalPositive = FBpositiveLikesCount + TWpositiveLikesCount + IGpositiveLikesCount;
        let totalNegative = FBnegativeLikesCount + TWnegativeLikesCount + IGnegativeLikesCount;

        if((totalPositive > 0 && totalNegative > 0) || (totalPositive > 0 && totalNegative == 0) || (totalPositive == 0 && totalNegative > 0))
        {
                let positiveMale = location.state.campaignDataAnalyze.positive.age.male;
                let positiveFemale = location.state.campaignDataAnalyze.positive.age.female;
        
                let negativeMale = location.state.campaignDataAnalyze.negative.age.male;
                let negativeFemale = location.state.campaignDataAnalyze.negative.age.female;
        
                // calculating positive by gender
                let pm13_17 = Math.floor(totalPositive * positiveMale.a13_17 /100);
                let pm18_24 = Math.floor(totalPositive * positiveMale.a18_24 /100);
                let pm25_34 = Math.floor(totalPositive * positiveMale.a25_34 /100);
                let pm35_44 = Math.floor(totalPositive * positiveMale.a35_44 /100);
                let pm45_54 = Math.floor(totalPositive * positiveMale.a45_54 /100);
                let pm55_64 = Math.floor(totalPositive * positiveMale.a55_64 /100);
                let pm65plus = Math.floor(totalPositive * positiveMale.a65plus /100);
        
                let pfm13_17 = Math.floor(totalPositive * positiveFemale.a13_17 /100);
                let pfm18_24 = Math.floor(totalPositive * positiveFemale.a18_24 /100);
                let pfm25_34 = Math.floor(totalPositive * positiveFemale.a25_34 /100);
                let pfm35_44 = Math.floor(totalPositive * positiveFemale.a35_44 /100);
                let pfm45_54 = Math.floor(totalPositive * positiveFemale.a45_54 /100);
                let pfm55_64 = Math.floor(totalPositive * positiveFemale.a55_64 /100);
                let pfm65plus = Math.floor(totalPositive * positiveFemale.a65plus /100);
        
                let pmale = [pm13_17, pm18_24, pm25_34, pm35_44, pm45_54, pm55_64, pm65plus];
                let pfemale = [pfm13_17, pfm18_24, pfm25_34, pfm35_44, pfm45_54, pfm55_64, pfm65plus];
        
                setGenderPositiveM(pmale);
                setGenderPositiveFM(pfemale);
        
                // calculating negative by gender      
                let nm13_17 = Math.floor(totalNegative * negativeMale.a13_17 /100);
                let nm18_24 = Math.floor(totalNegative * negativeMale.a18_24 /100);
                let nm25_34 = Math.floor(totalNegative * negativeMale.a25_34 /100);
                let nm35_44 = Math.floor(totalNegative * negativeMale.a35_44 /100);
                let nm45_54 = Math.floor(totalNegative * negativeMale.a45_54 /100);
                let nm55_64 = Math.floor(totalNegative * negativeMale.a55_64 /100);
                let nm65plus = Math.floor(totalNegative * negativeMale.a65plus /100);
        
                let nfm13_17 = Math.floor(totalNegative * negativeFemale.a13_17 /100);
                let nfm18_24 = Math.floor(totalNegative * negativeFemale.a18_24 /100);
                let nfm25_34 = Math.floor(totalNegative * negativeFemale.a25_34 /100);
                let nfm35_44 = Math.floor(totalNegative * negativeFemale.a35_44 /100);
                let nfm45_54 = Math.floor(totalNegative * negativeFemale.a45_54 /100);
                let nfm55_64 = Math.floor(totalNegative * negativeFemale.a55_64 /100);
                let nfm65plus = Math.floor(totalNegative * negativeFemale.a65plus /100);
        
                let nmale = [nm13_17, nm18_24, nm25_34, nm35_44, nm45_54, nm55_64, nm65plus];
                let nfemale = [nfm13_17, nfm18_24, nfm25_34, nfm35_44, nfm45_54, nfm55_64, nfm65plus];
        
                setGenderNegativeM(nmale);
                setGenderNegativeFM(nfemale);
        
                const pmaleSum = pmale.reduce((previousValue, currentValue, index) => previousValue + currentValue, 0);
                setTotalPMale(Math.floor(pmaleSum));
        
                const nmaleSum = nmale.reduce((previousValue, currentValue, index) => previousValue + currentValue, 0);
                setTotalNMale(Math.floor(nmaleSum));
        
                const pfemaleSum = pfemale.reduce((previousValue, currentValue, index) => previousValue + currentValue, 0);
                setTotalPFemale(Math.floor(pfemaleSum));
        
                const nfemaleSum = nfemale.reduce((previousValue, currentValue, index) => previousValue + currentValue, 0);
                setTotalNFemale(Math.floor(nfemaleSum));
        
                // calculating positive by location
                let pUlaanbaatar = location.state.campaignDataAnalyze.positive.Total_location.Ulaanbaatar;
                let pArkhangai = location.state.campaignDataAnalyze.positive.Total_location.Arkhangai;
                let pBayan_Ulgii = location.state.campaignDataAnalyze.positive.Total_location.Bayan_Ulgii;
                let pBayankhongor = location.state.campaignDataAnalyze.positive.Total_location.Bayankhongor;
                let pBulgan = location.state.campaignDataAnalyze.positive.Total_location.Bulgan;
                let pGovi_Altai = location.state.campaignDataAnalyze.positive.Total_location.Govi_Altai;
                let pGovisumber = location.state.campaignDataAnalyze.positive.Total_location.Govisumber;
                let pDarkhan_Uul = location.state.campaignDataAnalyze.positive.Total_location.Darkhan_Uul;
                let pDornogovi = location.state.campaignDataAnalyze.positive.Total_location.Dornogovi;
                let pDornod = location.state.campaignDataAnalyze.positive.Total_location.Dornod;
                let pDundgovi = location.state.campaignDataAnalyze.positive.Total_location.Dundgovi;
                let pZavkhan = location.state.campaignDataAnalyze.positive.Total_location.Zavkhan;
                let pOrkhon = location.state.campaignDataAnalyze.positive.Total_location.Orkhon;
                let pUvurkhangai = location.state.campaignDataAnalyze.positive.Total_location.Uvurkhangai;
                let pUmnugovi = location.state.campaignDataAnalyze.positive.Total_location.Umnugovi;
                let pSukhbaatar = location.state.campaignDataAnalyze.positive.Total_location.Sukhbaatar;
                let pSelenge = location.state.campaignDataAnalyze.positive.Total_location.Selenge;
                let pTuv = location.state.campaignDataAnalyze.positive.Total_location.Tuv;
                let pUvs = location.state.campaignDataAnalyze.positive.Total_location.Uvs;
                let pKhovd = location.state.campaignDataAnalyze.positive.Total_location.Khovd;
                let pKhuvsgul = location.state.campaignDataAnalyze.positive.Total_location.Khuvsgul;
                let pKhentii = location.state.campaignDataAnalyze.positive.Total_location.Khentii;
                let pGadaad = location.state.campaignDataAnalyze.positive.Total_location.Gadaad;
        
                const totalPLocation = [{
                        data: [{
                            x: 'Улаанбаатар',
                            y:  Math.floor(totalPositive * pUlaanbaatar /100)
                        },
                        {
                            x: 'Архангай',
                            y:  Math.floor(totalPositive * pArkhangai /100)
                        },
                        {
                            x: 'Баян-Өлгий',
                            y: Math.floor(totalPositive * pBayan_Ulgii /100)
                        },
                        {
                            x: 'Баянхонгор',
                            y: Math.floor(totalPositive * pBayankhongor /100)
                        },
                        {
                            x: 'Булган',
                            y: Math.floor(totalPositive * pBulgan /100)
                        },
                        {
                            x: 'Говь-Алтай',
                            y: Math.floor(totalPositive * pGovi_Altai /100)
                        },
                        {
                            x: 'Говьсүмбэр',
                            y: Math.floor(totalPositive * pGovisumber /100)
                        },
                        {
                            x: 'Дархан-Уул',
                            y: Math.floor(totalPositive * pDarkhan_Uul /100)
                        },
                        {
                            x: 'Дорноговь',
                            y: Math.floor(totalPositive * pDornogovi /100)
                        },
                        {
                            x: 'Дорнод',
                            y: Math.floor(totalPositive * pDornod /100)
                        },
                        {
                            x: 'Дундговь',
                            y: Math.floor(totalPositive * pDundgovi /100)
                        },
                        {
                            x: 'Завхан',
                            y: Math.floor(totalPositive * pZavkhan /100)
                        },
                        {
                            x: 'Орхон',
                            y: Math.floor(totalPositive * pOrkhon /100)
                        },
                        {
                            x: 'Өвөрхангай',
                            y: Math.floor(totalPositive * pUvurkhangai /100)
                        },
                        {
                            x: 'Өмнөговь',
                            y: Math.floor(totalPositive * pUmnugovi /100)
                        },
                        {
                            x: 'Сүхбаатар',
                            y: Math.floor(totalPositive * pSukhbaatar /100)
                        },
                        {
                            x: 'Сэлэнгэ',
                            y: Math.floor(totalPositive * pSelenge /100)
                        },
                        {
                            x: 'Төв',
                            y: Math.floor(totalPositive * pTuv /100)
                        },
                        {
                            x: 'Увс',
                            y: Math.floor(totalPositive * pUvs /100)
                        },
                        {
                            x: 'Ховд',
                            y: Math.floor(totalPositive * pKhovd /100)
                        },
                        {
                            x: 'Хөвсгөл',
                            y: Math.floor(totalPositive * pKhuvsgul /100)
                        },
                        {
                            x: 'Хэнтий',
                            y: Math.floor(totalPositive * pKhentii /100)
                        },
                        {
                            x: 'Гадаад улс',
                            y: Math.floor(totalPositive * pGadaad /100)
                        }         
                        ]
                }]
        
                setTotalPositiveLocation(totalPLocation);
        
                let totalPositiveLocationData = [
                        Math.floor(totalPositive * pUlaanbaatar /100), 
                        Math.floor(totalPositive * pArkhangai /100), 
                        Math.floor(totalPositive * pBayan_Ulgii /100), 
                        Math.floor(totalPositive * pBayankhongor /100), 
                        Math.floor(totalPositive * pBulgan /100), 
                        Math.floor(totalPositive * pGovi_Altai /100), 
                        Math.floor(totalPositive * pGovisumber /100), 
                        Math.floor(totalPositive * pDarkhan_Uul /100), 
                        Math.floor(totalPositive * pDornogovi /100), 
                        Math.floor(totalPositive * pDornod /100), 
                        Math.floor(totalPositive * pDundgovi /100), 
                        Math.floor(totalPositive * pZavkhan /100), 
                        Math.floor(totalPositive * pOrkhon /100), 
                        Math.floor(totalPositive * pUvurkhangai /100), 
                        Math.floor(totalPositive * pUmnugovi /100), 
                        Math.floor(totalPositive * pSukhbaatar /100), 
                        Math.floor(totalPositive * pSelenge /100), 
                        Math.floor(totalPositive * pTuv /100), 
                        Math.floor(totalPositive * pUvs /100), 
                        Math.floor(totalPositive * pKhovd /100), 
                        Math.floor(totalPositive * pKhuvsgul /100), 
                        Math.floor(totalPositive * pKhentii /100),
                        Math.floor(totalPositive * pGadaad /100)
                ];
        
                setTotalPositiveLocationData(totalPositiveLocationData);
        
                // calculating positive by location
                let nUlaanbaatar = location.state.campaignDataAnalyze.negative.Total_location.Ulaanbaatar;
                let nArkhangai = location.state.campaignDataAnalyze.negative.Total_location.Arkhangai;
                let nBayan_Ulgii = location.state.campaignDataAnalyze.negative.Total_location.Bayan_Ulgii;
                let nBayankhongor = location.state.campaignDataAnalyze.negative.Total_location.Bayankhongor;
                let nBulgan = location.state.campaignDataAnalyze.negative.Total_location.Bulgan;
                let nGovi_Altai = location.state.campaignDataAnalyze.negative.Total_location.Govi_Altai;
                let nGovisumber = location.state.campaignDataAnalyze.negative.Total_location.Govisumber;
                let nDarkhan_Uul = location.state.campaignDataAnalyze.negative.Total_location.Darkhan_Uul;
                let nDornogovi = location.state.campaignDataAnalyze.negative.Total_location.Dornogovi;
                let nDornod = location.state.campaignDataAnalyze.negative.Total_location.Dornod;
                let nDundgovi = location.state.campaignDataAnalyze.negative.Total_location.Dundgovi;
                let nZavkhan = location.state.campaignDataAnalyze.negative.Total_location.Zavkhan;
                let nOrkhon = location.state.campaignDataAnalyze.negative.Total_location.Orkhon;
                let nUvurkhangai = location.state.campaignDataAnalyze.negative.Total_location.Uvurkhangai;
                let nUmnugovi = location.state.campaignDataAnalyze.negative.Total_location.Umnugovi;
                let nSukhbaatar = location.state.campaignDataAnalyze.negative.Total_location.Sukhbaatar;
                let nSelenge = location.state.campaignDataAnalyze.negative.Total_location.Selenge;
                let nTuv = location.state.campaignDataAnalyze.negative.Total_location.Tuv;
                let nUvs = location.state.campaignDataAnalyze.negative.Total_location.Uvs;
                let nKhovd = location.state.campaignDataAnalyze.negative.Total_location.Khovd;
                let nKhuvsgul = location.state.campaignDataAnalyze.negative.Total_location.Khuvsgul;
                let nKhentii = location.state.campaignDataAnalyze.negative.Total_location.Khentii;
                let nGadaad = location.state.campaignDataAnalyze.negative.Total_location.Gadaad;
        
                const totalNLocation = [{
                        data: [{
                            x: 'Улаанбаатар',
                            y:  Math.floor(totalNegative * nUlaanbaatar /100)
                        },
                        {
                            x: 'Архангай',
                            y:  Math.floor(totalNegative * nArkhangai /100)
                        },
                        {
                            x: 'Баян-Өлгий',
                            y: Math.floor(totalNegative * nBayan_Ulgii /100)
                        },
                        {
                            x: 'Баянхонгор',
                            y: Math.floor(totalNegative * nBayankhongor /100)
                        },
                        {
                            x: 'Булган',
                            y: Math.floor(totalNegative * nBulgan /100)
                        },
                        {
                            x: 'Говь-Алтай',
                            y: Math.floor(totalNegative * nGovi_Altai /100)
                        },
                        {
                            x: 'Говьсүмбэр',
                            y: Math.floor(totalNegative * nGovisumber /100)
                        },
                        {
                            x: 'Дархан-Уул',
                            y: Math.floor(totalNegative * nDarkhan_Uul /100)
                        },
                        {
                            x: 'Дорноговь',
                            y: Math.floor(totalNegative * nDornogovi /100)
                        },
                        {
                            x: 'Дорнод',
                            y: Math.floor(totalNegative * nDornod /100)
                        },
                        {
                            x: 'Дундговь',
                            y: Math.floor(totalNegative * nDundgovi /100)
                        },
                        {
                            x: 'Завхан',
                            y: Math.floor(totalNegative * nZavkhan /100)
                        },
                        {
                            x: 'Орхон',
                            y: Math.floor(totalNegative * nOrkhon /100)
                        },
                        {
                            x: 'Өвөрхангай',
                            y: Math.floor(totalNegative * nUvurkhangai /100)
                        },
                        {
                            x: 'Өмнөговь',
                            y: Math.floor(totalNegative * nUmnugovi /100)
                        },
                        {
                            x: 'Сүхбаатар',
                            y: Math.floor(totalNegative * nSukhbaatar /100)
                        },
                        {
                            x: 'Сэлэнгэ',
                            y: Math.floor(totalNegative * nSelenge /100)
                        },
                        {
                            x: 'Төв',
                            y: Math.floor(totalNegative * nTuv /100)
                        },
                        {
                            x: 'Увс',
                            y: Math.floor(totalNegative * nUvs /100)
                        },
                        {
                            x: 'Ховд',
                            y: Math.floor(totalNegative * nKhovd /100)
                        },
                        {
                            x: 'Хөвсгөл',
                            y: Math.floor(totalNegative * nKhuvsgul /100)
                        },
                        {
                            x: 'Хэнтий',
                            y: Math.floor(totalNegative * nKhentii /100)
                        },
                        {
                            x: 'Гадаад улс',
                            y: Math.floor(totalNegative * nGadaad /100)
                        }           
                        ]
                }]
        
                setTotalNegativeLocation(totalNLocation);
        
                let totalNegativeLocationData = [
                        Math.floor(totalNegative * nUlaanbaatar /100), 
                        Math.floor(totalNegative * nArkhangai /100), 
                        Math.floor(totalNegative * nBayan_Ulgii /100), 
                        Math.floor(totalNegative * nBayankhongor /100), 
                        Math.floor(totalNegative * nBulgan /100), 
                        Math.floor(totalNegative * nGovi_Altai /100), 
                        Math.floor(totalNegative * nGovisumber /100), 
                        Math.floor(totalNegative * nDarkhan_Uul /100), 
                        Math.floor(totalNegative * nDornogovi /100), 
                        Math.floor(totalNegative * nDornod /100), 
                        Math.floor(totalNegative * nDundgovi /100), 
                        Math.floor(totalNegative * nZavkhan /100), 
                        Math.floor(totalNegative * nOrkhon /100), 
                        Math.floor(totalNegative * nUvurkhangai /100), 
                        Math.floor(totalNegative * nUmnugovi /100), 
                        Math.floor(totalNegative * nSukhbaatar /100), 
                        Math.floor(totalNegative * nSelenge /100), 
                        Math.floor(totalNegative * nTuv /100), 
                        Math.floor(totalNegative * nUvs /100), 
                        Math.floor(totalNegative * nKhovd /100), 
                        Math.floor(totalNegative * nKhuvsgul /100), 
                        Math.floor(totalNegative * nKhentii /100),
                        Math.floor(totalNegative * nGadaad /100)
                ];
        
                setTotalNegativeLocationData(totalNegativeLocationData);
        
                // calculating positive by UB location
                let totalPositiveUB = Math.floor(totalPositive * pUlaanbaatar /100)
                let totalNegativeUB = Math.floor(totalNegative * nUlaanbaatar /100)
        
                let pCHD = location.state.campaignDataAnalyze.positive.UB_location.CHD;
                let pKHUD = location.state.campaignDataAnalyze.positive.UB_location.KHUD;
                let pSBD = location.state.campaignDataAnalyze.positive.UB_location.SBD;
                let pBGD = location.state.campaignDataAnalyze.positive.UB_location.BGD;
                let pSKHD = location.state.campaignDataAnalyze.positive.UB_location.SKHD;
                let pBZD = location.state.campaignDataAnalyze.positive.UB_location.BZD;
                let pNalaikh = location.state.campaignDataAnalyze.positive.UB_location.Nalaikh;
                let pBaganuur = location.state.campaignDataAnalyze.positive.UB_location.Baganuur;
                let pBagakhangai = location.state.campaignDataAnalyze.positive.UB_location.Bagakhangai;
        
                let cCHD = Math.floor(totalPositiveUB * pCHD /100);
                let cKHUD = Math.floor(totalPositiveUB * pKHUD /100);
                let cSBD = Math.floor(totalPositiveUB * pSBD /100);
                let cBGD = Math.floor(totalPositiveUB * pBGD /100);
                let cSKHD = Math.floor(totalPositiveUB * pSKHD /100);
                let cBZD = Math.floor(totalPositiveUB * pBZD /100);
                let cNalaikh = Math.floor(totalPositiveUB * pNalaikh /100);
                let cBaganuur = Math.floor(totalPositiveUB * pBaganuur /100);
                let cBagakhangai = Math.floor(totalPositiveUB * pBagakhangai /100);
        
                const totalPUBLocation = [{
                        data: [{
                            x: 'Чингэлтэй',
                            y:  cCHD
                        },
                        {
                            x: 'Хан-Уул',
                            y:  cKHUD
                        },
                        {
                            x: 'Сүхбаатар',
                            y: cSBD
                        },
                        {
                            x: 'Баянгол',
                            y: cBGD
                        },
                        {
                            x: 'Сонгинохайрхан',
                            y: cSKHD
                        },
                        {
                            x: 'Баянзүрх',
                            y: cBZD
                        },
                        {
                            x: 'Налайх',
                            y: cNalaikh
                        },
                        {
                            x: 'Багануур',
                            y: cBaganuur
                        },
                        {
                            x: 'Багахангай',
                            y: cBagakhangai
                        }   
                        ]
                }]
        
                setTotalPositiveUBLocation(totalPUBLocation);
        
                let totalPositiveUBLocationData = [cCHD, cKHUD, cSBD, cBGD, cSKHD, cBZD, cNalaikh, cBaganuur, cBagakhangai];
        
                setTotalPositiveUBLocationData(totalPositiveUBLocationData);
        
                // calculating negative by UB location
                let nCHD = location.state.campaignDataAnalyze.negative.UB_location.CHD;
                let nKHUD = location.state.campaignDataAnalyze.negative.UB_location.KHUD;
                let nSBD = location.state.campaignDataAnalyze.negative.UB_location.SBD;
                let nBGD = location.state.campaignDataAnalyze.negative.UB_location.BGD;
                let nSKHD = location.state.campaignDataAnalyze.negative.UB_location.SKHD;
                let nBZD = location.state.campaignDataAnalyze.negative.UB_location.BZD;
                let nNalaikh = location.state.campaignDataAnalyze.negative.UB_location.Nalaikh;
                let nBaganuur = location.state.campaignDataAnalyze.negative.UB_location.Baganuur;
                let nBagakhangai = location.state.campaignDataAnalyze.negative.UB_location.Bagakhangai;
        
                let dCHD = Math.floor(totalNegativeUB * nCHD /100);
                let dKHUD = Math.floor(totalNegativeUB * nKHUD /100);
                let dSBD = Math.floor(totalNegativeUB * nSBD /100);
                let dBGD = Math.floor(totalNegativeUB * nBGD /100);
                let dSKHD = Math.floor(totalNegativeUB * nSKHD /100);
                let dBZD = Math.floor(totalNegativeUB * nBZD /100);
                let dNalaikh = Math.floor(totalNegativeUB * nNalaikh /100);
                let dBaganuur = Math.floor(totalNegativeUB * nBaganuur /100);
                let dBagakhangai = Math.floor(totalNegativeUB * nBagakhangai /100);
        
                const totalNUBLocation = [{
                        data: [{
                            x: 'Чингэлтэй',
                            y:  dCHD
                        },
                        {
                            x: 'Хан-Уул',
                            y:  dKHUD
                        },
                        {
                            x: 'Сүхбаатар',
                            y: dSBD
                        },
                        {
                            x: 'Баянгол',
                            y: dBGD
                        },
                        {
                            x: 'Сонгинохайрхан',
                            y: dSKHD
                        },
                        {
                            x: 'Баянзүрх',
                            y: dBZD
                        },
                        {
                            x: 'Налайх',
                            y: dNalaikh
                        },
                        {
                            x: 'Багануур',
                            y: dBaganuur
                        },
                        {
                            x: 'Багахангай',
                            y: dBagakhangai
                        }   
                        ]
                }]
        
                setTotalNegativeUBLocation(totalNUBLocation);
        
                let totalNegativeUBLocationData = [dCHD, dKHUD, dSBD, dBGD, dSKHD, dBZD, dNalaikh, dBaganuur, dBagakhangai];
        
                setTotalNegativeUBLocationData(totalNegativeUBLocationData);
        
                // calculating real or fake
                let positiveReal = location.state.campaignDataAnalyze.positive.real_fake.real;
                let negativeReal = location.state.campaignDataAnalyze.negative.real_fake.real;
        
                let positiveFake = location.state.campaignDataAnalyze.positive.real_fake.fake;
                let negativeFake = location.state.campaignDataAnalyze.negative.real_fake.fake;
        
                let positiveUnknown = location.state.campaignDataAnalyze.positive.real_fake.unknown;
                let negativeUnknown = location.state.campaignDataAnalyze.negative.real_fake.unknown;
        
                // calculating positive real or fake
                let cpositiveReal = Math.floor(totalPositive * positiveReal /100);
                let cpositiveFake = Math.floor(totalPositive * positiveFake /100);
                let cpositiveUnknown = Math.floor(totalPositive * positiveUnknown /100);

                
        
                let totalPRealFakeData = [cpositiveReal, cpositiveFake, cpositiveUnknown];
        
                setTotalPositiveRealFakeData(totalPRealFakeData);
        
                // calculating negative real or fake
                let cnegativeReal = Math.floor(totalNegative * negativeReal /100);
                let cnegativeFake = Math.floor(totalNegative * negativeFake /100);
                let cnegativeUnknown = Math.floor(totalNegative * negativeUnknown /100);
            
                let totalNRealFakeData = [cnegativeReal, cnegativeFake, cnegativeUnknown];

                // console.log("totalPositive:" + totalPositive);
                // console.log("totalNegative:" + totalNegative);
                // console.log("totalPRealFakeData:" + Math.floor(cpositiveReal + cpositiveFake + cpositiveUnknown));
                // console.log("totalNRealFakeData:" + Math.floor(cnegativeReal + cnegativeFake + cnegativeUnknown));
            
                setTotalNegativeRealFakeData(totalNRealFakeData);
        }
        // if (!isEmpty(statsposts)) {

        //     // let positiveLikesCount = 0;
        //     // let negativeLikesCount = 0;

        //     // statsposts?.map((item) => {        
        //     //             positiveLikesCount = positiveLikesCount + parseInt(item.totalPositiveLikes);
        //     //             negativeLikesCount = negativeLikesCount + parseInt(item.totalNegativeLikes);
        //     //         })
                
        //     // setTotalPositiveLikesCount(positiveLikesCount);
        //     // setTotalNegativeLikesCount(negativeLikesCount);  
            
        //     // let totalPositive = positiveLikesCount;
        //     // let totalNegative = negativeLikesCount;

        //     // let totalPositive = FBpositiveLikesCount + TWpositiveLikesCount + IGpositiveLikesCount;
        //     // let totalNegative = FBnegativeLikesCount + TWnegativeLikesCount + IGnegativeLikesCount;
            
        // }  
    }   
    catch {
        return <Navigate to="/" />;
    }    

    }, [statsposts, totalstatsposts, statsFBposts, statsTWposts, statsNWposts, statsIGposts]);

    const onChangeChartPeriod = pType => {
    try{
        switch(pType) {
            case '5year':
                setTimePeriod("5 жилийн"); break;
            case '2021':
                setTimePeriod("2021 оны"); break;
            case '2022':
                setTimePeriod("2022 оны"); break;
            case '2023':
                setTimePeriod("2023 оны"); break;
            case '2year':
                setTimePeriod("2 жилийн"); break;
            case '1year':
                setTimePeriod("1 жилийн"); break;
            case '6month':
                setTimePeriod("6 сарын"); break;
            case '1month':
                setTimePeriod("1 сарын"); break;
            case '7days':
                setTimePeriod("7 хоногийн"); break;
            default:
                setTimePeriod("1 сарын"); break;
        }
        const params = {
        taskId: location.state.campaignId,
        source: "all",
        dateRange: pType
        };

        dispatch(onGetStatsPosts(params));

        const totalparams = {
            taskId: location.state.campaignId,
            dateRange: pType
        };

        dispatch(onGetTotalStatsPosts(totalparams));

        const paramsFB = {
            taskId: location.state.campaignId,
            source: "facebook",
            dateRange: pType
        };

        dispatch(onGetStatsFBPosts(paramsFB));

        const paramsTW = {
            taskId: location.state.campaignId,
            source: "twitter",
            dateRange: pType
        };

        dispatch(onGetStatsTWPosts(paramsTW));

        const paramsNW = {
            taskId: location.state.campaignId,
            source: "news",
            dateRange: pType
        };

        dispatch(onGetStatsNWPosts(paramsNW));

        const paramsIG = {
            taskId: location.state.campaignId,
            source: "instagram",
            dateRange: pType
        };

        dispatch(onGetStatsIGPosts(paramsIG));
    }
    catch {
        return <Navigate to="/" />;
    }   
    };

    // useEffect(() => {
    //     if (!isEmpty(statsposts)) {

    //         let positiveLikesCount = 0;
    //         let negativeLikesCount = 0;

    //         statsposts?.map((item) => {        
    //                     positiveLikesCount = positiveLikesCount + parseInt(item.totalPositiveLikes);
    //                     negativeLikesCount = negativeLikesCount + parseInt(item.totalNegativeLikes);
    //                 })
                
    //         setTotalPositiveLikesCount(positiveLikesCount);
    //         setTotalNegativeLikesCount(negativeLikesCount);  
            
    //         let totalPositive = positiveLikesCount;
    //         let totalNegative = negativeLikesCount;

    //         // let totalPositive = FBpositiveLikesCount + TWpositiveLikesCount + IGpositiveLikesCount;
    //         // let totalNegative = FBnegativeLikesCount + TWnegativeLikesCount + IGnegativeLikesCount;
            
    //         if(location.state.campaignDataAnalyze && totalPositive && totalPositive > 0 && totalNegative && totalNegative > 0)
    //         {
    //         let positiveMale = location.state.campaignDataAnalyze.positive.age.male;
    //         let positiveFemale = location.state.campaignDataAnalyze.positive.age.female;
    
    //         let negativeMale = location.state.campaignDataAnalyze.negative.age.male;
    //         let negativeFemale = location.state.campaignDataAnalyze.negative.age.female;
    
    //         // calculating positive by gender
    //         let pm13_17 = Math.floor(totalPositive * positiveMale.a13_17 /100);
    //         let pm18_24 = Math.floor(totalPositive * positiveMale.a18_24 /100);
    //         let pm25_34 = Math.floor(totalPositive * positiveMale.a25_34 /100);
    //         let pm35_44 = Math.floor(totalPositive * positiveMale.a35_44 /100);
    //         let pm45_54 = Math.floor(totalPositive * positiveMale.a45_54 /100);
    //         let pm55_64 = Math.floor(totalPositive * positiveMale.a55_64 /100);
    //         let pm65plus = Math.floor(totalPositive * positiveMale.a65plus /100);
    
    //         let pfm13_17 = Math.floor(totalPositive * positiveFemale.a13_17 /100);
    //         let pfm18_24 = Math.floor(totalPositive * positiveFemale.a18_24 /100);
    //         let pfm25_34 = Math.floor(totalPositive * positiveFemale.a25_34 /100);
    //         let pfm35_44 = Math.floor(totalPositive * positiveFemale.a35_44 /100);
    //         let pfm45_54 = Math.floor(totalPositive * positiveFemale.a45_54 /100);
    //         let pfm55_64 = Math.floor(totalPositive * positiveFemale.a55_64 /100);
    //         let pfm65plus = Math.floor(totalPositive * positiveFemale.a65plus /100);
    
    //         let pmale = [pm13_17, pm18_24, pm25_34, pm35_44, pm45_54, pm55_64, pm65plus];
    //         let pfemale = [pfm13_17, pfm18_24, pfm25_34, pfm35_44, pfm45_54, pfm55_64, pfm65plus];
    
    //         setGenderPositiveM(pmale);
    //         setGenderPositiveFM(pfemale);
    
    //         // calculating negative by gender      
    //         let nm13_17 = Math.floor(totalNegative * negativeMale.a13_17 /100);
    //         let nm18_24 = Math.floor(totalNegative * negativeMale.a18_24 /100);
    //         let nm25_34 = Math.floor(totalNegative * negativeMale.a25_34 /100);
    //         let nm35_44 = Math.floor(totalNegative * negativeMale.a35_44 /100);
    //         let nm45_54 = Math.floor(totalNegative * negativeMale.a45_54 /100);
    //         let nm55_64 = Math.floor(totalNegative * negativeMale.a55_64 /100);
    //         let nm65plus = Math.floor(totalNegative * negativeMale.a65plus /100);
    
    //         let nfm13_17 = Math.floor(totalNegative * negativeFemale.a13_17 /100);
    //         let nfm18_24 = Math.floor(totalNegative * negativeFemale.a18_24 /100);
    //         let nfm25_34 = Math.floor(totalNegative * negativeFemale.a25_34 /100);
    //         let nfm35_44 = Math.floor(totalNegative * negativeFemale.a35_44 /100);
    //         let nfm45_54 = Math.floor(totalNegative * negativeFemale.a45_54 /100);
    //         let nfm55_64 = Math.floor(totalNegative * negativeFemale.a55_64 /100);
    //         let nfm65plus = Math.floor(totalNegative * negativeFemale.a65plus /100);
    
    //         let nmale = [nm13_17, nm18_24, nm25_34, nm35_44, nm45_54, nm55_64, nm65plus];
    //         let nfemale = [nfm13_17, nfm18_24, nfm25_34, nfm35_44, nfm45_54, nfm55_64, nfm65plus];
    
    //         setGenderNegativeM(nmale);
    //         setGenderNegativeFM(nfemale);
    
    //         const pmaleSum = pmale.reduce((previousValue, currentValue, index) => previousValue + currentValue, 0);
    //         setTotalPMale(Math.floor(pmaleSum));
    
    //         const nmaleSum = nmale.reduce((previousValue, currentValue, index) => previousValue + currentValue, 0);
    //         setTotalNMale(Math.floor(nmaleSum));
    
    //         const pfemaleSum = pfemale.reduce((previousValue, currentValue, index) => previousValue + currentValue, 0);
    //         setTotalPFemale(Math.floor(pfemaleSum));
    
    //         const nfemaleSum = nfemale.reduce((previousValue, currentValue, index) => previousValue + currentValue, 0);
    //         setTotalNFemale(Math.floor(nfemaleSum));
    
    //         // calculating positive by location
    //         let pUlaanbaatar = location.state.campaignDataAnalyze.positive.Total_location.Ulaanbaatar;
    //         let pArkhangai = location.state.campaignDataAnalyze.positive.Total_location.Arkhangai;
    //         let pBayan_Ulgii = location.state.campaignDataAnalyze.positive.Total_location.Bayan_Ulgii;
    //         let pBayankhongor = location.state.campaignDataAnalyze.positive.Total_location.Bayankhongor;
    //         let pBulgan = location.state.campaignDataAnalyze.positive.Total_location.Bulgan;
    //         let pGovi_Altai = location.state.campaignDataAnalyze.positive.Total_location.Govi_Altai;
    //         let pGovisumber = location.state.campaignDataAnalyze.positive.Total_location.Govisumber;
    //         let pDarkhan_Uul = location.state.campaignDataAnalyze.positive.Total_location.Darkhan_Uul;
    //         let pDornogovi = location.state.campaignDataAnalyze.positive.Total_location.Dornogovi;
    //         let pDornod = location.state.campaignDataAnalyze.positive.Total_location.Dornod;
    //         let pDundgovi = location.state.campaignDataAnalyze.positive.Total_location.Dundgovi;
    //         let pZavkhan = location.state.campaignDataAnalyze.positive.Total_location.Zavkhan;
    //         let pOrkhon = location.state.campaignDataAnalyze.positive.Total_location.Orkhon;
    //         let pUvurkhangai = location.state.campaignDataAnalyze.positive.Total_location.Uvurkhangai;
    //         let pUmnugovi = location.state.campaignDataAnalyze.positive.Total_location.Umnugovi;
    //         let pSukhbaatar = location.state.campaignDataAnalyze.positive.Total_location.Sukhbaatar;
    //         let pSelenge = location.state.campaignDataAnalyze.positive.Total_location.Selenge;
    //         let pTuv = location.state.campaignDataAnalyze.positive.Total_location.Tuv;
    //         let pUvs = location.state.campaignDataAnalyze.positive.Total_location.Uvs;
    //         let pKhovd = location.state.campaignDataAnalyze.positive.Total_location.Khovd;
    //         let pKhuvsgul = location.state.campaignDataAnalyze.positive.Total_location.Khuvsgul;
    //         let pKhentii = location.state.campaignDataAnalyze.positive.Total_location.Khentii;
    //         let pGadaad = location.state.campaignDataAnalyze.positive.Total_location.Gadaad;
    
    //         const totalPLocation = [{
    //                 data: [{
    //                     x: 'Улаанбаатар',
    //                     y:  Math.floor(totalPositive * pUlaanbaatar /100)
    //                 },
    //                 {
    //                     x: 'Архангай',
    //                     y:  Math.floor(totalPositive * pArkhangai /100)
    //                 },
    //                 {
    //                     x: 'Баян-Өлгий',
    //                     y: Math.floor(totalPositive * pBayan_Ulgii /100)
    //                 },
    //                 {
    //                     x: 'Баянхонгор',
    //                     y: Math.floor(totalPositive * pBayankhongor /100)
    //                 },
    //                 {
    //                     x: 'Булган',
    //                     y: Math.floor(totalPositive * pBulgan /100)
    //                 },
    //                 {
    //                     x: 'Говь-Алтай',
    //                     y: Math.floor(totalPositive * pGovi_Altai /100)
    //                 },
    //                 {
    //                     x: 'Говьсүмбэр',
    //                     y: Math.floor(totalPositive * pGovisumber /100)
    //                 },
    //                 {
    //                     x: 'Дархан-Уул',
    //                     y: Math.floor(totalPositive * pDarkhan_Uul /100)
    //                 },
    //                 {
    //                     x: 'Дорноговь',
    //                     y: Math.floor(totalPositive * pDornogovi /100)
    //                 },
    //                 {
    //                     x: 'Дорнод',
    //                     y: Math.floor(totalPositive * pDornod /100)
    //                 },
    //                 {
    //                     x: 'Дундговь',
    //                     y: Math.floor(totalPositive * pDundgovi /100)
    //                 },
    //                 {
    //                     x: 'Завхан',
    //                     y: Math.floor(totalPositive * pZavkhan /100)
    //                 },
    //                 {
    //                     x: 'Орхон',
    //                     y: Math.floor(totalPositive * pOrkhon /100)
    //                 },
    //                 {
    //                     x: 'Өвөрхангай',
    //                     y: Math.floor(totalPositive * pUvurkhangai /100)
    //                 },
    //                 {
    //                     x: 'Өмнөговь',
    //                     y: Math.floor(totalPositive * pUmnugovi /100)
    //                 },
    //                 {
    //                     x: 'Сүхбаатар',
    //                     y: Math.floor(totalPositive * pSukhbaatar /100)
    //                 },
    //                 {
    //                     x: 'Сэлэнгэ',
    //                     y: Math.floor(totalPositive * pSelenge /100)
    //                 },
    //                 {
    //                     x: 'Төв',
    //                     y: Math.floor(totalPositive * pTuv /100)
    //                 },
    //                 {
    //                     x: 'Увс',
    //                     y: Math.floor(totalPositive * pUvs /100)
    //                 },
    //                 {
    //                     x: 'Ховд',
    //                     y: Math.floor(totalPositive * pKhovd /100)
    //                 },
    //                 {
    //                     x: 'Хөвсгөл',
    //                     y: Math.floor(totalPositive * pKhuvsgul /100)
    //                 },
    //                 {
    //                     x: 'Хэнтий',
    //                     y: Math.floor(totalPositive * pKhentii /100)
    //                 },
    //                 {
    //                     x: 'Гадаад улс',
    //                     y: Math.floor(totalPositive * pGadaad /100)
    //                 }         
    //                 ]
    //         }]
    
    //         setTotalPositiveLocation(totalPLocation);
    
    //         let totalPositiveLocationData = [
    //                 Math.floor(totalPositive * pUlaanbaatar /100), 
    //                 Math.floor(totalPositive * pArkhangai /100), 
    //                 Math.floor(totalPositive * pBayan_Ulgii /100), 
    //                 Math.floor(totalPositive * pBayankhongor /100), 
    //                 Math.floor(totalPositive * pBulgan /100), 
    //                 Math.floor(totalPositive * pGovi_Altai /100), 
    //                 Math.floor(totalPositive * pGovisumber /100), 
    //                 Math.floor(totalPositive * pDarkhan_Uul /100), 
    //                 Math.floor(totalPositive * pDornogovi /100), 
    //                 Math.floor(totalPositive * pDornod /100), 
    //                 Math.floor(totalPositive * pDundgovi /100), 
    //                 Math.floor(totalPositive * pZavkhan /100), 
    //                 Math.floor(totalPositive * pOrkhon /100), 
    //                 Math.floor(totalPositive * pUvurkhangai /100), 
    //                 Math.floor(totalPositive * pUmnugovi /100), 
    //                 Math.floor(totalPositive * pSukhbaatar /100), 
    //                 Math.floor(totalPositive * pSelenge /100), 
    //                 Math.floor(totalPositive * pTuv /100), 
    //                 Math.floor(totalPositive * pUvs /100), 
    //                 Math.floor(totalPositive * pKhovd /100), 
    //                 Math.floor(totalPositive * pKhuvsgul /100), 
    //                 Math.floor(totalPositive * pKhentii /100),
    //                 Math.floor(totalPositive * pGadaad /100)
    //         ];
    
    //         setTotalPositiveLocationData(totalPositiveLocationData);
    
    //         // calculating positive by location
    //         let nUlaanbaatar = location.state.campaignDataAnalyze.negative.Total_location.Ulaanbaatar;
    //         let nArkhangai = location.state.campaignDataAnalyze.negative.Total_location.Arkhangai;
    //         let nBayan_Ulgii = location.state.campaignDataAnalyze.negative.Total_location.Bayan_Ulgii;
    //         let nBayankhongor = location.state.campaignDataAnalyze.negative.Total_location.Bayankhongor;
    //         let nBulgan = location.state.campaignDataAnalyze.negative.Total_location.Bulgan;
    //         let nGovi_Altai = location.state.campaignDataAnalyze.negative.Total_location.Govi_Altai;
    //         let nGovisumber = location.state.campaignDataAnalyze.negative.Total_location.Govisumber;
    //         let nDarkhan_Uul = location.state.campaignDataAnalyze.negative.Total_location.Darkhan_Uul;
    //         let nDornogovi = location.state.campaignDataAnalyze.negative.Total_location.Dornogovi;
    //         let nDornod = location.state.campaignDataAnalyze.negative.Total_location.Dornod;
    //         let nDundgovi = location.state.campaignDataAnalyze.negative.Total_location.Dundgovi;
    //         let nZavkhan = location.state.campaignDataAnalyze.negative.Total_location.Zavkhan;
    //         let nOrkhon = location.state.campaignDataAnalyze.negative.Total_location.Orkhon;
    //         let nUvurkhangai = location.state.campaignDataAnalyze.negative.Total_location.Uvurkhangai;
    //         let nUmnugovi = location.state.campaignDataAnalyze.negative.Total_location.Umnugovi;
    //         let nSukhbaatar = location.state.campaignDataAnalyze.negative.Total_location.Sukhbaatar;
    //         let nSelenge = location.state.campaignDataAnalyze.negative.Total_location.Selenge;
    //         let nTuv = location.state.campaignDataAnalyze.negative.Total_location.Tuv;
    //         let nUvs = location.state.campaignDataAnalyze.negative.Total_location.Uvs;
    //         let nKhovd = location.state.campaignDataAnalyze.negative.Total_location.Khovd;
    //         let nKhuvsgul = location.state.campaignDataAnalyze.negative.Total_location.Khuvsgul;
    //         let nKhentii = location.state.campaignDataAnalyze.negative.Total_location.Khentii;
    //         let nGadaad = location.state.campaignDataAnalyze.negative.Total_location.Gadaad;
    
    //         const totalNLocation = [{
    //                 data: [{
    //                     x: 'Улаанбаатар',
    //                     y:  Math.floor(totalNegative * nUlaanbaatar /100)
    //                 },
    //                 {
    //                     x: 'Архангай',
    //                     y:  Math.floor(totalNegative * nArkhangai /100)
    //                 },
    //                 {
    //                     x: 'Баян-Өлгий',
    //                     y: Math.floor(totalNegative * nBayan_Ulgii /100)
    //                 },
    //                 {
    //                     x: 'Баянхонгор',
    //                     y: Math.floor(totalNegative * nBayankhongor /100)
    //                 },
    //                 {
    //                     x: 'Булган',
    //                     y: Math.floor(totalNegative * nBulgan /100)
    //                 },
    //                 {
    //                     x: 'Говь-Алтай',
    //                     y: Math.floor(totalNegative * nGovi_Altai /100)
    //                 },
    //                 {
    //                     x: 'Говьсүмбэр',
    //                     y: Math.floor(totalNegative * nGovisumber /100)
    //                 },
    //                 {
    //                     x: 'Дархан-Уул',
    //                     y: Math.floor(totalNegative * nDarkhan_Uul /100)
    //                 },
    //                 {
    //                     x: 'Дорноговь',
    //                     y: Math.floor(totalNegative * nDornogovi /100)
    //                 },
    //                 {
    //                     x: 'Дорнод',
    //                     y: Math.floor(totalNegative * nDornod /100)
    //                 },
    //                 {
    //                     x: 'Дундговь',
    //                     y: Math.floor(totalNegative * nDundgovi /100)
    //                 },
    //                 {
    //                     x: 'Завхан',
    //                     y: Math.floor(totalNegative * nZavkhan /100)
    //                 },
    //                 {
    //                     x: 'Орхон',
    //                     y: Math.floor(totalNegative * nOrkhon /100)
    //                 },
    //                 {
    //                     x: 'Өвөрхангай',
    //                     y: Math.floor(totalNegative * nUvurkhangai /100)
    //                 },
    //                 {
    //                     x: 'Өмнөговь',
    //                     y: Math.floor(totalNegative * nUmnugovi /100)
    //                 },
    //                 {
    //                     x: 'Сүхбаатар',
    //                     y: Math.floor(totalNegative * nSukhbaatar /100)
    //                 },
    //                 {
    //                     x: 'Сэлэнгэ',
    //                     y: Math.floor(totalNegative * nSelenge /100)
    //                 },
    //                 {
    //                     x: 'Төв',
    //                     y: Math.floor(totalNegative * nTuv /100)
    //                 },
    //                 {
    //                     x: 'Увс',
    //                     y: Math.floor(totalNegative * nUvs /100)
    //                 },
    //                 {
    //                     x: 'Ховд',
    //                     y: Math.floor(totalNegative * nKhovd /100)
    //                 },
    //                 {
    //                     x: 'Хөвсгөл',
    //                     y: Math.floor(totalNegative * nKhuvsgul /100)
    //                 },
    //                 {
    //                     x: 'Хэнтий',
    //                     y: Math.floor(totalNegative * nKhentii /100)
    //                 },
    //                 {
    //                     x: 'Гадаад улс',
    //                     y: Math.floor(totalNegative * nGadaad /100)
    //                 }           
    //                 ]
    //         }]
    
    //         setTotalNegativeLocation(totalNLocation);
    
    //         let totalNegativeLocationData = [
    //                 Math.floor(totalNegative * nUlaanbaatar /100), 
    //                 Math.floor(totalNegative * nArkhangai /100), 
    //                 Math.floor(totalNegative * nBayan_Ulgii /100), 
    //                 Math.floor(totalNegative * nBayankhongor /100), 
    //                 Math.floor(totalNegative * nBulgan /100), 
    //                 Math.floor(totalNegative * nGovi_Altai /100), 
    //                 Math.floor(totalNegative * nGovisumber /100), 
    //                 Math.floor(totalNegative * nDarkhan_Uul /100), 
    //                 Math.floor(totalNegative * nDornogovi /100), 
    //                 Math.floor(totalNegative * nDornod /100), 
    //                 Math.floor(totalNegative * nDundgovi /100), 
    //                 Math.floor(totalNegative * nZavkhan /100), 
    //                 Math.floor(totalNegative * nOrkhon /100), 
    //                 Math.floor(totalNegative * nUvurkhangai /100), 
    //                 Math.floor(totalNegative * nUmnugovi /100), 
    //                 Math.floor(totalNegative * nSukhbaatar /100), 
    //                 Math.floor(totalNegative * nSelenge /100), 
    //                 Math.floor(totalNegative * nTuv /100), 
    //                 Math.floor(totalNegative * nUvs /100), 
    //                 Math.floor(totalNegative * nKhovd /100), 
    //                 Math.floor(totalNegative * nKhuvsgul /100), 
    //                 Math.floor(totalNegative * nKhentii /100),
    //                 Math.floor(totalNegative * nGadaad /100)
    //         ];
    
    //         setTotalNegativeLocationData(totalNegativeLocationData);
    
    //         // calculating positive by UB location
    //         let totalPositiveUB = Math.floor(totalPositive * pUlaanbaatar /100)
    //         let totalNegativeUB = Math.floor(totalNegative * nUlaanbaatar /100)
    
    //         let pCHD = location.state.campaignDataAnalyze.positive.UB_location.CHD;
    //         let pKHUD = location.state.campaignDataAnalyze.positive.UB_location.KHUD;
    //         let pSBD = location.state.campaignDataAnalyze.positive.UB_location.SBD;
    //         let pBGD = location.state.campaignDataAnalyze.positive.UB_location.BGD;
    //         let pSKHD = location.state.campaignDataAnalyze.positive.UB_location.SKHD;
    //         let pBZD = location.state.campaignDataAnalyze.positive.UB_location.BZD;
    //         let pNalaikh = location.state.campaignDataAnalyze.positive.UB_location.Nalaikh;
    //         let pBaganuur = location.state.campaignDataAnalyze.positive.UB_location.Baganuur;
    //         let pBagakhangai = location.state.campaignDataAnalyze.positive.UB_location.Bagakhangai;
    
    //         let cCHD = Math.floor(totalPositiveUB * pCHD /100);
    //         let cKHUD = Math.floor(totalPositiveUB * pKHUD /100);
    //         let cSBD = Math.floor(totalPositiveUB * pSBD /100);
    //         let cBGD = Math.floor(totalPositiveUB * pBGD /100);
    //         let cSKHD = Math.floor(totalPositiveUB * pSKHD /100);
    //         let cBZD = Math.floor(totalPositiveUB * pBZD /100);
    //         let cNalaikh = Math.floor(totalPositiveUB * pNalaikh /100);
    //         let cBaganuur = Math.floor(totalPositiveUB * pBaganuur /100);
    //         let cBagakhangai = Math.floor(totalPositiveUB * pBagakhangai /100);
    
    //         const totalPUBLocation = [{
    //                 data: [{
    //                     x: 'Чингэлтэй',
    //                     y:  cCHD
    //                 },
    //                 {
    //                     x: 'Хан-Уул',
    //                     y:  cKHUD
    //                 },
    //                 {
    //                     x: 'Сүхбаатар',
    //                     y: cSBD
    //                 },
    //                 {
    //                     x: 'Баянгол',
    //                     y: cBGD
    //                 },
    //                 {
    //                     x: 'Сонгинохайрхан',
    //                     y: cSKHD
    //                 },
    //                 {
    //                     x: 'Баянзүрх',
    //                     y: cBZD
    //                 },
    //                 {
    //                     x: 'Налайх',
    //                     y: cNalaikh
    //                 },
    //                 {
    //                     x: 'Багануур',
    //                     y: cBaganuur
    //                 },
    //                 {
    //                     x: 'Багахангай',
    //                     y: cBagakhangai
    //                 }   
    //                 ]
    //         }]
    
    //         setTotalPositiveUBLocation(totalPUBLocation);
    
    //         let totalPositiveUBLocationData = [cCHD, cKHUD, cSBD, cBGD, cSKHD, cBZD, cNalaikh, cBaganuur, cBagakhangai];
    
    //         setTotalPositiveUBLocationData(totalPositiveUBLocationData);
    
    //         // calculating negative by UB location
    //         let nCHD = location.state.campaignDataAnalyze.negative.UB_location.CHD;
    //         let nKHUD = location.state.campaignDataAnalyze.negative.UB_location.KHUD;
    //         let nSBD = location.state.campaignDataAnalyze.negative.UB_location.SBD;
    //         let nBGD = location.state.campaignDataAnalyze.negative.UB_location.BGD;
    //         let nSKHD = location.state.campaignDataAnalyze.negative.UB_location.SKHD;
    //         let nBZD = location.state.campaignDataAnalyze.negative.UB_location.BZD;
    //         let nNalaikh = location.state.campaignDataAnalyze.negative.UB_location.Nalaikh;
    //         let nBaganuur = location.state.campaignDataAnalyze.negative.UB_location.Baganuur;
    //         let nBagakhangai = location.state.campaignDataAnalyze.negative.UB_location.Bagakhangai;
    
    //         let dCHD = Math.floor(totalNegativeUB * nCHD /100);
    //         let dKHUD = Math.floor(totalNegativeUB * nKHUD /100);
    //         let dSBD = Math.floor(totalNegativeUB * nSBD /100);
    //         let dBGD = Math.floor(totalNegativeUB * nBGD /100);
    //         let dSKHD = Math.floor(totalNegativeUB * nSKHD /100);
    //         let dBZD = Math.floor(totalNegativeUB * nBZD /100);
    //         let dNalaikh = Math.floor(totalNegativeUB * nNalaikh /100);
    //         let dBaganuur = Math.floor(totalNegativeUB * nBaganuur /100);
    //         let dBagakhangai = Math.floor(totalNegativeUB * nBagakhangai /100);
    
    //         const totalNUBLocation = [{
    //                 data: [{
    //                     x: 'Чингэлтэй',
    //                     y:  dCHD
    //                 },
    //                 {
    //                     x: 'Хан-Уул',
    //                     y:  dKHUD
    //                 },
    //                 {
    //                     x: 'Сүхбаатар',
    //                     y: dSBD
    //                 },
    //                 {
    //                     x: 'Баянгол',
    //                     y: dBGD
    //                 },
    //                 {
    //                     x: 'Сонгинохайрхан',
    //                     y: dSKHD
    //                 },
    //                 {
    //                     x: 'Баянзүрх',
    //                     y: dBZD
    //                 },
    //                 {
    //                     x: 'Налайх',
    //                     y: dNalaikh
    //                 },
    //                 {
    //                     x: 'Багануур',
    //                     y: dBaganuur
    //                 },
    //                 {
    //                     x: 'Багахангай',
    //                     y: dBagakhangai
    //                 }   
    //                 ]
    //         }]
    
    //         setTotalNegativeUBLocation(totalNUBLocation);
    
    //         let totalNegativeUBLocationData = [dCHD, dKHUD, dSBD, dBGD, dSKHD, dBZD, dNalaikh, dBaganuur, dBagakhangai];
    
    //         setTotalNegativeUBLocationData(totalNegativeUBLocationData);
    
    //         // calculating real or fake
    //         let positiveReal = location.state.campaignDataAnalyze.positive.real_fake.real;
    //         let negativeReal = location.state.campaignDataAnalyze.negative.real_fake.real;
    
    //         let positiveFake = location.state.campaignDataAnalyze.positive.real_fake.fake;
    //         let negativeFake = location.state.campaignDataAnalyze.negative.real_fake.fake;
    
    //         let positiveUnknown = location.state.campaignDataAnalyze.positive.real_fake.unknown;
    //         let negativeUnknown = location.state.campaignDataAnalyze.negative.real_fake.unknown;
    
    //         // calculating positive real or fake
    //         let cpositiveReal = Math.floor(totalPositive * positiveReal /100);
    //         let cpositiveFake = Math.floor(totalPositive * positiveFake /100);
    //         let cpositiveUnknown = Math.floor(totalPositive * positiveUnknown /100);
    
    //         let totalPRealFakeData = [cpositiveReal, cpositiveFake, cpositiveUnknown];
    
    //         setTotalPositiveRealFakeData(totalPRealFakeData);
    
    //         // calculating negative real or fake
    //         let cnegativeReal = Math.floor(totalNegative * negativeReal /100);
    //         let cnegativeFake = Math.floor(totalNegative * negativeFake /100);
    //         let cnegativeUnknown = Math.floor(totalNegative * negativeUnknown /100);
        
    //         let totalNRealFakeData = [cnegativeReal, cnegativeFake, cnegativeUnknown];
        
    //         setTotalNegativeRealFakeData(totalNRealFakeData);
    //         }

            
    //     }     
    // }, [statsposts, statsFBposts, statsTWposts, statsIGposts]);

  // useEffect(() => {
  //     try {                    
  //         stats?.map((item) => {        
  //           positiveLikesCount = positiveLikesCount + parseInt(item.totalPositiveLikes);
  //           negativeLikesCount = negativeLikesCount + parseInt(item.totalNegativeLikes);
  //         })
     
  //         setTotalPositiveLikesCount(positiveLikesCount);
  //         setTotalNegativeLikesCount(negativeLikesCount);   
  //     }   
  //     catch {
  //         return <Navigate to="/" />;
  //     }    

  // }, [statsposts]);

  // useEffect(() => {
  //   try {
  //     let totalPositive = totalPositiveLikesCount;
  //     let totalNegative = totalNegativeLikesCount;

  //     let positiveMale = location.state.campaignDataAnalyze.positive.age.male;
  //     let positiveFemale = location.state.campaignDataAnalyze.positive.age.female;

  //     let negativeMale = location.state.campaignDataAnalyze.negative.age.male;
  //     let negativeFemale = location.state.campaignDataAnalyze.negative.age.female;

  //     // calculating positive by gender
  //     let pm13_17 = Math.floor(totalPositive * positiveMale.a13_17 /100);
  //     let pm18_24 = Math.floor(totalPositive * positiveMale.a18_24 /100);
  //     let pm25_34 = Math.floor(totalPositive * positiveMale.a25_34 /100);
  //     let pm35_44 = Math.floor(totalPositive * positiveMale.a35_44 /100);
  //     let pm45_54 = Math.floor(totalPositive * positiveMale.a45_54 /100);
  //     let pm55_64 = Math.floor(totalPositive * positiveMale.a55_64 /100);
  //     let pm65plus = Math.floor(totalPositive * positiveMale.a65plus /100);

  //     let pfm13_17 = Math.floor(totalPositive * positiveFemale.a13_17 /100);
  //     let pfm18_24 = Math.floor(totalPositive * positiveFemale.a18_24 /100);
  //     let pfm25_34 = Math.floor(totalPositive * positiveFemale.a25_34 /100);
  //     let pfm35_44 = Math.floor(totalPositive * positiveFemale.a35_44 /100);
  //     let pfm45_54 = Math.floor(totalPositive * positiveFemale.a45_54 /100);
  //     let pfm55_64 = Math.floor(totalPositive * positiveFemale.a55_64 /100);
  //     let pfm65plus = Math.floor(totalPositive * positiveFemale.a65plus /100);

  //     let pmale = [pm13_17, pm18_24, pm25_34, pm35_44, pm45_54, pm55_64, pm65plus];
  //     let pfemale = [pfm13_17, pfm18_24, pfm25_34, pfm35_44, pfm45_54, pfm55_64, pfm65plus];

  //     setGenderPositiveM(pmale);
  //     setGenderPositiveFM(pfemale);

  //     // calculating negative by gender      
  //     let nm13_17 = Math.floor(totalNegative * negativeMale.a13_17 /100);
  //     let nm18_24 = Math.floor(totalNegative * negativeMale.a18_24 /100);
  //     let nm25_34 = Math.floor(totalNegative * negativeMale.a25_34 /100);
  //     let nm35_44 = Math.floor(totalNegative * negativeMale.a35_44 /100);
  //     let nm45_54 = Math.floor(totalNegative * negativeMale.a45_54 /100);
  //     let nm55_64 = Math.floor(totalNegative * negativeMale.a55_64 /100);
  //     let nm65plus = Math.floor(totalNegative * negativeMale.a65plus /100);

  //     let nfm13_17 = Math.floor(totalNegative * negativeFemale.a13_17 /100);
  //     let nfm18_24 = Math.floor(totalNegative * negativeFemale.a18_24 /100);
  //     let nfm25_34 = Math.floor(totalNegative * negativeFemale.a25_34 /100);
  //     let nfm35_44 = Math.floor(totalNegative * negativeFemale.a35_44 /100);
  //     let nfm45_54 = Math.floor(totalNegative * negativeFemale.a45_54 /100);
  //     let nfm55_64 = Math.floor(totalNegative * negativeFemale.a55_64 /100);
  //     let nfm65plus = Math.floor(totalNegative * negativeFemale.a65plus /100);

  //     let nmale = [nm13_17, nm18_24, nm25_34, nm35_44, nm45_54, nm55_64, nm65plus];
  //     let nfemale = [nfm13_17, nfm18_24, nfm25_34, nfm35_44, nfm45_54, nfm55_64, nfm65plus];

  //     setGenderNegativeM(nmale);
  //     setGenderNegativeFM(nfemale);

  //     const pmaleSum = pmale.reduce((previousValue, currentValue, index) => previousValue + currentValue, 0);
  //     setTotalPMale(Math.floor(pmaleSum));

  //     const nmaleSum = nmale.reduce((previousValue, currentValue, index) => previousValue + currentValue, 0);
  //     setTotalNMale(Math.floor(nmaleSum));

  //     const pfemaleSum = pfemale.reduce((previousValue, currentValue, index) => previousValue + currentValue, 0);
  //     setTotalPFemale(Math.floor(pfemaleSum));

  //     const nfemaleSum = nfemale.reduce((previousValue, currentValue, index) => previousValue + currentValue, 0);
  //     setTotalNFemale(Math.floor(nfemaleSum));

  //     // calculating positive by location
  //     let pUlaanbaatar = location.state.campaignDataAnalyze.positive.Total_location.Ulaanbaatar;
  //     let pArkhangai = location.state.campaignDataAnalyze.positive.Total_location.Arkhangai;
  //     let pBayan_Ulgii = location.state.campaignDataAnalyze.positive.Total_location.Bayan_Ulgii;
  //     let pBayankhongor = location.state.campaignDataAnalyze.positive.Total_location.Bayankhongor;
  //     let pBulgan = location.state.campaignDataAnalyze.positive.Total_location.Bulgan;
  //     let pGovi_Altai = location.state.campaignDataAnalyze.positive.Total_location.Govi_Altai;
  //     let pGovisumber = location.state.campaignDataAnalyze.positive.Total_location.Govisumber;
  //     let pDarkhan_Uul = location.state.campaignDataAnalyze.positive.Total_location.Darkhan_Uul;
  //     let pDornogovi = location.state.campaignDataAnalyze.positive.Total_location.Dornogovi;
  //     let pDornod = location.state.campaignDataAnalyze.positive.Total_location.Dornod;
  //     let pDundgovi = location.state.campaignDataAnalyze.positive.Total_location.Dundgovi;
  //     let pZavkhan = location.state.campaignDataAnalyze.positive.Total_location.Zavkhan;
  //     let pOrkhon = location.state.campaignDataAnalyze.positive.Total_location.Orkhon;
  //     let pUvurkhangai = location.state.campaignDataAnalyze.positive.Total_location.Uvurkhangai;
  //     let pUmnugovi = location.state.campaignDataAnalyze.positive.Total_location.Umnugovi;
  //     let pSukhbaatar = location.state.campaignDataAnalyze.positive.Total_location.Sukhbaatar;
  //     let pSelenge = location.state.campaignDataAnalyze.positive.Total_location.Selenge;
  //     let pTuv = location.state.campaignDataAnalyze.positive.Total_location.Tuv;
  //     let pUvs = location.state.campaignDataAnalyze.positive.Total_location.Uvs;
  //     let pKhovd = location.state.campaignDataAnalyze.positive.Total_location.Khovd;
  //     let pKhuvsgul = location.state.campaignDataAnalyze.positive.Total_location.Khuvsgul;
  //     let pKhentii = location.state.campaignDataAnalyze.positive.Total_location.Khentii;
  //     let pGadaad = location.state.campaignDataAnalyze.positive.Total_location.Gadaad;

  //     const totalPLocation = [{
  //           data: [{
  //               x: 'Улаанбаатар',
  //               y:  Math.floor(totalPositive * pUlaanbaatar /100)
  //           },
  //           {
  //               x: 'Архангай',
  //               y:  Math.floor(totalPositive * pArkhangai /100)
  //           },
  //           {
  //               x: 'Баян-Өлгий',
  //               y: Math.floor(totalPositive * pBayan_Ulgii /100)
  //           },
  //           {
  //               x: 'Баянхонгор',
  //               y: Math.floor(totalPositive * pBayankhongor /100)
  //           },
  //           {
  //               x: 'Булган',
  //               y: Math.floor(totalPositive * pBulgan /100)
  //           },
  //           {
  //               x: 'Говь-Алтай',
  //               y: Math.floor(totalPositive * pGovi_Altai /100)
  //           },
  //           {
  //               x: 'Говьсүмбэр',
  //               y: Math.floor(totalPositive * pGovisumber /100)
  //           },
  //           {
  //               x: 'Дархан-Уул',
  //               y: Math.floor(totalPositive * pDarkhan_Uul /100)
  //           },
  //           {
  //               x: 'Дорноговь',
  //               y: Math.floor(totalPositive * pDornogovi /100)
  //           },
  //           {
  //               x: 'Дорнод',
  //               y: Math.floor(totalPositive * pDornod /100)
  //           },
  //           {
  //               x: 'Дундговь',
  //               y: Math.floor(totalPositive * pDundgovi /100)
  //           },
  //           {
  //               x: 'Завхан',
  //               y: Math.floor(totalPositive * pZavkhan /100)
  //           },
  //           {
  //               x: 'Орхон',
  //               y: Math.floor(totalPositive * pOrkhon /100)
  //           },
  //           {
  //               x: 'Өвөрхангай',
  //               y: Math.floor(totalPositive * pUvurkhangai /100)
  //           },
  //           {
  //               x: 'Өмнөговь',
  //               y: Math.floor(totalPositive * pUmnugovi /100)
  //           },
  //           {
  //               x: 'Сүхбаатар',
  //               y: Math.floor(totalPositive * pSukhbaatar /100)
  //           },
  //           {
  //               x: 'Сэлэнгэ',
  //               y: Math.floor(totalPositive * pSelenge /100)
  //           },
  //           {
  //               x: 'Төв',
  //               y: Math.floor(totalPositive * pTuv /100)
  //           },
  //           {
  //               x: 'Увс',
  //               y: Math.floor(totalPositive * pUvs /100)
  //           },
  //           {
  //               x: 'Ховд',
  //               y: Math.floor(totalPositive * pKhovd /100)
  //           },
  //           {
  //               x: 'Хөвсгөл',
  //               y: Math.floor(totalPositive * pKhuvsgul /100)
  //           },
  //           {
  //               x: 'Хэнтий',
  //               y: Math.floor(totalPositive * pKhentii /100)
  //           },
  //           {
  //               x: 'Гадаад улс',
  //               y: Math.floor(totalPositive * pKhentii /100)
  //           }         
  //           ]
  //     }]

  //     setTotalPositiveLocation(totalPLocation);

  //     let totalPositiveLocationData = [
  //             Math.floor(totalPositive * pUlaanbaatar /100), 
  //             Math.floor(totalPositive * pArkhangai /100), 
  //             Math.floor(totalPositive * pBayan_Ulgii /100), 
  //             Math.floor(totalPositive * pBayankhongor /100), 
  //             Math.floor(totalPositive * pBulgan /100), 
  //             Math.floor(totalPositive * pGovi_Altai /100), 
  //             Math.floor(totalPositive * pGovisumber /100), 
  //             Math.floor(totalPositive * pDarkhan_Uul /100), 
  //             Math.floor(totalPositive * pDornogovi /100), 
  //             Math.floor(totalPositive * pDornod /100), 
  //             Math.floor(totalPositive * pDundgovi /100), 
  //             Math.floor(totalPositive * pZavkhan /100), 
  //             Math.floor(totalPositive * pOrkhon /100), 
  //             Math.floor(totalPositive * pUvurkhangai /100), 
  //             Math.floor(totalPositive * pUmnugovi /100), 
  //             Math.floor(totalPositive * pSukhbaatar /100), 
  //             Math.floor(totalPositive * pSelenge /100), 
  //             Math.floor(totalPositive * pTuv /100), 
  //             Math.floor(totalPositive * pUvs /100), 
  //             Math.floor(totalPositive * pKhovd /100), 
  //             Math.floor(totalPositive * pKhuvsgul /100), 
  //             Math.floor(totalPositive * pKhentii /100),
  //             Math.floor(totalPositive * pGadaad /100)
  //     ];

  //     setTotalPositiveLocationData(totalPositiveLocationData);

  //     // calculating positive by location
  //     let nUlaanbaatar = location.state.campaignDataAnalyze.negative.Total_location.Ulaanbaatar;
  //     let nArkhangai = location.state.campaignDataAnalyze.negative.Total_location.Arkhangai;
  //     let nBayan_Ulgii = location.state.campaignDataAnalyze.negative.Total_location.Bayan_Ulgii;
  //     let nBayankhongor = location.state.campaignDataAnalyze.negative.Total_location.Bayankhongor;
  //     let nBulgan = location.state.campaignDataAnalyze.negative.Total_location.Bulgan;
  //     let nGovi_Altai = location.state.campaignDataAnalyze.negative.Total_location.Govi_Altai;
  //     let nGovisumber = location.state.campaignDataAnalyze.negative.Total_location.Govisumber;
  //     let nDarkhan_Uul = location.state.campaignDataAnalyze.negative.Total_location.Darkhan_Uul;
  //     let nDornogovi = location.state.campaignDataAnalyze.negative.Total_location.Dornogovi;
  //     let nDornod = location.state.campaignDataAnalyze.negative.Total_location.Dornod;
  //     let nDundgovi = location.state.campaignDataAnalyze.negative.Total_location.Dundgovi;
  //     let nZavkhan = location.state.campaignDataAnalyze.negative.Total_location.Zavkhan;
  //     let nOrkhon = location.state.campaignDataAnalyze.negative.Total_location.Orkhon;
  //     let nUvurkhangai = location.state.campaignDataAnalyze.negative.Total_location.Uvurkhangai;
  //     let nUmnugovi = location.state.campaignDataAnalyze.negative.Total_location.Umnugovi;
  //     let nSukhbaatar = location.state.campaignDataAnalyze.negative.Total_location.Sukhbaatar;
  //     let nSelenge = location.state.campaignDataAnalyze.negative.Total_location.Selenge;
  //     let nTuv = location.state.campaignDataAnalyze.negative.Total_location.Tuv;
  //     let nUvs = location.state.campaignDataAnalyze.negative.Total_location.Uvs;
  //     let nKhovd = location.state.campaignDataAnalyze.negative.Total_location.Khovd;
  //     let nKhuvsgul = location.state.campaignDataAnalyze.negative.Total_location.Khuvsgul;
  //     let nKhentii = location.state.campaignDataAnalyze.negative.Total_location.Khentii;
  //     let nGadaad = location.state.campaignDataAnalyze.negative.Total_location.Gadaad;

  //     const totalNLocation = [{
  //           data: [{
  //               x: 'Улаанбаатар',
  //               y:  Math.floor(totalNegative * nUlaanbaatar /100)
  //           },
  //           {
  //               x: 'Архангай',
  //               y:  Math.floor(totalNegative * nArkhangai /100)
  //           },
  //           {
  //               x: 'Баян-Өлгий',
  //               y: Math.floor(totalNegative * nBayan_Ulgii /100)
  //           },
  //           {
  //               x: 'Баянхонгор',
  //               y: Math.floor(totalNegative * nBayankhongor /100)
  //           },
  //           {
  //               x: 'Булган',
  //               y: Math.floor(totalNegative * nBulgan /100)
  //           },
  //           {
  //               x: 'Говь-Алтай',
  //               y: Math.floor(totalNegative * nGovi_Altai /100)
  //           },
  //           {
  //               x: 'Говьсүмбэр',
  //               y: Math.floor(totalNegative * nGovisumber /100)
  //           },
  //           {
  //               x: 'Дархан-Уул',
  //               y: Math.floor(totalNegative * nDarkhan_Uul /100)
  //           },
  //           {
  //               x: 'Дорноговь',
  //               y: Math.floor(totalNegative * nDornogovi /100)
  //           },
  //           {
  //               x: 'Дорнод',
  //               y: Math.floor(totalNegative * nDornod /100)
  //           },
  //           {
  //               x: 'Дундговь',
  //               y: Math.floor(totalNegative * nDundgovi /100)
  //           },
  //           {
  //               x: 'Завхан',
  //               y: Math.floor(totalNegative * nZavkhan /100)
  //           },
  //           {
  //               x: 'Орхон',
  //               y: Math.floor(totalNegative * nOrkhon /100)
  //           },
  //           {
  //               x: 'Өвөрхангай',
  //               y: Math.floor(totalNegative * nUvurkhangai /100)
  //           },
  //           {
  //               x: 'Өмнөговь',
  //               y: Math.floor(totalNegative * nUmnugovi /100)
  //           },
  //           {
  //               x: 'Сүхбаатар',
  //               y: Math.floor(totalNegative * nSukhbaatar /100)
  //           },
  //           {
  //               x: 'Сэлэнгэ',
  //               y: Math.floor(totalNegative * nSelenge /100)
  //           },
  //           {
  //               x: 'Төв',
  //               y: Math.floor(totalNegative * nTuv /100)
  //           },
  //           {
  //               x: 'Увс',
  //               y: Math.floor(totalNegative * nUvs /100)
  //           },
  //           {
  //               x: 'Ховд',
  //               y: Math.floor(totalNegative * nKhovd /100)
  //           },
  //           {
  //               x: 'Хөвсгөл',
  //               y: Math.floor(totalNegative * nKhuvsgul /100)
  //           },
  //           {
  //               x: 'Хэнтий',
  //               y: Math.floor(totalNegative * nKhentii /100)
  //           },
  //           {
  //               x: 'Гадаад улс',
  //               y: Math.floor(totalNegative * nKhentii /100)
  //           }           
  //           ]
  //     }]

  //     setTotalNegativeLocation(totalNLocation);

  //     let totalNegativeLocationData = [
  //             Math.floor(totalNegative * pUlaanbaatar /100), 
  //             Math.floor(totalNegative * pArkhangai /100), 
  //             Math.floor(totalNegative * pBayan_Ulgii /100), 
  //             Math.floor(totalNegative * pBayankhongor /100), 
  //             Math.floor(totalNegative * pBulgan /100), 
  //             Math.floor(totalNegative * pGovi_Altai /100), 
  //             Math.floor(totalNegative * pGovisumber /100), 
  //             Math.floor(totalNegative * pDarkhan_Uul /100), 
  //             Math.floor(totalNegative * pDornogovi /100), 
  //             Math.floor(totalNegative * pDornod /100), 
  //             Math.floor(totalNegative * pDundgovi /100), 
  //             Math.floor(totalNegative * pZavkhan /100), 
  //             Math.floor(totalNegative * pOrkhon /100), 
  //             Math.floor(totalNegative * pUvurkhangai /100), 
  //             Math.floor(totalNegative * pUmnugovi /100), 
  //             Math.floor(totalNegative * pSukhbaatar /100), 
  //             Math.floor(totalNegative * pSelenge /100), 
  //             Math.floor(totalNegative * pTuv /100), 
  //             Math.floor(totalNegative * pUvs /100), 
  //             Math.floor(totalNegative * pKhovd /100), 
  //             Math.floor(totalNegative * pKhuvsgul /100), 
  //             Math.floor(totalNegative * pKhentii /100),
  //             Math.floor(totalNegative * pGadaad /100)
  //     ];

  //     setTotalNegativeLocationData(totalNegativeLocationData);

  //     // calculating positive by UB location
  //     let totalPositiveUB = Math.floor(totalPositive * pUlaanbaatar /100)
  //     let totalNegativeUB = Math.floor(totalNegative * nUlaanbaatar /100)

  //     let pCHD = location.state.campaignDataAnalyze.positive.UB_location.CHD;
  //     let pKHUD = location.state.campaignDataAnalyze.positive.UB_location.KHUD;
  //     let pSBD = location.state.campaignDataAnalyze.positive.UB_location.SBD;
  //     let pBGD = location.state.campaignDataAnalyze.positive.UB_location.BGD;
  //     let pSKHD = location.state.campaignDataAnalyze.positive.UB_location.SKHD;
  //     let pBZD = location.state.campaignDataAnalyze.positive.UB_location.BZD;
  //     let pNalaikh = location.state.campaignDataAnalyze.positive.UB_location.Nalaikh;
  //     let pBaganuur = location.state.campaignDataAnalyze.positive.UB_location.Baganuur;
  //     let pBagakhangai = location.state.campaignDataAnalyze.positive.UB_location.Bagakhangai;

  //     let cCHD = Math.floor(totalPositiveUB * pCHD /100);
  //     let cKHUD = Math.floor(totalPositiveUB * pKHUD /100);
  //     let cSBD = Math.floor(totalPositiveUB * pSBD /100);
  //     let cBGD = Math.floor(totalPositiveUB * pBGD /100);
  //     let cSKHD = Math.floor(totalPositiveUB * pSKHD /100);
  //     let cBZD = Math.floor(totalPositiveUB * pBZD /100);
  //     let cNalaikh = Math.floor(totalPositiveUB * pNalaikh /100);
  //     let cBaganuur = Math.floor(totalPositiveUB * pBaganuur /100);
  //     let cBagakhangai = Math.floor(totalPositiveUB * pBagakhangai /100);

  //     const totalPUBLocation = [{
  //           data: [{
  //               x: 'Чингэлтэй',
  //               y:  cCHD
  //           },
  //           {
  //               x: 'Хан-Уул',
  //               y:  cKHUD
  //           },
  //           {
  //               x: 'Сүхбаатар',
  //               y: cSBD
  //           },
  //           {
  //               x: 'Баянгол',
  //               y: cBGD
  //           },
  //           {
  //               x: 'Сонгинохайрхан',
  //               y: cSKHD
  //           },
  //           {
  //               x: 'Баянзүрх',
  //               y: cBZD
  //           },
  //           {
  //               x: 'Налайх',
  //               y: cNalaikh
  //           },
  //           {
  //               x: 'Багануур',
  //               y: cBaganuur
  //           },
  //           {
  //               x: 'Багахангай',
  //               y: cBagakhangai
  //           }   
  //           ]
  //     }]

  //     setTotalPositiveUBLocation(totalPUBLocation);

  //     let totalPositiveUBLocationData = [cCHD, cKHUD, cSBD, cBGD, cSKHD, cBZD, cNalaikh, cBaganuur, cBagakhangai];

  //     setTotalPositiveUBLocationData(totalPositiveUBLocationData);

  //     // calculating negative by UB location
  //     let nCHD = location.state.campaignDataAnalyze.negative.UB_location.CHD;
  //     let nKHUD = location.state.campaignDataAnalyze.negative.UB_location.KHUD;
  //     let nSBD = location.state.campaignDataAnalyze.negative.UB_location.SBD;
  //     let nBGD = location.state.campaignDataAnalyze.negative.UB_location.BGD;
  //     let nSKHD = location.state.campaignDataAnalyze.negative.UB_location.SKHD;
  //     let nBZD = location.state.campaignDataAnalyze.negative.UB_location.BZD;
  //     let nNalaikh = location.state.campaignDataAnalyze.negative.UB_location.Nalaikh;
  //     let nBaganuur = location.state.campaignDataAnalyze.negative.UB_location.Baganuur;
  //     let nBagakhangai = location.state.campaignDataAnalyze.negative.UB_location.Bagakhangai;

  //     let dCHD = Math.floor(totalNegativeUB * nCHD /100);
  //     let dKHUD = Math.floor(totalNegativeUB * nKHUD /100);
  //     let dSBD = Math.floor(totalNegativeUB * nSBD /100);
  //     let dBGD = Math.floor(totalNegativeUB * nBGD /100);
  //     let dSKHD = Math.floor(totalNegativeUB * nSKHD /100);
  //     let dBZD = Math.floor(totalNegativeUB * nBZD /100);
  //     let dNalaikh = Math.floor(totalNegativeUB * nNalaikh /100);
  //     let dBaganuur = Math.floor(totalNegativeUB * nBaganuur /100);
  //     let dBagakhangai = Math.floor(totalNegativeUB * nBagakhangai /100);

  //     const totalNUBLocation = [{
  //           data: [{
  //               x: 'Чингэлтэй',
  //               y:  dCHD
  //           },
  //           {
  //               x: 'Хан-Уул',
  //               y:  dKHUD
  //           },
  //           {
  //               x: 'Сүхбаатар',
  //               y: dSBD
  //           },
  //           {
  //               x: 'Баянгол',
  //               y: dBGD
  //           },
  //           {
  //               x: 'Сонгинохайрхан',
  //               y: dSKHD
  //           },
  //           {
  //               x: 'Баянзүрх',
  //               y: dBZD
  //           },
  //           {
  //               x: 'Налайх',
  //               y: dNalaikh
  //           },
  //           {
  //               x: 'Багануур',
  //               y: dBaganuur
  //           },
  //           {
  //               x: 'Багахангай',
  //               y: dBagakhangai
  //           }   
  //           ]
  //     }]

  //     setTotalNegativeUBLocation(totalNUBLocation);

  //     let totalNegativeUBLocationData = [dCHD, dKHUD, dSBD, dBGD, dSKHD, dBZD, dNalaikh, dBaganuur, dBagakhangai];

  //     setTotalNegativeUBLocationData(totalNegativeUBLocationData);

  //     // calculating real or fake
  //     let positiveReal = location.state.campaignDataAnalyze.positive.real_fake.real;
  //     let negativeReal = location.state.campaignDataAnalyze.negative.real_fake.real;

  //     let positiveFake = location.state.campaignDataAnalyze.positive.real_fake.fake;
  //     let negativeFake = location.state.campaignDataAnalyze.negative.real_fake.fake;

  //     let positiveUnknown = location.state.campaignDataAnalyze.positive.real_fake.unknown;
  //     let negativeUnknown = location.state.campaignDataAnalyze.negative.real_fake.unknown;

  //     // calculating positive real or fake
  //     let cpositiveReal = Math.floor(totalPositive * positiveReal /100);
  //     let cpositiveFake = Math.floor(totalPositive * positiveFake /100);
  //     let cpositiveUnknown = Math.floor(totalPositive * positiveUnknown /100);

  //     let totalPRealFakeData = [cpositiveReal, cpositiveFake, cpositiveUnknown];

  //     setTotalPositiveRealFakeData(totalPRealFakeData);

  //      // calculating negative real or fake
  //      let cnegativeReal = Math.floor(totalNegative * negativeReal /100);
  //      let cnegativeFake = Math.floor(totalNegative * negativeFake /100);
  //      let cnegativeUnknown = Math.floor(totalNegative * negativeUnknown /100);
 
  //      let totalNRealFakeData = [cnegativeReal, cnegativeFake, cnegativeUnknown];
 
  //      setTotalNegativeRealFakeData(totalNRealFakeData);
    
  //   } catch (error) {
    
  //     console.error('An error occurred:', error);
    
  //   }
  // }, []);

  const [rightColumn, setRightColumn] = useState(true);
  const toggleRightColumn = () => {
    setRightColumn(!rightColumn);
  };

  const handleValidDate = date => {
    const date1 = moment(new Date(date)).format("Y-MM-DD");
    return date1;
  };

  const handleValidTime = (time) => {
    const time1 = new Date(time);
    const getHour = time1.getUTCHours();
    const getMin = time1.getUTCMinutes();
    const getTime = `${getHour}:${getMin}`;
    const updateTime = moment.utc(getTime, 'HH:mm').format('HH:mm');
    return updateTime;
  };

  const [seletedMonth, setSeletedMonth] = useState("Нийт");
   
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
            <Row>
                    <Col lg={12}>
                        <Card className="mt-n4 mx-n4">
                            <div className="bg-soft-secondary">
                                <CardBody className="pb-0 px-4">
                                    <Row className="mb-3">
                                        <div className="col-md">
                                            <Row className="align-items-center g-3">
                                                <div className="col-md-auto">
                                                    <div className="avatar-lg">
                                                        <img src={location.state.campaignImage} alt="user-img"
                                                            className="img-thumbnail rounded-circle" />
                                                    </div>                                               
                                                </div>
                                                <div className="col-md">
                                                    <div>
                                                        <h4 className="fw-bold">{location.state.campaignName}</h4>
                                                        <div className="hstack gap-3 flex-wrap">
                                                            <div><i className="ri-building-line align-bottom me-1"></i> {location.state.campaignDesc}</div>
                                                            <div className="vr"></div>
                                                            <div>
                                                                <i
                                                                className="ri-timer-line me-1 text-black-75 fs-16 align-middle"></i>
                                                                {handleValidDate(location.state.campaignValidFrom)},{" "}
                                                                <small className="text-muted">{handleValidTime(location.state.campaignValidFrom)}</small>
                                                            </div>
                                                            <div className="vr"></div>
                                                            <div>
                                                                <i
                                                                className="ri-timer-fill me-1 text-black-75 fs-16 align-middle"></i>
                                                                {handleValidDate(location.state.campaignValidTo)},{" "}
                                                                <small className="text-muted">{handleValidTime(location.state.campaignValidTo)}</small>
                                                            </div>                                                        
                                                        </div>
                                                    </div>
                                                </div>
                                            </Row>
                                        </div>

                                        <div className="col-md-auto">
                                            <div className="hstack gap-1 flex-wrap">
                                                <Link className="btn btn-info" to="/posts" state={{campaignId: location.state.campaignId, 
                                                        campaignName: location.state.campaignName,
                                                        campaignDesc: location.state.campaignDesc,
                                                        campaignImage: location.state.campaignImage,
                                                        campaignStatus: location.state.campaignStatus,
                                                        campaignValidFrom: location.state.campaignValidFrom,
                                                        campaignValidTo: location.state.campaignValidTo,
                                                        campaignDataAnalyze: location.state.campaignDataAnalyze
                                                    }}> 
                                                    <i className="ri-newspaper-line align-bottom me-1"></i>
                                                    Нийтлэл
                                                </Link> 
                                                <Link className="btn btn-warning" to="/report" state={{campaignId: location.state.campaignId, 
                                                        campaignName: location.state.campaignName,
                                                        campaignDesc: location.state.campaignDesc,
                                                        campaignImage: location.state.campaignImage,
                                                        campaignStatus: location.state.campaignStatus,
                                                        campaignValidFrom: location.state.campaignValidFrom,
                                                        campaignValidTo: location.state.campaignValidTo,
                                                        campaignDataAnalyze: location.state.campaignDataAnalyze
                                                    }}> 
                                                    <i className="ri-printer-line align-bottom me-1"></i>
                                                    Тайлан
                                                </Link>     
                                                <button type="button" className="btn py-0 fs-16 favourite-btn active shadow-none">
                                                    <i className="ri-star-fill"></i>
                                                </button>
                                                <button type="button" className="btn py-0 fs-16 text-body shadow-none">
                                                    <i className="ri-share-line"></i>
                                                </button>
                                                <button type="button" className="btn py-0 fs-16 text-body shadow-none">
                                                    <i className="ri-flag-line"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </Row>

                                    <Nav className="nav-tabs-custom border-bottom-0" role="tablist">
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: activeTab === '1' }, "fw-semibold")}
                                                onClick={() => { toggleTab('1'); }}
                                                href="#">
                                                <i className="ri-pie-chart-2-fill align-middle me-1"></i> Статистик тоон үзүүлэлт
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: activeTab === '2' }, "fw-semibold")}
                                                onClick={() => { toggleTab('2'); }}
                                                href="#">
                                                <i className="ri-line-chart-fill align-middle me-1"></i> Хамгийн их хандалттай нийтлэл
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: activeTab === '3' }, "fw-semibold")}
                                                onClick={() => { toggleTab('3'); }}
                                                href="#">
                                                <i className="ri-newspaper-fill align-middle me-1"></i> Хамгийн сүүлд нэмэгдсэн нийтлэл
                                            </NavLink>
                                        </NavItem>
                                        {/* <NavItem>
                                            <NavLink
                                                className={classnames({ active: activeTab === '4' }, "fw-semibold")}
                                                onClick={() => { toggleTab('4'); }}
                                                href="#">
                                                Team
                                            </NavLink>
                                        </NavItem> */}
                                    </Nav>
                                </CardBody>
                            </div>
                        </Card>
                    </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <TabContent activeTab={activeTab} className="text-muted">
                    <TabPane tabId="1">
                    <Col>
                            <div className="h-100">                  
                                    <Row>
                                        <Col xl={12}>
                                            <Row>
                                                <Col xxl={12}>
                                                        <Card>
                                                            <CardBody className="p-0">
                                                                <Row className="g-0">
                                                                <Col xxl={8}>
                                                                        <div className="">
                                                                            <CardHeader className="border-0 align-items-center d-flex">
                                                                                <h4 className="card-title mb-0 flex-grow-1">Олон нийтийн хандлага</h4>
                                                                                <div className="d-flex gap-1">
                                                                                <button type="button" className="btn btn-soft-secondary btn-sm" onClick={() => { onChangeChartPeriod("7days"); }}>
                                                                                    7 хоног
                                                                                </button>
                                                                                <button type="button" className="btn btn-soft-secondary btn-sm" onClick={() => { onChangeChartPeriod("1month"); }}>
                                                                                    1 сар
                                                                                </button>
                                                                                <button type="button" className="btn btn-soft-secondary btn-sm" onClick={() => { onChangeChartPeriod("6month"); }}>
                                                                                    6 сар
                                                                                </button>
                                                                                <button type="button" className="btn btn-soft-primary btn-sm" onClick={() => { onChangeChartPeriod("1year"); }}>
                                                                                    1 жил
                                                                                </button>
                                                                                <button type="button" className="btn btn-soft-primary btn-sm" onClick={() => { onChangeChartPeriod("2year"); }}>
                                                                                    2 жил
                                                                                </button>
                                                                                <button type="button" className="btn btn-soft-primary btn-sm" onClick={() => { onChangeChartPeriod("2023"); }}>
                                                                                    2023
                                                                                </button>
                                                                                <button type="button" className="btn btn-soft-primary btn-sm" onClick={() => { onChangeChartPeriod("2022"); }}>
                                                                                    2022
                                                                                </button>
                                                                                <button type="button" className="btn btn-soft-primary btn-sm" onClick={() => { onChangeChartPeriod("2021"); }}>
                                                                                    2021
                                                                                </button>
                                                                                {/* <button type="button" className="btn btn-soft-primary btn-sm" onClick={() => { onChangeChartPeriod("5year"); }}>
                                                                                    5 жил
                                                                                </button> */}
                                                                                </div>
                                                                            </CardHeader>
                                                                            <Row className="g-0 text-center">
                                                                                <Col sm={3} className="col-6">
                                                                                    <div className="p-3 border border-dashed border-start-0">
                                                                                        <h5 className="mb-1">
                                                                                            <span className="counter-value" data-target="36.48">
                                                                                                <CountUp start={0} end={totalPostCount} decimals={0} separator="," duration={10} />
                                                                                            </span>
                                                                                        </h5>
                                                                                        <p className="text-muted mb-0">Нийтлэл</p>
                                                                                    </div>
                                                                                </Col>
                                                                                <Col sm={3} className="col-6">
                                                                                    <div className="p-3 border border-dashed border-start-0">
                                                                                        <h5 className="mb-1">
                                                                                            <span className="counter-value" data-target="92.54">
                                                                                                <CountUp start={0} end={totalCommentsCount} decimals={0} separator="," duration={10} />
                                                                                            </span>
                                                                                        </h5>
                                                                                        <p className="text-muted mb-0">Сэтгэгдэл</p>
                                                                                    </div>
                                                                                </Col>
                                                                                <Col sm={3} className="col-6">
                                                                                    <div className="p-3 border border-dashed border-end-0">
                                                                                        <h5 className="mb-1">
                                                                                            <span className="counter-value" data-target="8.62">
                                                                                                <CountUp start={0} end={totalLikesCount} decimals={0} separator="," duration={10} />
                                                                                            </span>
                                                                                        </h5>
                                                                                        <p className="text-muted mb-0">Reactions</p>
                                                                                    </div>
                                                                                </Col>
                                                                                <Col sm={3} className="col-6">
                                                                                    <div className="p-3 border border-dashed border-end-0">
                                                                                        <h5 className="mb-1">
                                                                                            <span className="counter-value" data-target="8.62">
                                                                                                <CountUp start={0} end={totalSharesCount} decimals={0} separator="," duration={10} />
                                                                                            </span>
                                                                                        </h5>
                                                                                        <p className="text-muted mb-0">Shares</p>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                            <EngagementsCharts posts={totalPosts} likes={totalLikes} comments={totalComments} shares={totalShares} categories={postsID} dataColors='["--vz-primary", "--vz-info", "--vz-warning", "--vz-danger"]' />
                                                                        </div>
                                                                    </Col>                                
                                                                    <Col xxl={2}>
                                                                        <div className="border-start p-4 h-100 d-flex flex-column">
                                                                            <div className="w-100">
                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="avatar-sm flex-shrink-0">
                                                                                        <span className="avatar-title bg-primary rounded-circle fs-2">
                                                                                            <FeatherIcon
                                                                                                icon="layers"
                                                                                            />
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className="ms-3 flex-grow-1">
                                                                                        <h5 className="fs-16 mb-1">Эх сурвалж</h5>
                                                                                        <p className="text-muted mb-0">{timePeriod} хугацаан дахь</p>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="d-flex align-items-end justify-content-between mt-4">
                                                                                    <div>
                                                                                        <p className="fs-14 text-muted mb-1">Facebook</p>
                                                                                        <h4 className="fs-20 ff-secondary fw-semibold mb-0">{totalFBPostCount || 0}</h4>
                                                                                    </div>

                                                                                    <div>
                                                                                        <p className="fs-14 text-muted mb-1">X/Twitter</p>
                                                                                        <h4 className="fs-20 ff-secondary fw-semibold mb-0">{totalTWPostCount || 0}</h4>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="d-flex align-items-end justify-content-between mt-4">                                                
                                                                                    <div>
                                                                                        <p className="fs-14 text-muted mb-1">Мэдээний сайт</p>
                                                                                        <h4 className="fs-20 ff-secondary fw-semibold mb-0">{totalNWPostCount || 0}</h4>
                                                                                    </div>

                                                                                    <div>
                                                                                        <p className="fs-14 text-muted mb-1">Instagram</p>
                                                                                        <h4 className="fs-20 ff-secondary fw-semibold mb-0">{totalIGPostCount || 0}</h4>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="card-body">
                                                                                    <div dir="ltr">
                                                                                        <TotalSourceCharts fbCount={totalFBPostCount} twCount={totalTWPostCount} nwCount={totalNWPostCount} igCount={totalIGPostCount} dataColors='["--vz-primary", "--vz-info", "--vz-success", "--vz-danger"]' />
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                    <Col xxl={2}>
                                                                        <div className="border-start p-4 h-100 d-flex flex-column">

                                                                            <div className="w-100">
                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="avatar-sm flex-shrink-0">
                                                                                            <span className="avatar-title bg-primary rounded-circle fs-2">
                                                                                                <FeatherIcon
                                                                                                    icon="users"
                                                                                                />
                                                                                            </span>
                                                                                    </div>
                                                                                    <div className="ms-3 flex-grow-1">
                                                                                        <h5 className="fs-16 mb-1">Олон нийтийн хандлага</h5>
                                                                                        <p className="text-muted mb-0">{timePeriod} хугацаан дахь</p>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="d-flex align-items-end justify-content-between mt-4">
                                                                                    <div>
                                                                                        <p className="fs-14 text-muted mb-1">Эерэг хандлага</p>
                                                                                        <h4 className="fs-20 ff-secondary fw-semibold mb-0">{totalPositiveLikesCount || 0}</h4>
                                                                                    </div>

                                                                                    <div>
                                                                                        <p className="fs-14 text-muted mb-1">Сөрөг хандлага</p>
                                                                                        <h4 className="fs-20 ff-secondary fw-semibold mb-0">{totalNegativeLikesCount || 0}</h4>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="card-body">
                                                                                    <div dir="ltr">
                                                                                        <EngagementsPNCharts positiveLikesCount={totalPositiveLikesCount} negativeLikesCount={totalNegativeLikesCount} dataColors='["--vz-success", "--vz-danger"]' />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </CardBody>
                                                        </Card>
                                                </Col>
                                        </Row>
                                        
                                        <Row>
                                                <Col xxl={12}>
                                                        <Card>
                                                            <CardBody className="p-0">
                                                                <Row className="g-0">                                                                                         
                                                                    <Col xxl={3}>
                                                                        <div className="border-start p-4 h-100 d-flex flex-column">

                                                                            <div className="w-100">
                                                                                <div className="d-flex align-items-center">
                                                                                    <img src="https://firebasestorage.googleapis.com/v0/b/mindx-384a6.appspot.com/o/logos%2Ffacebook.webp?alt=media&token=edb9fe7a-90e8-460f-b118-9b46d5edaa41" className="img-fluid avatar-xs rounded-circle object-cover" alt="" />
                                                                                    <div className="ms-3 flex-grow-1">
                                                                                        <h5 className="fs-16 mb-1">Facebook</h5>
                                                                                        <p className="text-muted mb-0">{timePeriod} хугацаан дахь</p>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="d-flex align-items-end justify-content-between mt-4">
                                                                                    <div>
                                                                                        <p className="fs-14 text-muted mb-1">Эерэг хандлага</p>
                                                                                        <h4 className="fs-20 ff-secondary fw-semibold mb-0">{totalFBPositiveLikesCount || 0}</h4>
                                                                                    </div>

                                                                                    <div>
                                                                                        <p className="fs-14 text-muted mb-1">Сөрөг хандлага</p>
                                                                                        <h4 className="fs-20 ff-secondary fw-semibold mb-0">{totalFBNegativeLikesCount || 0}</h4>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="card-body">
                                                                                    <div dir="ltr">
                                                                                        <EngagementsPNCharts positiveLikesCount={totalFBPositiveLikesCount} negativeLikesCount={totalFBNegativeLikesCount} dataColors='["--vz-success", "--vz-danger"]' />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                    <Col xxl={3}>
                                                                        <div className="border-start p-4 h-100 d-flex flex-column">

                                                                            <div className="w-100">
                                                                                <div className="d-flex align-items-center">
                                                                                    <img src="https://firebasestorage.googleapis.com/v0/b/mindx-384a6.appspot.com/o/logos%2Ftwitter-x-logo-101C7D2420-seeklogo.com.png?alt=media&token=2a4d65d1-b5a6-4da2-896a-d9ba41104298" className="img-fluid avatar-xs rounded-circle object-cover" alt="" />
                                                                                    <div className="ms-3 flex-grow-1">
                                                                                        <h5 className="fs-16 mb-1">X/Twitter</h5>
                                                                                        <p className="text-muted mb-0">{timePeriod} хугацаан дахь</p>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="d-flex align-items-end justify-content-between mt-4">
                                                                                    <div>
                                                                                        <p className="fs-14 text-muted mb-1">Эерэг хандлага</p>
                                                                                        <h4 className="fs-20 ff-secondary fw-semibold mb-0">{totalTWPositiveLikesCount || 0}</h4>
                                                                                    </div>

                                                                                    <div>
                                                                                        <p className="fs-14 text-muted mb-1">Сөрөг хандлага</p>
                                                                                        <h4 className="fs-20 ff-secondary fw-semibold mb-0">{totalTWNegativeLikesCount || 0}</h4>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="card-body">
                                                                                    <div dir="ltr">
                                                                                        <EngagementsPNCharts positiveLikesCount={totalTWPositiveLikesCount} negativeLikesCount={totalTWNegativeLikesCount} dataColors='["--vz-success", "--vz-danger"]' />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                    <Col xxl={3}>
                                                                        <div className="border-start p-4 h-100 d-flex flex-column">

                                                                            <div className="w-100">
                                                                                <div className="d-flex align-items-center">
                                                                                    <img src="https://firebasestorage.googleapis.com/v0/b/mindx-384a6.appspot.com/o/logos%2Fnews-default.png?alt=media&token=1180b1c4-aae1-4fb9-9456-6c8bb882f0ce" className="img-fluid avatar-xs rounded-circle object-cover" alt="" />
                                                                                    <div className="ms-3 flex-grow-1">
                                                                                        <h5 className="fs-16 mb-1">Мэдээний сайт</h5>
                                                                                        <p className="text-muted mb-0">{timePeriod} хугацаан дахь</p>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="d-flex align-items-end justify-content-between mt-4">
                                                                                    <div>
                                                                                        <p className="fs-14 text-muted mb-1">Эерэг хандлага</p>
                                                                                        <h4 className="fs-20 ff-secondary fw-semibold mb-0">{totalNWPositiveLikesCount || 0}</h4>
                                                                                    </div>

                                                                                    <div>
                                                                                        <p className="fs-14 text-muted mb-1">Сөрөг хандлага</p>
                                                                                        <h4 className="fs-20 ff-secondary fw-semibold mb-0">{totalNWNegativeLikesCount || 0}</h4>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="card-body">
                                                                                    <div dir="ltr">
                                                                                        <EngagementsPNCharts positiveLikesCount={totalNWPositiveLikesCount} negativeLikesCount={totalNWNegativeLikesCount} dataColors='["--vz-success", "--vz-danger"]' />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                    <Col xxl={3}>
                                                                        <div className="border-start p-4 h-100 d-flex flex-column">

                                                                            <div className="w-100">
                                                                                <div className="d-flex align-items-center">
                                                                                    <img src="https://firebasestorage.googleapis.com/v0/b/mindx-384a6.appspot.com/o/logos%2Finstagram.png?alt=media&token=d330b8a2-581b-41f5-bea0-fec35b72c83e" className="img-fluid avatar-xs rounded-circle object-cover" alt="" />
                                                                                    <div className="ms-3 flex-grow-1">
                                                                                        <h5 className="fs-16 mb-1">Instagram</h5>
                                                                                        <p className="text-muted mb-0">{timePeriod} хугацаан дахь</p>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="d-flex align-items-end justify-content-between mt-4">
                                                                                    <div>
                                                                                        <p className="fs-14 text-muted mb-1">Эерэг хандлага</p>
                                                                                        <h4 className="fs-20 ff-secondary fw-semibold mb-0">{totalIGPositiveLikesCount || 0}</h4>
                                                                                    </div>

                                                                                    <div>
                                                                                        <p className="fs-14 text-muted mb-1">Сөрөг хандлага</p>
                                                                                        <h4 className="fs-20 ff-secondary fw-semibold mb-0">{totalIGNegativeLikesCount || 0}</h4>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="card-body">
                                                                                    <div dir="ltr">
                                                                                        <EngagementsPNCharts positiveLikesCount={totalIGPositiveLikesCount} negativeLikesCount={totalIGNegativeLikesCount} dataColors='["--vz-success", "--vz-danger"]' />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </CardBody>
                                                        </Card>
                                                </Col>
                                        </Row>
                                        </Col>
                                        {   JSON.parse(localStorage.getItem("authUser")).type === "super$" &&
                                            <React.Fragment>
                                                <Col xl={6}>
                                                                <Card>
                                                                    <CardHeader className="align-items-center d-flex">
                                                                        <h4 className="card-title mb-0 flex-grow-1">Байршлын <Badge color="success"> эерэг </Badge> хандлага /хот, аймаг/</h4>                                        
                                                                    </CardHeader>

                                                                    <CardBody>
                                                                        <DiffTotalPositiveLocation dataColors='["--vz-primary", "--vz-secondary", "--vz-success", "--vz-info","--vz-warning", "--vz-danger"]' totalPositiveLocation={totalPositiveLocation}/>
                                                                    </CardBody>
                                                                </Card>
                                                </Col>  
                                                <Col xl={6}>
                                                                <Card>
                                                                    <CardHeader className="align-items-center d-flex">
                                                                        <h4 className="card-title mb-0 flex-grow-1">Байршлын <Badge color="success"> эерэг </Badge> хандлага /хот, аймаг/</h4>                                        
                                                                    </CardHeader>

                                                                    <CardBody>
                                                                        <DataTotalPositiveLocationBasic dataColors='["--vz-success"]' totalPositiveLocationData={totalPositiveLocationData}/>
                                                                    </CardBody>
                                                                </Card>
                                                </Col>
                                                <Col xl={6}>
                                                                <Card>
                                                                    <CardHeader className="align-items-center d-flex">
                                                                        <h4 className="card-title mb-0 flex-grow-1">Байршлын <Badge color="danger"> сөрөг </Badge> хандлага /хот, аймаг/</h4>                                        
                                                                    </CardHeader>

                                                                    <CardBody>
                                                                        <DiffTotalNegativeLocation dataColors='["--vz-warning", "--vz-danger"]' totalNegativeLocation={totalNegativeLocation}/>
                                                                    </CardBody>
                                                                </Card>
                                                </Col>  
                                                <Col xl={6}>
                                                                <Card>
                                                                    <CardHeader className="align-items-center d-flex">
                                                                        <h4 className="card-title mb-0 flex-grow-1">Байршлын <Badge color="danger"> сөрөг </Badge> хандлага /хот, аймаг/</h4>                                        
                                                                    </CardHeader>

                                                                    <CardBody>
                                                                        <DataTotalNegativeLocationBasic dataColors='["--vz-danger"]' totalNegativeLocationData={totalNegativeLocationData}/>
                                                                    </CardBody>
                                                                </Card>
                                                </Col>
                                                <Col xl={6}>
                                                                <Card>
                                                                    <CardHeader className="align-items-center d-flex">
                                                                        <h4 className="card-title mb-0 flex-grow-1">Улаанбаатар хотын дүүргийн <Badge color="success"> эерэг </Badge> хандлага</h4>                                        
                                                                    </CardHeader>

                                                                    <CardBody>
                                                                        <DiffTotalPositiveLocation dataColors='["--vz-primary", "--vz-secondary", "--vz-success", "--vz-info","--vz-warning", "--vz-danger"]' totalPositiveLocation={totalPositiveUBLocation}/>
                                                                    </CardBody>
                                                                </Card>
                                                </Col>  
                                                <Col xl={6}>
                                                                <Card>
                                                                    <CardHeader className="align-items-center d-flex">
                                                                        <h4 className="card-title mb-0 flex-grow-1">Улаанбаатар хотын дүүргийн <Badge color="success"> эерэг </Badge> хандлага /хот, аймаг/</h4>                                        
                                                                    </CardHeader>

                                                                    <CardBody>
                                                                        <DataTotalUBLocationBasic dataColors='["--vz-success"]' totalUBLocationData={totalPositiveUBLocationData}/>
                                                                    </CardBody>
                                                                </Card>
                                                </Col>
                                                <Col xl={6}>
                                                                <Card>
                                                                    <CardHeader className="align-items-center d-flex">
                                                                        <h4 className="card-title mb-0 flex-grow-1">Улаанбаатар хотын дүүргийн <Badge color="danger"> сөрөг </Badge> хандлага /хот, аймаг/</h4>                                        
                                                                    </CardHeader>

                                                                    <CardBody>
                                                                        <DiffTotalNegativeLocation dataColors='["--vz-warning", "--vz-danger"]' totalNegativeLocation={totalNegativeUBLocation}/>
                                                                    </CardBody>
                                                                </Card>
                                                </Col>  
                                                <Col xl={6}>
                                                                <Card>
                                                                    <CardHeader className="align-items-center d-flex">
                                                                        <h4 className="card-title mb-0 flex-grow-1">Улаанбаатар хотын дүүргийн <Badge color="danger"> сөрөг </Badge> хандлага /хот, аймаг/</h4>                                        
                                                                    </CardHeader>

                                                                    <CardBody>
                                                                        <DataTotalUBLocationBasic dataColors='["--vz-danger"]' totalUBLocationData={totalNegativeUBLocationData}/>
                                                                    </CardBody>
                                                                </Card>
                                                </Col>
                                                <Col xl={5}>
                                                            <Card>
                                                                <CardHeader className="align-items-center d-flex">
                                                                    <h4 className="card-title mb-0 flex-grow-1">Нас, хүйсийн <Badge color="success"> эерэг </Badge> хандлага</h4>                                        
                                                                </CardHeader>
                                                                <CardBody>
                                                                    <StackedColumn dataColors='["--vz-warning", "--vz-info"]' positiveGenderM={genderPositiveM} positiveGenderFM={genderPositiveFM} />
                                                                </CardBody>
                                                            </Card>
                                                </Col> 
                                                <Col xl={5}>
                                                            <Card>
                                                                <CardHeader className="align-items-center d-flex">
                                                                    <h4 className="card-title mb-0 flex-grow-1">Нас, хүйсийн <Badge color="danger"> сөрөг </Badge> хандлага</h4>                                        
                                                                </CardHeader>
                                                                <CardBody>
                                                                    <StackedNegativeByGender dataColors='["--vz-danger", "--vz-primary"]' negativeGenderM={genderNegativeM} negativeGenderFM={genderNegativeFM} />
                                                                </CardBody>
                                                            </Card>
                                                </Col>
                                                <Col xl={2}>
                                                            <Card>
                                                                <CardHeader className="align-items-center d-flex">
                                                                    <h4 className="card-title mb-0 flex-grow-1">Хүйсийн <Badge color="success"> эерэг </Badge> <Badge color="danger"> сөрөг </Badge> харьцаа</h4>                                        
                                                                </CardHeader>
                                                                <CardBody>
                                                                    <PositiveGenderComparison dataColors='["--vz-warning", "--vz-info"]' totalMale={totalPMale} totalFemale={totalPFemale}/>
                                                                    <NegativeGenderComparison dataColors='["--vz-danger", "--vz-primary"]' totalMale={totalNMale} totalFemale={totalNFemale}/>
                                                                </CardBody>
                                                            </Card>
                                                </Col>                                   
                                                <Col xl={4}>
                                                        <Card>
                                                            <CardHeader>
                                                                <h4 className="card-title mb-0">Улаанбаатар хотын дүүргийн <Badge color="success"> эерэг </Badge> <Badge color="danger"> сөрөг </Badge> харьцаа</h4>
                                                            </CardHeader>
                                                            <CardBody>
                                                                <MultipleRadar dataColors='["--vz-success", "--vz-danger"]' positiveUB={totalPositiveUBLocationData} negativeUB={totalNegativeUBLocationData}/>
                                                            </CardBody>
                                                        </Card>
                                                </Col>                          
                                                <Col xl={4}>
                                                            <Card>
                                                                <CardHeader className="align-items-center d-flex">
                                                                    <h4 className="card-title mb-0 flex-grow-1"><Badge color="success"> Эерэг </Badge> хандлага үзүүлсэн хэрэглэгчид /хот, аймаг/</h4>                                        
                                                                </CardHeader>
                                                                <CardBody>
                                                                    <RealFakeData dataColors='["--vz-success", "--vz-danger", "--vz-warning"]' realfake={totalPositiveRealFakeData} />
                                                                </CardBody>
                                                            </Card>
                                                </Col>  
                                                <Col xl={4}>
                                                            <Card>
                                                                <CardHeader className="align-items-center d-flex">
                                                                    <h4 className="card-title mb-0 flex-grow-1"><Badge color="danger"> Сөрөг </Badge> хандлага үзүүлсэн хэрэглэгчид /хот, аймаг/</h4>                                        
                                                                </CardHeader>
                                                                <CardBody>
                                                                    <RealFakeData dataColors='["--vz-success", "--vz-danger", "--vz-warning"]' realfake={totalNegativeRealFakeData} />
                                                                </CardBody>
                                                            </Card>
                                                </Col>
                                            </React.Fragment>     
                                            } 
                                    </Row>  
                                        
                                
                            </div>
                            </Col>
                    </TabPane>
                    <TabPane tabId="2">
                            <TopPosts campaignId={location.state.campaignId} />
                    </TabPane>
                    <TabPane tabId="3">
                            <LatestPosts campaignId={location.state.campaignId} />              
                    </TabPane>
                    <TabPane tabId="4">
                        <TeamTab />
                    </TabPane>
                    </TabContent>
                </Col>
            </Row>

        </Container>
      </div>
    </React.Fragment>
  );
};

export default DashboardCampaign;
