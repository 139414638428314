import React, { useEffect, useState, useCallback, useMemo, useRef } from "react";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";
import * as moment from "moment";
import 'moment-timezone';
import Countdown from "react-countdown";
import { useLocation, Navigate } from "react-router-dom";
import storage from "../../../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import DualListBox from "react-dual-listbox";
import "react-dual-listbox/lib/react-dual-listbox.css";
import FeatherIcon from "feather-icons-react";
import classnames from 'classnames';

// Import Images
import profileBg from '../../../assets/images/profile-bg.png';
import dummyImg from "../../../assets/images/users/user-dummy-img.jpg";
import MAN from "../../../assets/images/users/MAN.png";
import AN from "../../../assets/images/users/AN.png";
import XYN from "../../../assets/images/users/XYN.png";
import UE from "../../../assets/images/users/UE.png";
import NN from "../../../assets/images/users/NN.png";
import UZ from "../../../assets/images/users/UZ.png";
import SHN from "../../../assets/images/users/SHN.png";
import MGLElection from "../../../assets/images/users/MGLELECTION.png";

import {
  Col,
  Container,
  Row,
  Card,
  CardHeader,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  ModalFooter,
  Table,
  FormFeedback,
  UncontrolledAlert,
  CardTitle,
  Badge,
  Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Progress, TabContent, TabPane, UncontrolledCollapse
} from "reactstrap";

import Select from "react-select";
import { TagsInput } from "react-tag-input-component";

import BreadCrumb from "../../../Components/Common/BreadCrumb";
import DeleteCampaign from "../../../Components/Common/DeleteCampaign";

//Import actions
import {
  getCampaigns as onGetCampaigns,
  addNewCampaign as onAddNewCampaign,
  updateCampaign as onUpdateCampaign,
  deleteCampaign as onDeleteCampaign,
  getUsers as onGetUsers,
} from "../../../slices/thunks";

//redux
import { useSelector, useDispatch } from "react-redux";
import TableContainer from "../../../Components/Common/TableContainer";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

import Loader from "../../../Components/Common/Loader";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Export Modal
import ExportCSVModal from "../../../Components/Common/ExportCSVModal";

import Flatpickr from "react-flatpickr";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";

const Campaigns = () => {
  const dispatch = useDispatch();
  const { campaigns, isCampaignSuccess, error } = useSelector((state) => ({
    campaigns: state.Campaign.campaigns,
    isCampaignSuccess: state.Campaign.isCampaignSuccess,
    error: state.Campaign.error,
  }));

  const { crmusers, isUserSuccess } = useSelector((state) => ({
      crmusers: state.Crm.crmusers,
      isUserSuccess: state.Crm.isUserSuccess,
  }));

  const componentRef = useRef();

  const [isEdit, setIsEdit] = useState(false);
  const [campaign, setCampaign] = useState([]);

  const [image, setImage] = useState(null);
  const [file, setFile] = useState(null);
  const [uploaded, setUploaded] = useState("");

  const [editId, setEditedId] = useState();
  const [contact, setContact] = useState([]);
  const [dataDual, setDataDual] = useState([]);
  const [dataTempDual, setDataTempDual] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [selected, setSelected] = useState([]);

  const [toirog1, setToirog1] = useState([]);
  const [toirog2, setToirog2] = useState([]);
  const [toirog3, setToirog3] = useState([]);
  const [toirog4, setToirog4] = useState([]);
  const [toirog5, setToirog5] = useState([]);
  const [toirog6, setToirog6] = useState([]);
  const [toirog7, setToirog7] = useState([]);
  const [toirog8, setToirog8] = useState([]);
  const [toirog9, setToirog9] = useState([]);
  const [toirog10, setToirog10] = useState([]);
  const [toirog11, setToirog11] = useState([]);
  const [toirog12, setToirog12] = useState([]);
  const [toirog13, setToirog13] = useState([]);
  const [jagsaaltMAN, setJagsaaltMAN] = useState([]);
  const [jagsaaltAN, setJagsaaltAN] = useState([]);
  const [jagsaaltXYN, setJagsaaltXYN] = useState([]);
  const [jagsaaltUE, setJagsaaltUE] = useState([]);
  const [jagsaaltNN, setJagsaaltNN] = useState([]);
  const [jagsaaltUZ, setJagsaaltUZ] = useState([]);
  const [jagsaaltSHN, setJagsaaltSHN] = useState([]);
  
  const [time, setTime] = useState(3600);

  useEffect(() => {
    if (campaigns && !campaigns.length) {
      dispatch(onGetCampaigns());
    }
  }, [dispatch, campaigns]);

  useEffect(() => {
    const section1 = campaigns.filter((user) => user.section == "1");
    const section1sorted = section1.sort((a, b) => b.points - a.points);
    const complete1 = []
    section1sorted?.map((item, index) => {     
        const rank1 = { ...item, rank : index + 1 }  
        complete1.push(rank1);
    });    
    setToirog1(complete1);

    const section2 = campaigns.filter((user) => user.section == "2");
    const section2sorted = section2.sort((a, b) => b.points - a.points);
    const complete2 = []
    section2sorted?.map((item, index) => {     
        const rank2 = { ...item, rank : index + 1 }  
        complete2.push(rank2);
    });    
    setToirog2(complete2);

    const section3 = campaigns.filter((user) => user.section == "3");
    const section3sorted = section2.sort((a, b) => b.points - a.points);
    const complete3 = []
    section3sorted?.map((item, index) => {     
        const rank3 = { ...item, rank : index + 1 }  
        complete3.push(rank3);
    });    
    setToirog3(complete3);

    const section4 = campaigns.filter((user) => user.section == "4");
    const section4sorted = section4.sort((a, b) => b.points - a.points);
    const complete4 = []
    section4sorted?.map((item, index) => {     
        const rank4 = { ...item, rank : index + 1 }  
        complete4.push(rank4);
    });    
    setToirog1(complete4);

    const section5 = campaigns.filter((user) => user.section == "5");
    const section5sorted = section5.sort((a, b) => b.points - a.points);
    const complete5 = []
    section5sorted?.map((item, index) => {     
        const rank5 = { ...item, rank : index + 1 }  
        complete5.push(rank5);
    });    
    setToirog5(complete5);

    const section6 = campaigns.filter((user) => user.section == "6");
    const section6sorted = section6.sort((a, b) => b.points - a.points);
    const complete6 = []
    section6sorted?.map((item, index) => {     
        const rank6 = { ...item, rank : index + 1 }  
        complete6.push(rank6);
    });    
    setToirog6(complete6);

    const section7 = campaigns.filter((user) => user.section == "7");
    const section7sorted = section7.sort((a, b) => b.points - a.points);
    const complete7 = []
    section7sorted?.map((item, index) => {     
        const rank7 = { ...item, rank : index + 1 }  
        complete7.push(rank7);
    });    
    setToirog7(complete7);

    const section8 = campaigns.filter((user) => user.section == "8");
    const section8sorted = section8.sort((a, b) => b.points - a.points);
    const complete8 = []
    section8sorted?.map((item, index) => {     
        const rank8 = { ...item, rank : index + 1 }  
        complete8.push(rank8);
    });    
    setToirog8(complete8);

    const section9 = campaigns.filter((user) => user.section == "9");
    const section9sorted = section9.sort((a, b) => b.points - a.points);
    const complete9 = []
    section9sorted?.map((item, index) => {     
        const rank9 = { ...item, rank : index + 1 }  
        complete9.push(rank9);
    });    
    setToirog9(complete9);

    const section10 = campaigns.filter((user) => user.section == "10");
    const section10sorted = section10.sort((a, b) => b.points - a.points);
    const complete10 = []
    section10sorted?.map((item, index) => {     
        const rank10 = { ...item, rank : index + 1 }  
        complete10.push(rank10);
    });    
    setToirog10(complete10);

    const section11 = campaigns.filter((user) => user.section == "11");
    const section11sorted = section11.sort((a, b) => b.points - a.points);
    const complete11 = []
    section11sorted?.map((item, index) => {     
        const rank11 = { ...item, rank : index + 1 }  
        complete11.push(rank11);
    });    
    setToirog11(complete11);

    const section12 = campaigns.filter((user) => user.section == "12");
    const section12sorted = section12.sort((a, b) => b.points - a.points);
    const complete12 = []
    section12sorted?.map((item, index) => {     
        const rank12 = { ...item, rank : index + 1 }  
        complete12.push(rank12);
    });    
    setToirog12(complete12);

    const section13 = campaigns.filter((user) => user.section == "13");
    const section13sorted = section13.sort((a, b) => b.points - a.points);
    const complete13 = []
    section13sorted?.map((item, index) => {     
        const rank13 = { ...item, rank : index + 1 }  
        complete13.push(rank13);
    });    
    setToirog13(complete13);

    const jagsaaltMAN = campaigns.filter((user) => user.section == "МАН-жагсаалт");
    const jagsaaltMANsorted = jagsaaltMAN.sort((a, b) => b.points - a.points);
    const completeMAN = []
    jagsaaltMANsorted?.map((item, index) => {     
        const rankMAN = { ...item, rank : index + 1 }  
        completeMAN.push(rankMAN);
    });    
    setJagsaaltMAN(completeMAN);

    const jagsaaltAN = campaigns.filter((user) => user.section == "АН-жагсаалт");
    const jagsaaltANsorted = jagsaaltAN.sort((a, b) => b.points - a.points);
    const completeAN = []
    jagsaaltANsorted?.map((item, index) => {     
        const rankAN = { ...item, rank : index + 1 }  
        completeAN.push(rankAN);
    });    
    setJagsaaltAN(completeAN);

    const jagsaaltXYN = campaigns.filter((user) => user.section == "ХҮН-жагсаалт");
    const jagsaaltXYNsorted = jagsaaltXYN.sort((a, b) => b.points - a.points);
    const completeXYN = []
    jagsaaltXYNsorted?.map((item, index) => {     
        const rankXYN = { ...item, rank : index + 1 }  
        completeXYN.push(rankXYN);
    });    
    setJagsaaltXYN(completeXYN);

    const jagsaaltUE = campaigns.filter((user) => user.section == "ҮЭ-жагсаалт");
    const jagsaaltUEsorted = jagsaaltUE.sort((a, b) => b.points - a.points);
    const completeUE = []
    jagsaaltUEsorted?.map((item, index) => {     
        const rankUE = { ...item, rank : index + 1 }  
        completeUE.push(rankUE);
    });    
    setJagsaaltUE(completeUE);

    setCampaign(campaigns);
  }, [campaigns]);

  useEffect(() => {
    if (!isEmpty(campaigns)) {
        const section1 = campaigns.filter((user) => user.section == "1");
        const section1sorted = section1.sort((a, b) => b.points - a.points);
        const complete1 = []
        section1sorted?.map((item, index) => {     
            const rank1 = { ...item, rank : index + 1 }  
            complete1.push(rank1);
        });    
        setToirog1(complete1);

        const section2 = campaigns.filter((user) => user.section == "2");
        const section2sorted = section2.sort((a, b) => b.points - a.points);
        const complete2 = []
        section2sorted?.map((item, index) => {     
            const rank2 = { ...item, rank : index + 1 }  
            complete2.push(rank2);
        });    
        setToirog2(complete2);

        const section3 = campaigns.filter((user) => user.section == "3");
        const section3sorted = section3.sort((a, b) => b.points - a.points);
        const complete3 = []
        section3sorted?.map((item, index) => {     
            const rank3 = { ...item, rank : index + 1 }  
            complete3.push(rank3);
        });    
        setToirog3(complete3);

        const section4 = campaigns.filter((user) => user.section == "4");
        const section4sorted = section4.sort((a, b) => b.points - a.points);
        const complete4 = []
        section4sorted?.map((item, index) => {     
            const rank4 = { ...item, rank : index + 1 }  
            complete4.push(rank4);
        });        
        setToirog4(complete4);

        const section5 = campaigns.filter((user) => user.section == "5");
        const section5sorted = section5.sort((a, b) => b.points - a.points);
        const complete5 = []
        section5sorted?.map((item, index) => {     
            const rank5 = { ...item, rank : index + 1 }  
            complete5.push(rank5);
        });    
        setToirog5(complete5);

        const section6 = campaigns.filter((user) => user.section == "6");
        const section6sorted = section6.sort((a, b) => b.points - a.points);
        const complete6 = []
        section6sorted?.map((item, index) => {     
            const rank6 = { ...item, rank : index + 1 }  
            complete6.push(rank6);
        });    
        setToirog6(complete6);

        const section7 = campaigns.filter((user) => user.section == "7");
        const section7sorted = section7.sort((a, b) => b.points - a.points);
        const complete7 = []
        section7sorted?.map((item, index) => {     
            const rank7 = { ...item, rank : index + 1 }  
            complete7.push(rank7);
        });    
        setToirog7(complete7);

        const section8 = campaigns.filter((user) => user.section == "8");
        const section8sorted = section8.sort((a, b) => b.points - a.points);
        const complete8 = []
        section8sorted?.map((item, index) => {     
            const rank8 = { ...item, rank : index + 1 }  
            complete8.push(rank8);
        });    
        setToirog8(complete8);

        const section9 = campaigns.filter((user) => user.section == "9");
        const section9sorted = section9.sort((a, b) => b.points - a.points);
        const complete9 = []
        section9sorted?.map((item, index) => {     
            const rank9 = { ...item, rank : index + 1 }  
            complete9.push(rank9);
        });    
        setToirog9(complete9);

        const section10 = campaigns.filter((user) => user.section == "10");
        const section10sorted = section10.sort((a, b) => b.points - a.points);
        const complete10 = []
        section10sorted?.map((item, index) => {     
            const rank10 = { ...item, rank : index + 1 }  
            complete10.push(rank10);
        });    
        setToirog10(complete10);

        const section11 = campaigns.filter((user) => user.section == "11");
        const section11sorted = section11.sort((a, b) => b.points - a.points);
        const complete11 = []
        section11sorted?.map((item, index) => {     
            const rank11 = { ...item, rank : index + 1 }  
            complete11.push(rank11);
        });    
        setToirog11(complete11);

        const section12 = campaigns.filter((user) => user.section == "12");
        const section12sorted = section12.sort((a, b) => b.points - a.points);
        const complete12 = []
        section12sorted?.map((item, index) => {     
            const rank12 = { ...item, rank : index + 1 }  
            complete12.push(rank12);
        });    
        setToirog12(complete12);

        const section13 = campaigns.filter((user) => user.section == "13");
        const section13sorted = section13.sort((a, b) => b.points - a.points);
        const complete13 = []
        section13sorted?.map((item, index) => {     
            const rank13 = { ...item, rank : index + 1 }  
            complete13.push(rank13);
        });    
        setToirog13(complete13);

        const jagsaaltMAN = campaigns.filter((user) => user.section == "МАН-жагсаалт");
        const jagsaaltMANsorted = jagsaaltMAN.sort((a, b) => b.points - a.points);
        const completeMAN = []
        jagsaaltMANsorted?.map((item, index) => {     
            const rankMAN = { ...item, rank : index + 1 }  
            completeMAN.push(rankMAN);
        });    
        setJagsaaltMAN(completeMAN);

        const jagsaaltAN = campaigns.filter((user) => user.section == "АН-жагсаалт");
        const jagsaaltANsorted = jagsaaltAN.sort((a, b) => b.points - a.points);
        const completeAN = []
        jagsaaltANsorted?.map((item, index) => {     
            const rankAN = { ...item, rank : index + 1 }  
            completeAN.push(rankAN);
        });    
        setJagsaaltAN(completeAN);

        const jagsaaltXYN = campaigns.filter((user) => user.section == "ХҮН-жагсаалт");
        const jagsaaltXYNsorted = jagsaaltXYN.sort((a, b) => b.points - a.points);
        const completeXYN = []
        jagsaaltXYNsorted?.map((item, index) => {     
            const rankXYN = { ...item, rank : index + 1 }  
            completeXYN.push(rankXYN);
        });    
        setJagsaaltXYN(completeXYN);

        const jagsaaltUE = campaigns.filter((user) => user.section == "ҮЭ-жагсаалт");
        const jagsaaltUEsorted = jagsaaltUE.sort((a, b) => b.points - a.points);
        const completeUE = []
        jagsaaltUEsorted?.map((item, index) => {     
            const rankUE = { ...item, rank : index + 1 }  
            completeUE.push(rankUE);
        });    
        setJagsaaltUE(completeUE);

      setCampaign(campaigns);
      setIsEdit(false);
    }
  }, [campaigns]);

  useEffect(() => {
    let timer = setInterval(() => {
      setTime((time) => {
        if (time === 0) {
          clearInterval(timer);
          return 0;
        } else return time - 1;
      });
    }, 1000);
  }, []);

  //delete Campaign
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);

  const [modal, setModal] = useState(false);
  const [keywords, setKeywords] = useState([]);
  
  SwiperCore.use([Autoplay]);

  const [activeTab, setActiveTab] = useState('2');
  const [activityTab, setActivityTab] = useState('2');

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
        setActiveTab(tab);
    }
  };

  const toggleActivityTab = (tab) => {
      if (activityTab !== tab) {
          setActivityTab(tab);
      }
  };
  
  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setCampaign(null);
      setKeywords([]);
      setImage(null);
    } else {
      setModal(true);
    }
  }, [modal]);


  // Delete Data
  const handleDeleteCampaign = () => {
    if (campaign) {
      dispatch(onDeleteCampaign(campaign._id));
      setDeleteModal(false);
    }
  };

  const onClickDelete = (campaign) => {
    setCampaign(campaign);
    setDeleteModal(true);
  };

  const onClickPosts = (campaign) => {
    return <Navigate to="/posts" state={campaign} />
  };

  // Add Data
  const handleCampaignClicks = () => {
    setCampaign("");
    setIsEdit(false);
    toggle();
  };

  // Date & Time Format

  const dateFormat = () => {
    var d = new Date(),
      months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return (d.getDate() + ' ' + months[d.getMonth()] + ', ' + d.getFullYear());
  };

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (campaign && campaign.name) || '',
      desc: (campaign && campaign.desc) || '',      
      keywords: (campaign && campaign.keywords) || [],
      party: (campaign && campaign.party) || '',
      section: (campaign && campaign.section) || '', 
      points: (campaign && campaign.points) || 0,       
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Судалгааны нэрийг заавал оруулна"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateCampaign = {
          _id: campaign ? campaign._id : 0,
          name: values.name,
          desc: values.desc,         
          keywords: keywords,
          party: values.party,
          section: values.section,  
          points: values.points,      
          image: image
        };

        // update Campaign
        dispatch(onUpdateCampaign(updateCampaign));
        validation.resetForm();
      } else {
        const newCampaign = {
          name: values["name"],
          desc: values["desc"],          
          keywords: keywords,
          party: values["party"],
          section: values["section"], 
          points: values["points"],              
          image: image
        };
        // save new Campaign
        dispatch(onAddNewCampaign(newCampaign));
        validation.resetForm();
      }
      toggle();
    },
  });
  
  // Changing state value when searching name
  useEffect(() => {
    
    if (filterText !== "") {
      const filteredObject = dataDual.filter((item) => {
        return item.label.toLowerCase().includes(filterText.toLowerCase());
      });
      setDataDual([...filteredObject]);
    } else {
      setDataDual([...dataTempDual]);
    }
  }, [filterText]);

  const onFilterChange = (e) => {
    setFilterText(e.target.value);
  };

  // function to close the form modal
  const onFormCancel = () => {
    setModal(false);
    setCampaign("");
    setIsEdit(false);
    setImage(dummyImg);
    validation.resetForm();
    setKeywords([]);
  };

  const onPermissionSave = () => {
    let submittedData;
    let newitems = campaigns;

    newitems?.map((item) => {
      if (item._id === editId) {
        submittedData = {
          _id: editId,                    
          whitelist: selected
        };
      }
    });

    // update Campaign
    dispatch(onUpdateCampaign(submittedData));

    setPermissionModal(false);
  };

  const onPermissionCancel = () => {
    setPermissionModal(false);
  };

  // Update Data
  const handleCampaignClick = useCallback((arg) => {
    const campaign = arg;

    setCampaign({
      _id: campaign._id,
      name: campaign.name,
      keywords: campaign.keywords,
      desc: campaign.desc,
      party: campaign.party,
      section: campaign.section,
      points: campaign.points,
    });
    setImage(campaign.image);
    setUploaded("");
    setKeywords(campaign.keywords);
    setIsEdit(true);
    toggle();
  }, [toggle]);

  const handlePreview = (file) => {    
        setImage(URL.createObjectURL(file));
        setFile(file);
  }
  const handleUpload = (e) => {
        e.preventDefault();
        //setImage(URL.createObjectURL(file))
        const fileName = new Date().getTime() + file.name;
        const storageRef = ref(storage, `/campaign/${fileName}`);

        // progress can be paused and resumed. It also exposes progress updates.
        // Receives the storage reference and the file to upload.
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
            "state_changed",
            (snapshot) => {
            const percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            const safePercent = Math.floor(percentage, 10).toString()

            // update progress
            setPercent(safePercent);
            //console.log(percent)
            },
            (err) => setUploaded("no"),
            () => {
            // download url
            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            //console.log(url);
                setImage(url);
                setUploaded("yes");
            });
            }
        );
  };

  const handleValidDate = date => {
    const date1 = moment(new Date(date)).format("Y-MM-DD");
    return date1;
  };

  const handleValidTime = (time) => {
    const time1 = new Date(time);
    const getHour = time1.getUTCHours();
    const getMin = time1.getUTCMinutes();
    const getTime = `${getHour}:${getMin}`;
    const updateTime = moment.utc(getTime, 'HH:mm').format('HH:mm');
    return updateTime;
  };

  const handleValidDateTime = (time) => {    
    const updateTime = moment.utc(time, 'YYYY-MM-DD HH:mm:ss').tz('Asia/Ulaanbaatar').format('YYYY-MM-DD HH:mm:ss');
    return updateTime;
  };

  // Checked All
  const checkedAll = useCallback(() => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".campaignCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
    deleteCheckbox();
  }, []);

  const defaultdate = () => {
    let d = new Date(),
      months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return ((d.getFullYear() + '-' + d.getMonth() + '-' + d.getDate()).toString());
  };

  const [dateValidFrom, setDateValidFrom] = useState(defaultdate());
  const [dateValidTo, setDateValidTo] = useState(defaultdate());

  const dateformaterValidFrom = (e) => {
    const date = e.toString().split(" ");
    const joinDate = (date[3] + "-" + date[2] + "-" + date[1]).toString();
    setDateValidFrom(joinDate);
  };

  const dateformaterValidTo = (e) => {
    const date = e.toString().split(" ");
    const joinDate = (date[3] + "-" + date[2] + "-" + date[1]).toString();
    setDateValidTo(joinDate);
  };

  // Delete Multiple
  const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
  const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);

  const deleteMultiple = () => {
    const checkall = document.getElementById("checkBoxAll");
    selectedCheckBoxDelete.forEach((element) => {
      dispatch(onDeleteCampaign(element.value));
      setTimeout(() => { toast.clearWaitingQueue(); }, 3000);
    });
    setIsMultiDeleteButton(false);
    checkall.checked = false;
  };

  const deleteCheckbox = () => {
    const ele = document.querySelectorAll(".campaignCheckBox:checked");
    ele.length > 0 ? setIsMultiDeleteButton(true) : setIsMultiDeleteButton(false);
    setSelectedCheckBoxDelete(ele);
  };

  // Column
  const columns1 = useMemo(
    () => [
      {
            Header: "№",
            accessor: "rank",
            filterable: true,
            Cell: (toirog1) => {
                if (toirog1.row.original.rank < 10) {
                    return (<h5><Badge color="primary">#{ toirog1.row.original.rank }</Badge></h5>);
                } else {
                    return (<div className="ranking">#{toirog1.row.original.rank}</div>);
                }
            }
      },      
      {
        Header: "Нэр",
        accessor: "name",
        filterable: true,
        Cell: (toirog1) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
                {toirog1.row.original.image ? 
                    <div className="avatar-sm bg-light rounded p-1 me-2">
                        <img
                          src={toirog1.row.original.image}
                          alt=""
                          className="img-fluid d-block"
                        /> 
                    </div> 
                    :
                    <div className="avatar-sm bg-light rounded p-1 me-2">                       
                        <img src={dummyImg} alt="" className="img-fluid d-block" />
                    </div>                  
                }
              </div>
              <div className="flex-grow-1 ms-2 name">
                    {
                        toirog1.row.original.rank < 10 ? <h6 className="mb-0 fw-semibold lh-base flex-grow-1">{toirog1.row.original.name}</h6>
                        : toirog1.row.original.name
                    }      
              </div>
            </div>
          </>
        ),
      },
      {
        Header: "Намын харьяалал",
        accessor: "party",
        filterable: true,
        Cell: (toirog1) => (
          <>
            <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                        {
                            toirog1.row.original.party === "МАН"
                            ? <img src={MAN} alt="" className="avatar-xxs rounded-circle" />
                            : toirog1.row.original.party === "АН"
                            ? <img src={AN} alt="" className="avatar-xxs rounded-circle" />
                            : toirog1.row.original.party === "ХҮН"
                            ? <img src={XYN} alt="" className="avatar-xxs rounded-circle" />
                            : toirog1.row.original.party === "ҮЭ"
                            ? <img src={UE} alt="" className="avatar-xxs rounded-circle" />
                            : toirog1.row.original.party === "НН"
                            ? <img src={NN} alt="" className="avatar-xxs rounded-circle" />
                            : toirog1.row.original.party === "ҮЗ"
                            ? <img src={UZ} alt="" className="avatar-xxs rounded-circle" />
                            : toirog1.row.original.party === "ШН"
                            ? <img src={SHN} alt="" className="avatar-xxs rounded-circle" />
                            : toirog1.row.original.party === "ИОНН"
                            ? <img src={IONN} alt="" className="avatar-xxs rounded-circle" />
                            : <img src={dummyImg} alt="" className="avatar-xxs rounded-circle" />
                        }  
                </div>
                <div className="flex-grow-1 ms-2 name">
                      {
                            toirog1.row.original.party === "МАН"
                            ? "Монгол ардын нам"
                            : toirog1.row.original.party === "АН"
                            ? "Ардчилсан нам"
                            : toirog1.row.original.party === "ХҮН"
                            ? "Хүн нам"
                            : toirog1.row.original.party === "ҮЭ"
                            ? "Үндэсний эвсэл"
                            : toirog1.row.original.party === "НН"
                            ? "Ногоон нам"
                            : toirog1.row.original.party === "ҮЗ"
                            ? "Үнэн ба зөв нам"
                            : toirog1.row.original.party === "ШН"
                            ? "Шинэ нэгдсэн эвсэл нам"
                            : toirog1.row.original.party === "ИОНН"
                            ? "Иргэдийн оролцооны нэгдэл нам"
                            : "Бусад"
                      }             
                </div>
            </div>            
          </>
        ),
      },   
      {
        Header: "Үйлдэл",
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">              
              <li className="list-inline-item">
                <UncontrolledDropdown>
                  <DropdownToggle
                    href="#"
                    className="btn btn-soft-secondary btn-sm dropdown"
                    tag="button"
                  >
                    <i className="ri-more-fill align-middle"></i>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">                    
                    <DropdownItem
                        className="dropdown-item edit-item-btn"
                        href="#"
                        onClick={() => { const campaignData = cellProps.row.original; handleCampaignClick(campaignData); }}
                      >
                      <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>{" "}
                      Харах
                    </DropdownItem>                     
                    <DropdownItem className="dropdown-item">
                      <i className="ri-dashboard-fill align-bottom me-2 text-muted"></i>{" "}
                      
                      <Link to="/dashboard" state={{campaignId: cellProps.row.original._id, 
                                              campaignName: cellProps.row.original.name,
                                              campaignDesc: cellProps.row.original.desc,
                                              campaignImage: cellProps.row.original.image,
                                              campaignStatus: cellProps.row.original.status,
                                              campaignValidFrom: cellProps.row.original.validFrom,
                                              campaignValidTo: cellProps.row.original.validTo,
                                              campaignDataAnalyze: cellProps.row.original.dataAnalyze
                                            }}> 
                          Хянах самбар
                      </Link>
                    </DropdownItem> 
                  </DropdownMenu>
                </UncontrolledDropdown>
              </li>
            </ul>
          );
        },
      },
    ],
    [handleCampaignClick, checkedAll]
  );

  const columns2 = useMemo(
    () => [
      {
            Header: "№",
            accessor: "rank",
            filterable: true,
            Cell: (toirog1) => {
                if (toirog1.row.original.rank < 4) {
                    return (<h5><Badge color="primary">#{ toirog1.row.original.rank }</Badge></h5>);
                } else {
                    return (<div className="ranking">#{toirog1.row.original.rank}</div>);
                }
            }
      },      
      {
        Header: "Нэр",
        accessor: "name",
        filterable: true,
        Cell: (toirog1) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
                {toirog1.row.original.image ? 
                    <div className="avatar-sm bg-light rounded p-1 me-2">
                        <img
                          src={toirog1.row.original.image}
                          alt=""
                          className="img-fluid d-block"
                        /> 
                    </div> 
                    :
                    <div className="avatar-sm bg-light rounded p-1 me-2">                       
                        <img src={dummyImg} alt="" className="img-fluid d-block" />
                    </div>                  
                }
              </div>
              <div className="flex-grow-1 ms-2 name">
                    {
                        toirog1.row.original.rank < 10 ? <h6 className="mb-0 fw-semibold lh-base flex-grow-1">{toirog1.row.original.name}</h6>
                        : toirog1.row.original.name
                    }      
              </div>
            </div>
          </>
        ),
      },
      {
        Header: "Намын харьяалал",
        accessor: "party",
        filterable: true,
        Cell: (toirog1) => (
          <>
            <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                        {
                            toirog1.row.original.party === "МАН"
                            ? <img src={MAN} alt="" className="avatar-xxs rounded-circle" />
                            : toirog1.row.original.party === "АН"
                            ? <img src={AN} alt="" className="avatar-xxs rounded-circle" />
                            : toirog1.row.original.party === "ХҮН"
                            ? <img src={XYN} alt="" className="avatar-xxs rounded-circle" />
                            : toirog1.row.original.party === "ҮЭ"
                            ? <img src={UE} alt="" className="avatar-xxs rounded-circle" />
                            : toirog1.row.original.party === "НН"
                            ? <img src={NN} alt="" className="avatar-xxs rounded-circle" />
                            : toirog1.row.original.party === "ҮЗ"
                            ? <img src={UZ} alt="" className="avatar-xxs rounded-circle" />
                            : toirog1.row.original.party === "ШН"
                            ? <img src={SHN} alt="" className="avatar-xxs rounded-circle" />
                            : toirog1.row.original.party === "ИОНН"
                            ? <img src={IONN} alt="" className="avatar-xxs rounded-circle" />
                            : <img src={dummyImg} alt="" className="avatar-xxs rounded-circle" />
                        }  
                </div>
                <div className="flex-grow-1 ms-2 name">
                      {
                            toirog1.row.original.party === "МАН"
                            ? "Монгол ардын нам"
                            : toirog1.row.original.party === "АН"
                            ? "Ардчилсан нам"
                            : toirog1.row.original.party === "ХҮН"
                            ? "Хүн нам"
                            : toirog1.row.original.party === "ҮЭ"
                            ? "Үндэсний эвсэл"
                            : toirog1.row.original.party === "НН"
                            ? "Ногоон нам"
                            : toirog1.row.original.party === "ҮЗ"
                            ? "Үнэн ба зөв нам"
                            : toirog1.row.original.party === "ШН"
                            ? "Шинэ нэгдсэн эвсэл нам"
                            : toirog1.row.original.party === "ИОНН"
                            ? "Иргэдийн оролцооны нэгдэл нам"
                            : "Бусад"
                      }             
                </div>
            </div>            
          </>
        ),
      },   
      {
        Header: "Үйлдэл",
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">              
              <li className="list-inline-item">
                <UncontrolledDropdown>
                  <DropdownToggle
                    href="#"
                    className="btn btn-soft-secondary btn-sm dropdown"
                    tag="button"
                  >
                    <i className="ri-more-fill align-middle"></i>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">                    
                    <DropdownItem
                        className="dropdown-item edit-item-btn"
                        href="#"
                        onClick={() => { const campaignData = cellProps.row.original; handleCampaignClick(campaignData); }}
                      >
                      <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>{" "}
                      Харах
                    </DropdownItem>                     
                    <DropdownItem className="dropdown-item">
                      <i className="ri-dashboard-fill align-bottom me-2 text-muted"></i>{" "}
                      
                      <Link to="/dashboard" state={{campaignId: cellProps.row.original._id, 
                                              campaignName: cellProps.row.original.name,
                                              campaignDesc: cellProps.row.original.desc,
                                              campaignImage: cellProps.row.original.image,
                                              campaignStatus: cellProps.row.original.status,
                                              campaignValidFrom: cellProps.row.original.validFrom,
                                              campaignValidTo: cellProps.row.original.validTo,
                                              campaignDataAnalyze: cellProps.row.original.dataAnalyze
                                            }}> 
                          Хянах самбар
                      </Link>
                    </DropdownItem> 
                  </DropdownMenu>
                </UncontrolledDropdown>
              </li>
            </ul>
          );
        },
      },
    ],
    [handleCampaignClick, checkedAll]
  );

  const columns3 = useMemo(
    () => [
      {
            Header: "№",
            accessor: "rank",
            filterable: true,
            Cell: (toirog1) => {
                if (toirog1.row.original.rank < 11) {
                    return (<h5><Badge color="primary">#{ toirog1.row.original.rank }</Badge></h5>);
                } else {
                    return (<div className="ranking">#{toirog1.row.original.rank}</div>);
                }
            }
      },      
      {
        Header: "Нэр",
        accessor: "name",
        filterable: true,
        Cell: (toirog1) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
                {toirog1.row.original.image ? 
                    <div className="avatar-sm bg-light rounded p-1 me-2">
                        <img
                          src={toirog1.row.original.image}
                          alt=""
                          className="img-fluid d-block"
                        /> 
                    </div> 
                    :
                    <div className="avatar-sm bg-light rounded p-1 me-2">                       
                        <img src={dummyImg} alt="" className="img-fluid d-block" />
                    </div>                  
                }
              </div>
              <div className="flex-grow-1 ms-2 name">
                    {
                        toirog1.row.original.rank < 10 ? <h6 className="mb-0 fw-semibold lh-base flex-grow-1">{toirog1.row.original.name}</h6>
                        : toirog1.row.original.name
                    }      
              </div>
            </div>
          </>
        ),
      },
      {
        Header: "Намын харьяалал",
        accessor: "party",
        filterable: true,
        Cell: (toirog1) => (
          <>
            <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                        {
                            toirog1.row.original.party === "МАН"
                            ? <img src={MAN} alt="" className="avatar-xxs rounded-circle" />
                            : toirog1.row.original.party === "АН"
                            ? <img src={AN} alt="" className="avatar-xxs rounded-circle" />
                            : toirog1.row.original.party === "ХҮН"
                            ? <img src={XYN} alt="" className="avatar-xxs rounded-circle" />
                            : toirog1.row.original.party === "ҮЭ"
                            ? <img src={UE} alt="" className="avatar-xxs rounded-circle" />
                            : toirog1.row.original.party === "НН"
                            ? <img src={NN} alt="" className="avatar-xxs rounded-circle" />
                            : toirog1.row.original.party === "ҮЗ"
                            ? <img src={UZ} alt="" className="avatar-xxs rounded-circle" />
                            : toirog1.row.original.party === "ШН"
                            ? <img src={SHN} alt="" className="avatar-xxs rounded-circle" />
                            : toirog1.row.original.party === "ИОНН"
                            ? <img src={IONN} alt="" className="avatar-xxs rounded-circle" />
                            : <img src={dummyImg} alt="" className="avatar-xxs rounded-circle" />
                        }  
                </div>
                <div className="flex-grow-1 ms-2 name">
                      {
                            toirog1.row.original.party === "МАН"
                            ? "Монгол ардын нам"
                            : toirog1.row.original.party === "АН"
                            ? "Ардчилсан нам"
                            : toirog1.row.original.party === "ХҮН"
                            ? "Хүн нам"
                            : toirog1.row.original.party === "ҮЭ"
                            ? "Үндэсний эвсэл"
                            : toirog1.row.original.party === "НН"
                            ? "Ногоон нам"
                            : toirog1.row.original.party === "ҮЗ"
                            ? "Үнэн ба зөв нам"
                            : toirog1.row.original.party === "ШН"
                            ? "Шинэ нэгдсэн эвсэл нам"
                            : toirog1.row.original.party === "ИОНН"
                            ? "Иргэдийн оролцооны нэгдэл нам"
                            : "Бусад"
                      }             
                </div>
            </div>            
          </>
        ),
      },    
      {
        Header: "Үйлдэл",
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">              
              <li className="list-inline-item">
                <UncontrolledDropdown>
                  <DropdownToggle
                    href="#"
                    className="btn btn-soft-secondary btn-sm dropdown"
                    tag="button"
                  >
                    <i className="ri-more-fill align-middle"></i>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">                    
                    <DropdownItem
                        className="dropdown-item edit-item-btn"
                        href="#"
                        onClick={() => { const campaignData = cellProps.row.original; handleCampaignClick(campaignData); }}
                      >
                      <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>{" "}
                      Харах
                    </DropdownItem>                     
                    <DropdownItem className="dropdown-item">
                      <i className="ri-dashboard-fill align-bottom me-2 text-muted"></i>{" "}
                      
                      <Link to="/dashboard" state={{campaignId: cellProps.row.original._id, 
                                              campaignName: cellProps.row.original.name,
                                              campaignDesc: cellProps.row.original.desc,
                                              campaignImage: cellProps.row.original.image,
                                              campaignStatus: cellProps.row.original.status,
                                              campaignValidFrom: cellProps.row.original.validFrom,
                                              campaignValidTo: cellProps.row.original.validTo,
                                              campaignDataAnalyze: cellProps.row.original.dataAnalyze
                                            }}> 
                          Хянах самбар
                      </Link>
                    </DropdownItem> 
                  </DropdownMenu>
                </UncontrolledDropdown>
              </li>
            </ul>
          );
        },
      },
    ],
    [handleCampaignClick, checkedAll]
  );

  const columns4 = useMemo(
    () => [
      {
            Header: "№",
            accessor: "rank",
            filterable: true,
            Cell: (toirog1) => {
                if (toirog1.row.original.rank < 9) {
                    return (<h5><Badge color="primary">#{ toirog1.row.original.rank }</Badge></h5>);
                } else {
                    return (<div className="ranking">#{toirog1.row.original.rank}</div>);
                }
            }
      },      
      {
        Header: "Нэр",
        accessor: "name",
        filterable: true,
        Cell: (toirog1) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
                {toirog1.row.original.image ? 
                    <div className="avatar-sm bg-light rounded p-1 me-2">
                        <img
                          src={toirog1.row.original.image}
                          alt=""
                          className="img-fluid d-block"
                        /> 
                    </div> 
                    :
                    <div className="avatar-sm bg-light rounded p-1 me-2">                       
                        <img src={dummyImg} alt="" className="img-fluid d-block" />
                    </div>                  
                }
              </div>
              <div className="flex-grow-1 ms-2 name">
                    {
                        toirog1.row.original.rank < 10 ? <h6 className="mb-0 fw-semibold lh-base flex-grow-1">{toirog1.row.original.name}</h6>
                        : toirog1.row.original.name
                    }      
              </div>
            </div>
          </>
        ),
      },
      {
        Header: "Намын харьяалал",
        accessor: "party",
        filterable: true,
        Cell: (toirog1) => (
          <>
            <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                        {
                            toirog1.row.original.party === "МАН"
                            ? <img src={MAN} alt="" className="avatar-xxs rounded-circle" />
                            : toirog1.row.original.party === "АН"
                            ? <img src={AN} alt="" className="avatar-xxs rounded-circle" />
                            : toirog1.row.original.party === "ХҮН"
                            ? <img src={XYN} alt="" className="avatar-xxs rounded-circle" />
                            : toirog1.row.original.party === "ҮЭ"
                            ? <img src={UE} alt="" className="avatar-xxs rounded-circle" />
                            : toirog1.row.original.party === "НН"
                            ? <img src={NN} alt="" className="avatar-xxs rounded-circle" />
                            : toirog1.row.original.party === "ҮЗ"
                            ? <img src={UZ} alt="" className="avatar-xxs rounded-circle" />
                            : toirog1.row.original.party === "ШН"
                            ? <img src={SHN} alt="" className="avatar-xxs rounded-circle" />
                            : toirog1.row.original.party === "ИОНН"
                            ? <img src={IONN} alt="" className="avatar-xxs rounded-circle" />
                            : <img src={dummyImg} alt="" className="avatar-xxs rounded-circle" />
                        }  
                </div>
                <div className="flex-grow-1 ms-2 name">
                      {
                            toirog1.row.original.party === "МАН"
                            ? "Монгол ардын нам"
                            : toirog1.row.original.party === "АН"
                            ? "Ардчилсан нам"
                            : toirog1.row.original.party === "ХҮН"
                            ? "Хүн нам"
                            : toirog1.row.original.party === "ҮЭ"
                            ? "Үндэсний эвсэл"
                            : toirog1.row.original.party === "НН"
                            ? "Ногоон нам"
                            : toirog1.row.original.party === "ҮЗ"
                            ? "Үнэн ба зөв нам"
                            : toirog1.row.original.party === "ШН"
                            ? "Шинэ нэгдсэн эвсэл нам"
                            : toirog1.row.original.party === "ИОНН"
                            ? "Иргэдийн оролцооны нэгдэл нам"
                            : "Бусад"
                      }             
                </div>
            </div>            
          </>
        ),
      },    
      {
        Header: "Үйлдэл",
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">              
              <li className="list-inline-item">
                <UncontrolledDropdown>
                  <DropdownToggle
                    href="#"
                    className="btn btn-soft-secondary btn-sm dropdown"
                    tag="button"
                  >
                    <i className="ri-more-fill align-middle"></i>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">                    
                    <DropdownItem
                        className="dropdown-item edit-item-btn"
                        href="#"
                        onClick={() => { const campaignData = cellProps.row.original; handleCampaignClick(campaignData); }}
                      >
                      <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>{" "}
                      Харах
                    </DropdownItem>                     
                    <DropdownItem className="dropdown-item">
                      <i className="ri-dashboard-fill align-bottom me-2 text-muted"></i>{" "}
                      
                      <Link to="/dashboard" state={{campaignId: cellProps.row.original._id, 
                                              campaignName: cellProps.row.original.name,
                                              campaignDesc: cellProps.row.original.desc,
                                              campaignImage: cellProps.row.original.image,
                                              campaignStatus: cellProps.row.original.status,
                                              campaignValidFrom: cellProps.row.original.validFrom,
                                              campaignValidTo: cellProps.row.original.validTo,
                                              campaignDataAnalyze: cellProps.row.original.dataAnalyze
                                            }}> 
                          Хянах самбар
                      </Link>
                    </DropdownItem> 
                  </DropdownMenu>
                </UncontrolledDropdown>
              </li>
            </ul>
          );
        },
      },
    ],
    [handleCampaignClick, checkedAll]
  );

  const columns5 = useMemo(
    () => [
      {
            Header: "№",
            accessor: "rank",
            filterable: true,
            Cell: (toirog1) => {
                if (toirog1.row.original.rank < 8) {
                    return (<h5><Badge color="primary">#{ toirog1.row.original.rank }</Badge></h5>);
                } else {
                    return (<div className="ranking">#{toirog1.row.original.rank}</div>);
                }
            }
      },      
      {
        Header: "Нэр",
        accessor: "name",
        filterable: true,
        Cell: (toirog1) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
                {toirog1.row.original.image ? 
                    <div className="avatar-sm bg-light rounded p-1 me-2">
                        <img
                          src={toirog1.row.original.image}
                          alt=""
                          className="img-fluid d-block"
                        /> 
                    </div> 
                    :
                    <div className="avatar-sm bg-light rounded p-1 me-2">                       
                        <img src={dummyImg} alt="" className="img-fluid d-block" />
                    </div>                  
                }
              </div>
              <div className="flex-grow-1 ms-2 name">
                    {
                        toirog1.row.original.rank < 10 ? <h6 className="mb-0 fw-semibold lh-base flex-grow-1">{toirog1.row.original.name}</h6>
                        : toirog1.row.original.name
                    }      
              </div>
            </div>
          </>
        ),
      },
      {
        Header: "Намын харьяалал",
        accessor: "party",
        filterable: true,
        Cell: (toirog1) => (
          <>
            <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                        {
                            toirog1.row.original.party === "МАН"
                            ? <img src={MAN} alt="" className="avatar-xxs rounded-circle" />
                            : toirog1.row.original.party === "АН"
                            ? <img src={AN} alt="" className="avatar-xxs rounded-circle" />
                            : toirog1.row.original.party === "ХҮН"
                            ? <img src={XYN} alt="" className="avatar-xxs rounded-circle" />
                            : toirog1.row.original.party === "ҮЭ"
                            ? <img src={UE} alt="" className="avatar-xxs rounded-circle" />
                            : toirog1.row.original.party === "НН"
                            ? <img src={NN} alt="" className="avatar-xxs rounded-circle" />
                            : toirog1.row.original.party === "ҮЗ"
                            ? <img src={UZ} alt="" className="avatar-xxs rounded-circle" />
                            : toirog1.row.original.party === "ШН"
                            ? <img src={SHN} alt="" className="avatar-xxs rounded-circle" />
                            : toirog1.row.original.party === "ИОНН"
                            ? <img src={IONN} alt="" className="avatar-xxs rounded-circle" />
                            : <img src={dummyImg} alt="" className="avatar-xxs rounded-circle" />
                        }  
                </div>
                <div className="flex-grow-1 ms-2 name">
                      {
                            toirog1.row.original.party === "МАН"
                            ? "Монгол ардын нам"
                            : toirog1.row.original.party === "АН"
                            ? "Ардчилсан нам"
                            : toirog1.row.original.party === "ХҮН"
                            ? "Хүн нам"
                            : toirog1.row.original.party === "ҮЭ"
                            ? "Үндэсний эвсэл"
                            : toirog1.row.original.party === "НН"
                            ? "Ногоон нам"
                            : toirog1.row.original.party === "ҮЗ"
                            ? "Үнэн ба зөв нам"
                            : toirog1.row.original.party === "ШН"
                            ? "Шинэ нэгдсэн эвсэл нам"
                            : toirog1.row.original.party === "ИОНН"
                            ? "Иргэдийн оролцооны нэгдэл нам"
                            : "Бусад"
                      }             
                </div>
            </div>            
          </>
        ),
      },  
      {
        Header: "Үйлдэл",
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">              
              <li className="list-inline-item">
                <UncontrolledDropdown>
                  <DropdownToggle
                    href="#"
                    className="btn btn-soft-secondary btn-sm dropdown"
                    tag="button"
                  >
                    <i className="ri-more-fill align-middle"></i>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">                    
                    <DropdownItem
                        className="dropdown-item edit-item-btn"
                        href="#"
                        onClick={() => { const campaignData = cellProps.row.original; handleCampaignClick(campaignData); }}
                      >
                      <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>{" "}
                      Харах
                    </DropdownItem>                     
                    <DropdownItem className="dropdown-item">
                      <i className="ri-dashboard-fill align-bottom me-2 text-muted"></i>{" "}
                      
                      <Link to="/dashboard" state={{campaignId: cellProps.row.original._id, 
                                              campaignName: cellProps.row.original.name,
                                              campaignDesc: cellProps.row.original.desc,
                                              campaignImage: cellProps.row.original.image,
                                              campaignStatus: cellProps.row.original.status,
                                              campaignValidFrom: cellProps.row.original.validFrom,
                                              campaignValidTo: cellProps.row.original.validTo,
                                              campaignDataAnalyze: cellProps.row.original.dataAnalyze
                                            }}> 
                          Хянах самбар
                      </Link>
                    </DropdownItem> 
                  </DropdownMenu>
                </UncontrolledDropdown>
              </li>
            </ul>
          );
        },
      },
    ],
    [handleCampaignClick, checkedAll]
  );

  const columns6 = useMemo(
    () => [
      {
            Header: "№",
            accessor: "rank",
            filterable: true,
            Cell: (toirog1) => {
                if (toirog1.row.original.rank < 6) {
                    return (<h5><Badge color="primary">#{ toirog1.row.original.rank }</Badge></h5>);
                } else {
                    return (<div className="ranking">#{toirog1.row.original.rank}</div>);
                }
            }
      },      
      {
        Header: "Нэр",
        accessor: "name",
        filterable: true,
        Cell: (toirog1) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
                {toirog1.row.original.image ? 
                    <div className="avatar-sm bg-light rounded p-1 me-2">
                        <img
                          src={toirog1.row.original.image}
                          alt=""
                          className="img-fluid d-block"
                        /> 
                    </div> 
                    :
                    <div className="avatar-sm bg-light rounded p-1 me-2">                       
                        <img src={dummyImg} alt="" className="img-fluid d-block" />
                    </div>                  
                }
              </div>
              <div className="flex-grow-1 ms-2 name">
                    {
                        toirog1.row.original.rank < 10 ? <h6 className="mb-0 fw-semibold lh-base flex-grow-1">{toirog1.row.original.name}</h6>
                        : toirog1.row.original.name
                    }      
              </div>
            </div>
          </>
        ),
      },
      {
        Header: "Намын харьяалал",
        accessor: "party",
        filterable: true,
        Cell: (toirog1) => (
          <>
            <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                        {
                            toirog1.row.original.party === "МАН"
                            ? <img src={MAN} alt="" className="avatar-xxs rounded-circle" />
                            : toirog1.row.original.party === "АН"
                            ? <img src={AN} alt="" className="avatar-xxs rounded-circle" />
                            : toirog1.row.original.party === "ХҮН"
                            ? <img src={XYN} alt="" className="avatar-xxs rounded-circle" />
                            : toirog1.row.original.party === "ҮЭ"
                            ? <img src={UE} alt="" className="avatar-xxs rounded-circle" />
                            : toirog1.row.original.party === "НН"
                            ? <img src={NN} alt="" className="avatar-xxs rounded-circle" />
                            : toirog1.row.original.party === "ҮЗ"
                            ? <img src={UZ} alt="" className="avatar-xxs rounded-circle" />
                            : toirog1.row.original.party === "ШН"
                            ? <img src={SHN} alt="" className="avatar-xxs rounded-circle" />
                            : toirog1.row.original.party === "ИОНН"
                            ? <img src={IONN} alt="" className="avatar-xxs rounded-circle" />
                            : <img src={dummyImg} alt="" className="avatar-xxs rounded-circle" />
                        }  
                </div>
                <div className="flex-grow-1 ms-2 name">
                      {
                            toirog1.row.original.party === "МАН"
                            ? "Монгол ардын нам"
                            : toirog1.row.original.party === "АН"
                            ? "Ардчилсан нам"
                            : toirog1.row.original.party === "ХҮН"
                            ? "Хүн нам"
                            : toirog1.row.original.party === "ҮЭ"
                            ? "Үндэсний эвсэл"
                            : toirog1.row.original.party === "НН"
                            ? "Ногоон нам"
                            : toirog1.row.original.party === "ҮЗ"
                            ? "Үнэн ба зөв нам"
                            : toirog1.row.original.party === "ШН"
                            ? "Шинэ нэгдсэн эвсэл нам"
                            : toirog1.row.original.party === "ИОНН"
                            ? "Иргэдийн оролцооны нэгдэл нам"
                            : "Бусад"
                      }             
                </div>
            </div>            
          </>
        ),
      },  
      {
        Header: "Үйлдэл",
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">              
              <li className="list-inline-item">
                <UncontrolledDropdown>
                  <DropdownToggle
                    href="#"
                    className="btn btn-soft-secondary btn-sm dropdown"
                    tag="button"
                  >
                    <i className="ri-more-fill align-middle"></i>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">                    
                    <DropdownItem
                        className="dropdown-item edit-item-btn"
                        href="#"
                        onClick={() => { const campaignData = cellProps.row.original; handleCampaignClick(campaignData); }}
                      >
                      <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>{" "}
                      Харах
                    </DropdownItem>                     
                    <DropdownItem className="dropdown-item">
                      <i className="ri-dashboard-fill align-bottom me-2 text-muted"></i>{" "}
                      
                      <Link to="/dashboard" state={{campaignId: cellProps.row.original._id, 
                                              campaignName: cellProps.row.original.name,
                                              campaignDesc: cellProps.row.original.desc,
                                              campaignImage: cellProps.row.original.image,
                                              campaignStatus: cellProps.row.original.status,
                                              campaignValidFrom: cellProps.row.original.validFrom,
                                              campaignValidTo: cellProps.row.original.validTo,
                                              campaignDataAnalyze: cellProps.row.original.dataAnalyze
                                            }}> 
                          Хянах самбар
                      </Link>
                    </DropdownItem> 
                  </DropdownMenu>
                </UncontrolledDropdown>
              </li>
            </ul>
          );
        },
      },
    ],
    [handleCampaignClick, checkedAll]
  );

  const columns7 = useMemo(
    () => [
      {
            Header: "№",
            accessor: "rank",
            filterable: true,
            Cell: (toirog1) => {
                if (toirog1.row.original.rank < 7) {
                    return (<h5><Badge color="primary">#{ toirog1.row.original.rank }</Badge></h5>);
                } else {
                    return (<div className="ranking">#{toirog1.row.original.rank}</div>);
                }
            }
      },      
      {
        Header: "Нэр",
        accessor: "name",
        filterable: true,
        Cell: (toirog1) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
                {toirog1.row.original.image ? 
                    <div className="avatar-sm bg-light rounded p-1 me-2">
                        <img
                          src={toirog1.row.original.image}
                          alt=""
                          className="img-fluid d-block"
                        /> 
                    </div> 
                    :
                    <div className="avatar-sm bg-light rounded p-1 me-2">                       
                        <img src={dummyImg} alt="" className="img-fluid d-block" />
                    </div>                  
                }
              </div>
              <div className="flex-grow-1 ms-2 name">
                    {
                        toirog1.row.original.rank < 10 ? <h6 className="mb-0 fw-semibold lh-base flex-grow-1">{toirog1.row.original.name}</h6>
                        : toirog1.row.original.name
                    }      
              </div>
            </div>
          </>
        ),
      },
      {
        Header: "Намын харьяалал",
        accessor: "party",
        filterable: true,
        Cell: (toirog1) => (
          <>
            <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                        {
                            toirog1.row.original.party === "МАН"
                            ? <img src={MAN} alt="" className="avatar-xxs rounded-circle" />
                            : toirog1.row.original.party === "АН"
                            ? <img src={AN} alt="" className="avatar-xxs rounded-circle" />
                            : toirog1.row.original.party === "ХҮН"
                            ? <img src={XYN} alt="" className="avatar-xxs rounded-circle" />
                            : toirog1.row.original.party === "ҮЭ"
                            ? <img src={UE} alt="" className="avatar-xxs rounded-circle" />
                            : toirog1.row.original.party === "НН"
                            ? <img src={NN} alt="" className="avatar-xxs rounded-circle" />
                            : toirog1.row.original.party === "ҮЗ"
                            ? <img src={UZ} alt="" className="avatar-xxs rounded-circle" />
                            : toirog1.row.original.party === "ШН"
                            ? <img src={SHN} alt="" className="avatar-xxs rounded-circle" />
                            : toirog1.row.original.party === "ИОНН"
                            ? <img src={IONN} alt="" className="avatar-xxs rounded-circle" />
                            : <img src={dummyImg} alt="" className="avatar-xxs rounded-circle" />
                        }  
                </div>
                <div className="flex-grow-1 ms-2 name">
                      {
                            toirog1.row.original.party === "МАН"
                            ? "Монгол ардын нам"
                            : toirog1.row.original.party === "АН"
                            ? "Ардчилсан нам"
                            : toirog1.row.original.party === "ХҮН"
                            ? "Хүн нам"
                            : toirog1.row.original.party === "ҮЭ"
                            ? "Үндэсний эвсэл"
                            : toirog1.row.original.party === "НН"
                            ? "Ногоон нам"
                            : toirog1.row.original.party === "ҮЗ"
                            ? "Үнэн ба зөв нам"
                            : toirog1.row.original.party === "ШН"
                            ? "Шинэ нэгдсэн эвсэл нам"
                            : toirog1.row.original.party === "ИОНН"
                            ? "Иргэдийн оролцооны нэгдэл нам"
                            : "Бусад"
                      }             
                </div>
            </div>            
          </>
        ),
      },  
      {
        Header: "Үйлдэл",
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">              
              <li className="list-inline-item">
                <UncontrolledDropdown>
                  <DropdownToggle
                    href="#"
                    className="btn btn-soft-secondary btn-sm dropdown"
                    tag="button"
                  >
                    <i className="ri-more-fill align-middle"></i>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">                    
                    <DropdownItem
                        className="dropdown-item edit-item-btn"
                        href="#"
                        onClick={() => { const campaignData = cellProps.row.original; handleCampaignClick(campaignData); }}
                      >
                      <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>{" "}
                      Харах
                    </DropdownItem>                     
                    <DropdownItem className="dropdown-item">
                      <i className="ri-dashboard-fill align-bottom me-2 text-muted"></i>{" "}
                      
                      <Link to="/dashboard" state={{campaignId: cellProps.row.original._id, 
                                              campaignName: cellProps.row.original.name,
                                              campaignDesc: cellProps.row.original.desc,
                                              campaignImage: cellProps.row.original.image,
                                              campaignStatus: cellProps.row.original.status,
                                              campaignValidFrom: cellProps.row.original.validFrom,
                                              campaignValidTo: cellProps.row.original.validTo,
                                              campaignDataAnalyze: cellProps.row.original.dataAnalyze
                                            }}> 
                          Хянах самбар
                      </Link>
                    </DropdownItem> 
                  </DropdownMenu>
                </UncontrolledDropdown>
              </li>
            </ul>
          );
        },
      },
    ],
    [handleCampaignClick, checkedAll]
  );

  const columns8 = useMemo(
    () => [
      {
            Header: "№",
            accessor: "rank",
            filterable: true,
            Cell: (toirog1) => {
                if (toirog1.row.original.rank < 49) {
                    return (<h5><Badge color="primary">#{ toirog1.row.original.rank }</Badge></h5>);
                } else {
                    return (<div className="ranking">#{toirog1.row.original.rank}</div>);
                }
            }
      },      
      {
        Header: "Нэр",
        accessor: "name",
        filterable: true,
        Cell: (toirog1) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
                {toirog1.row.original.image ? 
                    <div className="avatar-sm bg-light rounded p-1 me-2">
                        <img
                          src={toirog1.row.original.image}
                          alt=""
                          className="img-fluid d-block"
                        /> 
                    </div> 
                    :
                    <div className="avatar-sm bg-light rounded p-1 me-2">                       
                        <img src={dummyImg} alt="" className="img-fluid d-block" />
                    </div>                  
                }
              </div>
              <div className="flex-grow-1 ms-2 name">
                    {
                        toirog1.row.original.rank < 10 ? <h6 className="mb-0 fw-semibold lh-base flex-grow-1">{toirog1.row.original.name}</h6>
                        : toirog1.row.original.name
                    }      
              </div>
            </div>
          </>
        ),
      },
      {
        Header: "Намын харьяалал",
        accessor: "party",
        filterable: true,
        Cell: (toirog1) => (
          <>
            <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                        {
                            toirog1.row.original.party === "МАН"
                            ? <img src={MAN} alt="" className="avatar-xxs rounded-circle" />
                            : toirog1.row.original.party === "АН"
                            ? <img src={AN} alt="" className="avatar-xxs rounded-circle" />
                            : toirog1.row.original.party === "ХҮН"
                            ? <img src={XYN} alt="" className="avatar-xxs rounded-circle" />
                            : toirog1.row.original.party === "ҮЭ"
                            ? <img src={UE} alt="" className="avatar-xxs rounded-circle" />
                            : toirog1.row.original.party === "НН"
                            ? <img src={NN} alt="" className="avatar-xxs rounded-circle" />
                            : toirog1.row.original.party === "ҮЗ"
                            ? <img src={UZ} alt="" className="avatar-xxs rounded-circle" />
                            : toirog1.row.original.party === "ШН"
                            ? <img src={SHN} alt="" className="avatar-xxs rounded-circle" />
                            : toirog1.row.original.party === "ИОНН"
                            ? <img src={IONN} alt="" className="avatar-xxs rounded-circle" />
                            : <img src={dummyImg} alt="" className="avatar-xxs rounded-circle" />
                        }  
                </div>
                <div className="flex-grow-1 ms-2 name">
                      {
                            toirog1.row.original.party === "МАН"
                            ? "Монгол ардын нам"
                            : toirog1.row.original.party === "АН"
                            ? "Ардчилсан нам"
                            : toirog1.row.original.party === "ХҮН"
                            ? "Хүн нам"
                            : toirog1.row.original.party === "ҮЭ"
                            ? "Үндэсний эвсэл"
                            : toirog1.row.original.party === "НН"
                            ? "Ногоон нам"
                            : toirog1.row.original.party === "ҮЗ"
                            ? "Үнэн ба зөв нам"
                            : toirog1.row.original.party === "ШН"
                            ? "Шинэ нэгдсэн эвсэл нам"
                            : toirog1.row.original.party === "ИОНН"
                            ? "Иргэдийн оролцооны нэгдэл нам"
                            : "Бусад"
                      }             
                </div>
            </div>            
          </>
        ),
      },  
      {
        Header: "Үйлдэл",
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">              
              <li className="list-inline-item">
                <UncontrolledDropdown>
                  <DropdownToggle
                    href="#"
                    className="btn btn-soft-secondary btn-sm dropdown"
                    tag="button"
                  >
                    <i className="ri-more-fill align-middle"></i>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">                    
                    <DropdownItem
                        className="dropdown-item edit-item-btn"
                        href="#"
                        onClick={() => { const campaignData = cellProps.row.original; handleCampaignClick(campaignData); }}
                      >
                      <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>{" "}
                      Харах
                    </DropdownItem>                     
                    <DropdownItem className="dropdown-item">
                      <i className="ri-dashboard-fill align-bottom me-2 text-muted"></i>{" "}
                      
                      <Link to="/dashboard" state={{campaignId: cellProps.row.original._id, 
                                              campaignName: cellProps.row.original.name,
                                              campaignDesc: cellProps.row.original.desc,
                                              campaignImage: cellProps.row.original.image,
                                              campaignStatus: cellProps.row.original.status,
                                              campaignValidFrom: cellProps.row.original.validFrom,
                                              campaignValidTo: cellProps.row.original.validTo,
                                              campaignDataAnalyze: cellProps.row.original.dataAnalyze
                                            }}> 
                          Хянах самбар
                      </Link>
                    </DropdownItem> 
                  </DropdownMenu>
                </UncontrolledDropdown>
              </li>
            </ul>
          );
        },
      },
    ],
    [handleCampaignClick, checkedAll]
  );

  const tagsOptions = [
    { label: "Улс төр", value: "Улс төр" },
    { label: "Боловсрол", value: "Боловсрол" },
    { label: "Мэдээллийн технологи", value: "Мэдээллийн технологи" },
    { label: "Тусгай байгууллага", value: "Тусгай байгууллага" },
    { label: "Шинжлэх ухаан", value: "Шинжлэх ухаан" },
    { label: "Хэвлэл мэдээлэл", value: "Хэвлэл мэдээлэл" },
    { label: "Худалдаа", value: "Худалдаа" }
  ];

  function handlestag(keywords) {  
    setKeywords(keywords);
    setAssignTag(keywords);
  }

  const priorityOptions = [
    {
      options: [
        { label: "Маш чухал", value: "Urgent" },
        { label: "Чухал", value: "High" },
        { label: "Дунд", value: "Medium" },
        { label: "Бага", value: "Low" }
      ],
    },
  ];

  const statusOptions = [
    {
      options: [
        { label: "Идэвхтэй", value: "Active" },
        { label: "Идэвхгүй", value: "Suspend" },
        { label: "Хүлээгдэж буй", value: "Pending" },
      ],
    },
  ];

  const speedOptions = [
    {
      options: [
        { label: "15 минут", value: "15mins" },
        { label: "30 минут", value: "30mins" },
        { label: "1 цаг", value: "1hour" },
        { label: "2 цаг", value: "2hours" },
        { label: "3 цаг", value: "3hours" },
        { label: "6 цаг", value: "6hours" },
        { label: "12 цаг", value: "12hours" },
        { label: "24 цаг", value: "24hours" },
      ],
    },
  ];

  const postponeOptions = [
    {
      options: [
        { label: "Тийм", value: "Yes" },
        { label: "Үгүй", value: "No" },
        { label: "Мэдэгдэх", value: "Warn" },
      ],
    },
  ];

  // SideBar Campaign Detail
  const [info, setInfo] = useState([]);

  // Export Modal
  const [isExportCSV, setIsExportCSV] = useState(false);

  document.title = "Сонгууль 2024 мэдээлэл | IKHNUDEN - Media Monitoring System";
  return (
    <React.Fragment>
      <div className="page-content">
                <Container fluid>
                    <div className="profile-foreground position-relative mx-n4 mt-n4">
                        <div className="profile-wid-bg">
                            <img src={profileBg} alt="" className="profile-wid-img" />
                        </div>
                    </div>
                    <div className="pt-4 mb-4 mb-lg-3 pb-lg-4">
                        <Row className="g-4">
                            <Col>
                                <div className="p-2">
                                    <h3 className="text-white mb-1">МОНГОЛ УЛСЫН ИХ ХУРЛЫН 2024 ОНЫ ЭЭЛЖИТ СОНГУУЛЬ</h3>
                                    <p className="text-white-75"></p>
                                    <div className="hstack text-white-50 gap-1">
                                        <div><i
                                            className="ri-flag-line me-1 text-white-75 fs-16 align-middle"></i>13 тойрог
                                        </div>
                                        <div className="me-2"><i
                                            className="ri-map-pin-user-line me-1 text-white-75 fs-16 align-middle"></i>Тойрогт 78 нэр дэвшигч</div>
                                        <div className="me-2"><i
                                            className="ri-file-user-line me-1 text-white-75 fs-16 align-middle"></i>Жагсаалтад 48 нэр дэвшигч</div>                                        
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <Row>
                        <Col lg={12}>
                            <div>
                                <div className="d-flex">
                                    <Nav pills className="animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1"
                                        role="tablist">
                                        <NavItem>
                                            <NavLink
                                                href="#overview-tab"
                                                className={classnames({ active: activeTab === '1' }, "fs-14")}
                                                onClick={() => { toggleTab('1'); }}
                                                disabled
                                            >
                                                <i className="ri-airplay-fill d-inline-block d-md-none"></i> <span
                                                    className="d-none d-md-inline-block">Прогноз</span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                href="#activities"
                                                className={classnames({ active: activeTab === '2' }, "fs-14")}
                                                onClick={() => { toggleTab('2'); }}
                                            >
                                                <i className="ri-list-unordered d-inline-block d-md-none"></i> <span
                                                    className="d-none d-md-inline-block">Тойргууд</span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                href="#projects"
                                                className={classnames({ active: activeTab === '3' }, "fs-14")}
                                                onClick={() => { toggleTab('3'); }}
                                            >
                                                <i className="ri-price-tag-line d-inline-block d-md-none"></i> <span
                                                    className="d-none d-md-inline-block">Жагсаалт</span>
                                            </NavLink>
                                        </NavItem>                                        
                                    </Nav>
                                    <div className="flex-shrink-0">
                                          <button
                                                      className="btn btn-info add-btn"
                                                      onClick={() => {
                                                        setModal(true);
                                                      }}
                                                      disabled={JSON.parse(localStorage.getItem("authUser")).limit - campaigns.length > 0 ? false : true}
                                                    >
                                                      <i className="ri-add-fill me-1 align-bottom"></i> Шинээр нэр дэвшигч нэмэх
                                          </button>
                                    </div>
                                </div>

                                <TabContent activeTab={activeTab} className="pt-4">
                                    <TabPane tabId="1">
                                        <Row>
                                            <Col xxl={3}>
                                                <Card>
                                                    <CardBody>
                                                        <h5 className="card-title mb-5">Complete Your Profile1</h5>
                                                        <Progress value={30} color="danger" className="animated-progess custom-progress progress-label" ><div className="label">30%</div> </Progress>
                                                    </CardBody>
                                                </Card>

                                                <Card>
                                                    <CardBody>
                                                        <h5 className="card-title mb-3">Info</h5>
                                                        <div className="table-responsive">
                                                            <Table className="table-borderless mb-0">
                                                                <tbody>
                                                                    <tr>
                                                                        <th className="ps-0" scope="row">Full Name :</th>
                                                                        <td className="text-muted">Anna Adame</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th className="ps-0" scope="row">Mobile :</th>
                                                                        <td className="text-muted">+(1) 987 6543</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th className="ps-0" scope="row">E-mail :</th>
                                                                        <td className="text-muted">daveadame@velzon.com</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th className="ps-0" scope="row">Location :</th>
                                                                        <td className="text-muted">California, United States
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th className="ps-0" scope="row">Joining Date</th>
                                                                        <td className="text-muted">24 Nov 2021</td>
                                                                    </tr>
                                                                </tbody>
                                                            </Table>
                                                        </div>
                                                    </CardBody>
                                                </Card>

                                                <Card>
                                                    <CardBody>
                                                        <h5 className="card-title mb-4">Portfolio</h5>
                                                        <div className="d-flex flex-wrap gap-2">
                                                            <div>
                                                                <Link to="#" className="avatar-xs d-block">
                                                                    <span
                                                                        className="avatar-title rounded-circle fs-16 bg-dark text-light">
                                                                        <i className="ri-github-fill"></i>
                                                                    </span>
                                                                </Link>
                                                            </div>
                                                            <div>
                                                                <Link to="#" className="avatar-xs d-block">
                                                                    <span
                                                                        className="avatar-title rounded-circle fs-16 bg-primary">
                                                                        <i className="ri-global-fill"></i>
                                                                    </span>
                                                                </Link>
                                                            </div>
                                                            <div>
                                                                <Link to="#" className="avatar-xs d-block">
                                                                    <span
                                                                        className="avatar-title rounded-circle fs-16 bg-success">
                                                                        <i className="ri-dribbble-fill"></i>
                                                                    </span>
                                                                </Link>
                                                            </div>
                                                            <div>
                                                                <Link to="#" className="avatar-xs d-block">
                                                                    <span
                                                                        className="avatar-title rounded-circle fs-16 bg-danger">
                                                                        <i className="ri-pinterest-fill"></i>
                                                                    </span>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </CardBody>
                                                </Card>

                                                <Card>
                                                    <CardBody>
                                                        <h5 className="card-title mb-4">Skills</h5>
                                                        <div className="d-flex flex-wrap gap-2 fs-15">
                                                            <Link to="#"
                                                                className="badge badge-soft-primary">Photoshop</Link>
                                                            <Link to="#"
                                                                className="badge badge-soft-primary">illustrator</Link>
                                                            <Link to="#"
                                                                className="badge badge-soft-primary">HTML</Link>
                                                            <Link to="#"
                                                                className="badge badge-soft-primary">CSS</Link>
                                                            <Link to="#"
                                                                className="badge badge-soft-primary">Javascript</Link>
                                                            <Link to="#"
                                                                className="badge badge-soft-primary">Php</Link>
                                                            <Link to="#"
                                                                className="badge badge-soft-primary">Python</Link>
                                                        </div>
                                                    </CardBody>
                                                </Card>

                                                <Card>
                                                    <CardBody>
                                                        <div className="d-flex align-items-center mb-4">
                                                            <div className="flex-grow-1">
                                                                <h5 className="card-title mb-0">Suggestions</h5>
                                                            </div>
                                                            <div className="flex-shrink-0">

                                                                <UncontrolledDropdown direction='start'>
                                                                    <DropdownToggle tag="a" id="dropdownMenuLink2" role="button">
                                                                        <i className="ri-more-2-fill fs-14"></i>
                                                                    </DropdownToggle>
                                                                    <DropdownMenu>
                                                                        <DropdownItem>View</DropdownItem>
                                                                        <DropdownItem>Edit</DropdownItem>
                                                                        <DropdownItem>Delete</DropdownItem>
                                                                    </DropdownMenu>
                                                                </UncontrolledDropdown>

                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className="d-flex align-items-center py-3">
                                                                <div className="avatar-xs flex-shrink-0 me-3">
                                                                    <img src="{avatar3}" alt=""
                                                                        className="img-fluid rounded-circle" />
                                                                </div>
                                                                <div className="flex-grow-1">
                                                                    <div>
                                                                        <h5 className="fs-14 mb-1">Esther James</h5>
                                                                        <p className="fs-13 text-muted mb-0">Frontend
                                                                            Developer</p>
                                                                    </div>
                                                                </div>
                                                                <div className="flex-shrink-0 ms-2">
                                                                    <button type="button"
                                                                        className="btn btn-sm btn-outline-success shadow-none"><i
                                                                            className="ri-user-add-line align-middle"></i></button>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex align-items-center py-3">
                                                                <div className="avatar-xs flex-shrink-0 me-3">
                                                                    <img src="{avatar4}" alt=""
                                                                        className="img-fluid rounded-circle" />
                                                                </div>
                                                                <div className="flex-grow-1">
                                                                    <div>
                                                                        <h5 className="fs-14 mb-1">Jacqueline Steve</h5>
                                                                        <p className="fs-13 text-muted mb-0">UI/UX Designer
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="flex-shrink-0 ms-2">
                                                                    <button type="button"
                                                                        className="btn btn-sm btn-outline-success shadow-none"><i
                                                                            className="ri-user-add-line align-middle"></i></button>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex align-items-center py-3">
                                                                <div className="avatar-xs flex-shrink-0 me-3">
                                                                    <img src="{avatar5}" alt=""
                                                                        className="img-fluid rounded-circle" />
                                                                </div>
                                                                <div className="flex-grow-1">
                                                                    <div>
                                                                        <h5 className="fs-14 mb-1">George Whalen</h5>
                                                                        <p className="fs-13 text-muted mb-0">Backend
                                                                            Developer</p>
                                                                    </div>
                                                                </div>
                                                                <div className="flex-shrink-0 ms-2">
                                                                    <button type="button"
                                                                        className="btn btn-sm btn-outline-success shadow-none"><i
                                                                            className="ri-user-add-line align-middle"></i></button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </CardBody>
                                                </Card>


                                                <Card>
                                                    <CardBody>
                                                        <div className="d-flex align-items-center mb-4">
                                                            <div className="flex-grow-1">
                                                                <h5 className="card-title mb-0">Popular Posts</h5>
                                                            </div>
                                                            <div className="flex-shrink-0">
                                                                <UncontrolledDropdown direction='start'>
                                                                    <DropdownToggle tag="a" id="dropdownMenuLink1" role="button">
                                                                        <i className="ri-more-2-fill fs-14"></i>
                                                                    </DropdownToggle>
                                                                    <DropdownMenu>
                                                                        <DropdownItem>View</DropdownItem>
                                                                        <DropdownItem>Edit</DropdownItem>
                                                                        <DropdownItem>Delete</DropdownItem>
                                                                    </DropdownMenu>
                                                                </UncontrolledDropdown>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex mb-4">
                                                            <div className="flex-shrink-0">
                                                                <img src="{smallImage4}" alt=""
                                                                    height="50" className="rounded" />
                                                            </div>
                                                            <div className="flex-grow-1 ms-3 overflow-hidden">
                                                                <Link to="#">
                                                                    <h6 className="text-truncate fs-14">Design your apps in
                                                                        your own way</h6>
                                                                </Link>
                                                                <p className="text-muted mb-0">15 Dec 2021</p>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex mb-4">
                                                            <div className="flex-shrink-0">
                                                                <img src="{smallImage5}" alt=""
                                                                    height="50" className="rounded" />
                                                            </div>
                                                            <div className="flex-grow-1 ms-3 overflow-hidden">
                                                                <Link to="#">
                                                                    <h6 className="text-truncate fs-14">Smartest
                                                                        Applications for Business</h6>
                                                                </Link>
                                                                <p className="text-muted mb-0">28 Nov 2021</p>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex">
                                                            <div className="flex-shrink-0">
                                                                <img src="{smallImage6}" alt=""
                                                                    height="50" className="rounded" />
                                                            </div>
                                                            <div className="flex-grow-1 ms-3 overflow-hidden">
                                                                <Link to="#">
                                                                    <h6 className="text-truncate fs-14">How to get creative
                                                                        in your work</h6>
                                                                </Link>
                                                                <p className="text-muted mb-0">21 Nov 2021</p>
                                                            </div>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                            <Col xxl={9}>
                                                <Card>
                                                    <CardBody>
                                                        <h5 className="card-title mb-3">About</h5>
                                                        <p>Hi I'm Anna Adame, It will be as simple as Occidental; in
                                                            fact, it will be Occidental. To an English person, it will
                                                            seem like simplified English, as a skeptical Cambridge
                                                            friend of mine told me what Occidental is European languages
                                                            are members of the same family.</p>
                                                        <p>You always want to make sure that your fonts work well
                                                            together and try to limit the number of fonts you use to
                                                            three or less. Experiment and play around with the fonts
                                                            that you already have in the software you’re working with
                                                            reputable font websites. This may be the most commonly
                                                            encountered tip I received from the designers I spoke with.
                                                            They highly encourage that you use different fonts in one
                                                            design, but do not over-exaggerate and go overboard.</p>
                                                        <Row>
                                                            <Col xs={6} md={4}>
                                                                <div className="d-flex mt-4">
                                                                    <div
                                                                        className="flex-shrink-0 avatar-xs align-self-center me-3">
                                                                        <div
                                                                            className="avatar-title bg-light rounded-circle fs-16 text-primary">
                                                                            <i className="ri-user-2-fill"></i>
                                                                        </div>
                                                                    </div>
                                                                    <div className="flex-grow-1 overflow-hidden">
                                                                        <p className="mb-1">Designation :</p>
                                                                        <h6 className="text-truncate mb-0">Lead Designer /
                                                                            Developer</h6>
                                                                    </div>
                                                                </div>
                                                            </Col>

                                                            <Col xs={6} md={4}>
                                                                <div className="d-flex mt-4">
                                                                    <div
                                                                        className="flex-shrink-0 avatar-xs align-self-center me-3">
                                                                        <div
                                                                            className="avatar-title bg-light rounded-circle fs-16 text-primary">
                                                                            <i className="ri-global-line"></i>
                                                                        </div>
                                                                    </div>
                                                                    <div className="flex-grow-1 overflow-hidden">
                                                                        <p className="mb-1">Website :</p>
                                                                        <Link to="#" className="fw-semibold">www.velzon.com</Link>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>

                                                <Row>
                                                    <Col lg={12}>
                                                        <Card>
                                                            <CardHeader className="align-items-center d-flex">
                                                                <h4 className="card-title mb-0  me-2">Recent Activity</h4>
                                                                <div className="flex-shrink-0 ms-auto">
                                                                    <Nav className="justify-content-end nav-tabs-custom rounded card-header-tabs border-bottom-0"
                                                                        role="tablist">
                                                                        <NavItem>
                                                                            <NavLink
                                                                                href="#"
                                                                                className={classnames({ active: activityTab === '1' })}
                                                                                onClick={() => { toggleActivityTab('1'); }}
                                                                            >
                                                                                Today
                                                                            </NavLink>
                                                                        </NavItem>
                                                                        <NavItem>
                                                                            <NavLink
                                                                                href="#"
                                                                                className={classnames({ active: activityTab === '2' })}
                                                                                onClick={() => { toggleActivityTab('2'); }}
                                                                            >
                                                                                Weekly
                                                                            </NavLink>
                                                                        </NavItem>
                                                                        <NavItem className="nav-item">
                                                                            <NavLink
                                                                                href="#"
                                                                                className={classnames({ active: activityTab === '3' })}
                                                                                onClick={() => { toggleActivityTab('3'); }}
                                                                            >
                                                                                Monthly
                                                                            </NavLink>
                                                                        </NavItem>
                                                                    </Nav>
                                                                </div>
                                                            </CardHeader>
                                                            <CardBody>
                                                                <TabContent activeTab={activityTab} className="text-muted">
                                                                    <TabPane tabId="1">
                                                                        <div className="profile-timeline">
                                                                            <div>
                                                                            </div>
                                                                            <div className="accordion accordion-flush" id="todayExample">
                                                                                <div className="accordion-item border-0">
                                                                                    <div className="accordion-header">
                                                                                        <button className="accordion-button p-2 shadow-none" type="button" id="headingOne" >
                                                                                            <div className="d-flex">
                                                                                                <div className="flex-shrink-0">
                                                                                                    <img src="{avatar2}" alt="" className="avatar-xs rounded-circle shadow" />
                                                                                                </div>
                                                                                                <div
                                                                                                    className="flex-grow-1 ms-3">
                                                                                                    <h6
                                                                                                        className="fs-14 mb-1">
                                                                                                        Jacqueline Steve
                                                                                                    </h6>
                                                                                                    <small className="text-muted">We has changed 2 attributes on 05:16PM</small>
                                                                                                </div>
                                                                                            </div>
                                                                                        </button>
                                                                                    </div>
                                                                                    <UncontrolledCollapse className="accordion-collapse" toggler="#headingOne" defaultOpen>
                                                                                        <div
                                                                                            className="accordion-body ms-2 ps-5">
                                                                                            In an awareness campaign, it
                                                                                            is vital for people to begin
                                                                                            put 2 and 2 together and
                                                                                            begin to recognize your
                                                                                            cause. Too much or too
                                                                                            little spacing, as in the
                                                                                            example below, can make
                                                                                            things unpleasant for the
                                                                                            reader. The goal is to make
                                                                                            your text as comfortable to
                                                                                            read as possible. A
                                                                                            wonderful serenity has taken
                                                                                            possession of my entire
                                                                                            soul, like these sweet
                                                                                            mornings of spring which I
                                                                                            enjoy with my whole heart.
                                                                                        </div>
                                                                                    </UncontrolledCollapse>
                                                                                </div>
                                                                                <div className="accordion-item border-0">
                                                                                    <div className="accordion-header" id="headingTwo">
                                                                                        <Link to="#" className="accordion-button p-2 shadow-none" id="collapseTwo">
                                                                                            <div className="d-flex">
                                                                                                <div
                                                                                                    className="flex-shrink-0 avatar-xs">
                                                                                                    <div
                                                                                                        className="avatar-title bg-light text-success rounded-circle shadow">
                                                                                                        M
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="flex-grow-1 ms-3">
                                                                                                    <h6
                                                                                                        className="fs-14 mb-1">
                                                                                                        Megan Elmore
                                                                                                    </h6>
                                                                                                    <small
                                                                                                        className="text-muted">Adding
                                                                                                        a new event with
                                                                                                        attachments -
                                                                                                        04:45PM</small>
                                                                                                </div>
                                                                                            </div>
                                                                                        </Link>
                                                                                    </div>
                                                                                    <UncontrolledCollapse toggler="collapseTwo" defaultOpen>
                                                                                        <div
                                                                                            className="accordion-body ms-2 ps-5">
                                                                                            <Row className="g-2">
                                                                                                <div className="col-auto">
                                                                                                    <div
                                                                                                        className="d-flex border border-dashed p-2 rounded position-relative shadow">
                                                                                                        <div
                                                                                                            className="flex-shrink-0">
                                                                                                            <i
                                                                                                                className="ri-image-2-line fs-17 text-danger"></i>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            className="flex-grow-1 ms-2">
                                                                                                            <h6><Link to="#"
                                                                                                                className="stretched-link">Business
                                                                                                                Template
                                                                                                                -
                                                                                                                UI/UX
                                                                                                                design</Link>
                                                                                                            </h6>
                                                                                                            <small>685
                                                                                                                KB</small>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-auto">
                                                                                                    <div
                                                                                                        className="d-flex border border-dashed p-2 rounded position-relative shadow">
                                                                                                        <div
                                                                                                            className="flex-shrink-0">
                                                                                                            <i
                                                                                                                className="ri-file-zip-line fs-17 text-info"></i>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            className="flex-grow-1 ms-2">
                                                                                                            <h6><Link to="#"
                                                                                                                className="stretched-link">Bank
                                                                                                                Management
                                                                                                                System
                                                                                                                -
                                                                                                                PSD</Link>
                                                                                                            </h6>
                                                                                                            <small>8.78
                                                                                                                MB</small>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </Row>
                                                                                        </div>
                                                                                    </UncontrolledCollapse>
                                                                                </div>
                                                                                <div className="accordion-item border-0">
                                                                                    <div className="accordion-header"
                                                                                        id="headingThree">
                                                                                        <Link to="#" className="accordion-button p-2 shadow-none">
                                                                                            <div className="d-flex">
                                                                                                <div
                                                                                                    className="flex-shrink-0">
                                                                                                    <img src="{avatar5}"
                                                                                                        alt=""
                                                                                                        className="avatar-xs rounded-circle" />
                                                                                                </div>
                                                                                                <div
                                                                                                    className="flex-grow-1 ms-3">
                                                                                                    <h6
                                                                                                        className="fs-14 mb-1">
                                                                                                        New ticket
                                                                                                        received</h6>
                                                                                                    <small
                                                                                                        className="text-muted mb-2">User
                                                                                                        <span
                                                                                                            className="text-secondary">Erica245</span>
                                                                                                        submitted a
                                                                                                        ticket -
                                                                                                        02:33PM</small>
                                                                                                </div>
                                                                                            </div>
                                                                                        </Link>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="accordion-item border-0">
                                                                                    <div className="accordion-header"
                                                                                        id="headingFour">
                                                                                        <Link to="#" className="accordion-button p-2 shadow-none" id="collapseFour" >
                                                                                            <div className="d-flex">
                                                                                                <div
                                                                                                    className="flex-shrink-0 avatar-xs">
                                                                                                    <div
                                                                                                        className="avatar-title bg-light text-muted rounded-circle shadow">
                                                                                                        <i
                                                                                                            className="ri-user-3-fill"></i>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="flex-grow-1 ms-3">
                                                                                                    <h6
                                                                                                        className="fs-14 mb-1">
                                                                                                        Nancy Martino
                                                                                                    </h6>
                                                                                                    <small
                                                                                                        className="text-muted">Commented
                                                                                                        on
                                                                                                        12:57PM</small>
                                                                                                </div>
                                                                                            </div>
                                                                                        </Link>
                                                                                    </div>
                                                                                    <UncontrolledCollapse toggler="collapseFour" defaultOpen>
                                                                                        <div
                                                                                            className="accordion-body ms-2 ps-5">
                                                                                            " A wonderful serenity has
                                                                                            taken possession of my
                                                                                            entire soul, like these
                                                                                            sweet mornings of spring
                                                                                            which I enjoy with my whole
                                                                                            heart. Each design is a new,
                                                                                            unique piece of art birthed
                                                                                            into this world, and while
                                                                                            you have the opportunity to
                                                                                            be creative and make your
                                                                                            own style choices. "
                                                                                        </div>
                                                                                    </UncontrolledCollapse>
                                                                                </div>
                                                                                <div className="accordion-item border-0">
                                                                                    <div className="accordion-header"
                                                                                        id="headingFive">
                                                                                        <Link to="#" className="accordion-button p-2 shadow-none" id="collapseFive" >
                                                                                            <div className="d-flex">
                                                                                                <div
                                                                                                    className="flex-shrink-0">
                                                                                                    <img src="{avatar7}"
                                                                                                        alt=""
                                                                                                        className="avatar-xs rounded-circle" />
                                                                                                </div>
                                                                                                <div
                                                                                                    className="flex-grow-1 ms-3">
                                                                                                    <h6
                                                                                                        className="fs-14 mb-1">
                                                                                                        Lewis Arnold
                                                                                                    </h6>
                                                                                                    <small
                                                                                                        className="text-muted">Create
                                                                                                        new project
                                                                                                        buildng product
                                                                                                        -
                                                                                                        10:05AM</small>
                                                                                                </div>
                                                                                            </div>
                                                                                        </Link>
                                                                                    </div>
                                                                                    <UncontrolledCollapse toggler="collapseFive" defaultOpen>
                                                                                        <div
                                                                                            className="accordion-body ms-2 ps-5">
                                                                                            <p className="text-muted mb-2">
                                                                                                Every team project can
                                                                                                have a velzon. Use the
                                                                                                velzon to share
                                                                                                information with your
                                                                                                team to understand and
                                                                                                contribute to your
                                                                                                project.</p>
                                                                                            <div className="avatar-group">
                                                                                                <Link to="#"
                                                                                                    className="avatar-group-item shadow"
                                                                                                    data-bs-toggle="tooltip"
                                                                                                    data-bs-trigger="hover"
                                                                                                    data-bs-placement="top"
                                                                                                    title=""
                                                                                                    data-bs-original-title="Christi">
                                                                                                    <img src="{avatar4}"
                                                                                                        alt=""
                                                                                                        className="rounded-circle avatar-xs" />
                                                                                                </Link>
                                                                                                <Link to="#"
                                                                                                    className="avatar-group-item shadow"
                                                                                                    data-bs-toggle="tooltip"
                                                                                                    data-bs-trigger="hover"
                                                                                                    data-bs-placement="top"
                                                                                                    title=""
                                                                                                    data-bs-original-title="Frank Hook">
                                                                                                    <img src="{avatar3}"
                                                                                                        alt=""
                                                                                                        className="rounded-circle avatar-xs" />
                                                                                                </Link>
                                                                                                <Link to="#"
                                                                                                    className="avatar-group-item shadow"
                                                                                                    data-bs-toggle="tooltip"
                                                                                                    data-bs-trigger="hover"
                                                                                                    data-bs-placement="top"
                                                                                                    title=""
                                                                                                    data-bs-original-title=" Ruby">
                                                                                                    <div
                                                                                                        className="avatar-xs">
                                                                                                        <div
                                                                                                            className="avatar-title rounded-circle bg-light text-primary">
                                                                                                            R
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </Link>
                                                                                                <Link to="#"
                                                                                                    className="avatar-group-item shadow"
                                                                                                    data-bs-toggle="tooltip"
                                                                                                    data-bs-trigger="hover"
                                                                                                    data-bs-placement="top"
                                                                                                    title=""
                                                                                                    data-bs-original-title="more">
                                                                                                    <div
                                                                                                        className="avatar-xs">
                                                                                                        <div
                                                                                                            className="avatar-title rounded-circle">
                                                                                                            2+
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </Link>
                                                                                            </div>
                                                                                        </div>
                                                                                    </UncontrolledCollapse>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </TabPane>
                                                                    <TabPane tabId="2">
                                                                        <div className="profile-timeline">
                                                                            <div className="accordion accordion-flush"
                                                                                id="weeklyExample">
                                                                                <div className="accordion-item border-0">
                                                                                    <div className="accordion-header"
                                                                                        id="heading6">
                                                                                        <Link to="#" className="accordion-button p-2 shadow-none" id="collapse6">
                                                                                            <div className="d-flex">
                                                                                                <div
                                                                                                    className="flex-shrink-0">
                                                                                                    <img src="{avatar3}"
                                                                                                        alt=""
                                                                                                        className="avatar-xs rounded-circle" />
                                                                                                </div>
                                                                                                <div
                                                                                                    className="flex-grow-1 ms-3">
                                                                                                    <h6
                                                                                                        className="fs-14 mb-1">
                                                                                                        Joseph Parker
                                                                                                    </h6>
                                                                                                    <small
                                                                                                        className="text-muted">New
                                                                                                        people joined
                                                                                                        with our company
                                                                                                        -
                                                                                                        Yesterday</small>
                                                                                                </div>
                                                                                            </div>
                                                                                        </Link>
                                                                                    </div>
                                                                                    <UncontrolledCollapse toggler="#collapse6" defaultOpen>
                                                                                        <div
                                                                                            className="accordion-body ms-2 ps-5">
                                                                                            It makes a statement, it’s
                                                                                            impressive graphic design.
                                                                                            Increase or decrease the
                                                                                            letter spacing depending on
                                                                                            the situation and try, try
                                                                                            again until it looks right,
                                                                                            and each letter has the
                                                                                            perfect spot of its own.
                                                                                        </div>
                                                                                    </UncontrolledCollapse>
                                                                                </div>
                                                                                <div className="accordion-item border-0">
                                                                                    <div className="accordion-header"
                                                                                        id="heading7">
                                                                                        <Link className="accordion-button p-2 shadow-none"
                                                                                            data-bs-toggle="collapse"
                                                                                            to="#collapse7"
                                                                                            aria-expanded="false">
                                                                                            <div className="d-flex">
                                                                                                <div className="avatar-xs">
                                                                                                    <div
                                                                                                        className="avatar-title rounded-circle bg-light text-danger">
                                                                                                        <i
                                                                                                            className="ri-shopping-bag-line"></i>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="flex-grow-1 ms-3">
                                                                                                    <h6
                                                                                                        className="fs-14 mb-1">
                                                                                                        Your order is
                                                                                                        placed <span
                                                                                                            className="badge bg-soft-success text-success align-middle">Completed</span>
                                                                                                    </h6>
                                                                                                    <small
                                                                                                        className="text-muted">These
                                                                                                        customers can
                                                                                                        rest assured
                                                                                                        their order has
                                                                                                        been placed - 1
                                                                                                        week Ago</small>
                                                                                                </div>
                                                                                            </div>
                                                                                        </Link>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="accordion-item border-0">
                                                                                    <div className="accordion-header"
                                                                                        id="heading8">
                                                                                        <Link to="#" className="accordion-button p-2 shadow-none"
                                                                                            id="collapse8" >
                                                                                            <div className="d-flex">
                                                                                                <div
                                                                                                    className="flex-shrink-0 avatar-xs">
                                                                                                    <div
                                                                                                        className="avatar-title bg-light text-success rounded-circle">
                                                                                                        <i
                                                                                                            className="ri-home-3-line"></i>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="flex-grow-1 ms-3">
                                                                                                    <h6
                                                                                                        className="fs-14 mb-1">
                                                                                                        Velzon admin
                                                                                                        dashboard
                                                                                                        templates layout
                                                                                                        upload</h6>
                                                                                                    <small
                                                                                                        className="text-muted">We
                                                                                                        talked about a
                                                                                                        project on
                                                                                                        linkedin - 1
                                                                                                        week Ago</small>
                                                                                                </div>
                                                                                            </div>
                                                                                        </Link>
                                                                                    </div>
                                                                                    <UncontrolledCollapse toggler="#collapse8" defaultOpen>
                                                                                        <div
                                                                                            className="accordion-body ms-2 ps-5 fst-italic">
                                                                                            Powerful, clean & modern
                                                                                            responsive bootstrap 5 admin
                                                                                            template. The maximum file
                                                                                            size for uploads in this
                                                                                            demo :
                                                                                            <Row className="mt-2">
                                                                                                <Col xxl={6}>
                                                                                                    <Row
                                                                                                        className="border border-dashed gx-2 p-2">
                                                                                                        <Col xs={3}>
                                                                                                            <img src="{smallImage3}" alt="" className="img-fluid rounded" />
                                                                                                        </Col>

                                                                                                        <Col xs={3}>
                                                                                                            <img src="{smallImage5}" alt="" className="img-fluid rounded" />
                                                                                                        </Col>

                                                                                                        <Col xs={3}>
                                                                                                            <img src="{smallImage7}" alt="" className="img-fluid rounded" />
                                                                                                        </Col>

                                                                                                        <Col xs={3}>
                                                                                                            <img src="{smallImage9}" alt="" className="img-fluid rounded" />
                                                                                                        </Col>

                                                                                                    </Row>

                                                                                                </Col>
                                                                                            </Row>
                                                                                        </div>
                                                                                    </UncontrolledCollapse>
                                                                                </div>
                                                                                <div className="accordion-item border-0">
                                                                                    <div className="accordion-header"
                                                                                        id="heading9">
                                                                                        <Link className="accordion-button p-2 shadow-none"
                                                                                            data-bs-toggle="collapse"
                                                                                            to="#collapse9"
                                                                                            aria-expanded="false">
                                                                                            <div className="d-flex">
                                                                                                <div
                                                                                                    className="flex-shrink-0">
                                                                                                    <img src="{avatar6}"
                                                                                                        alt=""
                                                                                                        className="avatar-xs rounded-circle" />
                                                                                                </div>
                                                                                                <div
                                                                                                    className="flex-grow-1 ms-3">
                                                                                                    <h6
                                                                                                        className="fs-14 mb-1">
                                                                                                        New ticket
                                                                                                        created <span
                                                                                                            className="badge bg-soft-info text-info align-middle">Inprogress</span>
                                                                                                    </h6>
                                                                                                    <small
                                                                                                        className="text-muted mb-2">User
                                                                                                        <span
                                                                                                            className="text-secondary">Jack365</span>
                                                                                                        submitted a
                                                                                                        ticket - 2 week
                                                                                                        Ago</small>
                                                                                                </div>
                                                                                            </div>
                                                                                        </Link>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="accordion-item border-0">
                                                                                    <div className="accordion-header"
                                                                                        id="heading10">
                                                                                        <Link to="#" className="accordion-button p-2 shadow-none" id="collapse10">
                                                                                            <div className="d-flex">
                                                                                                <div
                                                                                                    className="flex-shrink-0">
                                                                                                    <img src="{avatar5}" alt="" className="avatar-xs rounded-circle" />
                                                                                                </div>
                                                                                                <div
                                                                                                    className="flex-grow-1 ms-3">
                                                                                                    <h6
                                                                                                        className="fs-14 mb-1">
                                                                                                        Jennifer Carter
                                                                                                    </h6>
                                                                                                    <small
                                                                                                        className="text-muted">Commented
                                                                                                        - 4 week
                                                                                                        Ago</small>
                                                                                                </div>
                                                                                            </div>
                                                                                        </Link>
                                                                                    </div>
                                                                                    <UncontrolledCollapse toggler="#collapse10" defaultOpen>
                                                                                        <div
                                                                                            className="accordion-body ms-2 ps-5">
                                                                                            <p
                                                                                                className="text-muted fst-italic mb-2">
                                                                                                " This is an awesome
                                                                                                admin dashboard
                                                                                                template. It is
                                                                                                extremely well
                                                                                                structured and uses
                                                                                                state of the art
                                                                                                components (e.g. one of
                                                                                                the only templates using
                                                                                                boostrap 5.1.3 so far).
                                                                                                I integrated it into a
                                                                                                Rails 6 project. Needs
                                                                                                manual integration work
                                                                                                of course but the
                                                                                                template structure made
                                                                                                it easy. "</p>
                                                                                        </div>
                                                                                    </UncontrolledCollapse>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </TabPane>
                                                                    <TabPane tabId="3">
                                                                        <div className="profile-timeline">
                                                                            <div className="accordion accordion-flush"
                                                                                id="monthlyExample">
                                                                                <div className="accordion-item border-0">
                                                                                    <div className="accordion-header"
                                                                                        id="heading11">
                                                                                        <Link to="#" className="accordion-button p-2 shadow-none" id="collapse11" >
                                                                                            <div className="d-flex">
                                                                                                <div
                                                                                                    className="flex-shrink-0 avatar-xs">
                                                                                                    <div
                                                                                                        className="avatar-title bg-light text-success rounded-circle">
                                                                                                        M
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="flex-grow-1 ms-3">
                                                                                                    <h6
                                                                                                        className="fs-14 mb-1">
                                                                                                        Megan Elmore
                                                                                                    </h6>
                                                                                                    <small
                                                                                                        className="text-muted">Adding
                                                                                                        a new event with
                                                                                                        attachments - 1
                                                                                                        month
                                                                                                        Ago.</small>
                                                                                                </div>
                                                                                            </div>
                                                                                        </Link>
                                                                                    </div>
                                                                                    <UncontrolledCollapse toggler="#collapse11" defaultOpen>
                                                                                        <div
                                                                                            className="accordion-body ms-2 ps-5">
                                                                                            <div className="row g-2">
                                                                                                <div className="col-auto">
                                                                                                    <div
                                                                                                        className="d-flex border border-dashed p-2 rounded position-relative">
                                                                                                        <div
                                                                                                            className="flex-shrink-0">
                                                                                                            <i
                                                                                                                className="ri-image-2-line fs-17 text-danger"></i>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            className="flex-grow-1 ms-2">
                                                                                                            <h6><Link to="#"
                                                                                                                className="stretched-link">Business
                                                                                                                Template
                                                                                                                -
                                                                                                                UI/UX
                                                                                                                design</Link>
                                                                                                            </h6>
                                                                                                            <small>685
                                                                                                                KB</small>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-auto">
                                                                                                    <div
                                                                                                        className="d-flex border border-dashed p-2 rounded position-relative">
                                                                                                        <div
                                                                                                            className="flex-shrink-0">
                                                                                                            <i
                                                                                                                className="ri-file-zip-line fs-17 text-info"></i>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            className="flex-grow-1 ms-2">
                                                                                                            <h6><Link to="#"
                                                                                                                className="stretched-link">Bank
                                                                                                                Management
                                                                                                                System
                                                                                                                -
                                                                                                                PSD</Link>
                                                                                                            </h6>
                                                                                                            <small>8.78
                                                                                                                MB</small>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-auto">
                                                                                                    <div
                                                                                                        className="d-flex border border-dashed p-2 rounded position-relative">
                                                                                                        <div
                                                                                                            className="flex-shrink-0">
                                                                                                            <i
                                                                                                                className="ri-file-zip-line fs-17 text-info"></i>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            className="flex-grow-1 ms-2">
                                                                                                            <h6><Link to="#"
                                                                                                                className="stretched-link">Bank
                                                                                                                Management
                                                                                                                System
                                                                                                                -
                                                                                                                PSD</Link>
                                                                                                            </h6>
                                                                                                            <small>8.78
                                                                                                                MB</small>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </UncontrolledCollapse>
                                                                                </div>
                                                                                <div className="accordion-item border-0">
                                                                                    <div className="accordion-header"
                                                                                        id="heading12">
                                                                                        <Link to="#" className="accordion-button p-2 shadow-none"
                                                                                            id="collapse12"
                                                                                            aria-expanded="true">
                                                                                            <div className="d-flex">
                                                                                                <div
                                                                                                    className="flex-shrink-0">
                                                                                                    <img src="{avatar2}"
                                                                                                        alt=""
                                                                                                        className="avatar-xs rounded-circle" />
                                                                                                </div>
                                                                                                <div
                                                                                                    className="flex-grow-1 ms-3">
                                                                                                    <h6
                                                                                                        className="fs-14 mb-1">
                                                                                                        Jacqueline Steve
                                                                                                    </h6>
                                                                                                    <small
                                                                                                        className="text-muted">We
                                                                                                        has changed 2
                                                                                                        attributes on 3
                                                                                                        month
                                                                                                        Ago</small>
                                                                                                </div>
                                                                                            </div>
                                                                                        </Link>
                                                                                    </div>
                                                                                    <UncontrolledCollapse toggler="collapse12" defaultOpen>
                                                                                        <div
                                                                                            className="accordion-body ms-2 ps-5">
                                                                                            In an awareness campaign, it
                                                                                            is vital for people to begin
                                                                                            put 2 and 2 together and
                                                                                            begin to recognize your
                                                                                            cause. Too much or too
                                                                                            little spacing, as in the
                                                                                            example below, can make
                                                                                            things unpleasant for the
                                                                                            reader. The goal is to make
                                                                                            your text as comfortable to
                                                                                            read as possible. A
                                                                                            wonderful serenity has taken
                                                                                            possession of my entire
                                                                                            soul, like these sweet
                                                                                            mornings of spring which I
                                                                                            enjoy with my whole heart.
                                                                                        </div>
                                                                                    </UncontrolledCollapse>
                                                                                </div>
                                                                                <div className="accordion-item border-0">
                                                                                    <div className="accordion-header"
                                                                                        id="heading13">
                                                                                        <Link className="accordion-button p-2 shadow-none"
                                                                                            data-bs-toggle="collapse"
                                                                                            to="#collapse13"
                                                                                            aria-expanded="false">
                                                                                            <div className="d-flex">
                                                                                                <div
                                                                                                    className="flex-shrink-0">
                                                                                                    <img src="{avatar6}"
                                                                                                        alt=""
                                                                                                        className="avatar-xs rounded-circle" />
                                                                                                </div>
                                                                                                <div
                                                                                                    className="flex-grow-1 ms-3">
                                                                                                    <h6
                                                                                                        className="fs-14 mb-1">
                                                                                                        New ticket
                                                                                                        received</h6>
                                                                                                    <small
                                                                                                        className="text-muted mb-2">User
                                                                                                        <span
                                                                                                            className="text-secondary">Erica245</span>
                                                                                                        submitted a
                                                                                                        ticket - 5 month
                                                                                                        Ago</small>
                                                                                                </div>
                                                                                            </div>
                                                                                        </Link>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="accordion-item border-0">
                                                                                    <div className="accordion-header"
                                                                                        id="heading14">
                                                                                        <Link to="#" className="accordion-button p-2 shadow-none" id="collapse14">
                                                                                            <div className="d-flex">
                                                                                                <div
                                                                                                    className="flex-shrink-0 avatar-xs">
                                                                                                    <div
                                                                                                        className="avatar-title bg-light text-muted rounded-circle">
                                                                                                        <i
                                                                                                            className="ri-user-3-fill"></i>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="flex-grow-1 ms-3">
                                                                                                    <h6
                                                                                                        className="fs-14 mb-1">
                                                                                                        Nancy Martino
                                                                                                    </h6>
                                                                                                    <small
                                                                                                        className="text-muted">Commented
                                                                                                        on 24 Nov,
                                                                                                        2021.</small>
                                                                                                </div>
                                                                                            </div>
                                                                                        </Link>
                                                                                    </div>
                                                                                    <UncontrolledCollapse toggler="#collapse14" defaultOpen>
                                                                                        <div
                                                                                            className="accordion-body ms-2 ps-5 fst-italic">
                                                                                            " A wonderful serenity has
                                                                                            taken possession of my
                                                                                            entire soul, like these
                                                                                            sweet mornings of spring
                                                                                            which I enjoy with my whole
                                                                                            heart. Each design is a new,
                                                                                            unique piece of art birthed
                                                                                            into this world, and while
                                                                                            you have the opportunity to
                                                                                            be creative and make your
                                                                                            own style choices. "
                                                                                        </div>
                                                                                    </UncontrolledCollapse>
                                                                                </div>
                                                                                <div className="accordion-item border-0">
                                                                                    <div className="accordion-header"
                                                                                        id="heading15">
                                                                                        <Link to="#" className="accordion-button p-2 shadow-none" id="collapse15">
                                                                                            <div className="d-flex">
                                                                                                <div
                                                                                                    className="flex-shrink-0">
                                                                                                    <img src="{avatar7}"
                                                                                                        alt=""
                                                                                                        className="avatar-xs rounded-circle" />
                                                                                                </div>
                                                                                                <div
                                                                                                    className="flex-grow-1 ms-3">
                                                                                                    <h6
                                                                                                        className="fs-14 mb-1">
                                                                                                        Lewis Arnold
                                                                                                    </h6>
                                                                                                    <small
                                                                                                        className="text-muted">Create
                                                                                                        new project
                                                                                                        buildng product
                                                                                                        - 8 month
                                                                                                        Ago</small>
                                                                                                </div>
                                                                                            </div>
                                                                                        </Link>
                                                                                    </div>
                                                                                    <UncontrolledCollapse toggler="#collapse15" defaultOpen>
                                                                                        <div
                                                                                            className="accordion-body ms-2 ps-5">
                                                                                            <p className="text-muted mb-2">
                                                                                                Every team project can
                                                                                                have a velzon. Use the
                                                                                                velzon to share
                                                                                                information with your
                                                                                                team to understand and
                                                                                                contribute to your
                                                                                                project.</p>
                                                                                            <div className="avatar-group">
                                                                                                <Link to="#"
                                                                                                    className="avatar-group-item"
                                                                                                    data-bs-toggle="tooltip"
                                                                                                    data-bs-trigger="hover"
                                                                                                    data-bs-placement="top"
                                                                                                    title=""
                                                                                                    data-bs-original-title="Christi">
                                                                                                    <img src="{avatar4}"
                                                                                                        alt=""
                                                                                                        className="rounded-circle avatar-xs" />
                                                                                                </Link>
                                                                                                <Link to="#"
                                                                                                    className="avatar-group-item"
                                                                                                    data-bs-toggle="tooltip"
                                                                                                    data-bs-trigger="hover"
                                                                                                    data-bs-placement="top"
                                                                                                    title=""
                                                                                                    data-bs-original-title="Frank Hook">
                                                                                                    <img src="{avatar3}"
                                                                                                        alt=""
                                                                                                        className="rounded-circle avatar-xs" />
                                                                                                </Link>
                                                                                                <Link to="#"
                                                                                                    className="avatar-group-item"
                                                                                                    data-bs-toggle="tooltip"
                                                                                                    data-bs-trigger="hover"
                                                                                                    data-bs-placement="top"
                                                                                                    title=""
                                                                                                    data-bs-original-title=" Ruby">
                                                                                                    <div
                                                                                                        className="avatar-xs">
                                                                                                        <div
                                                                                                            className="avatar-title rounded-circle bg-light text-primary">
                                                                                                            R
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </Link>
                                                                                                <Link to="#"
                                                                                                    className="avatar-group-item"
                                                                                                    data-bs-toggle="tooltip"
                                                                                                    data-bs-trigger="hover"
                                                                                                    data-bs-placement="top"
                                                                                                    title=""
                                                                                                    data-bs-original-title="more">
                                                                                                    <div
                                                                                                        className="avatar-xs">
                                                                                                        <div
                                                                                                            className="avatar-title rounded-circle">
                                                                                                            2+
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </Link>
                                                                                            </div>
                                                                                        </div>
                                                                                    </UncontrolledCollapse>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </TabPane>
                                                                </TabContent>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>

                                                <Card>
                                                    <CardBody>
                                                        <h5 className="card-title">Projects</h5>
                                                        <div className="d-flex justify-content-end gap-2 mb-2">
                                                            <div className="slider-button-prev">
                                                                <div className="avatar-title fs-18 rounded px-1">
                                                                    <i className="ri-arrow-left-s-line"></i>
                                                                </div>
                                                            </div>
                                                            <div className="slider-button-next">
                                                                <div className="avatar-title fs-18 rounded px-1">
                                                                    <i className="ri-arrow-right-s-line"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <Swiper className="project-swiper"
                                                            slidesPerView={3}
                                                            spaceBetween={20}
                                                            autoplay={{ delay: 3000, disableOnInteraction: false }}
                                                            pagination={{ clickable: true }}
                                                        >

                                                            <div className="swiper-wrapper">
                                                                <SwiperSlide>
                                                                    <Card
                                                                        className="profile-project-card shadow-none profile-project-success mb-0">
                                                                        <CardBody className="p-4">
                                                                            <div className="d-flex">
                                                                                <div
                                                                                    className="flex-grow-1 text-muted overflow-hidden">
                                                                                    <h5
                                                                                        className="fs-14 text-truncate mb-1">
                                                                                        <Link to="#"
                                                                                            className="text-dark">ABC
                                                                                            Project Customization</Link>
                                                                                    </h5>
                                                                                    <p className="text-muted text-truncate mb-0">
                                                                                        Last Update : <span className="fw-semibold text-dark">4 hr Ago</span></p>
                                                                                </div>
                                                                                <div className="flex-shrink-0 ms-2">
                                                                                    <div className="badge badge-soft-warning fs-10">
                                                                                        Inprogress</div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex mt-4">
                                                                                <div className="flex-grow-1">
                                                                                    <div
                                                                                        className="d-flex align-items-center gap-2">
                                                                                        <div>
                                                                                            <h5 className="fs-12 text-muted mb-0">
                                                                                                Members :</h5>
                                                                                        </div>
                                                                                        <div className="avatar-group">
                                                                                            <div
                                                                                                className="avatar-group-item shadow">
                                                                                                <div className="avatar-xs">
                                                                                                    <img src="{avatar4}"
                                                                                                        alt=""
                                                                                                        className="rounded-circle img-fluid" />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                                className="avatar-group-item shadow">
                                                                                                <div className="avatar-xs">
                                                                                                    <img src="{avatar5}"
                                                                                                        alt=""
                                                                                                        className="rounded-circle img-fluid" />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                                className="avatar-group-item shadow">
                                                                                                <div className="avatar-xs">
                                                                                                    <div
                                                                                                        className="avatar-title rounded-circle bg-light text-primary">
                                                                                                        A
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                                className="avatar-group-item shadow">
                                                                                                <div className="avatar-xs">
                                                                                                    <img src="{avatar2}"
                                                                                                        alt=""
                                                                                                        className="rounded-circle img-fluid" />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </CardBody>
                                                                    </Card>
                                                                </SwiperSlide>

                                                                <SwiperSlide>
                                                                    <Card className="profile-project-card shadow-none profile-project-danger mb-0">
                                                                        <CardBody className="p-4">
                                                                            <div className="d-flex">
                                                                                <div
                                                                                    className="flex-grow-1 text-muted overflow-hidden">
                                                                                    <h5 className="fs-14 text-truncate mb-1">
                                                                                        <Link to="#" className="text-dark">Client - John</Link></h5>
                                                                                    <p className="text-muted text-truncate mb-0">
                                                                                        Last Update : <span
                                                                                            className="fw-semibold text-dark">1
                                                                                            hr Ago</span></p>
                                                                                </div>
                                                                                <div className="flex-shrink-0 ms-2">
                                                                                    <div
                                                                                        className="badge badge-soft-success fs-10">
                                                                                        Completed</div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex mt-4">
                                                                                <div className="flex-grow-1">
                                                                                    <div
                                                                                        className="d-flex align-items-center gap-2">
                                                                                        <div>
                                                                                            <h5
                                                                                                className="fs-12 text-muted mb-0">
                                                                                                Members :</h5>
                                                                                        </div>
                                                                                        <div className="avatar-group">
                                                                                            <div
                                                                                                className="avatar-group-item shadow">
                                                                                                <div className="avatar-xs">
                                                                                                    <img src="{avatar2}"
                                                                                                        alt=""
                                                                                                        className="rounded-circle img-fluid" />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                                className="avatar-group-item shadow">
                                                                                                <div className="avatar-xs">
                                                                                                    <div
                                                                                                        className="avatar-title rounded-circle bg-light text-primary">
                                                                                                        C
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </CardBody>
                                                                    </Card>
                                                                </SwiperSlide>
                                                                <SwiperSlide>
                                                                    <Card
                                                                        className="profile-project-card shadow-none profile-project-info mb-0">
                                                                        <CardBody className="p-4">
                                                                            <div className="d-flex">
                                                                                <div
                                                                                    className="flex-grow-1 text-muted overflow-hidden">
                                                                                    <h5
                                                                                        className="fs-14 text-truncate mb-1">
                                                                                        <Link to="#" className="text-dark">Brand logo Design</Link></h5>
                                                                                    <p className="text-muted text-truncate mb-0">
                                                                                        Last Update : <span
                                                                                            className="fw-semibold text-dark">2
                                                                                            hr Ago</span></p>
                                                                                </div>
                                                                                <div className="flex-shrink-0 ms-2">
                                                                                    <div
                                                                                        className="badge badge-soft-warning fs-10">
                                                                                        Inprogress</div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex mt-4">
                                                                                <div className="flex-grow-1">
                                                                                    <div
                                                                                        className="d-flex align-items-center gap-2">
                                                                                        <div>
                                                                                            <h5
                                                                                                className="fs-12 text-muted mb-0">
                                                                                                Members :</h5>
                                                                                        </div>
                                                                                        <div className="avatar-group">
                                                                                            <div
                                                                                                className="avatar-group-item shadow">
                                                                                                <div className="avatar-xs">
                                                                                                    <img src="{avatar5}"
                                                                                                        alt=""
                                                                                                        className="rounded-circle img-fluid" />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </CardBody>
                                                                    </Card>
                                                                </SwiperSlide>
                                                                <SwiperSlide>
                                                                    <Card
                                                                        className="profile-project-card shadow-none profile-project-danger mb-0">
                                                                        <CardBody className="p-4">
                                                                            <div className="d-flex">
                                                                                <div
                                                                                    className="flex-grow-1 text-muted overflow-hidden">
                                                                                    <h5
                                                                                        className="fs-14 text-truncate mb-1">
                                                                                        <Link to="#"
                                                                                            className="text-dark">Project
                                                                                            update</Link></h5>
                                                                                    <p
                                                                                        className="text-muted text-truncate mb-0">
                                                                                        Last Update : <span
                                                                                            className="fw-semibold text-dark">4
                                                                                            hr Ago</span></p>
                                                                                </div>
                                                                                <div className="flex-shrink-0 ms-2">
                                                                                    <div
                                                                                        className="badge badge-soft-success fs-10">
                                                                                        Completed</div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="d-flex mt-4">
                                                                                <div className="flex-grow-1">
                                                                                    <div
                                                                                        className="d-flex align-items-center gap-2">
                                                                                        <div>
                                                                                            <h5
                                                                                                className="fs-12 text-muted mb-0">
                                                                                                Members :</h5>
                                                                                        </div>
                                                                                        <div className="avatar-group">
                                                                                            <div
                                                                                                className="avatar-group-item shadow">
                                                                                                <div className="avatar-xs">
                                                                                                    <img src="{avatar4}"
                                                                                                        alt=""
                                                                                                        className="rounded-circle img-fluid" />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                                className="avatar-group-item shadow">
                                                                                                <div className="avatar-xs">
                                                                                                    <img src="{avatar5}"
                                                                                                        alt=""
                                                                                                        className="rounded-circle img-fluid" />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </CardBody>

                                                                    </Card>

                                                                </SwiperSlide>

                                                                <SwiperSlide>
                                                                    <Card className="profile-project-card shadow-none profile-project-warning mb-0">
                                                                        <CardBody className="p-4">
                                                                            <div className="d-flex">
                                                                                <div className="flex-grow-1 text-muted overflow-hidden">
                                                                                    <h5 className="fs-14 text-truncate mb-1">
                                                                                        <Link to="#" className="text-dark">Chat App</Link></h5>
                                                                                    <p
                                                                                        className="text-muted text-truncate mb-0">
                                                                                        Last Update : <span
                                                                                            className="fw-semibold text-dark">1
                                                                                            hr Ago</span></p>
                                                                                </div>
                                                                                <div className="flex-shrink-0 ms-2">
                                                                                    <div
                                                                                        className="badge badge-soft-warning fs-10">
                                                                                        Inprogress</div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="d-flex mt-4">
                                                                                <div className="flex-grow-1">
                                                                                    <div
                                                                                        className="d-flex align-items-center gap-2">
                                                                                        <div>
                                                                                            <h5
                                                                                                className="fs-12 text-muted mb-0">
                                                                                                Members :</h5>
                                                                                        </div>
                                                                                        <div className="avatar-group">
                                                                                            <div
                                                                                                className="avatar-group-item shadow">
                                                                                                <div className="avatar-xs">
                                                                                                    <img src="{avatar4}"
                                                                                                        alt=""
                                                                                                        className="rounded-circle img-fluid" />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                                className="avatar-group-item shadow">
                                                                                                <div className="avatar-xs">
                                                                                                    <img src="{avatar5}"
                                                                                                        alt=""
                                                                                                        className="rounded-circle img-fluid" />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                                className="avatar-group-item shadow">
                                                                                                <div className="avatar-xs">
                                                                                                    <div
                                                                                                        className="avatar-title rounded-circle bg-light text-primary">
                                                                                                        A
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </CardBody>
                                                                    </Card>
                                                                </SwiperSlide>
                                                            </div>
                                                        </Swiper>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <Row>
                                            <Row
                                                  className="row-cols-xxl-4 row-cols-xl-4 row-cols-lg-3 row-cols-md-2 row-cols-1"
                                              >
                                                <Col id="toirog1" className="list-element">
                                                    <Card id="toirog1" className="explore-box card-animate border ribbon-box border shadow-none left">                                                                                          
                                                          <CardBody>
                                                                  <div className="ribbon ribbon-primary ribbon-shape">ТОЙРОГ: I</div> 
                                                                  <h5 className="fs-14 text-end">МАНДАТ:9</h5>
                                                                  <div className="ribbon-content text-muted mt-4">
                                                                      <p className="mb-0">
                                                                          <h5><Badge color="info" pill>АРХАНГАЙ</Badge>{" "}
                                                                          <Badge color="info" pill>БАЯНХОНГОР</Badge>{" "}
                                                                          <Badge color="info" pill>ӨВӨРХАНГАЙ</Badge></h5>
                                                                      </p>
                                                                  </div>                                                                              
                                                              
                                                              {isCampaignSuccess && toirog1.length ? (
                                                              <TableContainer
                                                                  columns={columns1}
                                                                  data={(toirog1 || [])}
                                                                  isGlobalFilter={true}
                                                                  isAddUserList={false}                      
                                                                  customPageSize={12}
                                                                  className="custom-header-css"
                                                                  divClass="table-responsive table-card mb-3"
                                                                  tableClass="align-middle table-nowrap"
                                                                  theadClass="table-light"
                                                                  handleCampaignClick={handleCampaignClicks}
                                                                  isCampaignsFilter={true}
                                                                  SearchPlaceholder='Search for campaign...'                        
                                                                  />
                                                                  ) : (<Loader error={error} />)
                                                              }
                                                                  

                                                          <Modal id="showModal" isOpen={modal} toggle={toggle} centered> 
                                                              <ModalHeader className="bg-soft-info p-3" toggle={toggle}>
                                                              {!!isEdit ? "Нэр дэвшигчийн мэдээллийг засварлах" : "Шинээр нэр дэвшигч нэмэх"}
                                                              </ModalHeader>

                                                              <Form className="tablelist-form" onSubmit={(e) => {
                                                              e.preventDefault();
                                                              validation.handleSubmit();
                                                              return false;
                                                              }}>
                                                              <ModalBody>
                                                                  <Input type="hidden" id="id-field" />
                                                                  <Row className="g-3">
                                                                  <Col lg={12}>
                                                                      <div className="text-center">
                                                                      <div className="position-relative d-inline-block">
                                                                          <div className="position-absolute  bottom-0 end-0">
                                                                          <Label htmlFor="image" className="mb-0">
                                                                              <div className="avatar-xs cursor-pointer">
                                                                              <div className="avatar-title bg-light border rounded-circle text-muted">
                                                                                  <i className="ri-image-fill"></i>
                                                                              </div>
                                                                              </div>
                                                                          </Label>
                                                                          <Input className="form-control d-none" id="image" type="file"
                                                                              accept="image/png, image/gif, image/jpeg"
                                                                              onChange={(e) => handlePreview(e.target.files[0])}                                   
                                                                          />                                 
                                                                          </div>
                                                                      
                                                                          <div className="avatar-lg p-1">
                                                                          <div className="avatar-title bg-light rounded-circle">
                                                                              <img src={image || dummyImg} alt="" id="image" className="avatar-md rounded-circle object-cover" />                                    
                                                                          </div>
                                                                          </div>                                
                                                                      </div> 
                                                                                                  
                                                                      </div>
                                                                  </Col>
                                                                  <Col lg={12}>
                                                                      <div className="text-center">
                                                                          <button type="button" className="btn btn-light" onClick={handleUpload} > Зураг илгээх </button>    
                                                                      </div>      
                                                                  </Col>
                                                                  <Col lg={12}>
                                                                      <div className="text-center">
                                                                          {
                                                                              uploaded === "yes" ?
                                                                              (
                                                                                  <UncontrolledAlert color="success">
                                                                                      Судалгааны зураг <strong>амжилттай</strong> илгээгдлээ!
                                                                                  </UncontrolledAlert>
                                                                              ) : uploaded === "no" ? (
                                                                                  <UncontrolledAlert color="danger" className="mb-xl-0">
                                                                                      Судалгааны зураг илгээхэд <strong>алдаа</strong> гарлаа!
                                                                                  </UncontrolledAlert>
                                                                              ) : null
                                                                          }     
                                                                      </div> 
                                                                  </Col>
                                                                  <Col lg={12}>
                                                                  <div>
                                                                      <Label
                                                                          htmlFor="name"
                                                                          className="form-label"
                                                                      >
                                                                          Судалгааны нэр{" "}
                                                                          <span className="text-danger">*</span>
                                                                      </Label>
                                                                      <Input
                                                                          name="name"
                                                                          id="customername-field"
                                                                          className="form-control"
                                                                          placeholder="Судалгааны нэрийг оруулах" 
                                                                          type="text"
                                                                          validate={{
                                                                          required: { value: true },
                                                                          }}
                                                                          onChange={validation.handleChange}
                                                                          onBlur={validation.handleBlur}
                                                                          value={validation.values.name || ""}
                                                                          invalid={
                                                                          validation.touched.name && validation.errors.name ? true : false
                                                                          }
                                                                      />
                                                                      {validation.touched.name && validation.errors.name ? (
                                                                          <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                                                      ) : null}

                                                                      </div>
                                                                  </Col>
                                                                  <Col lg={12}>
                                                                      <div>
                                                                      <Label
                                                                          htmlFor="keywords"
                                                                          className="form-label"
                                                                      >
                                                                          Судалгааны түлхүүр үгс{" "}
                                                                          <span className="text-danger">*</span>
                                                                      </Label>

                                                                      <TagsInput
                                                                              className="form-control"
                                                                              value={keywords}
                                                                              onChange={setKeywords}
                                                                              name="keywords"
                                                                              placeHolder="Мэдээлэл цуглуулах түлхүүр үгс"
                                                                          />
                                                                      </div>
                                                                  </Col>
                                                                  <Col lg={12}>
                                                                      <div>
                                                                      <Label
                                                                          htmlFor="party"
                                                                          className="form-label"
                                                                      >
                                                                          Намын харьяалал 
                                                                      </Label>
                                                                      <Input
                                                                          name="party"
                                                                          id="party-field"
                                                                          className="form-control"
                                                                          placeholder="Намын харьяалал оруулах"
                                                                          type="text"                                
                                                                          onChange={validation.handleChange}
                                                                          onBlur={validation.handleBlur}
                                                                          value={validation.values.party || ""}
                                                                      />
                                                                      </div>
                                                                  </Col>
                                                                  <Col lg={12}>
                                                                      <div>
                                                                      <Label
                                                                          htmlFor="section"
                                                                          className="form-label"
                                                                      >
                                                                          Бүс/Тойрог 
                                                                      </Label>
                                                                      <Input
                                                                          name="section"
                                                                          id="section-field"
                                                                          className="form-control"
                                                                          placeholder="Бүс/Тойрог оруулах"
                                                                          type="text"                                
                                                                          onChange={validation.handleChange}
                                                                          onBlur={validation.handleBlur}
                                                                          value={validation.values.section || ""}
                                                                      />
                                                                      </div>
                                                                  </Col>
                                                                  <Col lg={12}>
                                                                      <div>
                                                                      <Label
                                                                          htmlFor="points"
                                                                          className="form-label"
                                                                      >
                                                                          Social оноо 
                                                                      </Label>
                                                                      <Input
                                                                          name="points"
                                                                          id="points-field"
                                                                          className="form-control"
                                                                          placeholder="Social оноо оруулах"
                                                                          type="text"                                
                                                                          onChange={validation.handleChange}
                                                                          onBlur={validation.handleBlur}
                                                                          value={validation.values.points || ""}
                                                                      />
                                                                      </div>
                                                                  </Col>
                                                                  <Col lg={12}>
                                                                      <div>
                                                                      <Label
                                                                          htmlFor="desc"
                                                                          className="form-label"
                                                                      >
                                                                          Нэмэлт тайлбар 
                                                                      </Label>
                                                                      <Input
                                                                          name="desc"
                                                                          id="desc-field"
                                                                          className="form-control"
                                                                          placeholder="Тайлбар оруулах"
                                                                          type="text"                                
                                                                          onChange={validation.handleChange}
                                                                          onBlur={validation.handleBlur}
                                                                          value={validation.values.desc || ""}
                                                                      />
                                                                      </div>
                                                                  </Col>                         
                                                                  </Row>
                                                              </ModalBody>
                                                              <ModalFooter>
                                                                  <div className="hstack gap-2 justify-content-end"> 
                                                                  <button type="submit" className="btn btn-success" id="add-btn"> {!!isEdit ? "Засварлах" : "Хадгалах"} </button>
                                                                  <button type="button" className="btn btn-light" onClick={(ev) => { ev.preventDefault(); onFormCancel(); }} > Цуцлах </button>
                                                                  </div>
                                                              </ModalFooter>
                                                              </Form>
                                                          </Modal>

                                                          <ToastContainer closeButton={false} limit={1} />
                                                          </CardBody>
                                                    </Card>
                                                </Col>

                                                <Col id="toirog2" className="list-element">
                                                    <Card id="toirog2" className="explore-box card-animate border ribbon-box border shadow-none left">                                                                                          
                                                        <CardBody>
                                                              <div className="ribbon ribbon-primary ribbon-shape">ТОЙРОГ: II</div> 
                                                              <h5 className="fs-14 text-end">МАНДАТ:10</h5>
                                                              <div className="ribbon-content text-muted mt-4">
                                                                  <p className="mb-0">
                                                                      <h5><Badge color="info" pill>ГОВЬ-АЛТАЙ</Badge>{" "}
                                                                      <Badge color="info" pill>ЗАВХАН</Badge>{" "}
                                                                      <Badge color="info" pill>УВС</Badge>{" "}
                                                                      <Badge color="info" pill>ХОВД</Badge></h5>
                                                                  </p>
                                                              </div>                                                                              
                                                          
                                                          {isCampaignSuccess && toirog2.length ? (
                                                          <TableContainer
                                                              columns={columns3}
                                                              data={(toirog2 || [])}
                                                              isGlobalFilter={true}
                                                              isAddUserList={false}                      
                                                              customPageSize={12}
                                                              className="custom-header-css"
                                                              divClass="table-responsive table-card mb-3"
                                                              tableClass="align-middle table-nowrap"
                                                              theadClass="table-light"
                                                              handleCampaignClick={handleCampaignClicks}
                                                              isCampaignsFilter={true}
                                                              SearchPlaceholder='Search for campaign...'                        
                                                              />
                                                              ) : (<Loader error={error} />)
                                                          }                            
                                                        </CardBody>
                                                    </Card>
                                                </Col>

                                                <Col id="toirog3" className="list-element">
                                                    <Card id="toirog3" className="explore-box card-animate border ribbon-box border shadow-none left">                                                                                          
                                                        <CardBody>
                                                                <div className="ribbon ribbon-primary ribbon-shape">ТОЙРОГ: III</div> 
                                                                <h5 className="fs-14 text-end">МАНДАТ:3</h5>
                                                                <div className="ribbon-content text-muted mt-4">
                                                                    <p className="mb-0">
                                                                        <h5><Badge color="info" pill>БАЯН-ӨЛГИЙ</Badge></h5>
                                                                    </p>
                                                                </div>                                                                              
                                                            
                                                            {isCampaignSuccess && toirog3.length ? (
                                                            <TableContainer
                                                                columns={columns2}
                                                                data={(toirog3 || [])}
                                                                isGlobalFilter={true}
                                                                isAddUserList={false}                      
                                                                customPageSize={12}
                                                                className="custom-header-css"
                                                                divClass="table-responsive table-card mb-3"
                                                                tableClass="align-middle table-nowrap"
                                                                theadClass="table-light"
                                                                handleCampaignClick={handleCampaignClicks}
                                                                isCampaignsFilter={true}
                                                                SearchPlaceholder='Search for campaign...'                        
                                                                />
                                                                ) : (<Loader error={error} />)
                                                            }                            
                                                        </CardBody>
                                                    </Card>
                                                </Col>

                                                <Col id="toirog4" className="list-element">
                                                    <Card id="toirog4" className="explore-box card-animate border ribbon-box border shadow-none left">                                                                                          
                                                        <CardBody>
                                                              <div className="ribbon ribbon-primary ribbon-shape">ТОЙРОГ: IV</div> 
                                                              <h5 className="fs-14 text-end">МАНДАТ:8</h5>
                                                              <div className="ribbon-content text-muted mt-4">
                                                                  <p className="mb-0">
                                                                  <h5><Badge color="info" pill>ОРХОН</Badge>{" "}
                                                                      <Badge color="info" pill>БУЛГАН</Badge>{" "}
                                                                      <Badge color="info" pill>ХӨВСГӨЛ</Badge></h5>
                                                                  </p>
                                                              </div>                                                                              
                                                          
                                                          {isCampaignSuccess && toirog4.length ? (
                                                          <TableContainer
                                                              columns={columns4}
                                                              data={(toirog4 || [])}
                                                              isGlobalFilter={true}
                                                              isAddUserList={false}                      
                                                              customPageSize={12}
                                                              className="custom-header-css"
                                                              divClass="table-responsive table-card mb-3"
                                                              tableClass="align-middle table-nowrap"
                                                              theadClass="table-light"
                                                              handleCampaignClick={handleCampaignClicks}
                                                              isCampaignsFilter={true}
                                                              SearchPlaceholder='Search for campaign...'                        
                                                              />
                                                              ) : (<Loader error={error} />)
                                                          }                            
                                                        </CardBody>          
                                                    </Card>
                                                </Col>

                                                <Col id="toirog5" className="list-element">
                                                    <Card id="toirog5" className="explore-box card-animate border ribbon-box border shadow-none left">                                                                                          
                                                        <CardBody>
                                                              <div className="ribbon ribbon-primary ribbon-shape">ТОЙРОГ: V</div> 
                                                              <h5 className="fs-14 text-end">МАНДАТ:10</h5>
                                                              <div className="ribbon-content text-muted mt-4">
                                                                  <p className="mb-0">
                                                                  <h5><Badge color="info" pill>ДАРХАН-УУЛ</Badge>{" "}
                                                                      <Badge color="info" pill>СЭЛЭНГЭ</Badge>{" "}
                                                                      <Badge color="info" pill>ТӨВ</Badge></h5>
                                                                  </p>
                                                              </div>                                                                              
                                                          
                                                          {isCampaignSuccess && toirog5.length ? (
                                                          <TableContainer
                                                              columns={columns3}
                                                              data={(toirog5 || [])}
                                                              isGlobalFilter={true}
                                                              isAddUserList={false}                      
                                                              customPageSize={12}
                                                              className="custom-header-css"
                                                              divClass="table-responsive table-card mb-3"
                                                              tableClass="align-middle table-nowrap"
                                                              theadClass="table-light"
                                                              handleCampaignClick={handleCampaignClicks}
                                                              isCampaignsFilter={true}
                                                              SearchPlaceholder='Search for campaign...'                        
                                                              />
                                                              ) : (<Loader error={error} />)
                                                          }                            
                                                        </CardBody>         
                                                    </Card>
                                                </Col>

                                                <Col id="toirog6" className="list-element">
                                                    <Card id="toirog6" className="explore-box card-animate border ribbon-box border shadow-none left">                                                                                          
                                                        <CardBody>
                                                              <div className="ribbon ribbon-primary ribbon-shape">ТОЙРОГ: VI</div> 
                                                              <h5 className="fs-14 text-end">МАНДАТ:7</h5>
                                                              <div className="ribbon-content text-muted mt-4">
                                                                  <p className="mb-0">
                                                                  <h5><Badge color="info" pill>ДОРНОД</Badge>{" "}
                                                                      <Badge color="info" pill>СҮХБААТАР</Badge>{" "}
                                                                      <Badge color="info" pill>ХЭНТИЙ</Badge></h5>
                                                                  </p>
                                                              </div>                                                                              
                                                          
                                                          {isCampaignSuccess && toirog6.length ? (
                                                          <TableContainer
                                                              columns={columns5}
                                                              data={(toirog6 || [])}
                                                              isGlobalFilter={true}
                                                              isAddUserList={false}                      
                                                              customPageSize={12}
                                                              className="custom-header-css"
                                                              divClass="table-responsive table-card mb-3"
                                                              tableClass="align-middle table-nowrap"
                                                              theadClass="table-light"
                                                              handleCampaignClick={handleCampaignClicks}
                                                              isCampaignsFilter={true}
                                                              SearchPlaceholder='Search for campaign...'                        
                                                              />
                                                              ) : (<Loader error={error} />)
                                                          }                            
                                                        </CardBody>
                                                    </Card>
                                                </Col>

                                                <Col id="toirog7" className="list-element">
                                                    <Card id="toirog7" className="explore-box card-animate border ribbon-box border shadow-none left">                                                                                          
                                                        <CardBody>
                                                              <div className="ribbon ribbon-primary ribbon-shape">ТОЙРОГ: VII</div> 
                                                              <h5 className="fs-14 text-end">МАНДАТ:7</h5>
                                                              <div className="ribbon-content text-muted mt-4">
                                                                  <p className="mb-0">
                                                                  <h5><Badge color="info" pill>ГОВЬСҮМБЭР</Badge>{" "}
                                                                      <Badge color="info" pill>ДОРНОГОВЬ</Badge>{" "}
                                                                      <Badge color="info" pill>ДУНДГОВЬ</Badge>{" "}
                                                                      <Badge color="info" pill>ӨМНӨГОВЬ</Badge></h5>
                                                                  </p>
                                                              </div>                                                                              
                                                          
                                                          {isCampaignSuccess && toirog7.length ? (
                                                          <TableContainer
                                                              columns={columns5}
                                                              data={(toirog7 || [])}
                                                              isGlobalFilter={true}
                                                              isAddUserList={false}                      
                                                              customPageSize={12}
                                                              className="custom-header-css"
                                                              divClass="table-responsive table-card mb-3"
                                                              tableClass="align-middle table-nowrap"
                                                              theadClass="table-light"
                                                              handleCampaignClick={handleCampaignClicks}
                                                              isCampaignsFilter={true}
                                                              SearchPlaceholder='Search for campaign...'                        
                                                              />
                                                              ) : (<Loader error={error} />)
                                                          }                            
                                                        </CardBody>
                                                    </Card>
                                                </Col>

                                                <Col id="toirog8" className="list-element">
                                                    <Card id="toirog8" className="explore-box card-animate border ribbon-box border shadow-none left">                                                                                          
                                                        <CardBody>
                                                              <div className="ribbon ribbon-primary ribbon-shape">ТОЙРОГ: VIII</div> 
                                                              <h5 className="fs-14 text-end">МАНДАТ:5</h5>
                                                              <div className="ribbon-content text-muted mt-4">
                                                                  <p className="mb-0">
                                                                  <h5><Badge color="info" pill>БАЯНЗҮРХ</Badge></h5>
                                                                  </p>
                                                              </div>                                                                              
                                                          
                                                          {isCampaignSuccess && toirog8.length ? (
                                                          <TableContainer
                                                              columns={columns6}
                                                              data={(toirog8 || [])}
                                                              isGlobalFilter={true}
                                                              isAddUserList={false}                      
                                                              customPageSize={12}
                                                              className="custom-header-css"
                                                              divClass="table-responsive table-card mb-3"
                                                              tableClass="align-middle table-nowrap"
                                                              theadClass="table-light"
                                                              handleCampaignClick={handleCampaignClicks}
                                                              isCampaignsFilter={true}
                                                              SearchPlaceholder='Search for campaign...'                        
                                                              />
                                                              ) : (<Loader error={error} />)
                                                          }                            
                                                        </CardBody>
                                                    </Card>
                                                </Col>

                                                <Col id="toirog9" className="list-element">
                                                    <Card id="toirog9" className="explore-box card-animate border ribbon-box border shadow-none left">                                                                                          
                                                        <CardBody>
                                                              <div className="ribbon ribbon-primary ribbon-shape">ТОЙРОГ: IX</div> 
                                                              <h5 className="fs-14 text-end">МАНДАТ:3</h5>
                                                              <div className="ribbon-content text-muted mt-4">
                                                                  <p className="mb-0">
                                                                  <h5><Badge color="info" pill>БАЯНГОЛ</Badge></h5>
                                                                  </p>
                                                              </div>                                                                              
                                                          
                                                          {isCampaignSuccess && toirog9.length ? (
                                                          <TableContainer
                                                              columns={columns2}
                                                              data={(toirog9 || [])}
                                                              isGlobalFilter={true}
                                                              isAddUserList={false}                      
                                                              customPageSize={12}
                                                              className="custom-header-css"
                                                              divClass="table-responsive table-card mb-3"
                                                              tableClass="align-middle table-nowrap"
                                                              theadClass="table-light"
                                                              handleCampaignClick={handleCampaignClicks}
                                                              isCampaignsFilter={true}
                                                              SearchPlaceholder='Search for campaign...'                        
                                                              />
                                                              ) : (<Loader error={error} />)
                                                          }                            
                                                        </CardBody>
                                                    </Card>
                                                </Col>

                                                <Col id="toirog10" className="list-element">
                                                    <Card id="toirog10" className="explore-box card-animate border ribbon-box border shadow-none left">                                                                                          
                                                        <CardBody>
                                                              <div className="ribbon ribbon-primary ribbon-shape">ТОЙРОГ: X</div> 
                                                              <h5 className="fs-14 text-end">МАНДАТ:6</h5>
                                                              <div className="ribbon-content text-muted mt-4">
                                                                  <p className="mb-0">
                                                                  <h5><Badge color="info" pill>СҮХБААТАР</Badge>{" "}
                                                                      <Badge color="info" pill>ЧИНГЭЛТЭЙ</Badge></h5>
                                                                  </p>
                                                              </div>                                                                              
                                                          
                                                          {isCampaignSuccess && toirog10.length ? (
                                                          <TableContainer
                                                              columns={columns7}
                                                              data={(toirog10 || [])}
                                                              isGlobalFilter={true}
                                                              isAddUserList={false}                      
                                                              customPageSize={12}
                                                              className="custom-header-css"
                                                              divClass="table-responsive table-card mb-3"
                                                              tableClass="align-middle table-nowrap"
                                                              theadClass="table-light"
                                                              handleCampaignClick={handleCampaignClicks}
                                                              isCampaignsFilter={true}
                                                              SearchPlaceholder='Search for campaign...'                        
                                                              />
                                                              ) : (<Loader error={error} />)
                                                          }                            
                                                        </CardBody>
                                                    </Card>
                                                </Col>

                                                <Col id="toirog11" className="list-element">
                                                    <Card id="toirog11" className="explore-box card-animate border ribbon-box border shadow-none left">                                                                                          
                                                        <CardBody>
                                                              <div className="ribbon ribbon-primary ribbon-shape">ТОЙРОГ: XI</div> 
                                                              <h5 className="fs-14 text-end">МАНДАТ:5</h5>
                                                              <div className="ribbon-content text-muted mt-4">
                                                                  <p className="mb-0">
                                                                  <h5><Badge color="info" pill>СОНГИНОХАЙРХАН</Badge></h5>
                                                                  </p>
                                                              </div>                                                                              
                                                          
                                                          {isCampaignSuccess && toirog11.length ? (
                                                          <TableContainer
                                                              columns={columns6}
                                                              data={(toirog11 || [])}
                                                              isGlobalFilter={true}
                                                              isAddUserList={false}                      
                                                              customPageSize={12}
                                                              className="custom-header-css"
                                                              divClass="table-responsive table-card mb-3"
                                                              tableClass="align-middle table-nowrap"
                                                              theadClass="table-light"
                                                              handleCampaignClick={handleCampaignClicks}
                                                              isCampaignsFilter={true}
                                                              SearchPlaceholder='Search for campaign...'                        
                                                              />
                                                              ) : (<Loader error={error} />)
                                                          }                            
                                                        </CardBody>
                                                    </Card>
                                                </Col>

                                                <Col id="toirog12" className="list-element">
                                                    <Card id="toirog12" className="explore-box card-animate border ribbon-box border shadow-none left">                                                                                          
                                                        <CardBody>
                                                              <div className="ribbon ribbon-primary ribbon-shape">ТОЙРОГ: XII</div> 
                                                              <h5 className="fs-14 text-end">МАНДАТ:3</h5>
                                                              <div className="ribbon-content text-muted mt-4">
                                                                  <p className="mb-0">
                                                                  <h5><Badge color="info" pill>ХАН-УУЛ</Badge></h5>
                                                                  </p>
                                                              </div>                                                                              
                                                          
                                                          {isCampaignSuccess && toirog12.length ? (
                                                          <TableContainer
                                                              columns={columns2}
                                                              data={(toirog12 || [])}
                                                              isGlobalFilter={true}
                                                              isAddUserList={false}                      
                                                              customPageSize={12}
                                                              className="custom-header-css"
                                                              divClass="table-responsive table-card mb-3"
                                                              tableClass="align-middle table-nowrap"
                                                              theadClass="table-light"
                                                              handleCampaignClick={handleCampaignClicks}
                                                              isCampaignsFilter={true}
                                                              SearchPlaceholder='Search for campaign...'                        
                                                              />
                                                              ) : (<Loader error={error} />)
                                                          }                            
                                                        </CardBody>
                                                    </Card>
                                                </Col>

                                                <Col id="toirog13" className="list-element">
                                                    <Card id="toirog13" className="explore-box card-animate border ribbon-box border shadow-none left">                                                                                          
                                                        <CardBody>
                                                              <div className="ribbon ribbon-primary ribbon-shape">ТОЙРОГ: XIII</div> 
                                                              <h5 className="fs-14 text-end">МАНДАТ:3</h5>
                                                              <div className="ribbon-content text-muted mt-4">
                                                                  <p className="mb-0">
                                                                  <h5><Badge color="info" pill>БАГАНУУР</Badge>{" "}
                                                                      <Badge color="info" pill>БАГАХАНГАЙ</Badge>{" "}
                                                                      <Badge color="info" pill>НАЛАЙХ</Badge></h5>
                                                                  </p>
                                                              </div>                                                                              
                                                          
                                                          {isCampaignSuccess && toirog13.length ? (
                                                          <TableContainer
                                                              columns={columns2}
                                                              data={(toirog13 || [])}
                                                              isGlobalFilter={true}
                                                              isAddUserList={false}                      
                                                              customPageSize={12}
                                                              className="custom-header-css"
                                                              divClass="table-responsive table-card mb-3"
                                                              tableClass="align-middle table-nowrap"
                                                              theadClass="table-light"
                                                              handleCampaignClick={handleCampaignClicks}
                                                              isCampaignsFilter={true}
                                                              SearchPlaceholder='Search for campaign...'                        
                                                              />
                                                              ) : (<Loader error={error} />)
                                                          }                            
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </Row>
                                    </TabPane>

                                    <TabPane tabId="3">
                                        <Row>
                                        <Col xxl={3}>    
                                                <Card id="jagsaaltXYN" className="ribbon-box card-animate border shadow-none mb-lg-0">
                                                    <CardBody>
                                                            <div className="ribbon ribbon-primary ribbon-shape">ХҮН НАМ</div> 
                                                            <h5 className="fs-14 text-end">МАНДАТ:48</h5>
                                                            <div className="ribbon-content text-muted mt-4">
                                                                <p className="mb-0">
                                                                <h5><Badge color="info" pill>ЖАГСААЛТ</Badge></h5>
                                                                </p>
                                                            </div>                                                                              
                                                        
                                                        {isCampaignSuccess && jagsaaltXYN.length ? (
                                                        <TableContainer
                                                            columns={columns8}
                                                            data={(jagsaaltXYN || [])}
                                                            isGlobalFilter={true}
                                                            isAddUserList={false}                      
                                                            customPageSize={12}
                                                            className="custom-header-css"
                                                            divClass="table-responsive table-card mb-3"
                                                            tableClass="align-middle table-nowrap"
                                                            theadClass="table-light"
                                                            handleCampaignClick={handleCampaignClicks}
                                                            isCampaignsFilter={true}
                                                            SearchPlaceholder='Search for campaign...'                        
                                                            />
                                                            ) : (<Loader error={error} />)
                                                        }                            
                                                    </CardBody>
                                                </Card>
                                            </Col>

                                            <Col xxl={3}>    
                                                <Card id="jagsaaltMAN" className="ribbon-box card-animate border shadow-none mb-lg-0">
                                                    <CardBody>
                                                            <div className="ribbon ribbon-danger ribbon-shape">МОНГОЛ АРДЫН НАМ</div> 
                                                            <h5 className="fs-14 text-end">МАНДАТ:48</h5>
                                                            <div className="ribbon-content text-muted mt-4">
                                                                <p className="mb-0">
                                                                <h5><Badge color="info" pill>ЖАГСААЛТ</Badge></h5>
                                                                </p>
                                                            </div>                                                                              
                                                        
                                                        {isCampaignSuccess && jagsaaltMAN.length ? (
                                                        <TableContainer
                                                            columns={columns8}
                                                            data={(jagsaaltMAN || [])}
                                                            isGlobalFilter={true}
                                                            isAddUserList={false}                      
                                                            customPageSize={12}
                                                            className="custom-header-css"
                                                            divClass="table-responsive table-card mb-3"
                                                            tableClass="align-middle table-nowrap"
                                                            theadClass="table-light"
                                                            handleCampaignClick={handleCampaignClicks}
                                                            isCampaignsFilter={true}
                                                            SearchPlaceholder='Search for campaign...'                        
                                                            />
                                                            ) : (<Loader error={error} />)
                                                        }                            
                                                    </CardBody>
                                                </Card>
                                            </Col>

                                            <Col xxl={3}>    
                                                <Card id="jagsaaltAN" className="ribbon-box card-animate border shadow-none mb-lg-0">
                                                    <CardBody>
                                                            <div className="ribbon ribbon-secondary ribbon-shape">АРДЧИЛСАН НАМ</div> 
                                                            <h5 className="fs-14 text-end">МАНДАТ:48</h5>
                                                            <div className="ribbon-content text-muted mt-4">
                                                                <p className="mb-0">
                                                                <h5><Badge color="info" pill>ЖАГСААЛТ</Badge></h5>
                                                                </p>
                                                            </div>                                                                              
                                                        
                                                        {isCampaignSuccess && jagsaaltAN.length ? (
                                                        <TableContainer
                                                            columns={columns8}
                                                            data={(jagsaaltAN || [])}
                                                            isGlobalFilter={true}
                                                            isAddUserList={false}                      
                                                            customPageSize={12}
                                                            className="custom-header-css"
                                                            divClass="table-responsive table-card mb-3"
                                                            tableClass="align-middle table-nowrap"
                                                            theadClass="table-light"
                                                            handleCampaignClick={handleCampaignClicks}
                                                            isCampaignsFilter={true}
                                                            SearchPlaceholder='Search for campaign...'                        
                                                            />
                                                            ) : (<Loader error={error} />)
                                                        }                            
                                                    </CardBody>
                                                </Card>
                                            </Col>

                                            <Col xxl={3}>    
                                                <Card id="jagsaaltUE" className="ribbon-box card-animate border shadow-none mb-lg-0">
                                                    <CardBody>
                                                            <div className="ribbon ribbon-warning ribbon-shape">ҮНДЭСНИЙ ЭВСЭЛ</div> 
                                                            <h5 className="fs-14 text-end">МАНДАТ:48</h5>
                                                            <div className="ribbon-content text-muted mt-4">
                                                                <p className="mb-0">
                                                                <h5><Badge color="info" pill>ЖАГСААЛТ</Badge></h5>
                                                                </p>
                                                            </div>                                                                              
                                                        
                                                        {isCampaignSuccess && jagsaaltUE.length ? (
                                                        <TableContainer
                                                            columns={columns8}
                                                            data={(jagsaaltUE || [])}
                                                            isGlobalFilter={true}
                                                            isAddUserList={false}                      
                                                            customPageSize={12}
                                                            className="custom-header-css"
                                                            divClass="table-responsive table-card mb-3"
                                                            tableClass="align-middle table-nowrap"
                                                            theadClass="table-light"
                                                            handleCampaignClick={handleCampaignClicks}
                                                            isCampaignsFilter={true}
                                                            SearchPlaceholder='Search for campaign...'                        
                                                            />
                                                            ) : (<Loader error={error} />)
                                                        }                            
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                </TabContent>
                            </div>
                        </Col>
                    </Row>

                </Container>
      </div>

    </React.Fragment>
  );
};

export default Campaigns;
