import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";
import * as moment from "moment";
import 'moment-timezone';
import Countdown from "react-countdown";
import { useLocation, Navigate } from "react-router-dom";
import storage from "../../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import DualListBox from "react-dual-listbox";
import "react-dual-listbox/lib/react-dual-listbox.css";

// Import Images
import dummyImg from "../../assets/images/users/user-dummy-img.jpg";
import MAN from "../../assets/images/users/MAN.png";
import AN from "../../assets/images/users/AN.png";
import XYN from "../../assets/images/users/XYN.png";
import UE from "../../assets/images/users/UE.png";
import NN from "../../assets/images/users/NN.png";

import {
  Col,
  Container,
  Row,
  Card,
  CardHeader,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  ModalFooter,
  Table,
  FormFeedback,
  UncontrolledAlert,
  CardTitle
} from "reactstrap";
import Select from "react-select";
import { TagsInput } from "react-tag-input-component";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import DeleteCampaign from "../../Components/Common/DeleteCampaign";

//Import actions
import {
  getCampaigns as onGetCampaigns,
  addNewCampaign as onAddNewCampaign,
  updateCampaign as onUpdateCampaign,
  deleteCampaign as onDeleteCampaign,
  getUsers as onGetUsers,
} from "../../slices/thunks";

//redux
import { useSelector, useDispatch } from "react-redux";
import TableContainer from "../../Components/Common/TableContainer";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

import Loader from "../../Components/Common/Loader";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Export Modal
import ExportCSVModal from "../../Components/Common/ExportCSVModal";

import Flatpickr from "react-flatpickr";

const Campaigns = () => {
  const dispatch = useDispatch();
  const { campaigns, isCampaignSuccess, error } = useSelector((state) => ({
    campaigns: state.Campaign.campaigns,
    isCampaignSuccess: state.Campaign.isCampaignSuccess,
    error: state.Campaign.error,
  }));

  const { crmusers, isUserSuccess } = useSelector((state) => ({
      crmusers: state.Crm.crmusers,
      isUserSuccess: state.Crm.isUserSuccess,
  }));

  const [isEdit, setIsEdit] = useState(false);
  const [campaign, setCampaign] = useState([]);

  const [image, setImage] = useState(null);
  const [file, setFile] = useState(null);
  const [uploaded, setUploaded] = useState("");

  const [editId, setEditedId] = useState();
  const [contact, setContact] = useState([]);
  const [dataDual, setDataDual] = useState([]);
  const [dataTempDual, setDataTempDual] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [selected, setSelected] = useState([]);

  const [time, setTime] = useState(3600);

  useEffect(() => {
    if (campaigns && !campaigns.length) {
      dispatch(onGetCampaigns());
    }
  }, [dispatch, campaigns]);

  useEffect(() => {
    setCampaign(campaigns);
  }, [campaigns]);

  useEffect(() => {
    if (!isEmpty(campaigns)) {
      setCampaign(campaigns);
      setIsEdit(false);
    }
  }, [campaigns]);

  useEffect(() => {
    let timer = setInterval(() => {
      setTime((time) => {
        if (time === 0) {
          clearInterval(timer);
          return 0;
        } else return time - 1;
      });
    }, 1000);
  }, []);

  //delete Campaign
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);

  const [modal, setModal] = useState(false);
  const [permissionModal, setPermissionModal] = useState(false);
  const [keywords, setKeywords] = useState([]);
  
  const onListChange = (selected) => {
    setSelected(selected);
  };
  
  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setCampaign(null);
      setKeywords([]);
      setImage(null);
    } else {
      setModal(true);
    }
  }, [modal]);

  const permissionToggle = useCallback(() => { 
    if (permissionModal) {
      setPermissionModal(false);
    } else {
      setPermissionModal(true);
    }
  }, [permissionModal]);

  useEffect(() => {
    if (crmusers && !crmusers.length && JSON.parse(localStorage.getItem("authUser")).role === "SuperAdmin") {
      dispatch(onGetUsers());
    }
  }, [dispatch, crmusers]);

  useEffect(() => {
    setContact(crmusers);
  }, [crmusers]);

  useEffect(() => {
    if (!isEmpty(crmusers)) {
      setContact(crmusers);
    }
  }, [crmusers]);

  // Delete Data
  const handleDeleteCampaign = () => {
    if (campaign) {
      dispatch(onDeleteCampaign(campaign._id));
      setDeleteModal(false);
    }
  };

  const onClickDelete = (campaign) => {
    setCampaign(campaign);
    setDeleteModal(true);
  };

  const onClickPosts = (campaign) => {
    return <Navigate to="/posts" state={campaign} />
  };

  // Add Data
  const handleCampaignClicks = () => {
    setCampaign("");
    setIsEdit(false);
    toggle();
  };

  // Date & Time Format

  const dateFormat = () => {
    var d = new Date(),
      months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return (d.getDate() + ' ' + months[d.getMonth()] + ', ' + d.getFullYear());
  };

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (campaign && campaign.name) || '',
      desc: (campaign && campaign.desc) || '',      
      keywords: (campaign && campaign.keywords) || [],
      party: (campaign && campaign.party) || '',
      section: (campaign && campaign.section) || '', 
      points: (campaign && campaign.points) || 0,       
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Судалгааны нэрийг заавал оруулна"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateCampaign = {
          _id: campaign ? campaign._id : 0,
          name: values.name,
          desc: values.desc,         
          keywords: keywords,
          party: values.party,
          section: values.section,  
          points: values.points,      
          image: image
        };

        // update Campaign
        dispatch(onUpdateCampaign(updateCampaign));
        validation.resetForm();
      } else {
        const newCampaign = {
          name: values["name"],
          desc: values["desc"],          
          keywords: keywords,
          party: values["party"],
          section: values["section"], 
          points: values["points"],              
          image: image
        };
        // save new Campaign
        dispatch(onAddNewCampaign(newCampaign));
        validation.resetForm();
      }
      toggle();
    },
  });
  
  // Changing state value when searching name
  useEffect(() => {
    
    if (filterText !== "") {
      const filteredObject = dataDual.filter((item) => {
        return item.label.toLowerCase().includes(filterText.toLowerCase());
      });
      setDataDual([...filteredObject]);
    } else {
      setDataDual([...dataTempDual]);
    }
  }, [filterText]);

  const onFilterChange = (e) => {
    setFilterText(e.target.value);
  };

  // function to close the form modal
  const onFormCancel = () => {
    setModal(false);
    setCampaign("");
    setIsEdit(false);
    setImage(dummyImg);
    validation.resetForm();
    setKeywords([]);
  };

  const onPermissionSave = () => {
    let submittedData;
    let newitems = campaigns;

    newitems?.map((item) => {
      if (item._id === editId) {
        submittedData = {
          _id: editId,                    
          whitelist: selected
        };
      }
    });

    // update Campaign
    dispatch(onUpdateCampaign(submittedData));

    setPermissionModal(false);
  };

  const onPermissionCancel = () => {
    setPermissionModal(false);
  };

  // Update Data
  const handleCampaignClick = useCallback((arg) => {
    const campaign = arg;

    setCampaign({
      _id: campaign._id,
      name: campaign.name,
      keywords: campaign.keywords,
      desc: campaign.desc,
      party: campaign.party,
      section: campaign.section,
      points: campaign.points,
    });
    setImage(campaign.image);
    setUploaded("");
    setKeywords(campaign.keywords);
    setIsEdit(true);
    toggle();
  }, [toggle]);

  const handlePreview = (file) => {    
        setImage(URL.createObjectURL(file));
        setFile(file);
  }
  const handleUpload = (e) => {
        e.preventDefault();
        //setImage(URL.createObjectURL(file))
        const fileName = new Date().getTime() + file.name;
        const storageRef = ref(storage, `/campaign/${fileName}`);

        // progress can be paused and resumed. It also exposes progress updates.
        // Receives the storage reference and the file to upload.
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
            "state_changed",
            (snapshot) => {
            const percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            const safePercent = Math.floor(percentage, 10).toString()

            // update progress
            setPercent(safePercent);
            //console.log(percent)
            },
            (err) => setUploaded("no"),
            () => {
            // download url
            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            //console.log(url);
                setImage(url);
                setUploaded("yes");
            });
            }
        );
  };

  const handleValidDate = date => {
    const date1 = moment(new Date(date)).format("Y-MM-DD");
    return date1;
  };

  const handleValidTime = (time) => {
    const time1 = new Date(time);
    const getHour = time1.getUTCHours();
    const getMin = time1.getUTCMinutes();
    const getTime = `${getHour}:${getMin}`;
    const updateTime = moment.utc(getTime, 'HH:mm').format('HH:mm');
    return updateTime;
  };

  const handleValidDateTime = (time) => {    
    const updateTime = moment.utc(time, 'YYYY-MM-DD HH:mm:ss').tz('Asia/Ulaanbaatar').format('YYYY-MM-DD HH:mm:ss');
    return updateTime;
  };

  // Checked All
  const checkedAll = useCallback(() => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".campaignCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
    deleteCheckbox();
  }, []);

  const defaultdate = () => {
    let d = new Date(),
      months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return ((d.getFullYear() + '-' + d.getMonth() + '-' + d.getDate()).toString());
  };

  const [dateValidFrom, setDateValidFrom] = useState(defaultdate());
  const [dateValidTo, setDateValidTo] = useState(defaultdate());

  const dateformaterValidFrom = (e) => {
    const date = e.toString().split(" ");
    const joinDate = (date[3] + "-" + date[2] + "-" + date[1]).toString();
    setDateValidFrom(joinDate);
  };

  const dateformaterValidTo = (e) => {
    const date = e.toString().split(" ");
    const joinDate = (date[3] + "-" + date[2] + "-" + date[1]).toString();
    setDateValidTo(joinDate);
  };

  // Delete Multiple
  const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
  const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);

  const deleteMultiple = () => {
    const checkall = document.getElementById("checkBoxAll");
    selectedCheckBoxDelete.forEach((element) => {
      dispatch(onDeleteCampaign(element.value));
      setTimeout(() => { toast.clearWaitingQueue(); }, 3000);
    });
    setIsMultiDeleteButton(false);
    checkall.checked = false;
  };

  const deleteCheckbox = () => {
    const ele = document.querySelectorAll(".campaignCheckBox:checked");
    ele.length > 0 ? setIsMultiDeleteButton(true) : setIsMultiDeleteButton(false);
    setSelectedCheckBoxDelete(ele);
  };

  const onPermissionClick = (arg) => {
    
    setPermissionModal(true);
    const campaign = arg;
       
    setEditedId(campaign._id)
    let usersList = [];

    //console.log(contact);

    crmusers?.map((item) => {
      usersList.push({ "value": item._id, "label": item.name + " /" + item.company })
    });

    setDataDual(usersList)
    setDataTempDual(usersList)

    setSelected(campaign.whitelist);
    setPermissionModal(true);
  }
  // User Data
  const handlePermissionClick = useCallback((arg) => {
    const campaign = arg;
       
    setEditedId(campaign._id)
    let usersList = [];

    //console.log(contact);

    crmusers?.map((item) => {
      usersList.push({ "value": item._id, "label": item.name + " /" + item.company })
    });

    setDataDual(usersList)
    setDataTempDual(usersList)

    setSelected(campaign.whitelist);

    permissionToggle();
  }, [permissionToggle]);

  // Column
  const columns = useMemo(
    () => [
      {
        Header: <input type="checkbox" id="checkBoxAll" className="form-check-input" onClick={() => checkedAll()} />,
        Cell: (cellProps) => {
          return <input type="checkbox" className="campaignCheckBox form-check-input" value={cellProps.row.original._id} onChange={() => deleteCheckbox()} />;
        },
        id: '#',
      },
      {
        Header: "Судалгааны нэр",
        accessor: "name",
        filterable: true,
        Cell: (campaign) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
                {campaign.row.original.image ? 
                    <div className="avatar-sm bg-light rounded p-1 me-2">
                        <img
                          src={campaign.row.original.image}
                          alt=""
                          className="img-fluid d-block"
                        /> 
                    </div> 
                    :
                    <div className="avatar-sm bg-light rounded p-1 me-2">                       
                        <img src={dummyImg} alt="" className="img-fluid d-block" />
                    </div>                  
                }
              </div>
              <div className="flex-grow-1 ms-2 name">
                <Link to="/dashboard" campaignId={campaign.row.original._id} state={{campaignId: campaign.row.original._id, 
                                              campaignName: campaign.row.original.name,
                                              campaignDesc: campaign.row.original.desc,
                                              campaignImage: campaign.row.original.image || dummyImg,
                                              campaignStatus: campaign.row.original.status,
                                              campaignValidFrom: campaign.row.original.validFrom,
                                              campaignValidTo: campaign.row.original.validTo,
                                              campaignDataAnalyze: campaign.row.original.dataAnalyze
                                            }}> 
                     {campaign.row.original.name}
                </Link>               
              </div>
            </div>
          </>
        ),
      },
      {
        Header: "Намын харьяалал",
        accessor: "party",
        filterable: true,
        Cell: (toirog1) => (
          <>
            <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                        {
                            toirog1.row.original.party === "МАН"
                            ? <img src={MAN} alt="" className="avatar-xxs rounded-circle" />
                            : toirog1.row.original.party === "АН"
                            ? <img src={AN} alt="" className="avatar-xxs rounded-circle" />
                            : toirog1.row.original.party === "ХҮН"
                            ? <img src={XYN} alt="" className="avatar-xxs rounded-circle" />
                            : toirog1.row.original.party === "ҮЭ"
                            ? <img src={UE} alt="" className="avatar-xxs rounded-circle" />
                            : toirog1.row.original.party === "НН"
                            ? <img src={NN} alt="" className="avatar-xxs rounded-circle" />
                            : toirog1.row.original.party === "ҮЗ"
                            ? <img src={UZ} alt="" className="avatar-xxs rounded-circle" />
                            : toirog1.row.original.party === "ШН"
                            ? <img src={SHN} alt="" className="avatar-xxs rounded-circle" />
                            : toirog1.row.original.party === "ИОНН"
                            ? <img src={IONN} alt="" className="avatar-xxs rounded-circle" />
                            : <img src={dummyImg} alt="" className="avatar-xxs rounded-circle" />
                        }  
                </div>
                <div className="flex-grow-1 ms-2 name">
                      {
                            toirog1.row.original.party === "МАН"
                            ? "Монгол ардын нам"
                            : toirog1.row.original.party === "АН"
                            ? "Ардчилсан нам"
                            : toirog1.row.original.party === "ХҮН"
                            ? "Хүн нам"
                            : toirog1.row.original.party === "ҮЭ"
                            ? "Үндэсний эвсэл"
                            : toirog1.row.original.party === "НН"
                            ? "Ногоон нам"
                            : toirog1.row.original.party === "ҮЗ"
                            ? "Үнэн ба зөв нам"
                            : toirog1.row.original.party === "ШН"
                            ? "Шинэ нэгдсэн эвсэл нам"
                            : toirog1.row.original.party === "ИОНН"
                            ? "Иргэдийн оролцооны нэгдэл нам"
                            : "Бусад"
                      }             
                </div>
            </div>            
          </>
        ),
      },  
      {
        Header: "Тойрог",
        filterable: true,
        Cell: (campaign) => (
          <>
            {"Тойрог: " + campaign.row.original.section}
          </>
        ),
      },          
      {
        Header: "Сошиал оноо",
        Cell: (campaign) => (
          <>
            <span className="badge badge-soft-primary">{campaign.row.original.points}</span>
          </>
        ),
      },      
      {
        Header: "Үйлдэл",
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">              
              <li className="list-inline-item">
                <UncontrolledDropdown>
                  <DropdownToggle
                    href="#"
                    className="btn btn-soft-secondary btn-sm dropdown"
                    tag="button"
                  >
                    <i className="ri-more-fill align-middle"></i>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem className="dropdown-item" href="#"
                        onClick={() => { const campaignData = cellProps.row.original; setInfo(campaignData); }}
                      >
                      <i className="ri-eye-fill align-bottom me-2 text-muted"></i>{" "}
                      Харах
                    </DropdownItem>
                    <DropdownItem
                        className="dropdown-item edit-item-btn"
                        href="#"
                        onClick={() => { const campaignData = cellProps.row.original; handleCampaignClick(campaignData); }}
                      >
                      <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>{" "}
                      Засварлах
                    </DropdownItem>
                    { 
                        JSON.parse(localStorage.getItem("authUser")).role === "SuperAdmin" &&
                        <React.Fragment>
                              <DropdownItem
                                  className="dropdown-item remove-item-btn"
                                  href="#"
                                  onClick={() => { const campaignData = cellProps.row.original; onClickDelete(campaignData); }}
                                >
                                <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>{" "}
                                Устгах
                              </DropdownItem>
                        </React.Fragment>
                    }    
                    <DropdownItem className="dropdown-item">
                      <i className="ri-dashboard-fill align-bottom me-2 text-muted"></i>{" "}
                      
                      <Link to="/dashboard" state={{campaignId: cellProps.row.original._id, 
                                              campaignName: cellProps.row.original.name,
                                              campaignDesc: cellProps.row.original.desc,
                                              campaignImage: cellProps.row.original.image,
                                              campaignStatus: cellProps.row.original.status,
                                              campaignValidFrom: cellProps.row.original.validFrom,
                                              campaignValidTo: cellProps.row.original.validTo,
                                              campaignDataAnalyze: cellProps.row.original.dataAnalyze
                                            }}> 
                          Хянах самбар
                      </Link>
                    </DropdownItem>
                    {/* {
                        JSON.parse(localStorage.getItem("authUser")).role === "SuperAdmin" && 
                        <DropdownItem className="dropdown-item">
                          <i className="ri-dashboard-fill align-bottom me-2 text-muted"></i>{" "}
                          
                          <Link to="/main" state={{campaignId: cellProps.row.original._id, 
                                                  campaignName: cellProps.row.original.name,
                                                  campaignDesc: cellProps.row.original.desc,
                                                  campaignImage: cellProps.row.original.image,
                                                  campaignStatus: cellProps.row.original.status,
                                                  campaignValidFrom: cellProps.row.original.validFrom,
                                                  campaignValidTo: cellProps.row.original.validTo
                                                }}> 
                              Хянах самбар 2
                          </Link>
                        </DropdownItem>
                    } */}
                    <DropdownItem className="dropdown-item">
                      <i className="ri-file-text-fill align-bottom me-2 text-muted"></i>{" "}
                      
                      <Link to="/posts" state={{campaignId: cellProps.row.original._id, 
                                              campaignName: cellProps.row.original.name,
                                              campaignDesc: cellProps.row.original.desc,
                                              campaignImage: cellProps.row.original.image,
                                              campaignStatus: cellProps.row.original.status,
                                              campaignValidFrom: cellProps.row.original.validFrom,
                                              campaignValidTo: cellProps.row.original.validTo
                                            }}> 
                          Бүх нийтлэл
                      </Link>
                    </DropdownItem>
                    {
                        JSON.parse(localStorage.getItem("authUser")).role === "SuperAdmin" && 
                        <DropdownItem
                          className="dropdown-item edit-item-btn"
                          href="#"
                          onClick={(e) => { const campaignData = cellProps.row.original; handlePermissionClick(campaignData); }}
                        >
                        <i className="ri-settings-5-fill align-bottom me-2 text-muted"></i>{" "}
                        Эрх тохируулах
                      </DropdownItem>
                    }
                   
                  </DropdownMenu>
                </UncontrolledDropdown>
              </li>
            </ul>
          );
        },
      },
    ],
    [handleCampaignClick, checkedAll]
  );

  const tagsOptions = [
    { label: "Улс төр", value: "Улс төр" },
    { label: "Боловсрол", value: "Боловсрол" },
    { label: "Мэдээллийн технологи", value: "Мэдээллийн технологи" },
    { label: "Тусгай байгууллага", value: "Тусгай байгууллага" },
    { label: "Шинжлэх ухаан", value: "Шинжлэх ухаан" },
    { label: "Хэвлэл мэдээлэл", value: "Хэвлэл мэдээлэл" },
    { label: "Худалдаа", value: "Худалдаа" }
  ];

  function handlestag(keywords) {  
    setKeywords(keywords);
    setAssignTag(keywords);
  }

  const priorityOptions = [
    {
      options: [
        { label: "Маш чухал", value: "Urgent" },
        { label: "Чухал", value: "High" },
        { label: "Дунд", value: "Medium" },
        { label: "Бага", value: "Low" }
      ],
    },
  ];

  const statusOptions = [
    {
      options: [
        { label: "Идэвхтэй", value: "Active" },
        { label: "Идэвхгүй", value: "Suspend" },
        { label: "Хүлээгдэж буй", value: "Pending" },
      ],
    },
  ];

  const speedOptions = [
    {
      options: [
        { label: "15 минут", value: "15mins" },
        { label: "30 минут", value: "30mins" },
        { label: "1 цаг", value: "1hour" },
        { label: "2 цаг", value: "2hours" },
        { label: "3 цаг", value: "3hours" },
        { label: "6 цаг", value: "6hours" },
        { label: "12 цаг", value: "12hours" },
        { label: "24 цаг", value: "24hours" },
      ],
    },
  ];

  const postponeOptions = [
    {
      options: [
        { label: "Тийм", value: "Yes" },
        { label: "Үгүй", value: "No" },
        { label: "Мэдэгдэх", value: "Warn" },
      ],
    },
  ];

  // SideBar Campaign Detail
  const [info, setInfo] = useState([]);

  // Export Modal
  const [isExportCSV, setIsExportCSV] = useState(false);

  document.title = "Сонгууль 2024 нэр дэвшигчид | IKHNUDEN - Media Monitoring System";
  return (
    <React.Fragment>
      <div className="page-content">
        <ExportCSVModal
          show={isExportCSV}
          onCloseClick={() => setIsExportCSV(false)}
          data={campaigns}
        />

        <DeleteCampaign
          show={deleteModal}
          onDeleteClick={handleDeleteCampaign}
          onCloseClick={() => setDeleteModal(false)}
          disabled = { JSON.parse(localStorage.getItem("authUser")).role === "SuperAdmin" ? false : true }
        />

        <DeleteCampaign
          show={deleteModalMulti}
          onDeleteClick={() => {
            deleteMultiple();
            setDeleteModalMulti(false);
          }}
          onCloseClick={() => setDeleteModalMulti(false)}
          disabled = { JSON.parse(localStorage.getItem("authUser")).role === "SuperAdmin" ? false : true }
        />

        <Container fluid>
          <BreadCrumb title="Нэр дэвшигчдийн удирдлага" pageTitle="Сонгууль 2024" />
            <Row>
              <Col lg={12}>
                <Card>
                  <CardHeader>
                    <div className="d-flex align-items-center flex-wrap gap-2">
                      <div className="flex-grow-1">
                        <button
                          className="btn btn-info add-btn"
                          onClick={() => {
                            setModal(true);
                          }}
                          disabled={JSON.parse(localStorage.getItem("authUser")).limit - campaigns.length > 0 ? false : true}
                        >
                          <i className="ri-add-fill me-1 align-bottom"></i> Шинээр нэр дэвшигч нэмэх
                        </button>
                      </div>
                      <div className="flex-grow-1 gap-2">
                        {
                          JSON.parse(localStorage.getItem("authUser")).limit - campaigns.length > 0
                          ? <h6 className="text-black mb-1"> Та {" "}  
                              <span className="badge badge-soft-success">{JSON.parse(localStorage.getItem("authUser")).limit - campaigns.length}</span>
                              нэр дэвшигч нэмэх эрхтэй байна.
                            </h6>
                          : <h6 className="text-black mb-1">   
                            <span className="badge badge-soft-danger">
                                  Таны нэр дэвшигч нэмэх эрх дууссан байна
                            </span>
                          </h6>
                        }
                        
                      </div>
                      <div className="flex-shrink-0">
                        <div className="hstack text-nowrap gap-2">                     
                          {
                            JSON.parse(localStorage.getItem("authUser")).role === "SuperAdmin" && isMultiDeleteButton && <button className="btn btn-soft-danger"
                              onClick={() => setDeleteModalMulti(true)}
                            ><i className="ri-delete-bin-2-line"></i></button>
                          }
                          <button className="btn btn-danger">
                            <i className="ri-filter-2-line me-1 align-bottom"></i>{" "}
                            Шүүлт хийх
                          </button>
                          <button className="btn btn-soft-success" onClick={() => setIsExportCSV(true)}>Хадгалах</button>
                        </div>
                      </div>
                    </div>
                  </CardHeader>
                </Card>
              </Col>
              <Col xxl={9}>
                <Card id="campaignList">
                  <CardBody className="pt-0">
                    <div>
                      {isCampaignSuccess && campaigns.length ? (
                        <TableContainer
                          columns={columns}
                          data={(campaigns || [])}
                          isGlobalFilter={true}
                          isAddUserList={false}
                          customPageSize={300}
                          className="custom-header-css"
                          divClass="table-responsive table-card mb-3"
                          tableClass="align-middle table-nowrap"
                          theadClass="table-light"
                          handleCampaignClick={handleCampaignClicks}
                          isCampaignsFilter={true}
                          SearchPlaceholder='Search for campaign...'
                        />
                      ) : (<Loader error={error} />)
                      }
                    </div>

                    <Modal id="showModal" isOpen={modal} toggle={toggle} centered> 
                      <ModalHeader className="bg-soft-info p-3" toggle={toggle}>
                        {!!isEdit ? "Нэр дэвшигчийн мэдээллийг засварлах" : "Шинээр нэр дэвшигч нэмэх"}
                      </ModalHeader>

                      <Form className="tablelist-form" onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}>
                        <ModalBody>
                          <Input type="hidden" id="id-field" />
                          <Row className="g-3">
                            <Col lg={12}>
                              <div className="text-center">
                                <div className="position-relative d-inline-block">
                                  <div className="position-absolute  bottom-0 end-0">
                                    <Label htmlFor="image" className="mb-0">
                                      <div className="avatar-xs cursor-pointer">
                                        <div className="avatar-title bg-light border rounded-circle text-muted">
                                          <i className="ri-image-fill"></i>
                                        </div>
                                      </div>
                                    </Label>
                                    <Input className="form-control d-none" id="image" type="file"
                                      accept="image/png, image/gif, image/jpeg"
                                      onChange={(e) => handlePreview(e.target.files[0])}                                   
                                    />                                 
                                  </div>
                                
                                  <div className="avatar-lg p-1">
                                    <div className="avatar-title bg-light rounded-circle">
                                      <img src={image || dummyImg} alt="" id="image" className="avatar-md rounded-circle object-cover" />                                    
                                    </div>
                                  </div>                                
                                </div> 
                                                          
                              </div>
                            </Col>
                            <Col lg={12}>
                              <div className="text-center">
                                  <button type="button" className="btn btn-light" onClick={handleUpload} > Зураг илгээх </button>    
                              </div>      
                            </Col>
                            <Col lg={12}>
                              <div className="text-center">
                                  {
                                      uploaded === "yes" ?
                                      (
                                          <UncontrolledAlert color="success">
                                              Судалгааны зураг <strong>амжилттай</strong> илгээгдлээ!
                                          </UncontrolledAlert>
                                      ) : uploaded === "no" ? (
                                          <UncontrolledAlert color="danger" className="mb-xl-0">
                                              Судалгааны зураг илгээхэд <strong>алдаа</strong> гарлаа!
                                          </UncontrolledAlert>
                                      ) : null
                                  }     
                              </div> 
                            </Col>
                            <Col lg={12}>
                            <div>
                                <Label
                                  htmlFor="name"
                                  className="form-label"
                                >
                                  Судалгааны нэр{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  name="name"
                                  id="customername-field"
                                  className="form-control"
                                  placeholder="Судалгааны нэрийг оруулах" 
                                  type="text"
                                  validate={{
                                    required: { value: true },
                                  }}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.name || ""}
                                  invalid={
                                    validation.touched.name && validation.errors.name ? true : false
                                  }
                                />
                                {validation.touched.name && validation.errors.name ? (
                                  <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                ) : null}

                              </div>
                            </Col>
                            <Col lg={12}>
                              <div>
                                <Label
                                  htmlFor="keywords"
                                  className="form-label"
                                >
                                  Судалгааны түлхүүр үгс{" "}
                                  <span className="text-danger">*</span>
                                </Label>

                                <TagsInput
                                      className="form-control"
                                      value={keywords}
                                      onChange={setKeywords}
                                      name="keywords"
                                      placeHolder="Мэдээлэл цуглуулах түлхүүр үгс"
                                  />
                              </div>
                            </Col>
                            <Col lg={12}>
                              <div>
                                <Label
                                  htmlFor="party"
                                  className="form-label"
                                >
                                  Намын харьяалал 
                                </Label>
                                <Input
                                  name="party"
                                  id="party-field"
                                  className="form-control"
                                  placeholder="Намын харьяалал оруулах"
                                  type="text"                                
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.party || ""}
                                />
                              </div>
                            </Col>
                            <Col lg={12}>
                              <div>
                                <Label
                                  htmlFor="section"
                                  className="form-label"
                                >
                                  Бүс/Тойрог 
                                </Label>
                                <Input
                                  name="section"
                                  id="section-field"
                                  className="form-control"
                                  placeholder="Бүс/Тойрог оруулах"
                                  type="text"                                
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.section || ""}
                                />
                              </div>
                            </Col>
                            <Col lg={12}>
                              <div>
                                <Label
                                  htmlFor="points"
                                  className="form-label"
                                >
                                  Social оноо 
                                </Label>
                                <Input
                                  name="points"
                                  id="points-field"
                                  className="form-control"
                                  placeholder="Social оноо оруулах"
                                  type="text"                                
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.points || ""}
                                />
                              </div>
                            </Col>
                            <Col lg={12}>
                              <div>
                                <Label
                                  htmlFor="desc"
                                  className="form-label"
                                >
                                  Нэмэлт тайлбар 
                                </Label>
                                <Input
                                  name="desc"
                                  id="desc-field"
                                  className="form-control"
                                  placeholder="Тайлбар оруулах"
                                  type="text"                                
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.desc || ""}
                                />
                              </div>
                            </Col>                         
                          </Row>
                        </ModalBody>
                        <ModalFooter>
                          <div className="hstack gap-2 justify-content-end"> 
                            <button type="submit" className="btn btn-success" id="add-btn"> {!!isEdit ? "Засварлах" : "Хадгалах"} </button>
                            <button type="button" className="btn btn-light" onClick={(ev) => { ev.preventDefault(); onFormCancel(); }} > Цуцлах </button>
                          </div>
                        </ModalFooter>
                      </Form>
                    </Modal>

                    <Modal id="showPermissionModal" isOpen={permissionModal} toggle={permissionToggle} centered> 
                      <ModalHeader className="bg-soft-info p-3" toggle={permissionToggle}>
                        Судалгаанд хандах эрх тохируулах
                      </ModalHeader>

                      <ModalBody>
                          <Row>
                          <Col md={12}>
                              <Card>
                                <CardBody>                                
                                  <DualListBox
                                    canFilter
                                    filterCallback={(options, filterInput) => {
                                      if (filterInput === "") {
                                        return true;
                                      }

                                      return new RegExp(filterInput, "i").test(options.label);
                                    }}
                                    filterPlaceholder="Хайх..."
                                    options={dataDual}
                                    selected={selected}
                                    onChange={onListChange}
                                    icons={{
                                      moveLeft: <span className="mdi mdi-chevron-left"  key="key" />,
                                      moveAllLeft: [
                                          <span className="mdi mdi-chevron-double-left"  key="key" />
                                      ],
                                      moveRight: <span className="mdi mdi-chevron-right"  key="key" />,
                                      moveAllRight: [
                                          <span className="mdi mdi-chevron-double-right"  key="key" />
                                      ],
                                      moveDown: <span className="mdi mdi-chevron-down"  key="key" />,
                                      moveUp: <span className="mdi mdi-chevron-up"  key="key" />,
                                      moveTop: <span className="mdi mdi-chevron-double-up"  key="key" />,
                                      moveBottom: <span className="mdi mdi-chevron-double-down"  key="key" />,
                                  }}
                                  />
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>                    
                        </ModalBody>
                        <ModalFooter>
                          <div className="hstack gap-2 justify-content-end"> 
                            <button type="submit" className="btn btn-success" id="add-btn" onClick={(ev) => { ev.preventDefault(); onPermissionSave(); }}> Хадгалах </button>
                            <button type="button" className="btn btn-light" onClick={(ev) => { ev.preventDefault(); onPermissionCancel(); }} > Цуцлах </button>
                          </div>
                        </ModalFooter>
                    </Modal>

                    <ToastContainer closeButton={false} limit={1} />
                  </CardBody>
                </Card>
              </Col>

              <Col xxl={3}>
                <Card id="campaign-view-detail">
                  <CardBody className="text-center">
                    <div className="position-relative d-inline-block">
                      <img
                        src={info.image || dummyImg}
                        alt=""
                        className="avatar-lg rounded-circle img-thumbnail"
                      />
                      <span className="campaign-active position-absolute rounded-circle bg-success">
                        <span className="visually-hidden"></span>
                      </span>
                    </div>
                    <h5 className="mt-4 mb-1">{info.name || ""}</h5>
                    <p className="text-muted">
                      <span className={`badge badge-soft-${
                              info.status === "Active"
                              ? "success"
                              : info.status === "Suspend"
                              ? "danger"
                              : "warning"
                          } me-1`}>
                      {info.status === "Active"
                              ? "Идэвхтэй"
                              : info.status === "Suspend"
                              ? "Идэвхгүй"
                              : "Хүлээгдэж байгаа"}
                        </span>
                    </p>                  
                    
                    <ul className="list-inline mb-0">
                      <li className="list-inline-item avatar-xs">
                        <Link
                          to="#"
                          className="avatar-title bg-soft-success text-success fs-15 rounded"
                        >
                          <i className="ri-phone-line"></i>
                        </Link>
                      </li>
                      <li className="list-inline-item avatar-xs">
                        <Link
                          to="#"
                          className="avatar-title bg-soft-danger text-danger fs-15 rounded"
                        >
                          <i className="ri-mail-line"></i>
                        </Link>
                      </li>
                      <li className="list-inline-item avatar-xs">
                        <Link
                          to="#"
                          className="avatar-title bg-soft-warning text-warning fs-15 rounded"
                        >
                          <i className="ri-question-answer-line"></i>
                        </Link>
                      </li>
                    </ul>
                  </CardBody>
                  <CardBody>
                    <h6 className="text-muted text-uppercase fw-semibold mb-3">
                      Дэлгэрэнгүй мэдээлэл
                    </h6>
                    <p className="text-muted mb-4">
                      
                    </p>
                    <div className="table-responsive table-card">
                      <Table className="table table-borderless mb-0">
                        <tbody>
                          <tr>
                            <td className="fw-medium">
                              Тайлбар
                            </td>
                            <td>{info.desc || ""}</td>
                          </tr>                       
                          <tr>
                            <td className="fw-medium">
                              Ач холбогдол
                            </td>
                            <td>
                            <span className={`badge badge-soft-${
                              info.priority === "Urgent"
                              ? "primary"
                              : info.priority === "High"
                              ? "info"
                              : info.priority === "Medium"
                              ? "success"
                              : info.priority === "Low"
                              ? "danger"
                              : "warning"
                              } me-1`}>
                              {info.priority === "Urgent"
                                          ? "Маш чухал"
                                          : info.priority === "High"
                                          ? "Чухал"
                                          : info.priority === "Medium"
                                          ? "Дунд"
                                          : "Бага"} </span>
                            </td>
                          </tr>
                          <tr>
                            <td className="fw-medium">
                              Мэдээлэл цуглуулах хурд
                            </td>
                            <td>
                            <span className={`badge badge-soft-${
                              info.speed === "15mins"
                              ? "primary"
                              : info.speed === "30mins"
                              ? "info"
                              : info.speed === "1hour"
                              ? "success"
                              : info.speed === "2hours"
                              ? "danger"
                              : "warning"
                              } me-1`}>
                              {info.speed ? speedOptions.map((item, key) => (
                                    <React.Fragment key={key}>
                                      {item.options.map((item, key) => (
                                        info.speed === item.value && item.label
                                      ))}
                                    </React.Fragment>
                                  )) : "1 цаг"} </span>
                            </td>
                          </tr>
                          <tr>
                            <td className="fw-medium">
                              Түлхүүр үгc
                            </td>
                            <td>
                              {(info.keywords || [""]).map((item, key) => (<span className="badge badge-soft-primary me-1" key={key}>{item}</span>))}
                            </td>
                          </tr>
                          <tr>
                            <td className="fw-medium">
                              Facebook хэрэглэгчийн IDs
                            </td>
                            <td>
                              {(info.party || [""]).map((item, key) => (<span className="badge badge-soft-primary me-1" key={key}>{item}</span>))}
                            </td>
                          </tr>  
                          <tr>
                            <td className="fw-medium">
                              Автоматаар хугацаа сунгагдах эсэх
                            </td>
                            <td>
                            {info.postpone === "Yes"
                                          ? "Тийм"
                                          : info.postpone === "No"
                                          ? "Үгүй"                                        
                                          : "Мэдэгдэх"}
                            </td>
                          </tr>                        
                          <tr>
                            <td className="fw-medium">
                              Эхлэх огноо
                            </td>
                            <td>
                              {handleValidDate(info.validFrom || "")}{" "}
                              <small className="text-muted">{handleValidTime(info.validFrom || "")}</small>
                            </td>
                          </tr>
                          <tr>
                            <td className="fw-medium">
                              Дуусах огноо
                            </td>
                            <td>
                              {handleValidDate(info.validTo || "")}{" "}
                              <small className="text-muted">{handleValidTime(info.validTo || "")}</small>
                            </td>
                          </tr>                       
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Campaigns;
