module.exports = {
  google: {
    API_KEY: "",
    CLIENT_ID: "",
    SECRET: "",
  },
  facebook: {
    APP_ID: "",
  },
  api: {  
    API_URL: "https://datachain.art/api",
    REAPI_URL: "https://datachain.art/api_v2/v2",
  }
};