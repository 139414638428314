import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  getUsers as getUsersApi,    
  addNewUser as addNewUserApi,
  updateUser as updateUserApi,
  deleteUser as deleteUserApi
} from "../../helpers/realbackend_helper";

export const getUsers = createAsyncThunk("crm/getUsers" , async () => {
  try{
    const response = await getUsersApi()
    return response;
  }catch (error) {
    return error;
  }
})

export const addNewUser = createAsyncThunk("crm/addNewUser" , async (contact) => {
  try{
    const response = addNewUserApi(contact)
    toast.success("Шинэ хэрэглэгчийн мэдээллийг амжилттай нэмлээ", { autoClose: 3000 });
    return response;
  }catch (error) {
    toast.error("Шинэ хэрэглэгчийн мэдээллийг нэмэхэд алдаа гарлаа!", { autoClose: 3000 });
    return error;
  }
})

export const updateUser = createAsyncThunk("crm/updateUser" , async (contact) => {
  try{
    const response = updateUserApi(contact)
    toast.success("Хэрэглэгчийн мэдээллийг амжилттай засварлалаа", { autoClose: 3000 });
    return response;
  }catch (error) {
    toast.error("Хэрэглэгчийн мэдээллийг засварлахад алдаа гарлаа!", { autoClose: 3000 });
    return error;
  }
})

export const deleteUser = createAsyncThunk("crm/deleteUser" , async (contact) => {
  try{
    const response = deleteUserApi(contact)
    toast.success("Хэрэглэгчийн мэдээллийг амжилттай устгалаа", { autoClose: 3000 });
    return { contact, ...response };
  }catch (error) {
    toast.error("Хэрэглэгчийн мэдээллийг устгахад алдаа гарлаа!", { autoClose: 3000 });
    return error;
  }
})
