import BreadCrumb from '../../../Components/Common/BreadCrumb';
import Posts from './Posts';
import Widgets from './Widgets';
import profileBg from '../../../assets/images/dc-bg.gif';
import { useLocation, Navigate, Link } from "react-router-dom";
import * as moment from "moment";

import dummyImg from "../../../assets/images/users/user-dummy-img.jpg";

import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { Container, Card, CardBody, Col, Row, Input, Label, Form, UncontrolledAlert, Badge, Offcanvas, OffcanvasHeader, OffcanvasBody, UncontrolledCarousel, FormFeedback,} from 'reactstrap';
import { icoWidgetsList } from '../../../common/data';
import { isEmpty } from "lodash";
import ShowMoreText from "react-show-more-text";
import ReactPaginate from "react-paginate";
import { useReactToPrint } from 'react-to-print';
import ReactPlayer from 'react-player';

//Import Flatepicker
import Flatpickr from "react-flatpickr";
import FeatherIcon from "feather-icons-react";
import CountUp from "react-countup";
import { Spinner } from 'reactstrap';
import { useSelector, useDispatch } from "react-redux";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import like from "../../../assets/images/emojis/liked.png";
import love from "../../../assets/images/emojis/love.png";
import care from "../../../assets/images/emojis/care.png";
import haha from "../../../assets/images/emojis/haha.png";
import wow from "../../../assets/images/emojis/wow.png";
import sad from "../../../assets/images/emojis/sad.png";
import angry from "../../../assets/images/emojis/angry.png";
import heart from "../../../assets/images/emojis/heart.png";
import poop from "../../../assets/images/emojis/poop.png";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

import {
    getFBPosts as onGetFBPosts,
    getTWPosts as onGetTWPosts,
    getNWPosts as onGetNWPosts,
    getIGPosts as onGetIGPosts,
    addNewPost as onAddNewPost,
    updatePost as onUpdatePost,
    deletePost as onDeletePost,
    getTotalStatsQueryPosts as onGetTotalStatsQueryPosts,
  } from "../../../slices/thunks";

const CampaignPosts = () => {
    const location = useLocation();
    if (!location.state) {
      return <Navigate to="/" />;
    }
    
    document.title=`${location.state.campaignName} | IKHNUDEN - Media Monitoring System`;

    const dispatch = useDispatch();

    const { fbposts, twposts, nwposts, igposts, 
            isFBPostSuccess, isTWPostSuccess, isNWPostSuccess,  isIGPostSuccess, 
            fberror, twerror, nwerror, igerror,
            fbpostsLoading, twpostsLoading, nwpostsLoading, igpostsLoading, 
            totalstatsQueryposts, isTotalStatsQueryPostSuccess, totalstatsQueryerror, } = useSelector((state) => ({
      fbposts: state.Post.fbposts,
      isFBPostSuccess: state.Post.isFBPostSuccess,
      fberror: state.Post.fberror,
      fbpostsLoading: state.Post.fbpostsLoading,

      twposts: state.Post.twposts,
      isTWPostSuccess: state.Post.isTWPostSuccess,
      twerror: state.Post.twerror,
      twpostsLoading: state.Post.twpostsLoading,

      nwposts: state.Post.nwposts,
      isNWPostSuccess: state.Post.isNWPostSuccess,
      nwerror: state.Post.nwerror,
      nwpostsLoading: state.Post.nwpostsLoading,

      igposts: state.Post.igposts,
      isIGPostSuccess: state.Post.isIGPostSuccess,
      igerror: state.Post.igerror,
      igpostsLoading: state.Post.igpostsLoading,

      totalstatsQueryposts: state.Post.totalstatsQueryposts,
      isTotalStatsQueryPostSuccess: state.Post.isTotalStatsQueryPostSuccess,
      totalstatsQueryerror: state.Post.totalstatsQueryerror,
    }));

    const [isEdit, setIsEdit] = useState(false);
    const [fbpost, setFBPost] = useState(null);
    const [twpost, setTWPost] = useState(null);
    const [nwpost, setNWPost] = useState(null);
    const [igpost, setIGPost] = useState(null);

    const [commentsDetail, setCommentsDetail] = useState([]);
    const [commentsDetailSource, setCommentsDetailSource] = useState(null);
    const [reactionsDetail, setReactionsDetail] = useState([]);
    const [sharesDetail, setSharesDetail] = useState([]);

    const [totalFBPostCount, setTotalFBPostCount] = useState(0);
    const [totalTWPostCount, setTotalTWPostCount] = useState(0);
    const [totalNWPostCount, setTotalNWPostCount] = useState(0);
    const [totalIGPostCount, setTotalIGPostCount] = useState(0);

    const [searchText, setSearchText] = useState(null);
    const [beginDate, setBeginDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const [errorMsg, setErrorMsg] = useState(null);
    const [errorMsgShow, setErrorMsgShow] = useState(false);
    
    const [isInfoDetails, setIsInfoDetails] = useState(false);
    const [isCommentsDetails, setIsCommentsDetails] = useState(false);
    const [isReactionsDetails, setIsReactionsDetails] = useState(false);
    const [isSharesDetails, setIsSharesDetails] = useState(false);
    const [isAddNews, setIsAddNews] = useState(false);

    const [addNewsData, setAddNewsData] = useState("Мэдээний агуулга оруулах...");

    const componentRef = useRef();
    
    const defaultdate = () => {
        let d = new Date(),
          months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        return ((d.getFullYear() + '-' + d.getMonth() + '-' + d.getDate()).toString());
      };
    
      const [dateSearchFrom, setDateValidFrom] = useState(defaultdate());
      const [dateSearchTo, setDateValidTo] = useState(defaultdate());

    const toggleOffCanvas = () => {
        validation.setFieldValue("searchKeyword", "");
        validation.setFieldValue("searchFrom", "");
        validation.setFieldValue("searchTo", "");
    };

    const toggleOffAddNews = () => {
        
    };

    const toggleOffComments = () => {
        setCommentsDetail([]);
    };

    const toggleOffReactions = () => {
        setReactionsDetail([]);
    };

    const toggleOffShares = () => {
        setSharesDetail([]);
    };

    // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      searchKeyword: "",
      searchFrom: "",
      searchTo: "",    
    },
    validationSchema: Yup.object({
        searchKeyword: Yup.string().test(
            'len',
            'Утга хоосон байж болно эсвэл тэмдэгтийн урт 3-аас их байх ёстой',
            (val) => {
                if (val == undefined) {
                    return true;
                }
                return  ((val.length == 0 || (val.length >= 3)))
            }
        ),
        searchFrom: Yup.date().default(() => new Date()),
        searchTo: Yup
            .date()
            .when(
            'searchFrom',
            (searchFrom, schema) => (searchFrom && schema.min(searchFrom, 'Дуусах огноо эхлэх огнооноос баг байж болохгүй')),
            )
    }),
    onSubmit: (values) => {        
        // console.log(values.searchKeyword)
        // console.log(values.searchTo)
        // console.log(values.searchFrom)
        
        const genParams = {
            taskId: location.state.campaignId,            
            page: "1"              
        };

        if(values.searchFrom !== "" && values.searchTo !== "" && values.searchKeyword !== "")
        {
            setBeginDate(values.searchFrom);
            setEndDate(values.searchTo);
            setSearchText(values.searchKeyword);

            const genParams = {
                taskId: location.state.campaignId,
                startDate: values.searchFrom,
                endDate: values.searchTo,
                text: values.searchKeyword,
                page: "1"              
            };

            dispatch(onGetFBPosts(genParams));
            dispatch(onGetTWPosts(genParams));
            dispatch(onGetNWPosts(genParams));
            dispatch(onGetIGPosts(genParams));

            const totalQueryparams = {
                taskId: location.state.campaignId,
                startDate: values.searchFrom,
                endDate: values.searchTo,
                text: values.searchKeyword,
            };
      
            dispatch(onGetTotalStatsQueryPosts(totalQueryparams));
        } else 
        {
            if(values.searchFrom === "" && values.searchTo === "" && values.searchKeyword !== "")
            {
                const genParams = {
                    taskId: location.state.campaignId,
                    text: values.searchKeyword,
                    page: "1"              
                };

                setSearchText(values.searchKeyword);
    
                dispatch(onGetFBPosts(genParams));
                dispatch(onGetTWPosts(genParams));
                dispatch(onGetNWPosts(genParams));
                dispatch(onGetIGPosts(genParams));

                const totalQueryparams = {
                    taskId: location.state.campaignId,
                    text: values.searchKeyword,
                };
          
                dispatch(onGetTotalStatsQueryPosts(totalQueryparams));
            }
            else 
            {
                if(values.searchFrom !== "" && values.searchTo !== "" && values.searchKeyword === "") 
                {
                    setBeginDate(values.searchFrom);
                    setEndDate(values.searchTo);

                    const genParams = {
                        taskId: location.state.campaignId,
                        startDate: values.searchFrom,
                        endDate: values.searchTo,
                        page: "1"              
                    };
        
                    dispatch(onGetFBPosts(genParams));
                    dispatch(onGetTWPosts(genParams));
                    dispatch(onGetNWPosts(genParams));
                    dispatch(onGetIGPosts(genParams));

                    const totalQueryparams = {
                        taskId: location.state.campaignId,
                        startDate: values.searchFrom,
                        endDate: values.searchTo,
                    };
              
                    dispatch(onGetTotalStatsQueryPosts(totalQueryparams));
                }
            }
        }        

        validation.resetForm();
    },
  });


  const addNews = useFormik({
    enableReinitialize: true,

    initialValues: {
      newsSource: "",
      newsSourceImg: "",
      newsUrl: "",
      newsImg: "",
      newsBody: "",
      newsDate: "",  
      newsCategory: "",
      newsReaction: ""    
    },
    validationSchema: Yup.object({
        newsSource: Yup.string().test(
            'len',
            'Утга хоосон байж болно эсвэл тэмдэгтийн урт 3-аас их байх ёстой',
            (val) => {
                if (val == undefined) {
                    return true;
                }
                return  ((val.length == 0 || (val.length >= 3)))
            }
        ),    
        newsSourceImg: Yup.string().test(
            'len',
            'Утга хоосон байж болно эсвэл тэмдэгтийн урт 10-аас их байх ёстой',
            (val) => {
                if (val == undefined) {
                    return true;
                }
                return  ((val.length == 0 || (val.length >= 10)))
            }
        ),    
        newsUrl: Yup.string().test(
            'len',
            'Утга хоосон байж болно эсвэл тэмдэгтийн урт 10-аас их байх ёстой',
            (val) => {
                if (val == undefined) {
                    return true;
                }
                return  ((val.length == 0 || (val.length >= 10)))
            }
        ), 
        newsImg: Yup.string().test(
            'len',
            'Утга хоосон байж болно эсвэл тэмдэгтийн урт 10-аас их байх ёстой',
            (val) => {
                if (val == undefined) {
                    return true;
                }
                return  ((val.length == 0 || (val.length >= 10)))
            }
        ),     
    }),
    onSubmit: (values) => {        
        
        //console.log(values["newsUrl"]);
        
        const newNews = {
            taskId: location.state.campaignId,
            text: addNewsData,
            url: values["newsUrl"],
            media: { image: values["newsImg"], video: "" },
            date: values["newsDate"],
            sent_category: values["newsCategory"],
            sent_reaction: values["newsReaction"],
            user: { username: values["newsSource"], profileImageUrl: values["newsSourceImg"] },
            source: "news"
          };
        
        dispatch(onAddNewPost(newNews));

        addNews.resetForm();
        setIsAddNews(false);
    },
  });


    useEffect(() => {
        try {
            const totalQueryparams = {
                taskId: location.state.campaignId
            };
      
            dispatch(onGetTotalStatsQueryPosts(totalQueryparams));
        }
        catch {
            return <Navigate to="/" />;
        }       
    }, [dispatch]);

    useEffect(() => {
        try {
            const fbparams = {
                taskId: location.state.campaignId,
                page: "1"              
            };

          dispatch(onGetFBPosts(fbparams));
        }
        catch {
            return <Navigate to="/" />;
        }       
    }, [dispatch]);

    useEffect(() => {  
        try {     
            const twparams = {
                taskId: location.state.campaignId,
                page: "1"                                                 
        };

        dispatch(onGetTWPosts(twparams));
        }
        catch {
            return <Navigate to="/" />;
        }    
    }, [dispatch]);

    useEffect(() => { 
        try {      
            const nwparams = {
                taskId: location.state.campaignId,
                page: "1"                                                 
            };

            dispatch(onGetNWPosts(nwparams));
        }
        catch {
            return <Navigate to="/" />;
        }  
    }, [dispatch]);

    useEffect(() => { 
        try {      
            const igparams = {
                taskId: location.state.campaignId,
                page: "1"                                                 
        };

        dispatch(onGetIGPosts(igparams));
        }
        catch {
            return <Navigate to="/" />;
        }  
    }, [dispatch]);
    
    useEffect(() => {
        try {
            setTotalFBPostCount(0);
            setTotalTWPostCount(0);
            setTotalNWPostCount(0);
            setTotalIGPostCount(0);

            totalstatsQueryposts?.map((item) => {
                if(item.totalFBCount)
                {                    
                    setTotalFBPostCount(item.totalFBCount);
                }
                if(item.totalTWCount)
                {
                    setTotalTWPostCount(item.totalTWCount);
                }
                if(item.totalNWCount)
                {
                    setTotalNWPostCount(item.totalNWCount);
                }
                if(item.totalIGCount)
                {
                    setTotalIGPostCount(item.totalIGCount);
                }
            });
        }   
        catch {
            return <Navigate to="/" />;
        }    
    
    }, [totalstatsQueryposts]);

    useEffect(() => {
        setFBPost(fbposts); 
    }, [fbposts, isFBPostSuccess]);

    useEffect(() => {
        setTWPost(twposts);
    }, [twposts, isTWPostSuccess]);

    useEffect(() => {
        setNWPost(nwposts);
    }, [nwposts, isNWPostSuccess]);

    useEffect(() => {
        setIGPost(igposts);
    }, [igposts, isIGPostSuccess]);
    
    useEffect(() => {
        if (!isEmpty(fbposts)) {
            setFBPost(fbposts);
            setIsEdit(false);
        }
    }, [fbposts, fbpostsLoading]);

    useEffect(() => {        
        if (!isEmpty(twposts)) {
            setTWPost(twposts);
            setIsEdit(false);
        }
    }, [twposts]);

    useEffect(() => {        
        if (!isEmpty(nwposts)) {
            setNWPost(nwposts);
            setIsEdit(false);
        }
    }, [nwposts]);

    useEffect(() => {        
        if (!isEmpty(igposts)) {
            setIGPost(igposts);
            setIsEdit(false);
        }
    }, [igposts]);

    const dateformaterValidFrom = (e) => {
        const date = e.toString().split(" ");
        const joinDate = (date[3] + "-" + date[1] + "-" + date[2]).toString();
        const joined = moment(joinDate).format("YYYY-MM-DD");
        setDateValidFrom(joined);
    };
    
    const dateformaterValidTo = (e) => {
        const date = e.toString().split(" ");
        const joinDate = (date[3] + "-" + date[1] + "-" + date[2]).toString();
        const joined = moment(joinDate).format("YYYY-MM-DD");
        setDateValidTo(joined);
    };

    const handleFBPageClick = async (data) => {
        try {            

            let currentPage = data.selected + 1;

            if(beginDate != null && endDate != null && searchText != null)
            {
                const fbparams = {
                    taskId: location.state.campaignId,
                    page: currentPage,
                    startDate: beginDate,
                    endDate: endDate,
                    text: searchText 
                };
              
                dispatch(onGetFBPosts(fbparams));
            }
            else if(beginDate != null && endDate != null && searchText == null)
            {
                const fbparams = {
                    taskId: location.state.campaignId,
                    page: currentPage,
                    startDate: beginDate,
                    endDate: endDate
                };
              
                dispatch(onGetFBPosts(fbparams));
            } 
            else if(beginDate == null && endDate == null && searchText != null)
            {
                const fbparams = {
                    taskId: location.state.campaignId,
                    page: currentPage,
                    text: searchText 
                };
              
                dispatch(onGetFBPosts(fbparams));
            } else
            {
                const fbparams = {
                    taskId: location.state.campaignId,
                    page: currentPage 
                };
              
                dispatch(onGetFBPosts(fbparams));
            }            
        }
        catch {
            return <Navigate to="/" />;
        }
    };

    const handleTWPageClick = async (data) => {
        try {
            let currentPage = data.selected + 1;

            if(beginDate != null && endDate != null && searchText != null)
            {
                const twparams = {
                    taskId: location.state.campaignId,
                    page: currentPage,
                    startDate: beginDate,
                    endDate: endDate,
                    text: searchText 
                };
              
                dispatch(onGetTWPosts(twparams));
            }
            else if(beginDate != null && endDate != null && searchText == null)
            {
                const twparams = {
                    taskId: location.state.campaignId,
                    page: currentPage,
                    startDate: beginDate,
                    endDate: endDate
                };
              
                dispatch(onGetTWPosts(twparams));
            } 
            else if(beginDate == null && endDate == null && searchText != null)
            {
                const twparams = {
                    taskId: location.state.campaignId,
                    page: currentPage,
                    text: searchText 
                };
              
                dispatch(onGetTWPosts(twparams));
            } else
            {
                const twparams = {
                    taskId: location.state.campaignId,
                    page: currentPage 
                };
              
                dispatch(onGetTWPosts(twparams));
            }        
        }
        catch {
            return <Navigate to="/" />;
        }
    };

    const handleNWPageClick = async (data) => {
        try {
            let currentPage = data.selected + 1;

            if(beginDate != null && endDate != null && searchText != null)
            {
                const nwparams = {
                    taskId: location.state.campaignId,
                    page: currentPage,
                    startDate: beginDate,
                    endDate: endDate,
                    text: searchText 
                };
              
                dispatch(onGetNWPosts(nwparams));
            }
            else if(beginDate != null && endDate != null && searchText == null)
            {
                const nwparams = {
                    taskId: location.state.campaignId,
                    page: currentPage,
                    startDate: beginDate,
                    endDate: endDate
                };
              
                dispatch(onGetNWPosts(nwparams));
            } 
            else if(beginDate == null && endDate == null && searchText != null)
            {
                const nwparams = {
                    taskId: location.state.campaignId,
                    page: currentPage,
                    text: searchText 
                };
              
                dispatch(onGetNWPosts(nwparams));
            } else
            {
                const nwparams = {
                    taskId: location.state.campaignId,
                    page: currentPage 
                };
              
                dispatch(onGetNWPosts(nwparams));
            }   
        }
        catch {
            return <Navigate to="/" />;
        }
    };

    const handleIGPageClick = async (data) => {
        try {
            let currentPage = data.selected + 1;

            if(beginDate != null && endDate != null && searchText != null)
            {
                const igparams = {
                    taskId: location.state.campaignId,
                    page: currentPage,
                    startDate: beginDate,
                    endDate: endDate,
                    text: searchText 
                };
              
                dispatch(onGetIGPosts(igparams));
            }
            else if(beginDate != null && endDate != null && searchText == null)
            {
                const igparams = {
                    taskId: location.state.campaignId,
                    page: currentPage,
                    startDate: beginDate,
                    endDate: endDate
                };
              
                dispatch(onGetIGPosts(igparams));
            } 
            else if(beginDate == null && endDate == null && searchText != null)
            {
                const igparams = {
                    taskId: location.state.campaignId,
                    page: currentPage,
                    text: searchText 
                };
              
                dispatch(onGetIGPosts(igparams));
            } else
            {
                const igparams = {
                    taskId: location.state.campaignId,
                    page: currentPage 
                };
              
                dispatch(onGetIGPosts(igparams));
            }   
        }
        catch {
            return <Navigate to="/" />;
        }
    };

    const setMedia = (image, images, video) => {
        var imgs = []
        
        try {
            if (images && images.length > 1) 
            {
                images.map((value, key) => ( 
                    imgs.push({
                        altText: " ",
                        caption: " ",
                        key: key,
                        src: "https://datachain.art/api/images/DataChainMedia/" + value,
                    })
                 )) 

                 console.log(imgs)
            }

            if (image != "NULL" && video != "NULL" && image && video)
            {
                return <ReactPlayer
                            className="img-fluid explore-img"
                            url = {"https://datachain.art/api/images/DataChainMedia/" + video}                           
                            controls = {true}
                        />
            }
            else if (image != "NULL" && video == "NULL")
            {
                if (imgs.length > 1) 
                {
                    return <React.Fragment>                        
                        <UncontrolledCarousel
                        interval={62000}
                            items={imgs}
                        />
                    </React.Fragment>
                }
                else 
                    return <img src={"https://datachain.art/api/images/DataChainMedia/" + image} alt="" className="img-fluid explore-img" />
            }
        }
        catch {
                return "";
            }
    };

    const handleValidDate = date => {
        const date1 = moment(new Date(date)).format("Y-MM-DD");
        return date1;
    };
    
    const handleValidTime = (time) => {
        const time1 = new Date(time);
        const getHour = time1.getUTCHours();
        const getMin = time1.getUTCMinutes();
        const getTime = `${getHour}:${getMin}`;
        const updateTime = moment.utc(getTime, 'HH:mm').format('HH:mm');
        return updateTime;
    };

    const toggleInfo = () => {
        setIsInfoDetails(!isInfoDetails);
    };

    const toggleAddNews = () => {
        setIsAddNews(!isAddNews);
    };

    const toggleComments = () => {
        setIsCommentsDetails(!isCommentsDetails);
    };

    const onShowCommentClick = (comments_extra, source) => {
        setIsCommentsDetails(!isCommentsDetails);       
        setCommentsDetail(comments_extra);
        setCommentsDetailSource(source);
    };

    const onShowReactionClick = (reactions, source) => {
        setIsReactionsDetails(!isReactionsDetails);       
        setReactionsDetail(reactions);
    };

    const onShowShareClick = (shares, source) => {
        setIsSharesDetails(!isSharesDetails);       
        setSharesDetail(shares);
    };

    const detectReactions = (value) => {
        switch(value) {
          case 'like':
              return like;
          case 'love':
              return love;
          case 'care':
              return care;
          case 'haha':
              return haha;
          case 'wow':
              return wow;
          case 'sad':
              return sad;
          case 'angry':
              return angry;
          case 'heart':
              return heart;
          case 'poop':
              return poop;
          default:
            return like;
        }
      }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                   
                    <Row>
                        <Col lg={12}>
                            <Card className="mt-n4 mx-n4">                                
                                <div className="bg-soft-primary">
                                    <CardBody className="px-4 pb-4">
                                    <Row className="mb-3">
                                        <Col className="col-md">
                                        <Row className="align-items-center g-3">
                                            <Col md="auto">
                                            <div className="avatar-lg">
                                                <img src={location.state.campaignImage} alt="user-img"
                                                    className="img-thumbnail rounded-circle" />
                                            </div>
                                            </Col>
                                            <Col className="col-md">
                                            <div>
                                                <h4 className="fw-bold">{location.state.campaignName}</h4>
                                                <div className="hstack gap-3 flex-wrap">
                                                <div>
                                                    <i className="ri-building-line align-bottom me-1"></i>{" "}
                                                    {location.state.campaignDesc}
                                                </div>                                                                           
                                                <div className="vr"></div>
                                                <div>
                                                    <i
                                                    className="ri-timer-line me-1 text-black-75 fs-16 align-middle"></i>
                                                    {handleValidDate(location.state.campaignValidFrom)},{" "}
                                                    <small className="text-muted">{handleValidTime(location.state.campaignValidFrom)}</small>
                                                </div>
                                                <div>
                                                    <i
                                                    className="ri-timer-fill me-1 text-black-75 fs-16 align-middle"></i>
                                                    {handleValidDate(location.state.campaignValidTo)},{" "}
                                                    <small className="text-muted">{handleValidTime(location.state.campaignValidTo)}</small>
                                                </div>                                              
                                                </div>
                                            </div>
                                            </Col>
                                        </Row>
                                        </Col>

                                        <Col md="auto">
                                        <div className="hstack gap-1 flex-wrap mt-4 mt-md-0">
                                            <Link to="/dashboard" className="btn btn-info add-btn" state={{campaignId: location.state.campaignId, 
                                                            campaignName: location.state.campaignName,
                                                            campaignDesc: location.state.campaignDesc,
                                                            campaignImage: location.state.campaignImage,
                                                            campaignStatus: location.state.campaignStatus,
                                                            campaignValidFrom: location.state.campaignValidFrom,
                                                            campaignValidTo: location.state.campaignValidTo,
                                                            campaignDataAnalyze: location.state.campaignDataAnalyze
                                                        }}> 
                                                        <i className="ri-slideshow-line align-bottom me-1"></i>
                                                        Хянах самбар
                                            </Link>  
                                            <button type="button" className="btn btn-success" onClick={toggleInfo}>
                                                <i className="ri-filter-line"></i>{" "}
                                                Шүүж харах
                                            </button>
                                            <button
                                            type="button"
                                            className="btn btn-icon btn-sm btn-ghost-warning fs-16"
                                            >
                                            <i className="ri-star-fill"></i>
                                            </button>  
                                            <button
                                            type="button"
                                            className="btn btn-icon btn-sm btn-ghost-primary fs-16"
                                            >
                                            <i className="ri-flag-line"></i>
                                            </button>
                                            { 
                                                JSON.parse(localStorage.getItem("authUser")).role === "SuperAdmin" &&
                                                <React.Fragment>
                                                    <button type="button" className="btn btn-icon btn-sm btn-ghost-primary fs-16" onClick={toggleAddNews}>
                                                        <i className="ri-file-add-fill"></i>                                                        
                                                    </button>   
                                                </React.Fragment>
                                            }  
                                        </div>
                                        </Col>
                                    </Row>
                                    </CardBody>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                    
                    
                    <Row className="mt-n5">
                            <React.Fragment>                     
                                <Col xxl={3} md={6} ref={componentRef}>                               
                                            <Card className="card-animate">
                                                    <CardBody className="bg-soft-secondary">
                                                        <div className="d-flex align-items-center">
                                                            <div className="flex-shrink-0 avatar-sm">
                                                                        <div className="avatar-title bg-light rounded shadow">
                                                                            <img src="https://firebasestorage.googleapis.com/v0/b/mindx-384a6.appspot.com/o/logos%2Ffacebook.webp?alt=media&token=edb9fe7a-90e8-460f-b118-9b46d5edaa41&_gl=1*1g05h1y*_ga*NjM4NDcyNjcxLjE2ODE5MjUzMTk.*_ga_CW55HF8NVT*MTY4NjY0ODk4OC4xOC4xLjE2ODY2NDkzOTAuMC4wLjA." alt="" className="avatar-xs" />
                                                                        </div>
                                                            </div>
                                                            <div className="flex-grow-1 overflow-hidden ms-3">
                                                                <p className="text-uppercase fw-medium text-muted text-truncate mb-3">Facebook</p>
                                                                <div className="d-flex align-items-center mb-3">
                                                                    <h4 className="fs-4 flex-grow-1 mb-0">
                                                                    <span className="counter-value me-1" data-target="25">
                                                                            <CountUp
                                                                                start={0}
                                                                                separator={","}
                                                                                end={totalFBPostCount}
                                                                                duration={2}
                                                                            />
                                                                        </span>
                                                                    </h4>
                                                                </div>
                                                            </div>
                                                        </div>                                           
                                                    </CardBody>                                            
                                                </Card>

                                                <Card>
                                                    <CardBody>
                                                        <Row className="g-3">                                                   
                                                            <Col xxl={12}>
                                                                {<ReactPaginate
                                                                    previousLabel={"←"}
                                                                    nextLabel={"→"}
                                                                    breakLabel={"..."}
                                                                    pageCount={totalFBPostCount <= 12 ? 1 : Math.ceil(totalFBPostCount / 12)}
                                                                    marginPagesDisplayed={1}
                                                                    pageRangeDisplayed={2}
                                                                    onPageChange={handleFBPageClick}
                                                                    containerClassName={"pagination justify-content-center"}
                                                                    pageClassName={"page-item"}
                                                                    pageLinkClassName={"page-link"}
                                                                    previousClassName={"page-item"}
                                                                    previousLinkClassName={"page-link"}
                                                                    nextClassName={"page-item"}
                                                                    nextLinkClassName={"page-link"}
                                                                    breakClassName={"page-item"}
                                                                    breakLinkClassName={"page-link"}
                                                                    activeClassName={"active"}
                                                                />
                                                                }
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            {!isFBPostSuccess && fbpost ? ( <Spinner color="primary"> Loading... </Spinner> ) : (
                                                fbpost && fbpost.map((item, key) => (
                                                    item.source === "facebook" &&
                                                    <Card className="mb-2" key={key}>
                                                        <CardBody>
                                                            <div className="d-flex mb-3">
                                                                <div className="flex-shrink-0 avatar-md">
                                                                    <div className="avatar-title bg-light rounded shadow">
                                                                        <img src={"https://firebasestorage.googleapis.com/v0/b/mindx-384a6.appspot.com/o/logos%2Ffb-user.png?alt=media&token=327ea65e-e726-414c-a3d9-bc159242c2c9"} alt="" className="avatar-sm" />
                                                                    </div>
                                                                </div>
                                                                <div className="flex-grow-1 ms-3">
                                                                    <h5 className="fs-15 mb-1">{item.user.username}</h5>
                                                                    <p className="text-muted mb-2">@{item.user.user_id}</p> 
                                                                    <h6 className={"flex-shrink-0 text-" + item.dateClass + " mb-0"}><i className="ri-time-line align-bottom"></i> 
                                                                    {item.date.split('T')[0]},{" "}
                                                                    <small className="text-muted">{handleValidTime(item.date)}</small></h6>                                                                                                                  
                                                                </div>
                                                                <div>
                                                                    <Badge color="secondary" className="badge-label"> <i className="mdi mdi-circle-medium"></i> {item.sent_category} </Badge>                                                        
                                                                </div>                                                         
                                                                <div>
                                                                    <Link to={item.url} target="_blank" className="badge badge-soft-primary"><i className="ri-arrow-right-up-line align-bottom"></i></Link>
                                                                </div>
                                                            </div>                                               
                                                            <h6 className="text mb-0">
                                                            <ShowMoreText
                                                                    lines={10}
                                                                    more={<span style={{ color: "#0b71b9" }}>Цааш...</span>}
                                                                    less={<span style={{ color: "#0b71b9" }}>Хураах</span>}
                                                                    className="content-css"
                                                                    anchorClass="my-anchor-css-class"
                                                                    expanded={false}
                                                                    width={0}
                                                                    >
                                                                    <p>{item.text}</p>
                                                                </ShowMoreText> 
                                                            <span className="badge badge-soft-success">{item.percentage}</span></h6>                                                  
                                                            {
                                                                setMedia(item.media.image, item.media.images, item.media.video)
                                                            }                                                                              
                                                        </CardBody>
                                                        <CardBody className="border-top border-top-dashed">
                                                            <div className="d-flex">
                                                                <div className="flex-grow-1">                                                            
                                                                    {
                                                                        item.engagements.sharers && item.engagements.sharers.length > 0 ? 
                                                                        <button type="button" className="btn btn-soft-success" onClick={() => onShowShareClick(item.engagements.sharers && item.engagements.sharers, item.source)}>
                                                                            <h6 className="mb-0">{item.engagements.shares}<i className="las la-retweet align-bottom text-success"></i></h6>
                                                                        </button>  
                                                                        : <h6 className="mb-0">{item.engagements.shares}<i className="las la-retweet align-bottom text-success"></i></h6>
                                                                    } 
                                                                </div>
                                                                <div className="flex-grow-1">
                                                                    {
                                                                        item.engagements.comments_extra && item.engagements.comments_extra.length > 0 ? 
                                                                        <button type="button" className="btn btn-soft-success" onClick={() => onShowCommentClick(item.engagements.comments_extra && item.engagements.comments_extra, item.source)}>
                                                                            <h6 className="mb-0">{item.engagements.comments}<i className="bx bx-comment-detail align-bottom text-info"></i></h6>
                                                                        </button>  
                                                                        : <h6 className="mb-0">{item.engagements.comments}<i className="bx bx-comment-detail align-bottom text-info"></i></h6>
                                                                    }   
                                                                </div>
                                                                <div className="flex-grow-1">                                                                                                                          
                                                                    {
                                                                        item.engagements.reactors && item.engagements.reactors.length > 0 ? 
                                                                        <button type="button" className="btn btn-soft-success" onClick={() => onShowReactionClick(item.engagements.reactors && item.engagements.reactors, item.engagements.reactions, item.source)}>
                                                                            <h6 className="mb-0">{item.engagements.likes}<i className="bx bxs-like align-bottom text-primary"></i></h6>
                                                                        </button>  
                                                                        : <h6 className="mb-0">{item.engagements.likes}<i className="bx bxs-like align-bottom text-primary"></i></h6>
                                                                    } 
                                                                </div>                                                        
                                                                <div className="flex-grow-1">   
                                                                    {
                                                                            item.sent_reaction === "Эерэг" ? <Badge color="success" className="badge-label"> <i className="mdi mdi-circle-medium"></i> Эерэг </Badge>
                                                                            : item.sent_reaction === "Сөрөг" ? <Badge color="danger" className="badge-label"> <i className="mdi mdi-circle-medium"></i> Сөрөг </Badge>
                                                                            : <Badge color="warning" className="badge-label"> <i className="mdi mdi-circle-medium"></i> Саармаг </Badge>
                                                                    }
                                                                </div>                                                
                                                            </div>
                                                        </CardBody> 
                                                        {item.engagements.reactions &&  
                                                            <CardBody>
                                                            <div className="d-flex">       
                                                                <React.Fragment>
                                                                <div className="flex-grow-1">
                                                                        <h6 className="mb-0">{item.engagements.reactions.like || 0}<img className="title" src={like} alt=""  style={{ width: "20px", height: "20px" }} /></h6>                                               
                                                                    </div>   
                                                                    <div className="flex-grow-1">
                                                                        <h6 className="mb-0">{item.engagements.reactions.love || 0}<img className="title" src={love} alt=""  style={{ width: "20px", height: "20px" }} /></h6>                                               
                                                                    </div> 
                                                                    <div className="flex-grow-1">
                                                                        <h6 className="mb-0">{item.engagements.reactions.care || 0}<img className="title" src={care} alt=""  style={{ width: "20px", height: "20px" }} /></h6>                                               
                                                                    </div> 
                                                                    <div className="flex-grow-1">
                                                                        <h6 className="mb-0">{item.engagements.reactions.haha || 0}<img className="title" src={haha} alt=""  style={{ width: "20px", height: "20px" }} /></h6>                                               
                                                                    </div> 
                                                                    <div className="flex-grow-1">
                                                                        <h6 className="mb-0">{item.engagements.reactions.wow || 0}<img className="title" src={wow} alt=""  style={{ width: "20px", height: "20px" }} /></h6>                                               
                                                                    </div> 
                                                                    <div className="flex-grow-1">
                                                                        <h6 className="mb-0">{item.engagements.reactions.sad || 0}<img className="title" src={sad} alt=""  style={{ width: "20px", height: "20px" }} /></h6>                                               
                                                                    </div> 
                                                                    <div className="flex-grow-1">
                                                                        <h6 className="mb-0">{item.engagements.reactions.angry || 0}<img className="title" src={angry} alt=""  style={{ width: "20px", height: "20px" }} /></h6>                                               
                                                                    </div> 
                                                                
                                                                </React.Fragment>                                                                                                                                                                             
                                                            </div>
                                                            </CardBody>    
                                                        }                                           
                                                    </Card>
                                                ))
                                            )
                                            }
                                </Col>

                                <Col xxl={3} md={6}>
                                            <Card className="card-animate">
                                                <CardBody className="bg-soft-info">
                                                    <div className="d-flex align-items-center">
                                                        <div className="flex-shrink-0 avatar-sm">
                                                                <div className="avatar-title bg-light rounded shadow">
                                                                    <img src="https://firebasestorage.googleapis.com/v0/b/mindx-384a6.appspot.com/o/logos%2Ftwitter-x-logo-101C7D2420-seeklogo.com.png?alt=media&token=2a4d65d1-b5a6-4da2-896a-d9ba41104298" alt="" className="avatar-xs" />
                                                                </div>
                                                        </div>
                                                        <div className="flex-grow-1 overflow-hidden ms-3">
                                                            <p className="text-uppercase fw-medium text-muted text-truncate mb-3">X/Twitter</p>
                                                            <div className="d-flex align-items-center mb-3">
                                                                <h4 className="fs-4 flex-grow-1 mb-0">
                                                                <span className="counter-value me-1" data-target="25">
                                                                        <CountUp
                                                                            start={0}
                                                                            separator={","}
                                                                            end={totalTWPostCount}
                                                                            duration={2}
                                                                        />
                                                                    </span>
                                                                </h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                                <Card>
                                                    <CardBody>
                                                        <Row className="g-3">                                                   
                                                            <Col xxl={12}>
                                                                {<ReactPaginate
                                                                    previousLabel={"←"}
                                                                    nextLabel={"→"}
                                                                    breakLabel={"..."}
                                                                    pageCount={totalTWPostCount <= 12 ? 1 : Math.ceil(totalTWPostCount / 12)}
                                                                    marginPagesDisplayed={1}
                                                                    pageRangeDisplayed={2}
                                                                    onPageChange={handleTWPageClick}
                                                                    containerClassName={"pagination justify-content-center"}
                                                                    pageClassName={"page-item"}
                                                                    pageLinkClassName={"page-link"}
                                                                    previousClassName={"page-item"}
                                                                    previousLinkClassName={"page-link"}
                                                                    nextClassName={"page-item"}
                                                                    nextLinkClassName={"page-link"}
                                                                    breakClassName={"page-item"}
                                                                    breakLinkClassName={"page-link"}
                                                                    activeClassName={"active"}
                                                                />
                                                                }
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            {isTWPostSuccess ? twpost && twpost.map((item, key) => (
                                                item.source === "twitter" &&
                                                <Card className="mb-2" key={key}>
                                                    <CardBody>
                                                        <div className="d-flex mb-3">
                                                            <div className="flex-shrink-0 avatar-md">
                                                                <div className="avatar-title bg-light rounded shadow">
                                                                    <img src={item.user.profileImageUrl} alt="" className="avatar-sm" />
                                                                </div>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <h5 className="fs-15 mb-1">{item.user.name}</h5>
                                                                <p className="text-muted mb-2">
                                                                    @{item.user.username} <i className="bx bxs-like align-bottom text-primary"></i>{item.user.favouritesCount}
                                                                </p>                                                        
                                                                <h6 className={"flex-shrink-0 text-" + item.dateClass + " mb-0"}><i className="ri-time-line align-bottom"></i> 
                                                                {item.date.split('T')[0]},{" "}
                                                                <small className="text-muted">{handleValidTime(item.date)}</small></h6>                                                                                                       
                                                            </div>
                                                            <div>
                                                                <Badge color="secondary" className="badge-label"> <i className="mdi mdi-circle-medium"></i> {item.sent_category} </Badge>                                                        
                                                            </div> 
                                                            <div>                                                        
                                                                <Link to={item.url} target="_blank" className="badge badge-soft-primary"><i className="ri-arrow-right-up-line align-bottom"></i></Link>
                                                            </div>                                                    
                                                        </div>                                               
                                                        <h6 className="text mb-0">{item.text} <span className="badge badge-soft-success">{item.percentage}</span></h6>                                               
                                                        {item.media.image && item.media.image != "" &&
                                                                        item.media.image.map((value, key) => ( <img id={key} src={value} alt="" className="img-fluid explore-img" /> ))
                                                        }                                       
                                                    </CardBody>
                                                    <CardBody className="border-top border-top-dashed">
                                                        <div className="d-flex">
                                                            <div className="flex-grow-1">
                                                                <h6 className="mb-0">{item.engagements.shares}<i className="las la-retweet align-bottom text-success"></i></h6>
                                                            </div>
                                                            <div className="flex-grow-1">
                                                                <h6 className="mb-0">{item.engagements.comments}<i className="bx bx-comment-detail align-bottom text-info"></i></h6>
                                                            </div>
                                                            <div className="flex-grow-1">
                                                                <h6 className="mb-0">{item.engagements.likes}<i className="ri-heart-fill align-bottom text-danger"></i></h6>
                                                            </div>
                                                            <div className="flex-grow-1">
                                                                <h6 className="mb-0">{item.engagements.quoteCount}<i className="bx bxs-quote-alt-left align-bottom text-primary"></i></h6>
                                                            </div>
                                                            <div className="flex-grow-1">
                                                                <h6 className="mb-0">{item.speadCount}<i className="ri-bar-chart-fill align-bottom text-primary"></i></h6>
                                                            </div>
                                                            <div className="flex-grow-1">   
                                                                    {
                                                                            item.sent_reaction === "Эерэг" ? <Badge color="success" className="badge-label"> <i className="mdi mdi-circle-medium"></i> Эерэг </Badge>
                                                                            : item.sent_reaction === "Сөрөг" ? <Badge color="danger" className="badge-label"> <i className="mdi mdi-circle-medium"></i> Сөрөг </Badge>
                                                                            : <Badge color="warning" className="badge-label"> <i className="mdi mdi-circle-medium"></i> Саармаг </Badge>
                                                                    }
                                                            </div>                                                       
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            )) : <Spinner color="primary"> Loading... </Spinner>}
                                </Col>     

                                <Col xxl={3} md={6}>
                                            <Card className="card-animate">
                                                <CardBody className="bg-soft-warning">
                                                    <div className="d-flex align-items-center">
                                                            <div className="flex-shrink-0 avatar-sm">
                                                                    <div className="avatar-title bg-light rounded shadow">
                                                                        <img src="https://firebasestorage.googleapis.com/v0/b/mindx-384a6.appspot.com/o/logos%2Fnews-default.png?alt=media&token=1180b1c4-aae1-4fb9-9456-6c8bb882f0ce&_gl=1*1luhckv*_ga*NjM4NDcyNjcxLjE2ODE5MjUzMTk.*_ga_CW55HF8NVT*MTY4NjY0ODk4OC4xOC4xLjE2ODY2NDkyMzguMC4wLjA." alt="" className="avatar-xs" />
                                                                    </div>
                                                            </div>
                                                            <div className="flex-grow-1 overflow-hidden ms-3">
                                                                <p className="text-uppercase fw-medium text-muted text-truncate mb-3">Мэдээний сайт</p>
                                                                <div className="d-flex align-items-center mb-3">
                                                                    <h4 className="fs-4 flex-grow-1 mb-0">
                                                                    <span className="counter-value me-1" data-target="25">
                                                                            <CountUp
                                                                                start={0}
                                                                                separator={","}
                                                                                end={totalNWPostCount}
                                                                                duration={2}
                                                                            />
                                                                        </span>
                                                                    </h4>
                                                                </div>
                                                            </div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                            <Card>
                                                    <CardBody>
                                                        <Row className="g-3">                                                   
                                                            <Col xxl={12}>
                                                                {<ReactPaginate
                                                                    previousLabel={"←"}
                                                                    nextLabel={"→"}
                                                                    breakLabel={"..."}
                                                                    pageCount={totalNWPostCount <= 12 ? 1 : Math.ceil(totalNWPostCount / 12) - 1}
                                                                    marginPagesDisplayed={1}
                                                                    pageRangeDisplayed={2}
                                                                    onPageChange={handleNWPageClick}
                                                                    containerClassName={"pagination justify-content-center"}
                                                                    pageClassName={"page-item"}
                                                                    pageLinkClassName={"page-link"}
                                                                    previousClassName={"page-item"}
                                                                    previousLinkClassName={"page-link"}
                                                                    nextClassName={"page-item"}
                                                                    nextLinkClassName={"page-link"}
                                                                    breakClassName={"page-item"}
                                                                    breakLinkClassName={"page-link"}
                                                                    activeClassName={"active"}
                                                                />
                                                                }
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            {isNWPostSuccess ? nwpost && nwpost.map((item, key) => (
                                                item.source === "news" &&
                                                <Card className="mb-2" key={key}>
                                                    <CardBody>
                                                        <div className="d-flex mb-3">
                                                            <div className="flex-shrink-0 avatar-md">
                                                                <div className="avatar-title bg-light rounded shadow">
                                                                {
                                                                    item.source === "news" && <img src={item.user.profileImageUrl} alt="" className="avatar-sm" />
                                                                }                                                           
                                                                </div>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <h5 className="fs-15 mb-1">{item.user.username}</h5> 
                                                                <h6 className={"flex-shrink-0 text-" + item.dateClass + " mb-0"}><i className="ri-time-line align-bottom"></i> 
                                                                {item.date.split('T')[0]},{" "}
                                                                <small className="text-muted">{handleValidTime(item.date)}</small></h6>  
                                                                <div className="flex-grow-1">   
                                                                    {
                                                                            item.sent_reaction === "Эерэг" ? <Badge color="success" className="badge-label"> <i className="mdi mdi-circle-medium"></i> Эерэг </Badge>
                                                                            : item.sent_reaction === "Сөрөг" ? <Badge color="danger" className="badge-label"> <i className="mdi mdi-circle-medium"></i> Сөрөг </Badge>
                                                                            : <Badge color="warning" className="badge-label"> <i className="mdi mdi-circle-medium"></i> Саармаг </Badge>
                                                                    }
                                                                </div>                                                        
                                                            </div>
                                                            <div>
                                                                <Badge color="secondary" className="badge-label"> <i className="mdi mdi-circle-medium"></i> {item.sent_category} </Badge>                                                        
                                                            </div> 
                                                            <div>
                                                                <Link to={item.url} target="_blank" className="badge badge-soft-primary"><i className="ri-arrow-right-up-line align-bottom"></i></Link>
                                                            </div>
                                                        </div>                                               
                                                        <h6 className="text mb-0" >
                                                            <div className="project-details" dangerouslySetInnerHTML={{ __html: item.text.length > 420 ? item.text.substring(0, 420) + "... " : item.text }} />                                                                                            
                                                        </h6>
                                                        {item.media.image && item.media.image != "" &&
                                                                        item.media.image.map((value, key) => ( <img id={key} src={value} alt="" className="img-fluid explore-img" /> ))
                                                        }                                                
                                                    </CardBody>
                                                    <CardBody className="border-top border-top-dashed">
                                                        <div className="d-flex">                                                  
                                                            <div className="flex-grow-1">
                                                                {
                                                                    item.engagements.comments_extra && item.engagements.comments_extra.length > 0 ? 
                                                                    <button type="button" className="btn btn-soft-success" onClick={() => onShowCommentClick(item.engagements.comments_extra && item.engagements.comments_extra, item.source)}>
                                                                        <h6 className="mb-0">{item.engagements.comments}<i className="bx bx-comment-detail align-bottom text-info"></i></h6>
                                                                    </button>  
                                                                    : <h6 className="mb-0">{item.engagements.comments}<i className="bx bx-comment-detail align-bottom text-info"></i></h6>
                                                                }                                                        
                                                            </div>
                                                            {item.user.username === "news.mn" && item.engagements.reactions && 
                                                                <React.Fragment>
                                                                    <div className="flex-grow-1">
                                                                        <h6 className="mb-0">{item.engagements.reactions.wow}<img className="title" src={wow} alt=""  style={{ width: "20px", height: "20px" }} /></h6>                                               
                                                                    </div>   
                                                                    <div className="flex-grow-1">
                                                                        <h6 className="mb-0">{item.engagements.reactions.heart}<img className="title" src={heart} alt=""  style={{ width: "20px", height: "20px" }} /></h6>                                               
                                                                    </div> 
                                                                    <div className="flex-grow-1">
                                                                        <h6 className="mb-0">{item.engagements.reactions.haha}<img className="title" src={haha} alt=""  style={{ width: "20px", height: "20px" }} /></h6>                                               
                                                                    </div> 
                                                                    <div className="flex-grow-1">
                                                                        <h6 className="mb-0">{item.engagements.reactions.love}<img className="title" src={love} alt=""  style={{ width: "20px", height: "20px" }} /></h6>                                               
                                                                    </div> 
                                                                    <div className="flex-grow-1">
                                                                        <h6 className="mb-0">{item.engagements.reactions.sad}<img className="title" src={sad} alt=""  style={{ width: "20px", height: "20px" }} /></h6>                                               
                                                                    </div> 
                                                                    <div className="flex-grow-1">
                                                                        <h6 className="mb-0">{item.engagements.reactions.poop}<img className="title" src={poop} alt=""  style={{ width: "20px", height: "20px" }} /></h6>                                               
                                                                    </div> 
                                                                    <div className="flex-grow-1">
                                                                        <h6 className="mb-0">{item.engagements.reactions.angry}<img className="title" src={angry} alt=""  style={{ width: "20px", height: "20px" }} /></h6>                                               
                                                                    </div> 
                                                                
                                                                </React.Fragment>
                                                            } 
                                                            {item.user.username === "zarig.mn" && item.engagements.reactions && 
                                                                <React.Fragment>
                                                                    <div className="flex-grow-1">
                                                                        <h6 className="mb-0">{item.engagements.reactions.angry}<img className="title" src={angry} alt=""  style={{ width: "20px", height: "20px" }} /></h6>                                               
                                                                    </div>   
                                                                    <div className="flex-grow-1">
                                                                        <h6 className="mb-0">{item.engagements.reactions.care}<img className="title" src={care} alt=""  style={{ width: "20px", height: "20px" }} /></h6>                                               
                                                                    </div> 
                                                                    <div className="flex-grow-1">
                                                                        <h6 className="mb-0">{item.engagements.reactions.haha}<img className="title" src={haha} alt=""  style={{ width: "20px", height: "20px" }} /></h6>                                               
                                                                    </div> 
                                                                    <div className="flex-grow-1">
                                                                        <h6 className="mb-0">{item.engagements.reactions.like}<img className="title" src={like} alt=""  style={{ width: "20px", height: "20px" }} /></h6>                                               
                                                                    </div> 
                                                                    <div className="flex-grow-1">
                                                                        <h6 className="mb-0">{item.engagements.reactions.love}<img className="title" src={love} alt=""  style={{ width: "20px", height: "20px" }} /></h6>                                               
                                                                    </div> 
                                                                    <div className="flex-grow-1">
                                                                        <h6 className="mb-0">{item.engagements.reactions.sad}<img className="title" src={sad} alt=""  style={{ width: "20px", height: "20px" }} /></h6>                                               
                                                                    </div> 
                                                                    <div className="flex-grow-1">
                                                                        <h6 className="mb-0">{item.engagements.reactions.wow}<img className="title" src={wow} alt=""  style={{ width: "20px", height: "20px" }} /></h6>                                               
                                                                    </div> 
                                                                
                                                                </React.Fragment>
                                                            }                                                                                                                                                        
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            )) : <Spinner color="primary"> Loading... </Spinner>}
                                </Col> 

                                <Col xxl={3} md={6}>
                                            <Card className="card-animate">
                                                <CardBody className="bg-soft-danger">
                                                            <div className="d-flex align-items-center">
                                                                <div className="flex-shrink-0 avatar-sm">
                                                                        <div className="avatar-title bg-light rounded shadow">
                                                                            <img src="https://firebasestorage.googleapis.com/v0/b/mindx-384a6.appspot.com/o/logos%2Finstagram.png?alt=media&token=d330b8a2-581b-41f5-bea0-fec35b72c83e&_gl=1*1kt3k7f*_ga*NjM4NDcyNjcxLjE2ODE5MjUzMTk.*_ga_CW55HF8NVT*MTY4NjY0ODk4OC4xOC4xLjE2ODY2NDk1MTguMC4wLjA." alt="" className="avatar-xs" />
                                                                        </div>
                                                                </div>
                                                                <div className="flex-grow-1 overflow-hidden ms-3">
                                                                    <p className="text-uppercase fw-medium text-muted text-truncate mb-3">Instagram</p>
                                                                    <div className="d-flex align-items-center mb-3">
                                                                        <h4 className="fs-4 flex-grow-1 mb-0">
                                                                        <span className="counter-value me-1" data-target="25">
                                                                                <CountUp
                                                                                    start={0}
                                                                                    separator={","}
                                                                                    end={totalIGPostCount}
                                                                                    duration={2}
                                                                                />
                                                                            </span>
                                                                        </h4>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                </CardBody>
                                            </Card>
                                            <Card>
                                                    <CardBody>
                                                        <Row className="g-3">                                                   
                                                            <Col xxl={12}>
                                                                {<ReactPaginate
                                                                    previousLabel={"←"}
                                                                    nextLabel={"→"}
                                                                    breakLabel={"..."}
                                                                    pageCount={totalIGPostCount <= 12 ? 1 : Math.ceil(totalIGPostCount / 12)}
                                                                    marginPagesDisplayed={1}
                                                                    pageRangeDisplayed={2}
                                                                    onPageChange={handleIGPageClick}
                                                                    containerClassName={"pagination justify-content-center"}
                                                                    pageClassName={"page-item"}
                                                                    pageLinkClassName={"page-link"}
                                                                    previousClassName={"page-item"}
                                                                    previousLinkClassName={"page-link"}
                                                                    nextClassName={"page-item"}
                                                                    nextLinkClassName={"page-link"}
                                                                    breakClassName={"page-item"}
                                                                    breakLinkClassName={"page-link"}
                                                                    activeClassName={"active"}
                                                                />
                                                                }
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            {isIGPostSuccess ? igpost && igpost.map((item, key) => (                                        
                                                <Card className="mb-2" key={key}>
                                                    <CardBody>
                                                        <div className="d-flex mb-3">
                                                            <div className="flex-shrink-0 avatar-md">
                                                                <div className="avatar-title bg-light rounded shadow">
                                                                    <img src="https://firebasestorage.googleapis.com/v0/b/mindx-384a6.appspot.com/o/logos%2Fig-user.png?alt=media&token=d99f33ae-7589-46e7-aade-6bc8d45955cc" alt="" className="avatar-sm" />
                                                                </div>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <h5 className="fs-15 mb-1">{item.user.username}</h5>
                                                                <p className="text-muted mb-2">{item.user.user_id}</p> 
                                                                <h6 className={"flex-shrink-0 text-" + item.dateClass + " mb-0"}><i className="ri-time-line align-bottom"></i> 
                                                                {item.date.split('T')[0]},{" "}
                                                                <small className="text-muted">{handleValidTime(item.date)}</small></h6>                                                       
                                                            </div>
                                                            <div>
                                                                <Link to={item.url} target="_blank" className="badge badge-soft-primary"><i className="ri-arrow-right-up-line align-bottom"></i></Link>
                                                            </div>
                                                        </div>  
                                                        <ShowMoreText
                                                                    lines={10}
                                                                    more={<span style={{ color: "#0b71b9" }}>Цааш...</span>}
                                                                    less={<span style={{ color: "#0b71b9" }}>Хураах</span>}
                                                                    className="content-css"
                                                                    anchorClass="my-anchor-css-class"
                                                                    expanded={false}
                                                                    width={0}
                                                                    >
                                                                    <p>{item.text}</p>
                                                        </ShowMoreText>                                              
                                                        {item.media.image &&
                                                            <img src={"https://datachain.art/api/images/DataChainMedia/" + item.media.image} alt="" className="img-fluid explore-img" />
                                                        }                           
                                                    </CardBody>
                                                    <CardBody className="border-top border-top-dashed">
                                                        <div className="d-flex">                                                   
                                                            <div className="flex-grow-1">
                                                                <h6 className="mb-0">{item.engagements.comments}<i className="bx bx-comment-detail align-bottom text-info"></i></h6>
                                                            </div>
                                                            <div className="flex-grow-1">
                                                                <h6 className="mb-0">{item.engagements.likes}<i className="ri-heart-fill align-bottom text-danger"></i></h6>
                                                            </div>    
                                                            <div className="flex-grow-1">   
                                                                    {
                                                                            item.sent_reaction === "Эерэг" ? <Badge color="success" className="badge-label"> <i className="mdi mdi-circle-medium"></i> Эерэг </Badge>
                                                                            : item.sent_reaction === "Сөрөг" ? <Badge color="danger" className="badge-label"> <i className="mdi mdi-circle-medium"></i> Сөрөг </Badge>
                                                                            : <Badge color="warning" className="badge-label"> <i className="mdi mdi-circle-medium"></i> Саармаг </Badge>
                                                                    }
                                                            </div>                                               
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            )): <Spinner color="primary"> Loading... </Spinner>}
                                </Col>                             
                                
                            </React.Fragment>
                        
                    </Row>

                    <Form className="search-form" onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                            }}>
                        <Offcanvas
                        direction="end"
                        isOpen={isInfoDetails}
                        id="offcanvasExample"
                        toggle={() => setIsInfoDetails(false)}
                        onHide={toggleOffCanvas}
                        >
                        <OffcanvasHeader className="bg-light" toggle={() => setIsInfoDetails(false)}>
                            Нийтлэлийн утгаас хайх
                        </OffcanvasHeader>
                        <form action="" className="d-flex flex-column justify-content-end h-100">
                            <OffcanvasBody>
                            <div className="mb-4">
                                    <Label
                                        htmlFor="searchKeyword"
                                        className="form-label"
                                    >
                                    Түлхүүр үг
                                    </Label>

                                    <Input
                                        name="searchKeyword"
                                        id="searchKeyword-field"
                                        className="form-control"
                                        placeholder="Хайх түлхүүр үг оруулах" 
                                        type="text"
                                        value={validation.values.searchKeyword || ""}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        invalid={
                                            validation.touched.searchKeyword && validation.errors.searchKeyword ? true : false
                                        }
                                    />  
                                    {validation.touched.searchKeyword && validation.errors.searchKeyword ? (
                                        <FormFeedback type="invalid">{validation.errors.searchKeyword}</FormFeedback>
                                    ) : null}                      
                            </div>
                            <div className="mb-4">
                                    <Label
                                        htmlFor="searchFrom"
                                        className="form-label"
                                    >
                                    Эхлэх огноо
                                    </Label>

                                    <Flatpickr
                                        name="searchFrom"
                                        id="searchFrom-input"
                                        className="form-control"
                                        placeholder="Огноо сонгох"
                                        options={{
                                            altInput: true,
                                            altFormat: "Y-m-d",
                                            dateFormat: "Y-m-d",
                                        }}
                                        value={validation.values.searchFrom || "Огноо сонгох"}
                                        onChange={(e) => {
                                            dateformaterValidFrom(e);
                                            validation.setFieldValue("searchFrom", e);
                                        }                                    
                                        }
                                        onBlur={validation.handleBlur} 
                                        invalid={
                                            validation.touched.searchFrom && validation.errors.searchFrom ? true : false
                                        }                       
                                        />
                                        {validation.touched.searchFrom && validation.errors.searchFrom ? (
                                            <FormFeedback type="invalid">{validation.errors.searchFrom}</FormFeedback>
                                        ) : null}           
                            </div>
                            <div className="mb-4">
                                    <Label
                                        htmlFor="searchTo"
                                        className="form-label"
                                    >
                                    Дуусах огноо
                                    </Label>

                                    <Flatpickr
                                        name="searchTo"
                                        id="searchTo-input"
                                        className="form-control"
                                        placeholder="Огноо сонгох"
                                        options={{
                                            altInput: true,
                                            altFormat: "Y-m-d",
                                            dateFormat: "Y-m-d",
                                        }}
                                        value={validation.values.searchTo || "Огноо сонгох"}
                                        onChange={(e) => {
                                            dateformaterValidTo(e);
                                            validation.setFieldValue("searchTo", e);
                                        }                                    
                                        }
                                        onBlur={validation.handleBlur}
                                        invalid={
                                            validation.touched.searchTo && validation.errors.searchTo ? true : false
                                        }                              
                                        /> 
                                        {validation.touched.searchTo && validation.errors.searchTo ? (
                                            <FormFeedback type="invalid">{validation.errors.searchTo}</FormFeedback>
                                        ) : null}           
                            </div>
                            <div className="mb-4">
                                <Label
                                htmlFor="status-select"
                                className="form-label text-muted text-uppercase fw-semibold mb-3"
                                >
                                Эх сурвалж
                                </Label>
                                <Row className="g-2">
                                <Col lg={6}>
                                    <div className="form-check">
                                    <Input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="searchFB"
                                        defaultValue="option1"
                                        checked
                                    />
                                    <Label className="form-check-label" htmlFor="searchFB">
                                        Facebook
                                    </Label>
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="form-check">
                                    <Input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="searchTW"
                                        defaultValue="option2"
                                        checked
                                    />
                                    <Label className="form-check-label" htmlFor="searchTW">
                                        X/Twitter
                                    </Label>
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="form-check">
                                    <Input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="searchNW"
                                        defaultValue="option3"
                                        checked
                                    />
                                    <Label className="form-check-label" htmlFor="searchNW">
                                        Мэдээний сайт
                                    </Label>
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="form-check">
                                    <Input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="searchIG"
                                        defaultValue="option4"
                                        checked
                                    />
                                    <Label className="form-check-label" htmlFor="searchIG">
                                        Instagram
                                    </Label>
                                    </div>
                                </Col>
                                </Row>
                            </div>
                            <div className="mb-4">
                                <Label
                                htmlFor="status-select"
                                className="form-label text-muted text-uppercase fw-semibold mb-3"
                                >
                                Хандлага
                                </Label>
                                <Row className="g-2">
                                <Col lg={6}>
                                    <div className="form-check">
                                    <Input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="inlineCheckbox1"
                                        defaultValue="option1"
                                        checked
                                    />
                                    <Label className="form-check-label" htmlFor="inlineCheckbox1">
                                        Эерэг
                                    </Label>
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="form-check">
                                    <Input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="inlineCheckbox2"
                                        defaultValue="option2"
                                        checked
                                    />
                                    <Label className="form-check-label" htmlFor="inlineCheckbox2">
                                        Сөрөг
                                    </Label>
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="form-check">
                                    <Input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="inlineCheckbox3"
                                        defaultValue="option3"
                                        checked
                                    />
                                    <Label className="form-check-label" htmlFor="inlineCheckbox3">
                                        Саармаг
                                    </Label>
                                    </div>
                                </Col>              
                                </Row>
                            </div>
                            </OffcanvasBody>
                            <div className="offcanvas-footer border-top p-3 text-center hstack gap-2">
                            <button
                                className="btn btn-light w-100"
                                onClick={() => setIsInfoDetails(false)}
                            >
                                Цуцлах
                            </button>
                            <button
                                type="submit"
                                className="btn btn-success w-100"
                            >
                                Шүүх
                            </button>
                            </div>
                        </form>
                        </Offcanvas>
                    </Form>

                        <Offcanvas
                        direction="end"
                        isOpen={isCommentsDetails}
                        id="offcanvascomments"
                        toggle={() => setIsCommentsDetails(false)}
                        onHide={toggleOffComments}
                        >
                        <OffcanvasHeader className="bg-light" toggle={() => setIsCommentsDetails(false)}>
                            Сэтгэгдэл
                        </OffcanvasHeader>
                        <form action="" className="d-flex flex-column justify-content-end h-100">
                            <OffcanvasBody>                                        
                            <div className="mb-4">                    

                            {commentsDetail && commentsDetail.map((item, key) => (     
                                commentsDetailSource && commentsDetailSource === "news" ?                                   
                                                <Card className="border border-dashed shadow-none" key={key}>
                                                    <CardBody>
                                                        <div className="d-flex">
                                                            <div className="flex-shrink-0 avatar-sm">
                                                                <div className="avatar-title bg-light rounded">
                                                                    <img src={dummyImg} alt="" height="30" />
                                                                </div>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <h5 className="fs-15 mb-1">{item.commentor_name}</h5> 
                                                                <h6 className={"flex-shrink-0 mb-0"}><i className="ri-time-line align-bottom"></i> 
                                                                {item.commentor_date}</h6>                                                       
                                                            </div>                                                    
                                                            {/* <div>
                                                                <Link to={item.url} target="_blank" className="badge badge-soft-primary"><i className="ri-arrow-right-up-line align-bottom"></i></Link>
                                                            </div> */}
                                                        </div>                                        
                                                    </CardBody>
                                                    <CardBody className="border-top border-top-dashed">
                                                        <div className="d-flex">                                                  
                                                            <div className="flex-grow-1">
                                                                <p className="text-muted mb-1 fst-italic">
                                                                    {item.commentor_text}
                                                                </p>
                                                            </div>                                                                                                                                                
                                                        </div>
                                                    </CardBody>
                                                </Card> :
                                commentsDetailSource && commentsDetailSource === "facebook" &&                                   
                                            <Card className="border border-dashed shadow-none" key={key}>
                                                <CardBody>
                                                    <div className="d-flex">
                                                        <div className="flex-shrink-0 avatar-sm">
                                                            <div className="avatar-title bg-light rounded">
                                                                <img src={dummyImg} alt="" height="30" />
                                                            </div>
                                                        </div>
                                                        <div className="flex-grow-1 ms-3">
                                                            <h5 className="fs-15 mb-1">{item.commenter_name}</h5> 
                                                            <h6 className={"flex-shrink-0 mb-0"}><i className="ri-time-line align-bottom"></i> 
                                                            {item.comment_time}</h6>                                                       
                                                        </div>                                                    
                                                        <div>
                                                            <Link to={item.commenter_url} target="_blank" className="badge badge-soft-primary"><i className="ri-arrow-right-up-line align-bottom"></i></Link>
                                                        </div>
                                                    </div>                                        
                                                </CardBody>
                                                <CardBody className="border-top border-top-dashed">
                                                    <div className="d-flex">                                                  
                                                        <div className="flex-grow-1">
                                                            <p className="text-muted mb-1 fst-italic">
                                                                {item.comment_text}
                                                            </p>
                                                        </div>                                                                                                                                                
                                                    </div>
                                                </CardBody>
                                            </Card>

                                            ))}

                            </div>
                            </OffcanvasBody>
                            <div className="offcanvas-footer border-top p-3 text-center hstack gap-2">                                       
                            </div>
                        </form>
                        </Offcanvas>

                        <Offcanvas
                        direction="end"
                        isOpen={isReactionsDetails}
                        id="offcanvasreactions"
                        toggle={() => setIsReactionsDetails(false)}
                        onHide={toggleOffReactions}
                        >
                        <OffcanvasHeader className="bg-light" toggle={() => setIsReactionsDetails(false)}>
                            Reactions
                        </OffcanvasHeader>
                        <form action="" className="d-flex flex-column justify-content-end h-100">
                            <OffcanvasBody>                                        
                            <div className="mb-4">                    

                            {reactionsDetail && reactionsDetail.map((item, key) => (                                       
                                                <Card className="border border-dashed shadow-none" key={key}>
                                                    <CardBody>
                                                        <div className="d-flex">
                                                            <div className="flex-shrink-0 avatar-sm">
                                                                <div className="avatar-title bg-light rounded">
                                                                    <img src={dummyImg} alt="" height="30" />
                                                                </div>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <h5 className="fs-15 mb-1">{item.name}</h5> 
                                                                <h6 className={"flex-shrink-0 mb-0"}><i className="ri-time-line align-bottom"></i> 
                                                                {item.commentor_date}</h6>                                                       
                                                            </div>                                                    
                                                            <div>
                                                                <Link to={item.link} target="_blank" className="badge badge-soft-primary"><i className="ri-arrow-right-up-line align-bottom"></i></Link>
                                                            </div>
                                                        </div>                                        
                                                    </CardBody>
                                                    <CardBody className="border-top border-top-dashed">
                                                        <div className="d-flex">                                                  
                                                            <div className="flex-grow-1">
                                                                <p className="text-muted mb-1 fst-italic">
                                                                    <img className="title" src={detectReactions(item.type && item.type)} alt=""  style={{ width: "22px", height: "22px" }} />
                                                                </p>
                                                            </div>                                                                                                                                                
                                                        </div>
                                                    </CardBody>
                                                </Card> 

                                            ))}

                            </div>
                            </OffcanvasBody>
                            <div className="offcanvas-footer border-top p-3 text-center hstack gap-2">                                       
                            </div>
                        </form>
                        </Offcanvas>

                        <Offcanvas
                        direction="end"
                        isOpen={isSharesDetails}
                        id="offcanvasreactions"
                        toggle={() => setIsSharesDetails(false)}
                        onHide={toggleOffShares}
                        >
                        <OffcanvasHeader className="bg-light" toggle={() => setIsSharesDetails(false)}>
                            Shares
                        </OffcanvasHeader>
                        <form action="" className="d-flex flex-column justify-content-end h-100">
                            <OffcanvasBody>                                        
                            <div className="mb-4">                    

                            {sharesDetail && sharesDetail.map((item, key) => (                                       
                                                <Card className="border border-dashed shadow-none" key={key}>
                                                    <CardBody>
                                                        <div className="d-flex">
                                                            <div className="flex-shrink-0 avatar-sm">
                                                                <div className="avatar-title bg-light rounded">
                                                                    <img src={dummyImg} alt="" height="30" />
                                                                </div>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <h5 className="fs-15 mb-1">{item.name}</h5>                                                                                                            
                                                            </div>                                                    
                                                            <div>
                                                                <Link to={item.link} target="_blank" className="badge badge-soft-primary"><i className="ri-arrow-right-up-line align-bottom"></i></Link>
                                                            </div>
                                                        </div>                                        
                                                    </CardBody>
                                                </Card> 

                                            ))}

                            </div>
                            </OffcanvasBody>
                            <div className="offcanvas-footer border-top p-3 text-center hstack gap-2">                                       
                            </div>
                        </form>
                        </Offcanvas>

                        <Form className="addnewpost-form" onSubmit={(e) => {
                            e.preventDefault();
                            addNews.handleSubmit();
                            return false;
                            }}>
                            
                            <Offcanvas
                            direction="end"
                            isOpen={isAddNews}
                            id="offcanvasaddnews"
                            toggle={() => setIsAddNews(false)}
                            onHide={toggleOffAddNews}
                            >
                            <OffcanvasHeader className="bg-light" toggle={() => setIsAddNews(false)}>
                                Мэдээ нэмэх
                            </OffcanvasHeader>
                            <form action="" className="d-flex flex-column justify-content-end h-100">
                                <OffcanvasBody>                                        
                                    <div className="mb-4">
                                            <Label
                                                htmlFor="newsSource"
                                                className="form-label"
                                            >
                                            Эх сурвалж
                                            </Label>

                                            <Input
                                                name="newsSource"
                                                id="newsSource-field"
                                                className="form-control"
                                                placeholder="Эх сурвалж оруулах" 
                                                type="text"
                                                value={addNews.values.newsSource || ""}
                                                onChange={addNews.handleChange}
                                                onBlur={addNews.handleBlur}
                                                invalid={
                                                    addNews.touched.newsSource && addNews.errors.newsSource ? true : false
                                                }
                                            />  
                                            {addNews.touched.newsSource && addNews.errors.newsSource ? (
                                                <FormFeedback type="invalid">{addNews.errors.newsSource}</FormFeedback>
                                            ) : null}                      
                                    </div>
                                    <div className="mb-4">
                                            <Label
                                                htmlFor="newsSourceImg"
                                                className="form-label"
                                            >
                                            Эх сурвалжийн зураг
                                            </Label>

                                            <Input
                                                name="newsSourceImg"
                                                id="newsSourceImg-field"
                                                className="form-control"
                                                placeholder="Эх сурвалжийн зураг оруулах" 
                                                type="text"
                                                value={addNews.values.newsSourceImg || ""}
                                                onChange={addNews.handleChange}
                                                onBlur={addNews.handleBlur}
                                                invalid={
                                                    addNews.touched.newsSourceImg && addNews.errors.newsSourceImg ? true : false
                                                }
                                            />  
                                            {addNews.touched.newsSourceImg && addNews.errors.newsSourceImg ? (
                                                <FormFeedback type="invalid">{addNews.errors.newsSourceImg}</FormFeedback>
                                            ) : null}                      
                                    </div>
                                    <div className="mb-4">
                                            <Label
                                                htmlFor="newsDate"
                                                className="form-label"
                                            >
                                            Мэдээний огноо
                                            </Label>

                                            <Flatpickr
                                                name="newsDate"
                                                id="newsDate-input"
                                                className="form-control"
                                                placeholder="Огноо сонгох"
                                                options={{
                                                    altInput: true,
                                                    altFormat: "Y-m-d",
                                                    dateFormat: "Y-m-d",
                                                }}
                                                value={addNews.values.newsDate || "Огноо сонгох"}
                                                onChange={(e) => {
                                                    dateformaterValidTo(e);
                                                    addNews.setFieldValue("newsDate", e);
                                                }                                    
                                                }
                                                onBlur={addNews.handleBlur}
                                                invalid={
                                                    addNews.touched.newsDate && addNews.errors.newsDate ? true : false
                                                }                              
                                                /> 
                                                {addNews.touched.newsDate && addNews.errors.newsDate ? (
                                                    <FormFeedback type="invalid">{addNews.errors.newsDate}</FormFeedback>
                                                ) : null}           
                                    </div>
                                    <div className="mb-4">
                                            <Label
                                                htmlFor="newsUrl"
                                                className="form-label"
                                            >
                                            Мэдээний линк /url/
                                            </Label>

                                            <Input
                                                name="newsUrl"
                                                id="newsUrl-field"
                                                className="form-control"
                                                placeholder="Мэдээний линк хуулах" 
                                                type="text"
                                                value={addNews.values.newsUrl || ""}
                                                onChange={addNews.handleChange}
                                                onBlur={addNews.handleBlur}
                                                invalid={
                                                    addNews.touched.newsUrl && addNews.errors.newsUrl ? true : false
                                                }
                                            />  
                                            {addNews.touched.newsUrl && addNews.errors.newsUrl ? (
                                                <FormFeedback type="invalid">{addNews.errors.newsUrl}</FormFeedback>
                                            ) : null}                      
                                    </div>
                                    <div className="mb-4">
                                            <Label
                                                htmlFor="newsImg"
                                                className="form-label"
                                            >
                                            Мэдээний зураг /url/
                                            </Label>

                                            <Input
                                                name="newsImg"
                                                id="newsImg-field"
                                                className="form-control"
                                                placeholder="Мэдээний зургийн линк хуулах" 
                                                type="text"
                                                value={addNews.values.newsImg || ""}
                                                onChange={addNews.handleChange}
                                                onBlur={addNews.handleBlur}
                                                invalid={
                                                    addNews.touched.newsImg && addNews.errors.newsImg ? true : false
                                                }
                                            />  
                                            {addNews.touched.newsImg && addNews.errors.newsImg ? (
                                                <FormFeedback type="invalid">{addNews.errors.newsImg}</FormFeedback>
                                            ) : null}                      
                                    </div>
                                    <div className="mb-4">
                                            <Label
                                                htmlFor="newsTitle"
                                                className="form-label"
                                            >
                                            Мэдээний агуулга
                                            </Label>

                                            <CKEditor
                                                    editor={ClassicEditor}
                                                    data="<p> Мэдээний агуулга оруулах</p>"
                                                    onReady={(editor) => {
                                                            // You can store the "editor" and use when it is needed.
                                                            //console.log( 'Editor1 is ready to use!', editor );
                                                            
                                                    }}
                                                    onChange={ ( event, editor ) => {
                                                        const data = editor.getData();
                                                        setAddNewsData(data);
                                                    } }
                                            />                     
                                    </div>
                                    <div className="mb-4">
                                            <Label
                                                htmlFor="newsReaction"
                                                className="form-label"
                                            >
                                            Мэдээний Reaction /Эерэг, Сөрөг, Саармаг/
                                            </Label>

                                            <Input
                                                name="newsReaction"
                                                id="newsReaction-field"
                                                className="form-control"
                                                placeholder="Мэдээний Reaction" 
                                                type="text"
                                                value={addNews.values.newsReaction || ""}
                                                onChange={addNews.handleChange}
                                                onBlur={addNews.handleBlur}
                                                invalid={
                                                    addNews.touched.newsReaction && addNews.errors.newsReaction ? true : false
                                                }
                                            />                  
                                    </div>
                                    <div className="mb-4">
                                            <Label
                                                htmlFor="newsCategory"
                                                className="form-label"
                                            >
                                            Мэдээний Ангилал
                                            </Label>

                                            <Input
                                                name="newsCategory"
                                                id="newsCategory-field"
                                                className="form-control"
                                                placeholder="Мэдээний Ангилал" 
                                                type="text"
                                                value={addNews.values.newsCategory || ""}
                                                onChange={addNews.handleChange}
                                                onBlur={addNews.handleBlur}
                                                invalid={
                                                    addNews.touched.newsCategory && addNews.errors.newsCategory ? true : false
                                                }
                                            />                 
                                    </div>
                                </OffcanvasBody>
                                <div className="offcanvas-footer border-top p-3 text-center hstack gap-2">       
                                    <button
                                        className="btn btn-light w-100"
                                        onClick={() => setIsAddNews(false)}
                                    >
                                        Цуцлах
                                    </button>
                                    <button
                                        type="submit"
                                        className="btn btn-success w-100"
                                    >
                                        Нэмэх
                                    </button>                                
                                </div>
                            </form>
                            </Offcanvas>

                        </Form>
                </Container>
            </div>

            

            {/* <div className="page-content">
                <Container fluid>
                    <div className="profile-foreground position-relative mx-n4 mt-n4">
                        <div className="profile-wid-bg">
                            <img src={profileBg} alt="" className="profile-wid-img" />
                        </div>
                    </div>
                    <div className="pt-4 mb-4 mb-lg-3 pb-lg-4">
                        <Row className="g-4">
                            <div className="col-auto">
                                <div className="avatar-lg">
                                    <img src={location.state.campaignImage} alt="user-img"
                                        className="img-thumbnail rounded-circle" />
                                </div>
                            </div>

                            <Col>
                                <div className="p-2">
                                    <h3 className="text-white mb-1">{location.state.campaignName}</h3>
                                    <p className="text-white-75">{location.state.campaignDesc}</p>                                    
                                    <div className="hstack text-white-50 gap-1">
                                        <div className="me-2"><i
                                            className="ri-timer-line me-1 text-white-75 fs-16 align-middle"></i>
                                            {handleValidDate(location.state.campaignValidFrom)},{" "}
                                            <small className="text-muted">{handleValidTime(location.state.campaignValidFrom)}</small>
                                        </div>
                                        <div><i
                                            className="ri-timer-fill me-1 text-white-75 fs-16 align-middle"></i>
                                             {handleValidDate(location.state.campaignValidTo)},{" "}
                                            <small className="text-muted">{handleValidTime(location.state.campaignValidTo)}</small>
                                        </div>
                                        <div>
                                          <h4 className="text-white mb-1">
                                              <span className={`badge badge-soft-${
                                                  location.state.campaignStatus === "Active"
                                                                  ? "success"
                                                                  : location.state.campaignStatus === "Suspend"
                                                                  ? "danger"
                                                                  : "warning"
                                                              } me-1`}>
                                                  {location.state.campaignStatus === "Active"
                                                                  ? <i className="ri-checkbox-fill align-bottom me-1"></i>
                                                                  : location.state.campaignStatus === "Suspend"
                                                                  ? <i className="ri-checkbox-indeterminate-fill align-bottom me-1"></i>
                                                                  : <i className="ri-login-circle-fill align-bottom me-1"></i>}
                                                </span>
                                            </h4>    
                                        </div>
                                        <div>
                                            <Link to="/dashboard" className="btn btn-info add-btn" state={{location.state.campaignId: location.state.location.state.campaignId, 
                                                    campaignName: location.state.campaignName,
                                                    campaignDesc: location.state.campaignDesc,
                                                    campaignImage: location.state.campaignImage,
                                                    campaignStatus: location.state.campaignStatus,
                                                    campaignValidFrom: location.state.campaignValidFrom,
                                                    campaignValidTo: location.state.campaignValidTo,
                                                    campaignDataAnalyze: location.state.campaignDataAnalyze
                                                }}> 
                                                <i className="ri-slideshow-line align-bottom me-1"></i>
                                                Хянах самбар
                                            </Link>   
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>  
                    <Posts location.state.campaignId={location.state.location.state.campaignId} />
                </Container>
            </div> */}
        </React.Fragment>
    );
};

export default CampaignPosts;