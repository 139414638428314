import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  getTop10Posts as getTop10PostsApi,
  getTheLatestPosts as getTheLatestPostsApi,
  getFBPosts as getFBPostsApi, 
  getTWPosts as getTWPostsApi,  
  getNWPosts as getNWPostsApi,  
  getIGPosts as getIGPostsApi,     
  addNewPost as addNewPostApi,
  updatePost as updatePostApi,
  deletePost as deletePostApi,
  getRPTPosts as getRPTPostsApi, 

  getStatsPosts as getStatsPostsApi,
  getTotalStatsPosts as getTotalStatsPostsApi,
  getTotalStatsQueryPosts as getTotalStatsQueryPostsApi,
  getStatsFBPosts as getStatsFBPostsApi,
  getStatsTWPosts as getStatsTWPostsApi,
  getStatsNWPosts as getStatsNWPostsApi,
  getStatsIGPosts as getStatsIGPostsApi,

  getRPTStatsPosts as getRPTStatsPostsApi,
  getRPTStatsFBPosts as getRPTStatsFBPostsApi,
  getRPTStatsTWPosts as getRPTStatsTWPostsApi,
  getRPTStatsNWPosts as getRPTStatsNWPostsApi,
  getRPTStatsIGPosts as getRPTStatsIGPostsApi,
  getRPTTotalStatsPosts as getRPTTotalStatsPostsApi,
} from "../../helpers/realbackend_helper";

export const getTop10Posts = createAsyncThunk("post/getTop10Posts" , async (post) => {
  try{
    const response = await getTop10PostsApi(post)
    return response;
  }catch (error) {
    return error;
  }
})

export const getTheLatestPosts = createAsyncThunk("post/getTheLatestPosts" , async (post) => {
  try{
    const response = await getTheLatestPostsApi(post)
    return response;
  }catch (error) {
    return error;
  }
})

export const getTotalStatsPosts = createAsyncThunk("post/getTotalStatsPosts" , async (post) => {
  try{
    const response = await getTotalStatsPostsApi(post)
    return response;
  }catch (error) {
    return error;
  }
})

export const getRPTTotalStatsPosts = createAsyncThunk("post/getRPTTotalStatsPosts" , async (post) => {
  try{
    const response = await getRPTTotalStatsPostsApi(post)
    return response;
  }catch (error) {
    return error;
  }
})

export const getTotalStatsQueryPosts = createAsyncThunk("post/getTotalStatsQueryPosts" , async (post) => {
  try{
    const response = await getTotalStatsQueryPostsApi(post)
    return response;
  }catch (error) {
    return error;
  }
})

export const getStatsFBPosts = createAsyncThunk("post/getStatsFBPosts" , async (post) => {
  try{
    const response = await getStatsFBPostsApi(post)
    return response;
  }catch (error) {
    return error;
  }
})

export const getStatsTWPosts = createAsyncThunk("post/getStatsTWPosts" , async (post) => {
  try{
    const response = await getStatsTWPostsApi(post)
    return response;
  }catch (error) {
    return error;
  }
})

export const getStatsNWPosts = createAsyncThunk("post/getStatsNWPosts" , async (post) => {
  try{
    const response = await getStatsNWPostsApi(post)
    return response;
  }catch (error) {
    return error;
  }
})

export const getStatsIGPosts = createAsyncThunk("post/getStatsIGPosts" , async (post) => {
  try{
    const response = await getStatsIGPostsApi(post)
    return response;
  }catch (error) {
    return error;
  }
})

export const getStatsPosts = createAsyncThunk("post/getStatsPosts" , async (post) => {
  try{
    const response = await getStatsPostsApi(post)
    return response;
  }catch (error) {
    return error;
  }
})

export const getRPTStatsPosts = createAsyncThunk("post/getRPTStatsPosts" , async (post) => {
  try{
    const response = await getRPTStatsPostsApi(post)
    return response;
  }catch (error) {
    return error;
  }
})

export const getRPTStatsFBPosts = createAsyncThunk("post/getRPTStatsFBPosts" , async (post) => {
  try{
    const response = await getRPTStatsFBPostsApi(post)
    return response;
  }catch (error) {
    return error;
  }
})

export const getRPTStatsTWPosts = createAsyncThunk("post/getRPTStatsTWPosts" , async (post) => {
  try{
    const response = await getRPTStatsTWPostsApi(post)
    return response;
  }catch (error) {
    return error;
  }
})

export const getRPTStatsNWPosts = createAsyncThunk("post/getRPTStatsNWPosts" , async (post) => {
  try{
    const response = await getRPTStatsNWPostsApi(post)
    return response;
  }catch (error) {
    return error;
  }
})

export const getRPTStatsIGPosts = createAsyncThunk("post/getRPTStatsIGPosts" , async (post) => {
  try{
    const response = await getRPTStatsIGPostsApi(post)
    return response;
  }catch (error) {
    return error;
  }
})

export const getRPTPosts = createAsyncThunk("post/getRPTPosts" , async (post) => {
  try{
    const response = await getRPTPostsApi(post)
    return response;
  }catch (error) {
    return error;
  }
})

export const getFBPosts = createAsyncThunk("post/getFBPosts" , async (post) => {
  try{
    const response = await getFBPostsApi(post)
    return response;
  }catch (error) {
    return error;
  }
})

export const getTWPosts = createAsyncThunk("post/getTWPosts" , async (post) => {
  try{
    const response = await getTWPostsApi(post)
    return response;
  }catch (error) {
    return error;
  }
})

export const getNWPosts = createAsyncThunk("post/getNWPosts" , async (post) => {
  try{
    const response = await getNWPostsApi(post)
    return response;
  }catch (error) {
    return error;
  }
})

export const getIGPosts = createAsyncThunk("post/getIGPosts" , async (post) => {
  try{
    const response = await getIGPostsApi(post)
    return response;
  }catch (error) {
    return error;
  }
})

export const addNewPost = createAsyncThunk("post/addNewPost" , async (post) => {
  try{
    const response = addNewPostApi(post)
    toast.success("Нийтлэлийн мэдээллийг амжилттай нэмлээ", { autoClose: 3000 });
    return response;
  }catch (error) {
    toast.error("Нийтлэлийн мэдээллийг нэмэхэд алдаа гарлаа!", { autoClose: 3000 });
    return error;
  }
})

export const updatePost = createAsyncThunk("post/updatePost" , async (post) => {
  try{
    const response = updatePostApi(post)
    toast.success("Нийтлэлийн мэдээллийг амжилттай засварлалаа", { autoClose: 3000 });
    return response;
  }catch (error) {
    toast.error("Нийтлэлийн мэдээллийг засварлахад алдаа гарлаа!", { autoClose: 3000 });
    return error;
  }
})

export const deletePost = createAsyncThunk("post/deletePost" , async (post) => {
  try{
    const response = deletePostApi(post)
    toast.success("Нийтлэлийн мэдээллийг амжилттай устгалаа", { autoClose: 3000 });
    return { post, ...response };
  }catch (error) {
    toast.error("Нийтлэлийн мэдээллийг устгахад алдаа гарлаа!", { autoClose: 3000 });
    return error;
  }
})
