import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";
import * as moment from "moment";
import 'moment-timezone';
import { useLocation, Navigate } from "react-router-dom";

import storage from "../../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import DualListBox from "react-dual-listbox";
import "react-dual-listbox/lib/react-dual-listbox.css";

// Import Images
import dummyImg from "../../assets/images/users/user-dummy-img.jpg";

import {
  Col,
  Container,
  Row,
  Card,
  CardHeader,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  ModalFooter,
  Table,
  FormFeedback,
  UncontrolledAlert,
  CardTitle
} from "reactstrap";
import Select from "react-select";
import { TagsInput } from "react-tag-input-component";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import DeleteNews from "../../Components/Common/DeleteNews";

//Import actions
import {
  getNews as onGetNews,
  addNewNews as onAddNewNews,
  updateNews as onUpdateNews,
  deleteNews as onDeleteNews,
  getUsers as onGetUsers,
} from "../../slices/thunks";

//redux
import { useSelector, useDispatch } from "react-redux";
import TableContainer from "../../Components/Common/TableContainer";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

import Loader from "../../Components/Common/Loader";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Export Modal
import ExportCSVModal from "../../Components/Common/ExportCSVModal";

import Flatpickr from "react-flatpickr";

const News = () => {
  const dispatch = useDispatch();
  const { news, isNewsSuccess, error } = useSelector((state) => ({
    news: state.News.news,
    isNewsSuccess: state.News.isNewsSuccess,
    error: state.News.error,
  }));

  const [isEdit, setIsEdit] = useState(false);
  const [newsList, setNewsList] = useState([]);

  const [image, setImage] = useState(null);
  const [file, setFile] = useState(null);
  const [uploaded, setUploaded] = useState("");

  const [editId, setEditedId] = useState();
  const [dataDual, setDataDual] = useState([]);
  const [dataTempDual, setDataTempDual] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [selected, setSelected] = useState([]);

  const [time, setTime] = useState(3600);

  useEffect(() => {
    if (news && !news.length) {
      dispatch(onGetNews());
    }
  }, [dispatch, news]);

  useEffect(() => {
    setNewsList(news);
  }, [news]);

  useEffect(() => {
    if (!isEmpty(news)) {
      setNewsList(news);
      setIsEdit(false);
    }
  }, [news]);

  useEffect(() => {
    let timer = setInterval(() => {
      setTime((time) => {
        if (time === 0) {
          clearInterval(timer);
          return 0;
        } else return time - 1;
      });
    }, 1000);
  }, []);

  //delete News
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);

  const [modal, setModal] = useState(false);
  const [permissionModal, setPermissionModal] = useState(false);
  const [categories, setCategories] = useState([]);

  const onListChange = (selected) => {
    setSelected(selected);
  };
  
  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setNewsList(null);
      setCategories([]);
      setImage(null);
    } else {
      setModal(true);
    }
  }, [modal]);

  const permissionToggle = useCallback(() => { 
    if (permissionModal) {
      setPermissionModal(false);
    } else {
      setPermissionModal(true);
    }
  }, [permissionModal]);

  // Delete Data
  const handleDeleteNews = () => {
    if (newsList) {
      dispatch(onDeleteNews(newsList._id));
      setDeleteModal(false);
    }
  };

  const onClickDelete = (news) => {
    setNewsList(news);
    setDeleteModal(true);
  };

  const onClickPosts = (news) => {
    return <Navigate to="/newsposts" state={news} />
  };

  // Add Data
  const handleNewsClicks = () => {
    setNewsList("");
    setIsEdit(false);
    toggle();
  };
 
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      news_name: (newsList && newsList.news_name) || '',
      isEnabled: (newsList && newsList.isEnabled) || 'true',
      isSuspended: (newsList && newsList.isSuspended) || 'false',
      source_imageUrl: (newsList && newsList.source_imageUrl) || '',
      source_url: (newsList && newsList.source_url) || ''
    },
    validationSchema: Yup.object({
      news_name: Yup.string().required("Мэдээний веб сайтын нэрийг заавал оруулна"),
      source_url: Yup.string().required("Мэдээний веб сайтын хаягийг /url/ заавал оруулна"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateNews = {
          _id: newsList._id,
          news_name: values.news_name,
          isEnabled: values.isEnabled,
          isSuspended: values.isSuspended,
          source_imageUrl: image,
          source_url: values.source_url
        };

        // update News
        dispatch(onUpdateNews(updateNews));
        validation.resetForm();
      } else {
        const newNews = {
          news_name: values["news_name"],
          isEnabled: values["isEnabled"],
          isSuspended: values["isSuspended"],
          source_imageUrl: image,
          source_url: values["source_url"]        
        };
        // save new News
        dispatch(onAddNewNews(newNews));
        validation.resetForm();
      }
      toggle();
    },
  });
  
  // Changing state value when searching name
  useEffect(() => {
    
    if (filterText !== "") {
      const filteredObject = dataDual.filter((item) => {
        return item.label.toLowerCase().includes(filterText.toLowerCase());
      });
      setDataDual([...filteredObject]);
    } else {
      setDataDual([...dataTempDual]);
    }
  }, [filterText]);

  const onFilterChange = (e) => {
    setFilterText(e.target.value);
  };

  // function to close the form modal
  const onFormCancel = () => {
    setModal(false);
    setNewsList("");
    setIsEdit(false);
    setImage(dummyImg);
    validation.resetForm();
  };

  // Update Data
  const handleNewsClick = useCallback((arg) => {
    const news = arg;

    setNewsList({
      _id: news._id,
      news_name: news.news_name,
      isEnabled: news.isEnabled,
      isSuspended: news.isSuspended,
      source_url: news.source_url
    });
    setImage(news.source_imageUrl);
    setUploaded("");
    setIsEdit(true);
    toggle();
  }, [toggle]);

  const handlePreview = (file) => {    
        setImage(URL.createObjectURL(file));
        setFile(file);
  }
  const handleUpload = (e) => {
        e.preventDefault();
        const fileName = new Date().getTime() + file.name;
        const storageRef = ref(storage, `/logos/${fileName}`);

        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
            "state_changed",
            (snapshot) => {
            const percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            const safePercent = Math.floor(percentage, 10).toString()

            setPercent(safePercent);
            },
            (err) => setUploaded("no"),
            () => {
            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                setImage(url);
                setUploaded("yes");
            });
            }
        );
  };

  const handleValidDate = date => {
    const date1 = moment(new Date(date)).format("Y-MM-DD");
    return date1;
  };

  const handleValidTime = (time) => {
    const time1 = new Date(time);
    const getHour = time1.getUTCHours();
    const getMin = time1.getUTCMinutes();
    const getTime = `${getHour}:${getMin}`;
    const updateTime = moment.utc(getTime, 'HH:mm').format('HH:mm');
    return updateTime;
  };

  // Checked All
  const checkedAll = useCallback(() => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".newsCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
    deleteCheckbox();
  }, []);

  const defaultdate = () => {
    let d = new Date(),
      months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return ((d.getFullYear() + '-' + d.getMonth() + '-' + d.getDate()).toString());
  };

  const [lastUpdated, setLastUpdated] = useState(defaultdate());

  const dateformaterLastUpdated = (e) => {
    const date = e.toString().split(" ");
    const joinDate = (date[3] + "-" + date[2] + "-" + date[1]).toString();
    setLastUpdated(joinDate);
  };

  // Delete Multiple
  const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
  const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);

  const deleteMultiple = () => {
    const checkall = document.getElementById("checkBoxAll");
    selectedCheckBoxDelete.forEach((element) => {
      dispatch(onDeleteNews(element.value));
      setTimeout(() => { toast.clearWaitingQueue(); }, 3000);
    });
    setIsMultiDeleteButton(false);
    checkall.checked = false;
  };

  const deleteCheckbox = () => {
    const ele = document.querySelectorAll(".newsCheckBox:checked");
    ele.length > 0 ? setIsMultiDeleteButton(true) : setIsMultiDeleteButton(false);
    setSelectedCheckBoxDelete(ele);
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: <input type="checkbox" id="checkBoxAll" className="form-check-input" onClick={() => checkedAll()} />,
        Cell: (cellProps) => {
          return <input type="checkbox" className="newsCheckBox form-check-input" value={cellProps.row.original._id} onChange={() => deleteCheckbox()} />;
        },
        id: '#',
      },
      {
        Header: "Веб сайтын нэр",
        accessor: "news_name",
        filterable: true,
        Cell: (newsList) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
                {newsList.row.original.source_imageUrl ? <img
                  src={newsList.row.original.source_imageUrl}
                  alt=""
                  className="avatar-xxs rounded-circle"
                /> :
                  <div className="flex-shrink-0 avatar-xs me-2">
                    <div className="avatar-title bg-soft-success text-success rounded-circle fs-13">
                      <img src={dummyImg} alt="" className="avatar-xxs rounded-circle" />
                    </div>
                  </div>
                }
              </div>
              <div className="flex-grow-1 ms-2 name">
                <Link to="/dashboard" state={{newsId: newsList.row.original._id, 
                                              newsNewsName: newsList.row.original.news_name,
                                              newsIsEnabled: newsList.row.original.isEnabled,
                                              newsIsSuspended: newsList.row.original.isSuspended,
                                              newsLastUpdated: newsList.row.original.lastUpdated,
                                              newsSourceImageUrl: newsList.row.original.source_imageUrl,
                                              newsSourceUrl: newsList.row.original.source_url,
                                              newsCategories: newsList.row.original.categories,
                                            }}> 
                     {newsList.row.original.news_name}
                </Link>               
              </div>
            </div>
          </>
        ),
      },
      {
        Header: "Мониторинг хийх эсэх",
        accessor: "isEnabled",
        filterable: false,
        Cell: (newsList) => (
          <>
            <span className={`badge badge-soft-${
                            newsList.row.original.isEnabled === "true"
                            ? "success"
                            : "danger"
                        } me-1`}>
            {newsList.row.original.isEnabled === "true"
                        ? "Идэвхтэй"
                        : "Идэвхгүй"} </span>
          </>
        ),
      },
      {
        Header: "Сүүлд шинэчлэгдсэн огноо",
        Cell: (newsList) => (
          <>
            {newsList.row.original.lastUpdated}
          </>
        ),
      }, 
      {
        Header: "Үндсэн веб сайтын ажиллагаа",
        filterable: true,
        Cell: (newsList) => (
          <>
            <span className={`badge badge-soft-${
                            newsList.row.original.isSuspended === "false"
                            ? "success"
                            : "danger"
                        } me-1`}>
            {newsList.row.original.isSuspended === "false"
                            ? "Хэвийн"
                            : "Ажиллагаагүй"}</span>
          </>
        ),
      },              
      {
        Header: "Үйлдэл",
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">              
              <li className="list-inline-item">
                <UncontrolledDropdown>
                  <DropdownToggle
                    href="#"
                    className="btn btn-soft-secondary btn-sm dropdown"
                    tag="button"
                  >
                    <i className="ri-more-fill align-middle"></i>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem className="dropdown-item" href="#"
                        onClick={() => { const newsData = cellProps.row.original; setInfo(newsData); }}
                      >
                      <i className="ri-eye-fill align-bottom me-2 text-muted"></i>{" "}
                      Харах
                    </DropdownItem>
                    <DropdownItem
                        className="dropdown-item edit-item-btn"
                        href="#"
                        onClick={() => { const newsData = cellProps.row.original; handleNewsClick(newsData); }}
                      >
                      <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>{" "}
                      Засварлах
                    </DropdownItem>
                    { 
                        JSON.parse(localStorage.getItem("authUser")).role === "SuperAdmin" &&
                        <React.Fragment>
                              <DropdownItem
                                  className="dropdown-item remove-item-btn"
                                  href="#"
                                  onClick={() => { const newsData = cellProps.row.original; onClickDelete(newsData); }}
                                >
                                <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>{" "}
                                Устгах
                              </DropdownItem>
                        </React.Fragment>
                    }    
                    <DropdownItem className="dropdown-item">
                      <i className="ri-dashboard-fill align-bottom me-2 text-muted"></i>{" "}
                      
                      <Link to="/dashboard" state={{newsId: cellProps.row.original._id, 
                                              newsNewsName: cellProps.row.original.news_name,
                                              newsIsEnabled: cellProps.row.original.isEnabled,
                                              newsIsSuspended: cellProps.row.original.isSuspended,
                                              newsLastUpdated: cellProps.row.original.lastUpdated,
                                              newsSourceImageUrl: cellProps.row.original.source_imageUrl,
                                              newsSourceUrl: cellProps.row.original.source_url,
                                              newsCategories: cellProps.row.original.categories,
                                            }}> 
                          Хянах самбар
                      </Link>
                    </DropdownItem>                   
                    <DropdownItem className="dropdown-item">
                      <i className="ri-file-text-fill align-bottom me-2 text-muted"></i>{" "}
                      
                      <Link to="/posts" state={{newsId: cellProps.row.original._id, 
                                              newsNewsName: cellProps.row.original.news_name,
                                              newsIsEnabled: cellProps.row.original.isEnabled,
                                              newsIsSuspended: cellProps.row.original.isSuspended,
                                              newsLastUpdated: cellProps.row.original.lastUpdated,
                                              newsSourceImageUrl: cellProps.row.original.source_imageUrl,
                                              newsSourceUrl: cellProps.row.original.source_url,
                                              newsCategories: cellProps.row.original.categories,
                                            }}> 
                          Бүх нийтлэл
                      </Link>
                    </DropdownItem>
                   
                  </DropdownMenu>
                </UncontrolledDropdown>
              </li>
            </ul>
          );
        },
      },
    ],
    [handleNewsClick, checkedAll]
  );

  const tagsOptions = [
    { label: "Улс төр", value: "Улс төр" },
    { label: "Боловсрол", value: "Боловсрол" },
    { label: "Мэдээллийн технологи", value: "Мэдээллийн технологи" },
    { label: "Тусгай байгууллага", value: "Тусгай байгууллага" },
    { label: "Шинжлэх ухаан", value: "Шинжлэх ухаан" },
    { label: "Хэвлэл мэдээлэл", value: "Хэвлэл мэдээлэл" },
    { label: "Худалдаа", value: "Худалдаа" }
  ];

  const isEnabledOptions = [
    {
      options: [
        { label: "Идэвхтэй", value: "true" },
        { label: "Идэвхгүй", value: "false" }
      ],
    },
  ];

  const isSuspendedOptions = [
    {
      options: [
        { label: "Ажиллагаатай", value: "false" },
        { label: "Ажиллагаагүй", value: "true" }
      ],
    },
  ];

  // SideBar News Detail
  const [info, setInfo] = useState([]);

  // Export Modal
  const [isExportCSV, setIsExportCSV] = useState(false);

  document.title = "Мэдээний веб сайтын удирдлага | IKHNUDEN - Media Monitoring System";
  return (
    <React.Fragment>
      <div className="page-content">
        <ExportCSVModal
          show={isExportCSV}
          onCloseClick={() => setIsExportCSV(false)}
          data={news}
          disabled = { JSON.parse(localStorage.getItem("authUser")).role === "SuperAdmin" ? false : true }
        />

        <DeleteNews
          show={deleteModal}
          onDeleteClick={handleDeleteNews}
          onCloseClick={() => setDeleteModal(false)}
          disabled = { JSON.parse(localStorage.getItem("authUser")).role === "SuperAdmin" ? false : true }
        />

        <DeleteNews
          show={deleteModalMulti}
          onDeleteClick={() => {
            deleteMultiple();
            setDeleteModalMulti(false);
          }}
          onCloseClick={() => setDeleteModalMulti(false)}
          disabled = { JSON.parse(localStorage.getItem("authUser")).role === "SuperAdmin" ? false : true }
        />

        <Container fluid>
          <BreadCrumb title="Мэдээний веб сайтын удирдлага" pageTitle="Мэдээний веб сайт" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <div className="d-flex align-items-center flex-wrap gap-2">
                    <div className="flex-grow-1">
                      <button
                        className="btn btn-info add-btn"
                        onClick={() => {
                          setModal(true);
                        }}                        
                      >
                        <i className="ri-add-fill me-1 align-bottom"></i> Шинээр мэдээний веб сайт нэмэх
                      </button>
                    </div>                    
                    <div className="flex-shrink-0">
                      <div className="hstack text-nowrap gap-2">                     
                        {
                          JSON.parse(localStorage.getItem("authUser")).role === "SuperAdmin" && isMultiDeleteButton && <button className="btn btn-soft-danger"
                            onClick={() => setDeleteModalMulti(true)}
                          ><i className="ri-delete-bin-2-line"></i></button>
                        }
                        <button className="btn btn-danger">
                          <i className="ri-filter-2-line me-1 align-bottom"></i>{" "}
                          Шүүлт хийх
                        </button>
                        <button className="btn btn-soft-success" onClick={() => setIsExportCSV(true)}>Хадгалах</button>
                      </div>
                    </div>
                  </div>
                </CardHeader>
              </Card>
            </Col>
            <Col xxl={9}>
              <Card id="newsList">
                <CardBody className="pt-0">
                  <div>
                    {isNewsSuccess && news.length ? (
                      <TableContainer
                        columns={columns}
                        data={(news || [])}
                        isGlobalFilter={true}
                        isAddUserList={false}
                        customPageSize={100}
                        className="custom-header-css"
                        divClass="table-responsive table-card mb-3"
                        tableClass="align-middle table-nowrap"
                        theadClass="table-light"
                        handleNewsClicks={handleNewsClicks}
                        isNewsFilter={true}
                        SearchPlaceholder='Search for news...'
                      />
                    ) : (<Loader error={error} />)
                    }
                  </div>

                  <Modal id="showModal" isOpen={modal} toggle={toggle} centered> 
                    <ModalHeader className="bg-soft-info p-3" toggle={toggle}>
                      {!!isEdit ? "Веб сайтын мэдээллийг засварлах" : "Шинээр веб сайт нэмэх"}
                    </ModalHeader>

                    <Form className="tablelist-form" onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}>
                      <ModalBody>
                        <Input type="hidden" id="id-field" />
                        <Row className="g-3">
                          <Col lg={12}>
                            <div className="text-center">
                              <div className="position-relative d-inline-block">
                                <div className="position-absolute  bottom-0 end-0">
                                  <Label htmlFor="image" className="mb-0">
                                    <div className="avatar-xs cursor-pointer">
                                      <div className="avatar-title bg-light border rounded-circle text-muted">
                                        <i className="ri-image-fill"></i>
                                      </div>
                                    </div>
                                  </Label>
                                  <Input className="form-control d-none" id="image" type="file"
                                    accept="image/png, image/gif, image/jpeg"
                                    onChange={(e) => handlePreview(e.target.files[0])}                                   
                                  />                                 
                                </div>
                               
                                <div className="avatar-lg p-1">
                                  <div className="avatar-title bg-light rounded-circle">
                                    <img src={image || dummyImg} alt="" id="image" className="avatar-md rounded-circle object-cover" />                                    
                                  </div>
                                </div>                                
                              </div> 
                                                         
                            </div>
                          </Col>
                          <Col lg={12}>
                            <div className="text-center">
                                <button type="button" className="btn btn-light" onClick={handleUpload} > Зураг илгээх </button>    
                            </div>      
                          </Col>
                          <Col lg={12}>
                            <div className="text-center">
                                {
                                    uploaded === "yes" ?
                                    (
                                        <UncontrolledAlert color="success">
                                            Веб сайтын зураг <strong>амжилттай</strong> илгээгдлээ!
                                        </UncontrolledAlert>
                                    ) : uploaded === "no" ? (
                                        <UncontrolledAlert color="danger" className="mb-xl-0">
                                            Веб сайтын зураг илгээхэд <strong>алдаа</strong> гарлаа!
                                        </UncontrolledAlert>
                                    ) : null
                                }     
                            </div> 
                          </Col>
                          <Col lg={12}>
                          <div>
                              <Label
                                htmlFor="news_name"
                                className="form-label"
                              >
                                Веб сайтын нэр{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="news_name"
                                id="newsname-field"
                                className="form-control"
                                placeholder="Веб сайтын нэрийг оруулах" 
                                type="text"
                                validate={{
                                  required: { value: true },
                                }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.news_name || ""}
                                invalid={
                                  validation.touched.news_name && validation.errors.news_name ? true : false
                                }
                              />
                              {validation.touched.news_name && validation.errors.news_name ? (
                                <FormFeedback type="invalid">{validation.errors.news_name}</FormFeedback>
                              ) : null}

                            </div>
                          </Col>                         
                          <Col lg={12}>
                          <div>
                              <Label
                                htmlFor="source_url"
                                className="form-label"
                              >
                                Веб сайтын хаяг /url/{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="source_url"
                                id="sourceurl-field"
                                className="form-control"
                                placeholder="Веб сайтын хаягийг оруулах" 
                                type="text"
                                validate={{
                                  required: { value: true },
                                }}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.source_url || ""}
                                invalid={
                                  validation.touched.source_url && validation.errors.source_url ? true : false
                                }
                              />
                              {validation.touched.source_url && validation.errors.source_url ? (
                                <FormFeedback type="invalid">{validation.errors.source_url}</FormFeedback>
                              ) : null}

                            </div>
                          </Col>       
                          <Col lg={6}>
                            <div>
                              <Label
                                htmlFor="isEnabled"
                                className="form-label"
                              >
                                Идэвхтэй эсэх
                              </Label>

                              <Input
                                name="isEnabled"
                                type="select"
                                className="form-select"
                                id="isEnabled-field"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={
                                  validation.values.isEnabled || "Идэвхтэй"
                                }
                                disabled = { JSON.parse(localStorage.getItem("authUser")).role === "SuperAdmin" ? false : true }
                              >
                                {isEnabledOptions.map((item, key) => (
                                  <React.Fragment key={key}>
                                    {item.options.map((item, key) => (<option value={item.value} key={key}>{item.label}</option>))}
                                  </React.Fragment>
                                ))}
                              </Input>
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div>
                              <Label
                                htmlFor="isSuspended"
                                className="form-label"
                              >
                                Ажиллагаатай эсэх
                              </Label>

                              <Input
                                name="isSuspended"
                                type="select"
                                className="form-select"
                                id="isSuspended-field"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={
                                  validation.values.isSuspended || "Ажиллагаатай"
                                }
                                disabled = { JSON.parse(localStorage.getItem("authUser")).role === "SuperAdmin" ? false : true }
                              >
                                {isSuspendedOptions.map((item, key) => (
                                  <React.Fragment key={key}>
                                    {item.options.map((item, key) => (<option value={item.value} key={key}>{item.label}</option>))}
                                  </React.Fragment>
                                ))}
                              </Input>
                            </div>
                          </Col>                                     
                        </Row>
                      </ModalBody>
                      <ModalFooter>
                        <div className="hstack gap-2 justify-content-end"> 
                          <button type="submit" className="btn btn-success" id="add-btn"> {!!isEdit ? "Засварлах" : "Хадгалах"} </button>
                          <button type="button" className="btn btn-light" onClick={(ev) => { ev.preventDefault(); onFormCancel(); }} > Цуцлах </button>
                        </div>
                      </ModalFooter>
                    </Form>
                  </Modal>

                  <ToastContainer closeButton={false} limit={1} />
                </CardBody>
              </Card>
            </Col>

            <Col xxl={3}>
              <Card id="news-view-detail">
                <CardBody className="text-center">
                  <div className="position-relative d-inline-block">
                    <img
                      src={info.source_imageUrl || dummyImg}
                      alt=""
                      className="avatar-lg rounded-circle img-thumbnail"
                    />
                    <span className="news-active position-absolute rounded-circle bg-success">
                      <span className="visually-hidden"></span>
                    </span>
                  </div>
                  <h5 className="mt-4 mb-1">{info.news_name || ""}</h5>
                  <p className="text-muted">
                    <span className={`badge badge-soft-${
                            info.isEnabled === "true"
                            ? "success"
                            : "danger"
                        } me-1`}>
                    {info.isEnabled === "true"
                            ? "Идэвхтэй"
                            : "Идэвхгүй"}
                      </span>
                  </p> 
                </CardBody>
                <CardBody>
                  <h6 className="text-muted text-uppercase fw-semibold mb-3">
                    Дэлгэрэнгүй мэдээлэл
                  </h6>
                  <p className="text-muted mb-4">
                    
                  </p>
                  <div className="table-responsive table-card">
                    <Table className="table table-borderless mb-0">
                      <tbody>
                        <tr>
                          <td className="fw-medium">
                            Веб сайтын хаяг
                          </td>
                          <td>{info.source_url || ""}</td>
                        </tr>  
                        <tr>
                          <td className="fw-medium">
                            Сүүлд шинэчлэгдсэн огноо
                          </td>
                          <td>
                            {handleValidDate(info.lastUpdated || "")}{" "}
                            <small className="text-muted">{handleValidTime(info.lastUpdated || "")}</small>
                          </td>
                        </tr>                                                                                                                               
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default News;
