import React from "react";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../../Components/Common/ChartsDynamicColor";

const ReportEngagementsCharts = ({ dataColors, posts, likes, comments, shares, categories }) => {
  var linechartcustomerColors = getChartColorsArray(dataColors);

  const postsT = [
    {
      name: "Нийтлэл",
      type: "line",
      data: posts,
    },
    {
      name: "Сэтгэгдэл",
      type: "line",
      data: comments,
    },   
    {
        name: "Хандлага /Reactions/",
        type: "line",
        data: likes,
    },
    {
        name: "Shares",
        type: "line",
        data: shares,
    }
  ]

  var options = {
    chart: {
        height: 380,
        type: 'line',
        zoom: {
            enabled: true
        },
        toolbar: {
            show: true
        }
    },
    colors: linechartcustomerColors,
    dataLabels: {
        enabled: false,
    },
    stroke: {
        width: [3, 3],
        curve: 'straight'
    },   
    grid: {
        row: {
            colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.2
        },
        borderColor: '#f1f1f1'
    },
    markers: {
        style: 'inverted',
        size: 6
    },
    xaxis: {
        categories: categories,
        title: {
            text: 'Хугацаа'
        }
    },
    yaxis: {
        title: {
            text: 'Тоон үзүүлэлт'
        },
    },
    legend: {
        show: true,
        horizontalAlign: "center",
        offsetX: 0,
        offsetY: -5,
        markers: {
          width: 9,
          height: 9,
          radius: 6,
        },
        itemMargin: {
          horizontal: 10,
          vertical: 0,
        },
      },
    responsive: [{
        breakpoint: 600,
        options: {
            chart: {
                toolbar: {
                    show: false
                }
            },
            legend: {
                show: false
            },
        }
    }],
    tooltip: {
        shared: true,
        y: [
          {
            formatter: function (y) {
              if (typeof y !== "undefined") {
                return y.toFixed(0);
              }
              return y;
            },
          },
          {
            formatter: function (y) {
              if (typeof y !== "undefined") {
                return y.toFixed(0);
              }
              return y;
            },
          },
          {
            formatter: function (y) {
              if (typeof y !== "undefined") {
                return y.toFixed(0);
              }
              return y;
            },
          },
        ],
      },
};

  return (
    <React.Fragment>
      <ReactApexChart dir="ltr"
        options={options}
        series={postsT}
        type="line"
        height="370"
        className="apex-charts"
      />
    </React.Fragment>
  );
};

const ReportEngagementsPNCharts = ({ dataColors, positiveLikesCount, negativeLikesCount }) => {
  var chartDonutBasicColors = getChartColorsArray(dataColors);

  const series = [positiveLikesCount, negativeLikesCount];
  var options = {
    labels: ["Эерэг", "Сөрөг"],
    chart: {
      height: 333,
      type: "donut",
    },
    legend: {
      position: "bottom",
    },
    stroke: {
      show: false,
    },
    dataLabels: {
      dropShadow: {
        enabled: false,
      },
    },
    colors: chartDonutBasicColors,
  };
  return (
    <React.Fragment>
      <ReactApexChart dir="ltr"
        options={options}
        series={series}
        type="donut"
        height="333"
        className="apex-charts"
      />
    </React.Fragment>
  );
};

const ReportTotalSourceCharts = ({ dataColors, fbCount, twCount, nwCount, igCount }) => {
  var chartDonutBasicColors = getChartColorsArray(dataColors);

  const series = [fbCount, twCount, nwCount, igCount];
  var options = {
    labels: ["Facebook", "X/Twitter", "Мэдээний сайт", "Instagram"],
    chart: {
      height: 333,
      type: "donut",
    },
    legend: {
      position: "bottom",
    },
    stroke: {
      show: false,
    },
    dataLabels: {
      dropShadow: {
        enabled: false,
      },
    },
    colors: chartDonutBasicColors,
  };
  return (
    <React.Fragment>
      <ReactApexChart dir="ltr"
        options={options}
        series={series}
        type="donut"
        height="333"
        className="apex-charts"
      />
    </React.Fragment>
  );
};

export { ReportEngagementsCharts, ReportEngagementsPNCharts, ReportTotalSourceCharts };
