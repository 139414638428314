import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  getNewsPosts as getNewsPostsApi,    
  addNewNewsPost as addNewNewsPostApi,
  updateNewsPost as updateNewsPostApi,
  deleteNewsPost as deleteNewsPostApi
} from "../../helpers/realbackend_helper";

export const getNewsPosts = createAsyncThunk("newsposts/getNewsPosts" , async (newsposts) => {
  try{
    const response = await getNewsPostsApi(newsposts)
    return response;
  }catch (error) {
    return error;
  }
})

export const addNewNewsPost = createAsyncThunk("newsposts/addNewNewsPost" , async (newsposts) => {
  try{
    const response = addNewNewsPostApi(newsposts)
    toast.success("Мэдээний веб сайтыг амжилттай нэмлээ", { autoClose: 3000 });
    return response;
  }catch (error) {
    toast.error("Мэдээний веб сайтыг нэмэхэд алдаа гарлаа!", { autoClose: 3000 });
    return error;
  }
})

export const updateNewsPost = createAsyncThunk("newsposts/updateNewsPost" , async (newsposts) => {
  try{
    const response = updateNewsPostApi(newsposts)
    toast.success("Мэдээний веб сайтыг амжилттай засварлалаа", { autoClose: 3000 });
    return response;
  }catch (error) {
    toast.error("Мэдээний веб сайтыг засварлахад алдаа гарлаа!", { autoClose: 3000 });
    return error;
  }
})

export const deleteNewsPost = createAsyncThunk("newsposts/deleteNewsPost" , async (newsposts) => {
  try{
    const response = deleteNewsPostApi(newsposts)
    toast.success("Мэдээний веб сайтыг амжилттай устгалаа", { autoClose: 3000 });
    return { newsposts, ...response };
  }catch (error) {
    toast.error("Мэдээний веб сайтыг устгахад алдаа гарлаа!", { autoClose: 3000 });
    return error;
  }
})
