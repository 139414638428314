import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  getCampaigns as getCampaignsApi,   
  getCampaignsSpecial as getCampaignsSpecialApi, 
  addNewCampaign as addNewCampaignApi,
  updateCampaign as updateCampaignApi,
  deleteCampaign as deleteCampaignApi
} from "../../helpers/realbackend_helper";

export const getCampaigns = createAsyncThunk("campaign/getCampaigns" , async () => {
  try{
    const response = await getCampaignsApi()
    return response;
  }catch (error) {
    return error;
  }
})

export const getCampaignsSpecial = createAsyncThunk("campaign/getCampaignsSpecial" , async (special) => {
  try{
    console.log(special)
    const response = await getCampaignsSpecialApi(special)    
    return response;
  }catch (error) {
    return error;
  }
})

export const addNewCampaign = createAsyncThunk("campaign/addNewCampaign" , async (campaign) => {
  try{
    const response = addNewCampaignApi(campaign)
    toast.success("Судалгааны мэдээллийг амжилттай нэмлээ", { autoClose: 3000 });
    return response;
  }catch (error) {
    toast.error("Судалгааны мэдээллийг нэмэхэд алдаа гарлаа!", { autoClose: 3000 });
    return error;
  }
})

export const updateCampaign = createAsyncThunk("campaign/updateCampaign" , async (campaign) => {
  try{
    const response = updateCampaignApi(campaign)
    toast.success("Судалгааны мэдээллийг амжилттай засварлалаа", { autoClose: 3000 });
    return response;
  }catch (error) {
    toast.error("Судалгааны мэдээллийг засварлахад алдаа гарлаа!", { autoClose: 3000 });
    return error;
  }
})

export const deleteCampaign = createAsyncThunk("campaign/deleteCampaign" , async (campaign) => {
  try{
    const response = deleteCampaignApi(campaign)
    toast.success("Судалгааны мэдээллийг амжилттай устгалаа", { autoClose: 3000 });
    return { campaign, ...response };
  }catch (error) {
    toast.error("Судалгааны мэдээллийг устгахад алдаа гарлаа!", { autoClose: 3000 });
    return error;
  }
})
