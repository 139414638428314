import { createSlice } from "@reduxjs/toolkit";
import { getCampaigns, getCampaignsSpecial, addNewCampaign, updateCampaign, deleteCampaign } from './thunk';

export const initialState = {
  campaigns: [],
  campaignsSpecial: [],
  error: {},
  errorSpecial: {}
};

const campaignSlice = createSlice({
  name: "campaign",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCampaigns.fulfilled, (state, action) => {
      state.campaigns = action.payload;
      state.isCampaignFail = false;
      state.isCampaignSuccess = true;
    });

    builder.addCase(getCampaigns.rejected, (state, action) => {
      state.error = action.payload.error || null;
      state.isCampaignFail = true;
      state.isCampaignSuccess = false;
    });

    builder.addCase(getCampaignsSpecial.fulfilled, (state, action) => {
      state.campaignsSpecial = action.payload;
      state.isCampaignSpecialFail = false;
      state.isCampaignSpecialSuccess = true;
    });

    builder.addCase(getCampaignsSpecial.rejected, (state, action) => {
      state.error = action.payload.error || null;
      state.isCampaignSpecialFail = true;
      state.isCampaignSpecialSuccess = false;
    });

    builder.addCase(addNewCampaign.fulfilled, (state, action) => {
      state.campaigns.push(action.payload);
      state.isCampaignAdd = true;
      state.isCampaignAddFail = false;
    });

    builder.addCase(addNewCampaign.rejected, (state, action) => {
      state.error = action.payload.error || null;
      state.isCampaignAdd = false;
      state.isCampaignAddFail = true;
    });

    builder.addCase(updateCampaign.fulfilled, (state, action) => {
      state.campaigns = state.campaigns.map(campaign =>
        campaign._id.toString() === action.payload._id.toString()
          ? { ...campaign, ...action.payload }
          : campaign
      );
      state.isCampaignUpdate = true;
      state.isCampaignUpdateFail = false;
    });

    builder.addCase(updateCampaign.rejected, (state, action) => {
      state.error = action.payload.error || null;
      state.isCampaignUpdate = false;
      state.isCampaignUpdateFail = true;
    });

    builder.addCase(deleteCampaign.fulfilled, (state, action) => {
      state.campaigns = (state.campaigns || []).filter((campaign) => campaign._id.toString() !== action.payload.campaign.toString());

      state.isCampaignDelete = true;
      state.isCampaignDeleteFail = false;
    });

    builder.addCase(deleteCampaign.rejected, (state, action) => {
      state.error = action.payload.error || null;
      state.isCampaignDelete = false;
      state.isCampaignDeleteFail = true;
    });

  },
});

export default campaignSlice.reducer;