import React, { useState, useEffect } from "react";
import { Card, CardBody, CardHeader, Col, Row, Form, FormFeedback, Offcanvas, Input, Label, OffcanvasHeader, OffcanvasBody } from 'reactstrap';
import { isEmpty } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import * as moment from "moment";
import 'moment-timezone';
import ShowMoreText from "react-show-more-text";
import { Spinner } from 'reactstrap';

// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation, Autoplay, EffectCoverflow, Pagination } from "swiper";
import { getTop10Posts as onGetTop10Posts,
         getTheLatestPosts as onGetTheLatestPosts } from "../../slices/thunks";

import Flatpickr from "react-flatpickr";
import { Link, Navigate } from 'react-router-dom';
import dummyImg from "../../assets/images/users/user-dummy-img.jpg";
import Rating from "react-rating";
import * as Yup from "yup";
import { useFormik } from "formik";

import like from "../../assets/images/emojis/liked.png";
import love from "../../assets/images/emojis/love.png";
import care from "../../assets/images/emojis/care.png";
import haha from "../../assets/images/emojis/haha.png";
import wow from "../../assets/images/emojis/wow.png";
import sad from "../../assets/images/emojis/sad.png";
import angry from "../../assets/images/emojis/angry.png";
import heart from "../../assets/images/emojis/heart.png";
import poop from "../../assets/images/emojis/poop.png";

const TopPosts = ({ campaignId }) => {
    const dispatch = useDispatch();

    const [campId, setCampId] = useState(null);
    const [top10Post, setTop10Post] = useState(null);
    const [thelatestPost, setTheLatestPost] = useState([]);

    const [commentsDetail, setCommentsDetail] = useState([]);
    const [commentsDetailSource, setCommentsDetailSource] = useState(null);
    const [reactionsDetail, setReactionsDetail] = useState([]);
    const [sharesDetail, setSharesDetail] = useState([]);

    const [isInfoDetails, setIsInfoDetails] = useState(false);
    const [isCommentsDetails, setIsCommentsDetails] = useState(false);
    const [isReactionsDetails, setIsReactionsDetails] = useState(false);
    const [isSharesDetails, setIsSharesDetails] = useState(false);

    const defaultdate = () => {
        let d = new Date(),
          months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        return ((d.getFullYear() + '-' + d.getMonth() + '-' + d.getDate()).toString());
    };

    const [dateSearchFrom, setDateValidFrom] = useState(defaultdate());
    const [dateSearchTo, setDateValidTo] = useState(defaultdate());

    const [beginDate, setBeginDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const toggleOffComments = () => {
        setCommentsDetail([]);
    };

    const toggleOffReactions = () => {
        setReactionsDetail([]);
    };

    const toggleOffShares = () => {
        setSharesDetail([]);
    };

    const { top10posts, isTop10PostSuccess, top10error,
        thelatestposts, isTheLatestPostSuccess, thelatesterror,} = useSelector((state) => ({
        top10posts: state.Post.top10posts,
        isTop10PostSuccess: state.Post.isTop10PostSuccess,
        top10error: state.Post.top10error,

        thelatestposts: state.Post.thelatestposts,
        isTheLatestPostSuccess: state.Post.isTheLatestPostSuccess,
        thelatesterror: state.Post.thelatesterror
        }));

    const detectReactions = (value) => {
        switch(value) {
            case 'like':
                return like;
            case 'love':
                return love;
            case 'care':
                return care;
            case 'haha':
                return haha;
            case 'wow':
                return wow;
            case 'sad':
                return sad;
            case 'angry':
                return angry;
            case 'heart':
                return heart;
            case 'poop':
                return poop;
            default:
            return like;
        }
        }

    const validation = useFormik({
            // enableReinitialize : use this flag when initial values needs to be changed
            enableReinitialize: true,
        
            initialValues: {   
              sentiment: "Нийт",
              searchFrom: "",
              searchTo: "",    
            },
            validationSchema: Yup.object({               
                searchFrom: Yup.date().default(() => new Date()),
                searchTo: Yup
                    .date()
                    .when(
                    'searchFrom',
                    (searchFrom, schema) => (searchFrom && schema.min(searchFrom, 'Дуусах огноо эхлэх огнооноос баг байж болохгүй')),
                    )
            }),
            onSubmit: (values) => {        
                // console.log(values.sentiment)
                // console.log(values.searchTo)
                // console.log(values.searchFrom)
                        
                if(values.searchFrom !== "" && values.searchTo !== "" && values.sentiment !== "")
                {
                    setBeginDate(values.searchFrom);
                    setEndDate(values.searchTo);
        
                    const genParams = {
                        taskId: campaignId,
                        startDate: values.searchFrom,
                        endDate: values.searchTo,
                        text: values.sentiment,
                        limit: "50"
                    };
        
                    dispatch(onGetTop10Posts(genParams));
                }  
        
            },
    });

    useEffect(() => {
        try {
            const params = {
                taskId: campaignId,
                source: "all",
                dateRange: "1month",
                limit: "50"
              };
            
              dispatch(onGetTop10Posts(params));

            //   const thelatestparams = {
            //     taskId: campaignId,
            //     source: "all",
            //     dateRange: "1month"
            //   };
        
            //   dispatch(onGetTheLatestPosts(thelatestparams));
        }
        catch {
            return <Navigate to="/" />;
        }
        
    }, [dispatch]);

    useEffect(() => {     
        setTop10Post(top10posts);          
    }, [top10posts]);
    
    useEffect(() => {
        if (!isEmpty(top10posts)) {
            setTop10Post(top10posts);        
        }
    }, [top10posts]);
     
    const datefilter = (e) => {
        const date = e.toString().split(" ");
        const joinDate = (date[2] + " " + date[1] + ", " + date[3]).toString();

        let output = icoWidgetsList.filter(item => {
            let opt = item.subItem.some((
                { date }) => date === joinDate);
            return opt;
        });
        setIcoList(output);
    };

    const handleValidDate = date => {
        const date1 = moment(new Date(date)).format("Y-MM-DD");
        return date1;
    };
    
    const handleValidTime = (time) => {
        const time1 = new Date(time);
        const getHour = time1.getUTCHours();
        const getMin = time1.getUTCMinutes();
        const getTime = `${getHour}:${getMin}`;
        const updateTime = moment.utc(getTime, 'HH:mm').format('HH:mm');
        return updateTime;
    };

    const onShowCommentClick = (comments_extra, source) => {
        setIsCommentsDetails(!isCommentsDetails);       
        setCommentsDetail(comments_extra);
        setCommentsDetailSource(source);
    };

    const onShowReactionClick = (reactions, source) => {
        setIsReactionsDetails(!isReactionsDetails);       
        setReactionsDetail(reactions);
    };

    const onShowShareClick = (shares, source) => {
        setIsSharesDetails(!isSharesDetails);       
        setSharesDetail(shares);
    };

    const dateformaterValidFrom = (e) => {
        const date = e.toString().split(" ");
        const joinDate = (date[3] + "-" + date[1] + "-" + date[2]).toString();
        const joined = moment(joinDate).format("YYYY-MM-DD");
        setDateValidFrom(joined);
    };
    
    const dateformaterValidTo = (e) => {
        const date = e.toString().split(" ");
        const joinDate = (date[3] + "-" + date[1] + "-" + date[2]).toString();
        const joined = moment(joinDate).format("YYYY-MM-DD");
        setDateValidTo(joined);
    };

    const sentimentOptions = [
        {
          options: [
            { label: "Нийт", value: "Нийт" },
            { label: "Эерэг", value: "Эерэг" },
            { label: "Сөрөг", value: "Сөрөг" },
            { label: "Саармаг", value: "Саармаг" }
          ],
        },
      ];

    return (
        <React.Fragment>
                <Card>
                    <CardBody>
                        <Form className="search-form" onSubmit={(e) => {
                                e.preventDefault();
                                validation.handleSubmit();
                                return false;
                                }}>                        
                                <Row className="g-3">                                     
                                    <Col xxl={2} lg={4}>
                                            <Flatpickr
                                                name="searchFrom"
                                                id="searchFrom-input"
                                                className="form-control"
                                                placeholder="Эхлэх огноо сонгох"
                                                options={{
                                                    altInput: true,
                                                    altFormat: "Y-m-d",
                                                    dateFormat: "Y-m-d",
                                                }}
                                                value={validation.values.searchFrom || "Огноо сонгох"}
                                                onChange={(e) => {
                                                    dateformaterValidFrom(e);
                                                    validation.setFieldValue("searchFrom", e);
                                                }                                    
                                                }
                                                onBlur={validation.handleBlur} 
                                                invalid={
                                                    validation.touched.searchFrom && validation.errors.searchFrom ? true : false
                                                }                       
                                                />
                                                {validation.touched.searchFrom && validation.errors.searchFrom ? (
                                                    <FormFeedback type="invalid">{validation.errors.searchFrom}</FormFeedback>
                                                ) : null}    
                                    </Col>                          
                                    <Col xxl={2} lg={4}>                                        

                                        <Flatpickr
                                            name="searchTo"
                                            id="searchTo-input"
                                            className="form-control"
                                            placeholder="Дуусах огноо сонгох"
                                            options={{
                                                altInput: true,
                                                altFormat: "Y-m-d",
                                                dateFormat: "Y-m-d",
                                            }}
                                            value={validation.values.searchTo || "Огноо сонгох"}
                                            onChange={(e) => {
                                                dateformaterValidTo(e);
                                                validation.setFieldValue("searchTo", e);
                                            }                                    
                                            }
                                            onBlur={validation.handleBlur}
                                            invalid={
                                                validation.touched.searchTo && validation.errors.searchTo ? true : false
                                            }                              
                                            /> 
                                            {validation.touched.searchTo && validation.errors.searchTo ? (
                                                <FormFeedback type="invalid">{validation.errors.searchTo}</FormFeedback>
                                            ) : null}  
                                    </Col>
                                    <Col xxl={1} lg={4}>
                                        <Input
                                            name="sentiment"
                                            type="select"
                                            className="form-select"
                                            id="sentiment-field"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={
                                            validation.values.sentiment || "Нийт"
                                            }                                            
                                        >
                                            {sentimentOptions.map((item, key) => (
                                            <React.Fragment key={key}>
                                                {item.options.map((item, key) => (<option value={item.value} key={key}>{item.label}</option>))}
                                            </React.Fragment>
                                            ))}
                                        </Input>
                                    </Col>                            
                                    <Col xxl={1} lg={4}>
                                        <button className="btn btn-primary w-100"><i className="ri-equalizer-line align-bottom me-1"></i> Шүүж харах</button>
                                    </Col>
                                </Row>
                        </Form>
                    </CardBody>
                </Card>
                <Row
                    className="row-cols-xxl-5 row-cols-xl-4 row-cols-lg-3 row-cols-md-2 row-cols-1"
                >
                    {isTop10PostSuccess && top10Post ? top10Post.length > 0 && top10Post.map((item, key) => (<Col className="list-element" key={key}>
                        <Card className="explore-box card-animate border ribbon-box border shadow-none right">                                                                                          
                            <CardBody>                                          
                            <div className="d-flex align-items-center mb-3">                                
                                {item.source === "facebook" && <img src={item.user.profileImageUrl || "https://firebasestorage.googleapis.com/v0/b/mindx-384a6.appspot.com/o/logos%2Ffb-user.png?alt=media&token=327ea65e-e726-414c-a3d9-bc159242c2c9"} alt="" className="avatar-xs rounded-circle" />}
                                {item.source === "twitter" && <img src={item.user.profileImageUrl} alt="" className="avatar-xs rounded-circle" />}
                                {
                                    item.source === "news" && <img src={item.user.profileImageUrl} alt="" className="avatar-xs rounded-circle" />
                                }
                                {item.source === "instagram" && <img src={item.user.profileImageUrl || "https://firebasestorage.googleapis.com/v0/b/mindx-384a6.appspot.com/o/logos%2Fig-user.png?alt=media&token=d99f33ae-7589-46e7-aade-6bc8d45955cc"} alt="" className="avatar-xs rounded-circle" />}
                                <div className="ms-2 flex-grow-1">                                   
                                    {item.source === "twitter" ? 
                                            <React.Fragment>
                                                <h6 className="fs-15 mb-1">{item.user.name}</h6>
                                            </React.Fragment>
                                        : <React.Fragment>
                                                <h6 className="fs-15 mb-1">{item.user.username}</h6> 
                                        </React.Fragment>                                                        
                                        }                                        
                                    <p className="mb-0 text-muted">
                                    <i className="ri-time-line align-bottom"></i> 
                                    {item.date.split('T')[0]},{" "}
                                    <small className="text-muted">{handleValidTime(item.date)}</small>
                                    </p>
                                </div>
                                <div className="bookmark-icon">
                                    
                                    <Link to={item.url} target="_blank" className="badge badge-soft-primary"><i className="ri-arrow-right-up-line align-bottom"></i></Link>
                                </div>
                            </div>
                            <div className="explore-place-bid-img overflow-hidden rounded">                                
                                {
                                    item.media.image && (item.source === "facebook" || item.source === "instagram") ? <img src={"https://datachain.art/api/images/DataChainMedia/" + item.media.image} alt="" className="img-fluid explore-img" />
                                    : item.media.image && item.media.image != "[]" &&
                                    item.media.image.map((value, key) => ( <img key={key} src={value} alt="" className="img-fluid explore-img"  /> ))
                                }                                                                  
                            </div>
                            <div className="mt-3">                                                                                                                               
                                <h5 className="mb-1">
                                    <Link to={item.url} target="_blank">{item.title}</Link>
                                </h5>
                                <h6 className="mb-1">
                                        {(item.source === "facebook" || item.source === "twitter" || item.source === "instagram") &&
                                            <ShowMoreText
                                                    lines={5}
                                                    more={<span style={{ color: "#0b71b9" }}>Цааш...</span>}
                                                    less={<span style={{ color: "#0b71b9" }}>Хураах</span>}
                                                    className="content-css"
                                                    anchorClass="my-anchor-css-class"
                                                    expanded={false}
                                                    width={250}
                                                    >
                                                    <p>{item.text}</p>
                                            </ShowMoreText> 
                                        }
                                        {item.source === "news" &&
                                            <div className="project-details" dangerouslySetInnerHTML={{ __html: item.text.length > 420 ? item.text.substring(0, 420) + "... " : item.text }} /> 
                                        }
                                </h6>                                                                
                            </div>
                            </CardBody>
                            <div className="card-footer border-top border-top-dashed">
                                <div className="d-flex align-items-center">
                                    <div className="flex-grow-1 fs-14">
                                        <i className="ri-price-tag-3-fill text-warning align-bottom me-1"></i>                                        
                                        <span className={`badge badge-soft-${
                                                item.source === "facebook"
                                                ? "primary"
                                                : item.source === "twitter"
                                                ? "info"
                                                : item.source === "instagram"
                                                ? "danger"
                                                : "warning"
                                            }`}>
                                            {item.source === "facebook"
                                                ? "facebook"
                                                : item.source === "twitter"
                                                ? "x/twitter"
                                                : item.source === "instagram"
                                                ? "instagram"
                                                : "мэдээний сайт"
                                            }</span>                                           
                                    </div>
                                    <Rating
                                        initialRating={item.sent_reaction === "Эерэг" ? 5 : item.sent_reaction === "Сөрөг" ? 1.5: 3.5}
                                        fractions={2}
                                        emptySymbol="mdi mdi-star-outline text-muted "
                                        fullSymbol="mdi mdi-star text-warning "
                                        
                                    />
                                </div>
                            </div>
                                            <CardBody className="border-top border-top-dashed">
                                                <div className="d-flex">
                                                    <div className="flex-grow-1">
                                                        {
                                                            item.engagements.sharers && item.engagements.sharers.length > 0 ? 
                                                            <button type="button" className="btn btn-soft-success" onClick={() => onShowShareClick(item.engagements.sharers && item.engagements.sharers, item.source)}>
                                                                <h6 className="mb-0">{item.engagements.shares}<i className="las la-retweet align-bottom text-success"></i></h6>
                                                            </button>  
                                                            : <button type="button" className="btn btn-white">
                                                                <h6 className="mb-0">{item.engagements.shares}<i className="las la-retweet align-bottom text-success"></i></h6>
                                                            </button>  
                                                        } 
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        {
                                                            item.engagements.comments_extra && item.engagements.comments_extra.length > 0 ? 
                                                            <button type="button" className="btn btn-soft-success" onClick={() => onShowCommentClick(item.engagements.comments_extra && item.engagements.comments_extra, item.source)}>
                                                                <h6 className="mb-0">{item.engagements.comments}<i className="bx bx-comment-detail align-bottom text-info"></i></h6>
                                                            </button>  
                                                            : <button type="button" className="btn btn-white" >
                                                                <h6 className="mb-0">{item.engagements.comments}<i className="bx bx-comment-detail align-bottom text-info"></i></h6>
                                                            </button> 
                                                        }  
                                                    </div>
                                                    <div className="flex-grow-1">                                                        
                                                        {
                                                            item.engagements.reactors && item.engagements.reactors.length > 0 ? 
                                                            <button type="button" className="btn btn-soft-success" onClick={() => onShowReactionClick(item.engagements.reactors && item.engagements.reactors, item.engagements.reactions, item.source)}>
                                                                <h6 className="mb-0">{item.engagements.likes}<i className="bx bxs-like align-bottom text-primary"></i></h6>
                                                            </button>  
                                                            : <button type="button" className="btn btn-white">
                                                                <h6 className="mb-0">{item.engagements.likes}<i className="bx bxs-like align-bottom text-primary"></i></h6>
                                                            </button> 
                                                        } 
                                                    </div>  
                                                    {item.source === "twitter" && <React.Fragment>
                                                                    <div className="flex-grow-1">
                                                                        <button type="button" className="btn btn-white">
                                                                            <h6 className="mb-0">{item.engagements.quoteCount}<i className="bx bxs-quote-alt-left align-bottom text-primary"></i></h6>
                                                                        </button>                                                                         
                                                                    </div> 
                                                                    <div className="flex-grow-1">
                                                                        <button type="button" className="btn btn-white">
                                                                            <h6 className="mb-0">{item.speadCount}<i className="ri-bar-chart-fill align-bottom text-primary"></i></h6>
                                                                        </button>                                                                        
                                                                    </div>
                                                                    </React.Fragment>
                                                    }                                                
                                                </div>
                                            </CardBody>
                            
                                            {item.engagements.reactions && (item.source == "facebook" || item.source == "news") &&
                                                           <div className="card-footer border-top border-top-dashed">                                                                
                                                                <div className="d-flex align-items-center">  
                                                                    <div className="flex-grow-1">
                                                                        <h6 className="mb-0">{item.engagements.reactions.like || 0}<img className="title" src={like} alt=""  style={{ width: "20px", height: "20px" }} /></h6>                                               
                                                                    </div>   
                                                                    <div className="flex-grow-1">
                                                                        <h6 className="mb-0">{item.engagements.reactions.love || 0}<img className="title" src={love} alt=""  style={{ width: "20px", height: "20px" }} /></h6>                                               
                                                                    </div> 
                                                                    <div className="flex-grow-1">
                                                                        <h6 className="mb-0">{item.engagements.reactions.care || 0}<img className="title" src={care} alt=""  style={{ width: "20px", height: "20px" }} /></h6>                                               
                                                                    </div> 
                                                                    <div className="flex-grow-1">
                                                                        <h6 className="mb-0">{item.engagements.reactions.haha || 0}<img className="title" src={haha} alt=""  style={{ width: "20px", height: "20px" }} /></h6>                                               
                                                                    </div> 
                                                                    <div className="flex-grow-1">
                                                                        <h6 className="mb-0">{item.engagements.reactions.wow || 0}<img className="title" src={wow} alt=""  style={{ width: "20px", height: "20px" }} /></h6>                                               
                                                                    </div> 
                                                                    <div className="flex-grow-1">
                                                                        <h6 className="mb-0">{item.engagements.reactions.sad || 0}<img className="title" src={sad} alt=""  style={{ width: "20px", height: "20px" }} /></h6>                                               
                                                                    </div> 
                                                                    <div className="flex-grow-1">
                                                                        <h6 className="mb-0">{item.engagements.reactions.angry || 0}<img className="title" src={angry} alt=""  style={{ width: "20px", height: "20px" }} /></h6>                                               
                                                                    </div> 
                                                                </div>                                                                                                                                                                    
                                                            </div>   
                                                        }        
                                                        
                                                                                     
                        </Card>
                    </Col>)) : <Spinner color="primary"> Loading... </Spinner>}
                </Row>

                <Offcanvas
                        direction="end"
                        isOpen={isCommentsDetails}
                        id="offcanvascomments"
                        toggle={() => setIsCommentsDetails(false)}
                        onHide={toggleOffComments}
                        >
                        <OffcanvasHeader className="bg-light" toggle={() => setIsCommentsDetails(false)}>
                            Сэтгэгдэл
                        </OffcanvasHeader>
                        <form action="" className="d-flex flex-column justify-content-end h-100">
                            <OffcanvasBody>                                        
                            <div className="mb-4">                    

                            {commentsDetail && commentsDetail.map((item, key) => (     
                                commentsDetailSource && commentsDetailSource === "news" ?                                   
                                                <Card className="border border-dashed shadow-none" key={key}>
                                                    <CardBody>
                                                        <div className="d-flex">
                                                            <div className="flex-shrink-0 avatar-sm">
                                                                <div className="avatar-title bg-light rounded">
                                                                    <img src={dummyImg} alt="" height="30" />
                                                                </div>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <h5 className="fs-15 mb-1">{item.commentor_name}</h5> 
                                                                <h6 className={"flex-shrink-0 mb-0"}><i className="ri-time-line align-bottom"></i> 
                                                                {item.commentor_date}</h6>                                                       
                                                            </div>                                                    
                                                            {/* <div>
                                                                <Link to={item.url} target="_blank" className="badge badge-soft-primary"><i className="ri-arrow-right-up-line align-bottom"></i></Link>
                                                            </div> */}
                                                        </div>                                        
                                                    </CardBody>
                                                    <CardBody className="border-top border-top-dashed">
                                                        <div className="d-flex">                                                  
                                                            <div className="flex-grow-1">
                                                                <p className="text-muted mb-1 fst-italic">
                                                                    {item.commentor_text}
                                                                </p>
                                                            </div>                                                                                                                                                
                                                        </div>
                                                    </CardBody>
                                                </Card> :
                                commentsDetailSource && commentsDetailSource === "facebook" &&                                   
                                            <Card className="border border-dashed shadow-none" key={key}>
                                                <CardBody>
                                                    <div className="d-flex">
                                                        <div className="flex-shrink-0 avatar-sm">
                                                            <div className="avatar-title bg-light rounded">
                                                                <img src={dummyImg} alt="" height="30" />
                                                            </div>
                                                        </div>
                                                        <div className="flex-grow-1 ms-3">
                                                            <h5 className="fs-15 mb-1">{item.commenter_name}</h5> 
                                                            <h6 className={"flex-shrink-0 mb-0"}><i className="ri-time-line align-bottom"></i> 
                                                            {item.comment_time}</h6>                                                       
                                                        </div>                                                    
                                                        <div>
                                                            <Link to={item.commenter_url} target="_blank" className="badge badge-soft-primary"><i className="ri-arrow-right-up-line align-bottom"></i></Link>
                                                        </div>
                                                    </div>                                        
                                                </CardBody>
                                                <CardBody className="border-top border-top-dashed">
                                                    <div className="d-flex">                                                  
                                                        <div className="flex-grow-1">
                                                            <p className="text-muted mb-1 fst-italic">
                                                                {item.comment_text}
                                                            </p>
                                                        </div>                                                                                                                                                
                                                    </div>
                                                </CardBody>
                                            </Card>

                                            ))}

                            </div>
                            </OffcanvasBody>
                            <div className="offcanvas-footer border-top p-3 text-center hstack gap-2">                                       
                            </div>
                        </form>
                        </Offcanvas>

                        <Offcanvas
                        direction="end"
                        isOpen={isReactionsDetails}
                        id="offcanvasreactions"
                        toggle={() => setIsReactionsDetails(false)}
                        onHide={toggleOffReactions}
                        >
                        <OffcanvasHeader className="bg-light" toggle={() => setIsReactionsDetails(false)}>
                            Reactions
                        </OffcanvasHeader>
                        <form action="" className="d-flex flex-column justify-content-end h-100">
                            <OffcanvasBody>                                        
                            <div className="mb-4">                    

                            {reactionsDetail && reactionsDetail.map((item, key) => (                                       
                                                <Card className="border border-dashed shadow-none" key={key}>
                                                    <CardBody>
                                                        <div className="d-flex">
                                                            <div className="flex-shrink-0 avatar-sm">
                                                                <div className="avatar-title bg-light rounded">
                                                                    <img src={dummyImg} alt="" height="30" />
                                                                </div>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <h5 className="fs-15 mb-1">{item.name}</h5> 
                                                                <h6 className={"flex-shrink-0 mb-0"}><i className="ri-time-line align-bottom"></i> 
                                                                {item.commentor_date}</h6>                                                       
                                                            </div>                                                    
                                                            <div>
                                                                <Link to={item.link} target="_blank" className="badge badge-soft-primary"><i className="ri-arrow-right-up-line align-bottom"></i></Link>
                                                            </div>
                                                        </div>                                        
                                                    </CardBody>
                                                    <CardBody className="border-top border-top-dashed">
                                                        <div className="d-flex">                                                  
                                                            <div className="flex-grow-1">
                                                                <p className="text-muted mb-1 fst-italic">
                                                                    <img className="title" src={detectReactions(item.type && item.type)} alt=""  style={{ width: "22px", height: "22px" }} />
                                                                </p>
                                                            </div>                                                                                                                                                
                                                        </div>
                                                    </CardBody>
                                                </Card> 

                                            ))}

                            </div>
                            </OffcanvasBody>
                            <div className="offcanvas-footer border-top p-3 text-center hstack gap-2">                                       
                            </div>
                        </form>
                        </Offcanvas>

                        <Offcanvas
                        direction="end"
                        isOpen={isSharesDetails}
                        id="offcanvasreactions"
                        toggle={() => setIsSharesDetails(false)}
                        onHide={toggleOffShares}
                        >
                        <OffcanvasHeader className="bg-light" toggle={() => setIsSharesDetails(false)}>
                            Shares
                        </OffcanvasHeader>
                        <form action="" className="d-flex flex-column justify-content-end h-100">
                            <OffcanvasBody>                                        
                            <div className="mb-4">                    

                            {sharesDetail && sharesDetail.map((item, key) => (                                       
                                                <Card className="border border-dashed shadow-none" key={key}>
                                                    <CardBody>
                                                        <div className="d-flex">
                                                            <div className="flex-shrink-0 avatar-sm">
                                                                <div className="avatar-title bg-light rounded">
                                                                    <img src={dummyImg} alt="" height="30" />
                                                                </div>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <h5 className="fs-15 mb-1">{item.name}</h5>                                                                                                            
                                                            </div>                                                    
                                                            <div>
                                                                <Link to={item.link} target="_blank" className="badge badge-soft-primary"><i className="ri-arrow-right-up-line align-bottom"></i></Link>
                                                            </div>
                                                        </div>                                        
                                                    </CardBody>
                                                </Card> 

                                            ))}

                            </div>
                            </OffcanvasBody>
                            <div className="offcanvas-footer border-top p-3 text-center hstack gap-2">                                       
                            </div>
                        </form>
                        </Offcanvas>
        </React.Fragment>
    );
};

export default TopPosts;